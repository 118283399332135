import React from "react";
import { useNavigate } from "react-router-dom";

/* comp imports */

const Newsroom = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="comm-section white-color-section speckle-bg">
          <div className="section-overlay-dark"></div>
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap text-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        newsroom, coming soon...
                      </h3>

                      <div className="section-content pay-page">
                        be ready for exciting news and updates!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
    </>
  );
};

export default Newsroom;
