import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl, endPoints } from "../../data/endPoints";

const CareerForm = () => {
  const location = useLocation();
  const [pathName, setPathName] = useState("");
  const [applyingFor, setApplyingFor] = useState("");

  useEffect(() => {
    setPathName(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (pathName != "") {
      let tempArr = pathName.split("/");
      let tempText = tempArr[tempArr.length - 1];
      setApplyingFor(tempText.replace("-", " "));
    }
  }, [pathName]);

  const [careerMainData, setCareerMainData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showThankyou, setShowThankyou] = useState(false);

  const careerMainDataDefault = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    applyingFor: applyingFor,
  };

  useEffect(() => {
    setCareerMainData(careerMainDataDefault);
  }, [applyingFor]);

  const inputsHandler = (e, fileNameElem) => {
    const { name, value, files } = e.target;
    let inputElem = files ? files[0] : "";
    setCareerMainData((careerMainData) => ({
      ...careerMainData,
      [name]: files ? inputElem : value,
    }));

    if (fileNameElem) {
      let fileName = document.getElementById(`fileName${fileNameElem}`);
      fileName.innerText = inputElem.name;
    }
  };

  const validateEmail = (email) => {
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;
    return regex.test(email);
  };

  const validateMobileNumber = (number) => {
    let numRegex = new RegExp(/^\d{10}$/);
    return numRegex.test(number);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("firstName", careerMainData.firstName);
    data.append("lastName", careerMainData.lastName);
    data.append("phone", careerMainData.phone);
    data.append("email", careerMainData.email);
    data.append("applyingFor", careerMainData.applyingFor);

    data.append("coverLetterFile", careerMainData.coverLetterFile);
    data.append("resumeFile", careerMainData.resumeFile);

    let config = {
      method: "post",
      url: `${baseUrl}${endPoints.careerApplication}`,
      headers: {},
      data: data,
    };

    try {
      setErrorMessage("");
      if (
        careerMainData.firstName != "" &&
        careerMainData.lastName != "" &&
        careerMainData.phone != "" &&
        careerMainData.email != ""
      ) {
        let numberValidated = validateMobileNumber(careerMainData.phone);
        if (numberValidated) {
          let emailValidated = validateEmail(careerMainData.email);
          if (emailValidated) {
            const response = await axios.request(config);
            if (response && response.data.status) {
              setShowThankyou(true);
              setCareerMainData(careerMainDataDefault);
            } else {
              console.log(response.data.message);
              setErrorMessage("Please try again later");
            }
          } else {
            setErrorMessage("Please enter a valid email id");
          }
        } else {
          setErrorMessage("phone number should be a 10 digit number");
        }
      } else {
        setErrorMessage("Please enter all required fields");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Please try again later");
    }
  };

  console.log("careerMainData", careerMainData);

  return (
    <>
      <div className="form-wrapper">
        <div className="form-title"></div>
        <div className="form-sub-title">
          {showThankyou ? "" : "fill in below form to apply."}
        </div>
        <span className="error-text">{errorMessage}</span>
        {showThankyou ? (
          <>
            <h4 className="mt-5">thank you for applying.</h4>
            <h5 className="mb-5">
              shortlisted candidates will be contacted shortly!
            </h5>
          </>
        ) : (
          <form onSubmit={handleFormSubmit}>
            <div className="form-group-wrapper d-flex">
              <Form.Group className="mb-3 main-form-group">
                <Form.Control
                  type="text"
                  placeholder="first name*"
                  name="firstName"
                  required
                  maxLength="20"
                  onChange={(e) => {
                    inputsHandler(e, "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 main-form-group">
                <Form.Control
                  type="text"
                  placeholder="last name*"
                  name="lastName"
                  required
                  maxLength="20"
                  onChange={(e) => {
                    inputsHandler(e, "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 main-form-group">
                <Form.Control
                  type="text"
                  placeholder="phone number*"
                  name="phone"
                  required
                  maxLength="10"
                  onChange={(e) => {
                    inputsHandler(e, "");
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 main-form-group">
                <Form.Control
                  type="email"
                  placeholder="email address*"
                  name="email"
                  required
                  maxLength="100"
                  onChange={(e) => {
                    inputsHandler(e, "");
                  }}
                />
              </Form.Group>

              <Form.Group
                controlId="coverLetterFile"
                className="mb-3 main-form-group"
              >
                <label
                  htmlFor="coverLetterFile"
                  className="custom-file-upload-label"
                >
                  <span className="material-symbols-outlined">
                    picture_as_pdf
                  </span>
                  <Form.Control
                    type="file"
                    placeholder="upload cover letter"
                    accept="application/pdf"
                    name="coverLetterFile"
                    onChange={(e) => {
                      inputsHandler(e, "CoverLetter");
                    }}
                  />
                  <span id="fileNameCoverLetter">Cover Letter</span>
                </label>
              </Form.Group>

              <Form.Group
                controlId="resumeFile"
                className="mb-3 main-form-group"
              >
                <label
                  htmlFor="resumeFile"
                  className="custom-file-upload-label"
                >
                  <span className="material-symbols-outlined">
                    picture_as_pdf
                  </span>
                  <Form.Control
                    type="file"
                    placeholder="upload resume"
                    accept="application/pdf"
                    name="resumeFile"
                    onChange={(e) => {
                      inputsHandler(e, "Resume");
                    }}
                  />
                  <span id="fileNameResume">Resume</span>
                </label>
              </Form.Group>
            </div>

            <div>
              <button className="starry-btn mt-3" type="submit">
                <span className="btn-text">apply</span>
                <div id="container-stars">
                  <div id="stars"></div>
                </div>

                <div id="glow">
                  <div className="circle"></div>
                  <div className="circle"></div>
                </div>
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default CareerForm;
