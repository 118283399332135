import React, { useState, useRef, useEffect } from "react";
import PhantomFind from "../components/games/PhantomFind";
import NeonLagoon from "../components/games/NeonLagoon";
// import Whirlpool from "../components/games/Whirlpool";
import MeteorRise from "../components/games/MeteorRise";
import PixelBrain from "../components/games/PixelBrain";
// import { gsap, Sine } from "gsap";
import { motion } from "framer-motion";

/* comp imports */
import Vouchers from "../components/Vouchers";

/* video imports */
import starfield from "../assets/img/video-assets/starfield.mp4";

/* img imports */
import neonLagoonName from "../assets/img/gaming/neon-lagoon-name.png";
import meteorRiseName from "../assets/img/gaming/meteor-rise-name.png";
import phantomFindName from "../assets/img/gaming/phantom-find-name.png";
import pixelBrainName from "../assets/img/gaming/pixel-brain-name.png";
import neonLagoonNameDim from "../assets/img/gaming/neon-lagoon-name-dim.png";
import meteorRiseNameDim from "../assets/img/gaming/meteor-rise-name-dim.png";
import phantomFindNameDim from "../assets/img/gaming/phantom-find-name-dim.png";
import pixelBrainNameDim from "../assets/img/gaming/pixel-brain-name-dim.png";
import coinIconSmall from "../assets/img/coin-icon-small.png";

const Rewards = () => {
  const videoRefStarfield = useRef(undefined);

  useEffect(() => {
    if (activeGame === 3) {
      videoRefStarfield.current.defaultMuted = true;
    }
  });

  const [rewardsAmount, setRewardsAmount] = useState(0);
  const [showRewardsNlWon, setShowRewardsNlWon] = useState(false);
  const [showRewardsPfWon, setShowRewardsPfWon] = useState(false);
  const [showRewardsMrWon, setShowRewardsMrWon] = useState(false);
  const [showRewardsPbWon, setShowRewardsPbWon] = useState(false);

  const [activeGame, setActiveGame] = useState(1);
  const [activeGameBg, setActiveGameBg] = useState("neon-lagoon-bg");

  const [activeGameArr, setActiveArr] = useState([true, true, true, true]);

  const handleChangeGame = (gameNum, gameBg) => {
    for (let i = 1; i < 5; i++) {
      if (i == gameNum) {
        setActiveGame(i);
      }
    }
    setActiveGameBg(gameBg);
  };

  // gsap.to("#rewardsBannerSection, .scrolling-text-wrapper", {
  //   duration: 3,
  //   delay: 5,
  //   height: "0vh",

  //   opacity: 0,
  //   ease: Sine.easeOut,
  // });

  const exitGameAnimation = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5, delay: 0.1 },
    },
    hidden: {
      opacity: 0,
      x: -400,
    },
  };

  return (
    <>
      <main>
        <section className="comm-section white-color-section speckle-bg rewards-main-bg-section">
          <div className="shape right"></div>

          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">rewards</h3>
                      <div className="section-content pay-page">
                        win rewards allday. everyday.
                      </div>
                    </div>
                  </div>

                  <Vouchers />
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>

        {/* end of section main */}

        <section className="content-section white-color-section gaming-section">
          <div className={`blurry-bg ${activeGameBg}`}></div>
          {/* <div className="shape right"></div> */}
          <div className="container-md">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <div className="game-names-wrapper">
                      {activeGameArr[0] ? (
                        <div
                          className={`neon-lagoon-name ${
                            activeGame == 1 ? "active" : ""
                          }`}
                          onClick={() => {
                            handleChangeGame(1, "neon-lagoon-bg");
                          }}
                        >
                          <img src={neonLagoonName} alt="" />
                        </div>
                      ) : (
                        <div className={`neon-lagoon-name game-name-dim`}>
                          <img src={neonLagoonNameDim} alt="" />
                        </div>
                      )}

                      {/* <div
                        className=""
                        onClick={() => {
                          handleChangeGame(2);
                        }}
                      >
                        whirlpool
                      </div> */}
                      {/* ---- */}
                      {activeGameArr[1] ? (
                        <div
                          className={`phantom-find-name ${
                            activeGame == 2 ? "active" : ""
                          }`}
                          onClick={() => {
                            handleChangeGame(2, "phantom-find-bg");
                          }}
                        >
                          <img src={phantomFindName} alt="" />
                        </div>
                      ) : (
                        <div className={`phantom-find-name game-name-dim`}>
                          <img src={phantomFindNameDim} alt="" />
                        </div>
                      )}
                      {/* ----- */}

                      {activeGameArr[2] ? (
                        <div
                          className={`meteor-rise-name ${
                            activeGame == 3 ? "active" : ""
                          }`}
                          onClick={() => {
                            handleChangeGame(3, "meteor-rise-bg");
                          }}
                        >
                          <img src={meteorRiseName} alt="" />
                        </div>
                      ) : (
                        <div className={`meteor-rise-name game-name-dim`}>
                          <img src={meteorRiseNameDim} alt="" />
                        </div>
                      )}
                      {/* ------ */}
                      {activeGameArr[3] ? (
                        <div
                          className={`pixel-brain-name ${
                            activeGame == 4 ? "active" : ""
                          }`}
                          onClick={() => {
                            handleChangeGame(4, "pixel-brain-bg");
                          }}
                        >
                          <img src={pixelBrainName} alt="" />
                        </div>
                      ) : (
                        <div className={`pixel-brain-name game-name-dim`}>
                          <img src={pixelBrainNameDim} alt="" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-8 game-col">
                    {activeGame === 1 && (
                      <motion.div
                        className="game-wrapper neon-lagoon-main-bg"
                        key="nlblk"
                        initial="hidden"
                        animate="visible"
                        variants={exitGameAnimation}
                      >
                        <>
                          {showRewardsNlWon && (
                            <div className="rewards-won">
                              <span> you have got</span>
                              <span className="img-blk">
                                <img
                                  src={coinIconSmall}
                                  alt="chaching coins"
                                  title="chaching coins"
                                />{" "}
                              </span>
                              <span>{rewardsAmount}</span>
                            </div>
                          )}
                          <div className="game-placeholder neon-lagoon-placeholder"></div>
                          <div className="actual-game neon-lagoon">
                            <NeonLagoon
                              setRewardsAmount={setRewardsAmount}
                              setShowRewardsNlWon={setShowRewardsNlWon}
                              setActiveArr={setActiveArr}
                            />
                          </div>
                        </>
                      </motion.div>
                    )}

                    {activeGame === 2 && (
                      <motion.div
                        className="game-wrapper phantom-find-main-bg"
                        key="pfblk"
                        initial="hidden"
                        animate="visible"
                        variants={exitGameAnimation}
                      >
                        <>
                          {showRewardsPfWon && (
                            <div className="rewards-won">
                              <span> you have got</span>
                              <span className="img-blk">
                                <img
                                  src={coinIconSmall}
                                  alt="chaching coins"
                                  title="chaching coins"
                                />{" "}
                              </span>
                              <span>{rewardsAmount}</span>
                            </div>
                          )}
                          <div className="actual-game phantom-find">
                            <PhantomFind
                              setRewardsAmount={setRewardsAmount}
                              setShowRewardsPfWon={setShowRewardsPfWon}
                              setActiveArr={setActiveArr}
                            />
                          </div>
                        </>
                      </motion.div>
                    )}
                    {activeGame === 3 && (
                      <motion.div
                        className="game-wrapper meteor-rise-main-bg"
                        key="mrblk"
                        initial="hidden"
                        animate="visible"
                        variants={exitGameAnimation}
                      >
                        <>
                          {showRewardsMrWon && (
                            <div className="rewards-won">
                              <span> you have got</span>
                              <span className="img-blk">
                                <img
                                  src={coinIconSmall}
                                  alt="chaching coins"
                                  title="chaching coins"
                                />{" "}
                              </span>
                              <span>{rewardsAmount}</span>
                            </div>
                          )}
                          <div className="game-placeholder meteor-rise-placeholder">
                            <video
                              preload="true"
                              muted
                              autoPlay
                              loop
                              playsInline
                              ref={videoRefStarfield}
                              // poster={everyPaymentCountPoster}
                            >
                              <source src={starfield} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                            {/* <Lottie
                            options={defaultOptions}
                            className="lottieVideo"
                          /> */}
                          </div>
                          <div className="actual-game meteor-rise">
                            <MeteorRise
                              setRewardsAmount={setRewardsAmount}
                              setShowRewardsMrWon={setShowRewardsMrWon}
                              setActiveArr={setActiveArr}
                            />
                          </div>
                        </>
                      </motion.div>
                    )}
                    {activeGame === 4 && (
                      <motion.div
                        className="game-wrapper pixel-brain-main-bg"
                        key="pbblk"
                        initial="hidden"
                        animate="visible"
                        variants={exitGameAnimation}
                      >
                        <>
                          {showRewardsPbWon && (
                            <div className="rewards-won">
                              <span> you have got</span>
                              <span className="img-blk">
                                <img
                                  src={coinIconSmall}
                                  alt="chaching coins"
                                  title="chaching coins"
                                />{" "}
                              </span>
                              <span>{rewardsAmount}</span>
                            </div>
                          )}
                          <div className="actual-game pixel-brain">
                            <PixelBrain
                              setRewardsAmount={setRewardsAmount}
                              setShowRewardsPbWon={setShowRewardsPbWon}
                              setActiveArr={setActiveArr}
                            />
                          </div>
                        </>
                      </motion.div>
                    )}
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default Rewards;
