import React from "react";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";
import ConciergeMain from "../components/ConciergeMain";

const Concierge = () => {
  return (
    <>
      <ConciergeMain />
    </>
  );
};

export default Concierge;
