import React from "react";

const CurrencyList = ({ currencyIcons, setPayBtnBgClass }) => {
  const changeActiveColorHandler = (bgColor, e) => {
    setPayBtnBgClass(bgColor);

    let elements = document.querySelectorAll(".curr-item");
    let clickedElem = e.target;

    for (let i = 0; i < elements.length; i++) {
      let curElem = elements[i];
      curElem.classList.contains("active") &&
        curElem.classList.remove("active");
    }
    clickedElem.parentElement.classList.add("active");
  };

  return (
    <>
      <span
        className="curr-item"
        onClick={(e) => {
          changeActiveColorHandler("dollarBg", e);
        }}
      >
        <img src={currencyIcons.dollarIcon} alt="" />
      </span>
      <span
        className="curr-item"
        onClick={(e) => {
          changeActiveColorHandler("desoBg", e);
        }}
      >
        <img src={currencyIcons.desoIcon} alt="" />
      </span>
      <span
        className="curr-item"
        onClick={(e) => {
          changeActiveColorHandler("solanaBg", e);
        }}
      >
        <img src={currencyIcons.solanaIcon} alt="" />
      </span>
      <span
        className="curr-item"
        onClick={(e) => {
          changeActiveColorHandler("eRupeeBg", e);
        }}
      >
        <img src={currencyIcons.rupeeIcon} alt="" />
      </span>
      <span
        className="curr-item"
        onClick={(e) => {
          changeActiveColorHandler("bitcoinBg", e);
        }}
      >
        <img src={currencyIcons.bitcoinIcon} alt="" />
      </span>
      <span
        className="curr-item"
        onClick={(e) => {
          changeActiveColorHandler("commGrayBg", e);
        }}
      >
        <img src={currencyIcons.poundIcon} alt="" />
      </span>
      <span
        className="curr-item"
        onClick={(e) => {
          changeActiveColorHandler("rippleBg", e);
        }}
      >
        <img src={currencyIcons.rippleIcon} alt="" />
      </span>
      <span
        className="curr-item active"
        onClick={(e) => {
          changeActiveColorHandler("commGrayBg", e);
        }}
      >
        <img src={currencyIcons.ethIcon} alt="" />
      </span>
      <span
        className="curr-item"
        onClick={(e) => {
          changeActiveColorHandler("commGrayBg", e);
        }}
      >
        <img src={currencyIcons.nearIcon} alt="" />
      </span>
      <span
        className="curr-item"
        onClick={(e) => {
          changeActiveColorHandler("dodgeCoinBg", e);
        }}
      >
        <img src={currencyIcons.dodgecoinIcon} alt="" />
      </span>
      <span
        className="curr-item"
        onClick={(e) => {
          changeActiveColorHandler("commGrayBg", e);
        }}
      >
        <img src={currencyIcons.dirhamIcon} alt="" />
      </span>
    </>
  );
};

export default CurrencyList;
