import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

/* comp imports */
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Checkout from "./pages/Checkout";
import Pay from "./pages/Pay";
import CheckCreditScore from "./pages/CheckCreditScore";
import BuildCreditHistory from "./pages/BuildCreditHistory";
import CheckoutCounter from "./pages/CheckoutCounter";
import Concierge from "./pages/Concierge";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";
import Discover from "./pages/Discover";
import UiUxDesigner from "./components/careers/UiUxDesigner";
import IosDeveloper from "./components/careers/IosDeveloper";
import BackendDeveloper from "./components/careers/BackendDeveloper";
import FullstackDeveloper from "./components/careers/FullstackDeveloper";
import PublicRelationshipManager from "./components/careers/PublicRelationshipManager";
import PerformanceMarketingSpecialist from "./components/careers/PerformanceMarketingSpecialist";
import StrategicBusinessDevelopment from "./components/careers/StrategicBusinessDevelopment";
import Perks from "./pages/Perks";
import Principles from "./pages/Principles";
import Insights from "./pages/Insights";
import Pride from "./pages/Pride";
import RewardsHourlyPay from "./pages/RewardsHourlyPay";
import Contact from "./pages/Contact";
import ContactSales from "./pages/ContactSales";
import Documents from "./pages/Documents";
import Help from "./pages/Help";
import RefundForm from "./pages/RefundForm";
import Security from "./pages/Security";
import Legal from "./pages/Legal";
import MainWrapper from "./components/MainWrapper";
import MainNavbar from "./components/MainNavbar";
import Footer from "./components/Footer";
import MainCareerNavbar from "./components/careers/MainCareerNavbar";
import FooterCareer from "./components/careers/FooterCareer";
import Personal from "./pages/Personal";
import Rewards from "./pages/Rewards";
import Shopping from "./pages/Shopping";
import CartContextProvider from "./context/CartContextProvider";
import Swap from "./components/swap/Swap";
import UserContextProvider from "./context/UserContextProvider";
import Newsroom from "./pages/Newsroom";
import Refer from "./pages/login/Refer";
import EditProfile from "./pages/login/EditProfile";
import Account from "./pages/login/Account";
import TransactionReport from "./pages/login/TransactionReport";
import Activity from "./pages/login/Activity";
import Settings from "./pages/login/NotificationSettings";
import NotificationSettings from "./pages/login/NotificationSettings";
import CreditScore from "./pages/login/CreditScore";
import LifetimeRewards from "./pages/login/LifetimeRewards";
import InvestorRelations from "./pages/InvestorRelations";
import Log from "./pages/Log";
import Unauthorized from "./pages/Unauthorized";

function App() {
  const location = useLocation();
  const [pathName, setPathName] = useState("");
  const [absolutePathName, setAbsolutePathName] = useState("");
  const normalizePath = (path) => {
    return path.endsWith("/") ? path.slice(0, -1) : path;
  };

  useEffect(() => {
    setPathName(location.pathname);
    let temp = normalizePath(location.pathname).split("/");
    setAbsolutePathName(temp[temp.length - 1]);
    setTimeout(window.scrollTo(0, 0), 100);
  }, [location.pathname]);

  /* swap states */

  const [swapModalshow, setSwapModalShow] = useState(false);
  const [showSwapWallets, setShowSwapWallets] = useState(false);
  const [selectingCurrencyFor, setSelectingCurrencyFor] = useState("");
  const [isWalletSelectedPay, setIsWalletSelectedPay] = useState(false);
  const [isWalletSelectedReceive, setIsWalletSelectedReceive] = useState(false);
  const [showCurrencyDropdownPay, setShowCurrencyDropdownPay] = useState(false);
  const [showCurrencyDropdownReceive, setShowCurrencyDropdownReceive] =
    useState(false);
  const [selectedPayCurrency, setSelectedPayCurrency] = useState({
    img: "ethIcon",
    text: "etherium",
  });
  const [selectedReceiveCurrency, setSelectedReceiveCurrency] = useState({
    img: "solanaIcon",
    text: "solana",
  });
  const [dollarRateConverted, setDollarRateConverted] = useState(0);
  const [receiveCrytoRateConverted, setReceiveCrytoRateConverted] = useState(0);
  const [inputValuePay, setInputValuePay] = useState("");
  const [swapTransactionCost, setSwapTransactionCost] = useState(0);
  const [swapStarted, setSwapStarted] = useState(false);
  const [swapFinalScreen, setSwapFinalScreen] = useState(false);
  const [isConfirmSwap, setIsConfirmSwap] = useState(false);

  /* end swap states */

  const handleSwapClose = () => {
    setSwapModalShow(false);
    setShowSwapWallets(false);
    setSelectingCurrencyFor("");
    setIsWalletSelectedPay(false);
    setIsWalletSelectedReceive(false);
    setShowCurrencyDropdownPay(false);
    setShowCurrencyDropdownReceive(false);
    setDollarRateConverted(0);
    setInputValuePay("");
    setSwapTransactionCost(0);
    setSwapStarted(false);
    setSwapFinalScreen(false);
  };
  const handleSwapModalShow = () => setSwapModalShow(true);
  const handleShowSwapWalletList = (type) => {
    setShowSwapWallets(true);
    setSelectingCurrencyFor(type);
  };
  const handleCloseSwapWalletList = () => setShowSwapWallets(false);

  const handleShowCurrencyDropdown = (type) => {
    if (type === "pay") {
      setShowCurrencyDropdownPay(!showCurrencyDropdownPay);
      setShowCurrencyDropdownReceive(false);
    } else {
      setShowCurrencyDropdownPay(false);
      setShowCurrencyDropdownReceive(!showCurrencyDropdownReceive);
    }
  };

  return (
    <>
      <UserContextProvider swapModalshow={swapModalshow}>
        <MainWrapper>
          {pathName.includes("careers") ? (
            <MainCareerNavbar />
          ) : pathName === "/checkout" ? (
            ""
          ) : (
            <MainNavbar
              isShoppingPage={
                pathName === "/shopping" || pathName === "/rewards-hourly-pay"
                  ? true
                  : false
              }
              handleSwapModalShow={handleSwapModalShow}
              pathName={pathName}
              absolutePathName={absolutePathName}
            />
          )}
          <CartContextProvider>
            <Routes key={location.pathname} location={location}>
              <Route path="/">
                <Route index element={<Home />} />
                <Route path="/pay" element={<Pay />} />
                <Route
                  path="/rewards-hourly-pay"
                  element={<RewardsHourlyPay />}
                />
                <Route
                  path="/check-credit-score"
                  element={<CheckCreditScore />}
                />
                <Route path="/checkout-counter" element={<CheckoutCounter />} />
                <Route path="/concierge" element={<Concierge />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/newsroom" element={<Newsroom />} />
                <Route
                  path="/investor-relations"
                  element={<InvestorRelations />}
                />
                <Route path="/log" element={<Log />} />
                <Route path="/contact-sales" element={<ContactSales />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/careers/discover" element={<Discover />} />
                <Route path="/careers/perks" element={<Perks />} />
                <Route path="/careers/principles" element={<Principles />} />
                <Route path="/careers/insights" element={<Insights />} />
                <Route path="/careers/pride" element={<Pride />} />
                <Route
                  path="/careers/discover/ui-ux-designer"
                  element={<UiUxDesigner />}
                />
                <Route
                  path="/careers/discover/ios-developer"
                  element={<IosDeveloper />}
                />
                <Route
                  path="/careers/discover/backend-developer"
                  element={<BackendDeveloper />}
                />
                <Route
                  path="/careers/discover/fullstack-developer"
                  element={<FullstackDeveloper />}
                />
                <Route
                  path="/careers/discover/public-relationship-manager"
                  element={<PublicRelationshipManager />}
                />
                <Route
                  path="/careers/discover/performance-marketing-specialist"
                  element={<PerformanceMarketingSpecialist />}
                />
                <Route
                  path="/careers/discover/strategic-business-development"
                  element={<StrategicBusinessDevelopment />}
                />
                <Route
                  path="/newsroom/check-credit-score/no-credit-score-history-heres-how-to-build-one"
                  element={<BuildCreditHistory />}
                />
                <Route path="/documents" element={<Documents />} />
                <Route path="/help" element={<Help />} />
                <Route path="/refund-form" element={<RefundForm />} />
                <Route path="/security" element={<Security />} />
                <Route path="/legal" element={<Legal />} />
                <Route path="/personal" element={<Personal />} />
                <Route path="/rewards" element={<Rewards />} />
                <Route path="/shopping" element={<Shopping />} />
                {/* login pages */}
                <Route path="/refer" element={<Refer />} />
                <Route path="/edit-profile" element={<EditProfile />} />
                <Route path="/account" element={<Account />} />
                <Route
                  path="/transaction-report"
                  element={<TransactionReport />}
                />
                <Route path="/activity" element={<Activity />} />
                <Route
                  path="/notification-settings"
                  element={<NotificationSettings />}
                />
                <Route path="/credit-score" element={<CreditScore />} />
                <Route path="/lifetime-rewards" element={<LifetimeRewards />} />

                <Route path="*" exact={true} element={<NotFound />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/checkout" element={<Checkout />} />
              </Route>
            </Routes>
          </CartContextProvider>
          {pathName === "/rewards-hourly-pay" || pathName === "/checkout" ? (
            ""
          ) : pathName.includes("career") ? (
            <FooterCareer />
          ) : (
            <Footer
              isRewardPage={pathName === "/rewards" ? true : false}
              handleSwapModalShow={handleSwapModalShow}
            />
          )}
          <Swap
            swapModalshow={swapModalshow}
            handleSwapClose={handleSwapClose}
            handleShowSwapWalletList={handleShowSwapWalletList}
            handleCloseSwapWalletList={handleCloseSwapWalletList}
            showSwapWallets={showSwapWallets}
            selectingCurrencyFor={selectingCurrencyFor}
            setShowSwapWallets={setShowSwapWallets}
            isWalletSelectedPay={isWalletSelectedPay}
            isWalletSelectedReceive={isWalletSelectedReceive}
            setIsWalletSelectedPay={setIsWalletSelectedPay}
            setIsWalletSelectedReceive={setIsWalletSelectedReceive}
            handleShowCurrencyDropdown={handleShowCurrencyDropdown}
            showCurrencyDropdownPay={showCurrencyDropdownPay}
            showCurrencyDropdownReceive={showCurrencyDropdownReceive}
            selectedPayCurrency={selectedPayCurrency}
            setSelectedPayCurrency={setSelectedPayCurrency}
            setShowCurrencyDropdownPay={setShowCurrencyDropdownPay}
            selectedReceiveCurrency={selectedReceiveCurrency}
            setSelectedReceiveCurrency={setSelectedReceiveCurrency}
            setShowCurrencyDropdownReceive={setShowCurrencyDropdownReceive}
            dollarRateConverted={dollarRateConverted}
            setDollarRateConverted={setDollarRateConverted}
            receiveCrytoRateConverted={receiveCrytoRateConverted}
            setReceiveCrytoRateConverted={setReceiveCrytoRateConverted}
            inputValuePay={inputValuePay}
            setInputValuePay={setInputValuePay}
            swapTransactionCost={swapTransactionCost}
            setSwapTransactionCost={setSwapTransactionCost}
            swapStarted={swapStarted}
            setSwapStarted={setSwapStarted}
            setSelectingCurrencyFor={setSelectingCurrencyFor}
            swapFinalScreen={swapFinalScreen}
            setSwapFinalScreen={setSwapFinalScreen}
            isConfirmSwap={isConfirmSwap}
            setIsConfirmSwap={setIsConfirmSwap}
          />
        </MainWrapper>
      </UserContextProvider>
    </>
  );
}

export default App;
