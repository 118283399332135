import React, { useState, useEffect, useContext } from "react";
import { getCurrentDesoRate } from "../data/storeApiCalls";
import { storeBaseUrl, storeEndpoints } from "../data/endPoints";
import Offcanvas from "react-bootstrap/Offcanvas";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import { CartContext } from "../context/CartContextProvider";

/* img imports */
import dollarIcon from "../assets/img/currency-icons/dollarIcon.svg";
import desoIcon from "../assets/img/currency-icons/desoIcon.svg";

/* comp imports */
import ProductBlock from "../components/shopping/ProductBlock";
import Pagination from "../components/shopping/Pagination";
import ProductListing from "../components/shopping/ProductListing";
import HeadingSort from "../components/shopping/HeadingSort";
import ProductDetails from "../components/shopping/ProductDetails";
import ShoppingHeader from "../components/shopping/ShoppingHeader";
import ShoppingCart from "../components/shopping/ShoppingCart";

const Shopping = () => {
  /* start getting cart info */
  const { dispCartNumber } = useContext(CartContext);

  // console.log("dispCartNumber", dispCartNumber);

  /* end getting cart info */

  const [desoRate, setDesoRate] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [allBrandsSorted, setAllBrandsSorted] = useState([]);
  const [electronicMenuList, setElectronicMenuList] = useState([]);
  const [toysGamesMenuList, setToysGamesMenuList] = useState([]);
  const [allSubMenuList, setAllSubMenuList] = useState([]);
  const [pageNum, setPageNum] = useState("1");
  const [sortBy, setSortBy] = useState("");
  const [catId, setCatId] = useState("1");
  const [defaultParams, setDefaultParams] = useState("");
  const [productList, setProductList] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [menuTabKey, setMenuTabKey] = useState("electronics");
  const [pageHeadDetails, setPageHeadDetails] = useState({
    rootId: 1,
    displayName: "all",
  });
  const [rootCatId, setRootCatId] = useState("");
  const [parentCatId, setParentCatId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCatData, setSearchCatData] = useState([]);
  const [searchProdData, setSearchProdData] = useState([]);
  const [productId, setProductId] = useState(0);
  const [selCartRandomNum, setSelCartRandomNum] = useState(0);
  const [productDetailsData, setProductDetailsData] = useState({});

  /* flags states */
  const [showSort, setShowSort] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [brandShow, setBrandShow] = useState(false);
  const [isProductByFilter, setIsProductByFilter] = useState(false);
  const [showListingtDetails, setShowListingDetails] = useState(true);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [showCartDetails, setShowCartDetails] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  /* default function calls */

  /* getting all categories */

  let getAllCategoriesCallConfig = {
    method: "get",
    url: `${storeBaseUrl}/${storeEndpoints.getAllCategories}`,
    headers: {
      source: "MOBILE_SITE",
      // token: token,
      // userId: userId,
    },
  };

  const getAllCategoriesCall = async () => {
    try {
      const response = await axios.request(getAllCategoriesCallConfig);
      if (response.data.status == "SUCCESS") {
        let categoryList = response.data.response.categoriesMap;
        if (categoryList && Object.keys(categoryList).length > 0) {
          const categoryItemsMaster = Object.values(categoryList).map(
            (property) => {
              return {
                id: property["id"],
                displayName: property["displayName"],
                catlevel: property["catlevel"],
                parentId: property["parentId"],
                parentName: property["parentName"],
                rootId: property["rootId"],
                rootName: property["rootName"],
                name: property["name"],
                seqno: property["seqno"],
              };
            }
          );
          if (categoryItemsMaster.length) {
            setAllCategories(categoryItemsMaster);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* end getting all categories */

  /* getting all brands */

  let getAllBrandsDataConfig = {
    method: "post",
    url: `${storeBaseUrl}/${storeEndpoints.getSearchPageData}`,
    headers: {
      source: "MOBILE_SITE",
      // token: token,
      // userId: userId,
    },
  };

  const getAllBrandsData = async () => {
    try {
      const response = await axios.request(getAllBrandsDataConfig);
      if (response.data.status == "SUCCESS") {
        let tempBrandList =
          response.data.response.prductFiltter.proudctBrandFiltter;
        if (tempBrandList.length) {
          setAllBrands(tempBrandList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* end getting all brands */

  useEffect(() => {
    async function fetchDesoData() {
      try {
        const rate = await getCurrentDesoRate();
        setDesoRate(rate);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchDesoData();
    getAllCategoriesCall();
    getAllBrandsData();
  }, []);

  /* end default function calls */

  /* sorting and bifurcating categories */

  useEffect(() => {
    if (allCategories.length) {
      let electronicItemsMain = [];
      let toysGamesItemsMain = [];
      let allItemsSub = [];

      electronicItemsMain = allCategories.filter((val) => {
        return val.catlevel == 1 && val.parentId == 1;
      });
      toysGamesItemsMain = allCategories.filter((val) => {
        return val.catlevel == 1 && val.parentId == 58;
      });
      allItemsSub = allCategories.filter((val) => {
        return val.catlevel == 2;
      });
      let sortingFunc = (a, b) => {
        const nameA = a.displayName.toUpperCase();
        const nameB = b.displayName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      };
      setElectronicMenuList(electronicItemsMain.sort(sortingFunc));
      setToysGamesMenuList(toysGamesItemsMain.sort(sortingFunc));
      setAllSubMenuList(allItemsSub.sort(sortingFunc));
    }
  }, [allCategories]);

  /* end sorting and bifurcating categories */

  /* sorting all brands data */

  useEffect(() => {
    if (allBrands.length) {
      let sortingFunc = (a, b) => {
        const nameA = a.value.toUpperCase();
        const nameB = b.value.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      };
      setAllBrandsSorted(allBrands.sort(sortingFunc));
    }
  }, [allBrands]);

  /* end sorting all brands data */

  /* 1st page product list call */

  let getSearchPageDataConfig = {
    method: "post",
    url: `${storeBaseUrl}/${storeEndpoints.getSearchPageData}?catId=${catId}`,
    headers: {
      source: "MOBILE_SITE",
      // token: token,
      // userId: userId,
    },
  };

  const getSearchPageData = async () => {
    try {
      const response = await axios.request(getSearchPageDataConfig);
      if (response.data.status == "SUCCESS") {
        let tempProductList = response.data.response.prductList.data;
        // tempProductList = tempProductList.filter((product) => {
        //   return product.isparent == 0;
        // });
        if (tempProductList.length) {
          setProductList(tempProductList);
          setTotalProducts(response.data.response.prductList.recordsTotal);
          setPageNum("1");
          setPageHeadDetails((pageHeadDetails) => ({
            ...pageHeadDetails,
            displayName:
              response.data.response.categoriesMap[catId].displayName,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (catId != "" && !isProductByFilter) {
      getSearchPageData();
    }
  }, [catId]);

  const displayProductListHandler = (e) => {
    setShowSort(false);
    setShowSearch(false);
    setIsProductByFilter(false);
    setSortBy(false);
    setShowListingDetails(true);
    setShowProductDetails(false);
    setShowCartDetails(false);
    let { catid, under, rootcatid, parentcatid } = e.target.dataset;
    if (catid) {
      setCatId(catid);
      setMenuShow(false);
    }
    if (under) {
      setPageHeadDetails((pageHeadDetails) => ({
        ...pageHeadDetails,
        rootId: Number(under),
      }));
    }
    if (rootcatid != "") {
      setRootCatId(rootcatid);
    }
    if (parentcatid != "") {
      setParentCatId(parentcatid);
    }
  };

  /* end 1st page product list call */

  /* start of getting products as per filter */

  let getAllProductsFilteredConfig = {
    method: "get",
    url: `${storeBaseUrl}/${storeEndpoints.getProductByQuery}${defaultParams}`,
    headers: {
      source: "MOBILE_SITE",
      // token: token,
      // userId: userId,
    },
  };

  const getAllProductsFiltered = async () => {
    try {
      const response = await axios.request(getAllProductsFilteredConfig);
      if (response.status) {
        let tempProductList = response.data.data;

        if (tempProductList.length) {
          setProductList(tempProductList);
          setTotalProducts(response.data.recordsTotal);
        }
        setTimeout(window.scrollTo(0, 0), 200);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortByHandler = (e) => {
    setIsProductByFilter(true);
    setShowSort(false);
    let { sort } = e.target.dataset;
    if (sort) {
      setSortBy(sort);
    }
  };

  useEffect(() => {
    if (pageNum || sortBy) {
      let tempDefaultParams = `?filtterReq=false&mnp=&mxp=&sort=${sortBy}&page=${pageNum}&rootCatId=${
        rootCatId != 0 ? rootCatId : ``
      }&parentCatId=${parentCatId != 0 ? parentCatId : ``}&catId=${catId}`;
      setDefaultParams(tempDefaultParams);
    }
  }, [pageNum, sortBy]);

  useEffect(() => {
    if (isProductByFilter) {
      getAllProductsFiltered();
    }
  }, [defaultParams]);

  /* end of getting products as per filter */

  /* start getting search list data */
  const changeSearchTermHandler = (e) => {
    let searchText = e.target.value;
    if (searchText.length) {
      setSearchTerm(searchText);
    } else {
      setSearchTerm("");
    }
  };

  let getSearchBoxDataConfig = {
    method: "post",
    url: `${storeBaseUrl}/${storeEndpoints.autoSearchProdAndCategory}?query=${searchTerm}`,
    headers: {
      source: "MOBILE_SITE",
      // token: token,
      // userId: userId,
    },
  };

  const getSearchBoxData = async () => {
    try {
      const response = await axios.request(getSearchBoxDataConfig);
      if (response.data.status == "SUCCESS") {
        if (response.data.response.CategoryData.length) {
          setSearchCatData(response.data.response.CategoryData);
        }
        if (response.data.response.ProductData.length) {
          setSearchProdData(response.data.response.ProductData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchTerm.length >= 4) {
      getSearchBoxData();
    } else {
      setSearchCatData([]);
      setSearchProdData([]);
    }
  }, [searchTerm]);

  /* end getting search list data */

  /* start getting product details data */

  const handleProductRedirect = (prodId, selCartRandomNum) => {
    // console.log("prodId details", prodId, selCartRandomNum);
    setProductId(prodId);
    setSelCartRandomNum(selCartRandomNum);
    setShowProductDetails(true);
    setShowCartDetails(false);
    setShowListingDetails(false);
    setShowSearch(false);
    setShowSort(false);
  };

  let getProductDetailsDataConfig = {
    method: "post",
    url: `${storeBaseUrl}/${storeEndpoints.getProductPageData}`,
    headers: {
      source: "MOBILE_SITE",
      // token: "d54374a9643892a036e2937f1ccebbf2877dad0c",
      // userId: 1,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: { prodId: productId },
  };

  const getProductDetailsData = async () => {
    try {
      const response = await axios.request(getProductDetailsDataConfig);

      if (response.data.status == "SUCCESS") {
        let tempProductDetailsData = response.data.response.productInputBean;
        if (Object.keys(tempProductDetailsData).length) {
          setProductDetailsData(tempProductDetailsData);
        }
        //
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (productId) {
      getProductDetailsData();
    }
  }, [productId]);

  /* end getting product details data */

  const showCartHandler = () => {
    setShowCartDetails(true);
    setShowProductDetails(false);
    setShowListingDetails(false);
  };

  const handleMenuClose = () => setMenuShow(false);
  const handleBrandClose = () => setBrandShow(false);
  const handleMenuShow = (tabKey) => {
    setMenuShow(true);
    setMenuTabKey(tabKey);
  };
  const handleBrandShow = () => {
    setBrandShow(true);
  };

  return (
    <>
      <main>
        <section className="content-section shopping-section">
          {/* <div className="deso-rate">{desoRate}</div> */}
          <Offcanvas
            show={menuShow}
            onHide={handleMenuClose}
            className="shopping-offcanvas"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="shopping-tab-section">
                <Tabs
                  id="shoppingTab"
                  activeKey={menuTabKey}
                  onSelect={(k) => setMenuTabKey(k)}
                  className="mb-3"
                >
                  <Tab eventKey="electronics" title="electronics">
                    <ul className="shopping-menu">
                      <li>
                        <span
                          className="main-head"
                          data-rootcatid="0"
                          data-parentcatid="0"
                          data-catid="1"
                          data-under="1"
                          onClick={displayProductListHandler}
                        >
                          all
                        </span>
                      </li>
                      {electronicMenuList.length > 0 &&
                        allSubMenuList.length > 0 &&
                        electronicMenuList.map((val) => (
                          <li key={val.id}>
                            {" "}
                            <span
                              data-rootcatid={val.rootId}
                              data-parentcatid={val.parentId}
                              data-catid={val.id}
                              data-under="1"
                              onClick={displayProductListHandler}
                              className={`head ${
                                val.id == catId ? "active" : ""
                              }`}
                            >
                              {val.displayName}
                            </span>
                            <ul>
                              {" "}
                              {/* Render sub-menu items within nested ul */}
                              {allSubMenuList.map((valSub) =>
                                valSub.parentId === val.id ? (
                                  <li key={valSub.id}>
                                    {" "}
                                    <span
                                      data-rootcatid={valSub.rootId}
                                      data-parentcatid={valSub.parentId}
                                      data-catid={valSub.id}
                                      data-under="1"
                                      onClick={displayProductListHandler}
                                      className={
                                        valSub.id == catId ? "active" : ""
                                      }
                                    >
                                      {valSub.displayName}
                                    </span>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                        ))}
                    </ul>
                  </Tab>
                  <Tab eventKey="toysGames" title="toys & games">
                    <ul className="shopping-menu">
                      <li>
                        <span
                          className="main-head"
                          data-rootcatid="0"
                          data-parentcatid="0"
                          data-catid="58"
                          data-under="58"
                          onClick={displayProductListHandler}
                        >
                          all
                        </span>
                      </li>
                      {toysGamesMenuList.length > 0 &&
                        allSubMenuList.length > 0 &&
                        toysGamesMenuList.map((val) => (
                          <li key={val.id}>
                            {" "}
                            <span
                              data-rootcatid={val.rootId}
                              data-parentcatid={val.parentId}
                              data-catid={val.id}
                              data-under="58"
                              onClick={displayProductListHandler}
                              className={`head ${
                                val.id == catId ? "active" : ""
                              }`}
                            >
                              {val.displayName}
                            </span>
                            <ul>
                              {" "}
                              {/* Render sub-menu items within nested ul */}
                              {allSubMenuList.map((valSub) =>
                                valSub.parentId === val.id ? (
                                  <li key={valSub.id}>
                                    {" "}
                                    <span
                                      data-rootcatid={valSub.rootId}
                                      data-parentcatid={valSub.parentId}
                                      data-catid={valSub.id}
                                      data-under="58"
                                      onClick={displayProductListHandler}
                                      className={
                                        valSub.id == catId ? "active" : ""
                                      }
                                    >
                                      {valSub.displayName}
                                    </span>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                        ))}
                    </ul>
                  </Tab>
                </Tabs>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas
            show={brandShow}
            onHide={handleBrandClose}
            className="shopping-offcanvas"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="brands-list-wrapper">
                <ul className="shopping-menu">
                  <li>
                    <span className="main-head">all brands</span>

                    {allBrands.length
                      ? allBrands.map((brandVal) => {
                          return (
                            <span key={brandVal.key}>{brandVal.value}</span>
                          );
                        })
                      : "No brands to display"}
                  </li>
                </ul>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <div className="container">
            <ShoppingHeader
              pageHeadDetails={pageHeadDetails}
              handleMenuShow={handleMenuShow}
              setShowSearch={setShowSearch}
              showSearch={showSearch}
              setShowSort={setShowSort}
              handleBrandShow={handleBrandShow}
              changeSearchTermHandler={changeSearchTermHandler}
              searchCatData={searchCatData}
              searchProdData={searchProdData}
              searchTerm={searchTerm}
              displayProductListHandler={displayProductListHandler}
              handleProductRedirect={handleProductRedirect}
              setSearchCatData={setSearchCatData}
              setSearchProdData={setSearchProdData}
              showCartHandler={showCartHandler}
            />
            {/* end of fixed row section */}

            {showProductDetails && (
              <ProductDetails
                productDetailsData={
                  Object.keys(productDetailsData).length && productDetailsData
                }
                desoRate={desoRate}
                isAdded={isAdded}
                selCartRandomNum={selCartRandomNum}
              />
            )}

            {showCartDetails && <ShoppingCart desoRate={desoRate} />}

            {showListingtDetails && (
              <>
                <HeadingSort
                  sortByHandler={sortByHandler}
                  pageHeadDetails={pageHeadDetails}
                  setShowSort={setShowSort}
                  showSort={showSort}
                  setShowSearch={setShowSearch}
                />

                <ProductListing
                  productList={productList}
                  handleProductRedirect={handleProductRedirect}
                  desoRate={desoRate}
                />
                {/* end of product listing */}

                <Pagination
                  pageNum={pageNum}
                  setPageNum={setPageNum}
                  totalProducts={totalProducts}
                  setIsProductByFilter={setIsProductByFilter}
                />
                {/* end of pagination */}
              </>
            )}
          </div>
        </section>
        {/* end of section main */}

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default Shopping;
