import React from "react";

const SecurityShieldRadar = () => {
  return (
    <div className="radar-loader-wrapper">
      <div className="radar-loader">
        <span></span>
      </div>
    </div>
  );
};

export default SecurityShieldRadar;
