import React, { createContext, useState, useEffect } from "react";

export const CartContext = createContext(null);

const CartContextProvider = ({ children }) => {
  const [dispCartNumber, setDispCartNumber] = useState(0);
  const [cartProducts, setCartProducts] = useState({});
  const [totalCartValue, setTotalCartValue] = useState(0);
  const [btnVariants, setBtnVariants] = useState([
    "variant-1",
    "variant-2",
    "variant-3",
    "variant-4",
    "variant-5",
  ]);

  useEffect(() => {
    let productNumbers = localStorage.getItem("cartNumbers");
    let displayCartItems = localStorage.getItem("productsInCart");
    displayCartItems = JSON.parse(displayCartItems);
    if (productNumbers) {
      setDispCartNumber(parseInt(productNumbers));
    }

    // if (displayCartItems && Object.keys(displayCartItems).length) {
    //   setCartProducts(displayCartItems);
    // }
    if (!undefined) {
      setCartProducts(displayCartItems);
    }
  }, [dispCartNumber]);

  const setCartNumbers = () => {
    let productNumbers = localStorage.getItem("cartNumbers");
    productNumbers = parseInt(productNumbers);
    if (productNumbers) {
      localStorage.setItem("cartNumbers", productNumbers + 1);
      setDispCartNumber((prev) => prev + 1);
    } else {
      localStorage.setItem("cartNumbers", 1);
      setDispCartNumber(1);
    }
  };

  const setItems = (prodId, prodPrice) => {
    let cartItems = {};
    let cartItem = {};
    cartItems = localStorage.getItem("productsInCart");
    cartItems = JSON.parse(cartItems);

    if (cartItems) {
      if (cartItems[prodId] === undefined) {
        cartItem = {
          skuId: prodId,
          quantity: 1,
          price: prodPrice,
          totalprice: prodPrice * 1,
        };
        cartItems = {
          ...cartItems,
          [prodId]: cartItem,
        };
      } else {
        let curProdQty = cartItems[prodId].quantity;
        let updatedProdQty = curProdQty + 1;
        cartItems[prodId].totalprice = updatedProdQty * cartItems[prodId].price;
        cartItems[prodId].quantity += 1;
      }
    } else {
      cartItem = {
        skuId: prodId,
        quantity: 1,
        price: prodPrice,
        totalprice: prodPrice * 1,
      };
      cartItems = {
        [prodId]: cartItem,
      };
    }

    localStorage.setItem("productsInCart", JSON.stringify(cartItems));
  };

  //start add to cart function

  const getIsAdded = (prodId) => {
    let cartItems = localStorage.getItem("productsInCart");
    cartItems = JSON.parse(cartItems);
    if (cartItems) {
      if (cartItems[prodId] === undefined) {
        return false;
      } else {
        return true;
      }
    }
  };

  const addToCartHandler = (e, prodId, prodPrice) => {
    let addCartEle = e.target;
    setCartNumbers();
    setItems(prodId, prodPrice);

    // if (getUserLogin()) {
    //   //rules when logged in
    //   addProductToUserCart(prodId, "1").then(() => {
    //     if (addCartEle.attr("src")) {
    //       addCartEle.attr("src", "images/home-prod-cart-added.png");
    //       addCartEle.removeClass("addToCart");
    //     } else {
    //       addCartEle.removeClass("addToCart");
    //       addCartEle.text("Added");
    //       addCartEle.css("background-color", "#1c8d17");
    //     }
    //     getCurrentDesoRate().then((desoRate) => {
    //       getPaymentInformationCall(desoRate);
    //     });
    //   });
    // }
  };

  const incDecQty = (e, prodId, type) => {
    let cartItems = localStorage.getItem("productsInCart");
    cartItems = JSON.parse(cartItems);
    let productNumbers = localStorage.getItem("cartNumbers");
    productNumbers = parseInt(productNumbers);

    if (cartItems != null) {
      let curProdQty = cartItems[prodId].quantity;

      let updatedProdQty;

      if (type === "inc") {
        updatedProdQty = curProdQty + 1;
        localStorage.setItem("cartNumbers", productNumbers + 1);
        setDispCartNumber((prev) => prev + 1);
      } else if (type === "dec") {
        updatedProdQty = curProdQty - 1;
        localStorage.setItem("cartNumbers", productNumbers - 1);
        setDispCartNumber((prev) => prev - 1);
      } else {
        updatedProdQty = updatedProdQty;
      }
      cartItems[prodId].totalprice = updatedProdQty * cartItems[prodId].price;
      cartItems[prodId].quantity = updatedProdQty;
      localStorage.setItem("productsInCart", JSON.stringify(cartItems));

      // if (getUserLogin()) {
      //   addProductToUserCart(prodId, updatedProdQty);
      //   getCurrentDesoRate().then((desoRate) => {
      //     getPaymentInformationCall(desoRate);
      //   });
      // }
    }
  };

  const deleteCartItem = (e, prodId) => {
    let cartItems = localStorage.getItem("productsInCart");
    cartItems = JSON.parse(cartItems);
    let productNumbers = localStorage.getItem("cartNumbers");
    productNumbers = parseInt(productNumbers);

    let selProductQty = cartItems[prodId].quantity;

    if (cartItems != null) {
      delete cartItems[prodId];
      localStorage.setItem("productsInCart", JSON.stringify(cartItems));
      localStorage.setItem("cartNumbers", productNumbers - selProductQty);
      setDispCartNumber((prev) => prev - selProductQty);
      // if (getUserLogin()) {
      //   removeProductFromUserCart(prodId);
      //   getCurrentDesoRate().then((desoRate) => {
      //     getPaymentInformationCall(desoRate);
      //   });
      // }
    }
  };

  const calculateTotal = () => {
    let cartItems = localStorage.getItem("productsInCart");
    cartItems = JSON.parse(cartItems);
    let totalValue = 0;

    if (cartItems != null) {
      for (const key in cartItems) {
        if (cartItems.hasOwnProperty(key)) {
          totalValue += cartItems[key].totalprice;
        }
      }
    }
    setTotalCartValue(totalValue);
  };

  useEffect(() => {
    calculateTotal();
  }, [dispCartNumber]);

  //end add to cart function

  return (
    <CartContext.Provider
      value={{
        dispCartNumber,
        getIsAdded,
        addToCartHandler,
        btnVariants,
        cartProducts,
        incDecQty,
        deleteCartItem,
        totalCartValue,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
