import React, { useState, useEffect, useContext } from "react";
import { getRandomNumber } from "../../data/custom";
import { UserContext } from "../../context/UserContextProvider";
import axios from "axios";
import { endPoints, baseUrl } from "../../data/endPoints";

const NeonLagoon = ({
  setRewardsAmount,
  setShowRewardsNlWon,
  setActiveArr,
}) => {
  const {
    userCognito,
    getUserCognitoData,
    handleGetRewards,
    deductStandardCoin,
  } = useContext(UserContext);

  useEffect(() => {
    getUserCognitoData();
  }, []);

  const [updatedNlCoin, setUpdatedNlCoin] = useState(0);
  const [nlCoinsWon, setNlCoinsWon] = useState(0);
  const [dbUpdatedNlRewards, setDbUpdatedNlRewards] = useState([false, false]);

  const updateNlCoins = (coin) => {
    setNlCoinsWon(coin);
    let tempRewards = JSON.parse(localStorage.getItem("rewards"));
    if (tempRewards) {
      tempRewards.coin = tempRewards.coin + coin;
      localStorage.setItem("rewards", JSON.stringify(tempRewards));
      setUpdatedNlCoin(tempRewards.coin);
    }
  };

  const handleUpdateNlRewards = (
    mobile,
    type,
    transactionType,
    reason,
    value,
    winValue
  ) => {
    setDbUpdatedNlRewards([false, false]);

    let configUpdateRewards = {
      method: "post",
      url: `${baseUrl}${endPoints.updateRewards}`,
      data: {
        mobile: mobile,
        toUpdate: {
          [type]: value,
        },
      },
      withCredentials: true,
    };

    let configUpdateAllRewards = {
      method: "post",
      url: `${baseUrl}${endPoints.addLifetimeReward}`,
      data: {
        mobile: mobile,
        rewardType: type,
        transactionType: transactionType,
        reason: reason,
        details: "",
        voucherCode: "",
        redeemed: false,
        value: winValue,
      },
      withCredentials: true,
    };

    const updateUserNlRewardsData = async () => {
      try {
        const response = await axios.request(configUpdateRewards);
        if (response.data.status) {
          setDbUpdatedNlRewards([true, false]);
        } else {
          setDbUpdatedNlRewards([false, false]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const updateAllUserNlRewardsData = async () => {
      try {
        const response = await axios.request(configUpdateAllRewards);
        if (response.data.status) {
          setDbUpdatedNlRewards([true, true]);
        } else {
          setDbUpdatedNlRewards([false, false]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    updateUserNlRewardsData();
    setTimeout(() => {
      updateAllUserNlRewardsData();
    }, 500);
    setTimeout(() => {
      handleGetRewards(mobile, false);
    }, 1000);
  };

  useEffect(() => {
    if (updatedNlCoin !== 0 && userCognito.mobile && nlCoinsWon) {
      handleUpdateNlRewards(
        userCognito.mobile,
        "coin",
        "received",
        "game - neon lagoon",
        updatedNlCoin,
        nlCoinsWon
      );
      setRewardsAmount(nlCoinsWon);
      setShowRewardsNlWon(true);
      // console.log("updatedNlCoin", updatedNlCoin, userCognito.mobile, nlCoinsWon);
    }
  }, [updatedNlCoin]);

  useEffect(() => {
    if (dbUpdatedNlRewards[0] && dbUpdatedNlRewards[1]) {
      setTimeout(() => {
        let spinBtn = document.getElementById("spinBtn");
        if (spinBtn) {
          spinBtn.classList.remove("disabled-btn");
          spinBtn.style.pointerEvents = "all";
        }
      }, 2000);

      setTimeout(() => {
        setShowRewardsNlWon(false);
        setRewardsAmount(0);
      }, 2000);
    }
  }, [dbUpdatedNlRewards[0], dbUpdatedNlRewards[1]]);

  const [indexesArr, setIndexesArr] = useState([]);
  //   const debugEl = document.getElementById("debug");
  // Mapping of indexes to icons: start from banana in middle of initial position and then upwards
  const iconMap = [
    "seven",
    "crown",
    "grapes",
    "diamond",
    "bell",
    "spade",
    "cherry",
    "shoe",
    "clove",
  ];
  // Width of the icons
  let icon_width;
  let icon_height;
  if (window.matchMedia("(max-width: 767px)").matches) {
    icon_width = 67;
    icon_height = 60;
  } else {
    icon_width = 105;
    icon_height = 95;
  }

  // Height of one icon in the strip
  //

  // Number of icons in the strip
  const num_icons = 9;
  // Max-speed in ms for animating one icon down
  const time_per_icon = 100;
  // Holds icon indexes
  let indexes = [0, 0, 0];

  /**
   * Roll one reel
   */
  const roll = (reel, offset = 0) => {
    // Minimum of 2 + the reel offset rounds
    const delta =
      (offset + 2) * num_icons + Math.round(Math.random() * num_icons);

    /* deactivate btn */
    let spinBtn = document.getElementById("spinBtn");
    spinBtn.style.pointerEvents = "none";
    spinBtn.classList.add("disabled-btn");

    // Return promise so we can wait for all reels to finish
    return new Promise((resolve, reject) => {
      const style = getComputedStyle(reel),
        // Current background position
        backgroundPositionY = parseFloat(style["background-position-y"]),
        // Target background position
        targetBackgroundPositionY = backgroundPositionY + delta * icon_height,
        // Normalized background position, for reset
        normTargetBackgroundPositionY =
          targetBackgroundPositionY % (num_icons * icon_height);

      // Delay animation with timeout, for some reason a delay in the animation property causes stutter
      setTimeout(() => {
        // Set transition properties ==> https://cubic-bezier.com/#.41,-0.01,.63,1.09
        reel.style.transition = `background-position-y ${
          (8 + 1 * delta) * time_per_icon
        }ms cubic-bezier(.41,-0.01,.63,1.09)`;
        // Set background position
        reel.style.backgroundPositionY = `${
          backgroundPositionY + delta * icon_height
        }px`;
      }, offset * 150);

      // After animation
      setTimeout(() => {
        // Reset position, so that it doesn't get higher without limit
        reel.style.transition = `none`;
        reel.style.backgroundPositionY = `${normTargetBackgroundPositionY}px`;
        // Resolve this promise
        resolve(delta % num_icons);
      }, (8 + 1 * delta) * time_per_icon + offset * 150);
    });
  };

  /**
   * Roll all reels, when promise resolves roll again
   */
  const rollAll = () => {
    /* deduct 1000 reward coins here */
    deductStandardCoin();
    setActiveArr([true, false, false, false]);
    {
      indexesArr.length ? (indexes = indexesArr) : (indexes = indexes);
    }

    // debugEl.textContent = "rolling...";

    const reelsList = document.querySelectorAll(".slots > .reel");

    Promise

      // Activate each reel, must convert NodeList to Array for this with spread operator
      .all([...reelsList].map((reel, i) => roll(reel, i)))

      // When all reels done animating (all promises solve)
      .then((deltas) => {
        // add up indexes
        deltas.forEach((delta, i) => {
          indexes[i] = (indexes[i] + delta) % num_icons;
        });
        // debugEl.textContent = indexes.map((i) => iconMap[i]).join(" - ");
        setIndexesArr(indexes);

        /* reactivate btn */

        // Win conditions
        /* 1- jackppot, 2- any 2 same, 3- any 3 same */
        if (indexes[0] === 0 && indexes[1] === 0 && indexes[2] === 0) {
          document.querySelector(".slots").classList.add("win3");
          updateNlCoins(10000);
          setTimeout(() => {
            document.querySelector(".slots").classList.remove("win3");
          }, 2000);
        } else if (
          (indexes[0] === indexes[1] && indexes[0] !== indexes[2]) ||
          (indexes[0] === indexes[2] && indexes[0] !== indexes[1]) ||
          (indexes[1] === indexes[2] && indexes[1] !== indexes[0])
        ) {
          let randomCoin = getRandomNumber(1500, 1999);
          updateNlCoins(randomCoin);
          document.querySelector(".slots").classList.add("win1");
          setTimeout(() => {
            document.querySelector(".slots").classList.remove("win1");
          }, 2000);
        } else if (
          indexes[0] === indexes[1] &&
          indexes[1] === indexes[2] &&
          indexes[0] !== 0
        ) {
          let randomCoin = getRandomNumber(2000, 5000);
          updateNlCoins(randomCoin);
          document.querySelector(".slots").classList.add("win2");
          setTimeout(() => {
            document.querySelector(".slots").classList.remove("win2");
          }, 2000);
        } else {
          let randomCoin = getRandomNumber(100, 750);
          updateNlCoins(randomCoin);
        }
        setTimeout(() => {
          setActiveArr([true, true, true, true]);
        }, 3000);
      });
  };

  const rollAllHandler = () => {
    rollAll();
  };

  return (
    <>
      <span className="nl-rotating-text" id="scroll-container">
        <span id="scroll-text">
          {`get 7-7-7 and win the jackpot > spin for a chance to win`}
        </span>
      </span>
      <span className="game-price">1000</span>
      <div className="slots">
        <div className="reel"></div>
        <div className="reel"></div>
        <div className="reel"></div>
      </div>

      {/* <div id="debug" className="debug"></div> */}

      <div className="game-btn-wrapper">
        <button onClick={rollAllHandler} id="spinBtn">
          <span> spin </span>
        </button>
      </div>
    </>
  );
};

export default NeonLagoon;
