import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { convertDate } from "../../data/custom";

import { vendorLogos } from "../../components/vendorLogos";
import { walletBankLogos } from "../../components/walletBankLogos";
import { currencyIcons } from "../../components/currencyIcons";

const ConfirmPaymentCard = ({
  handleShowPaymentThankyou,
  dateValue,
  amountPayable,
}) => {
  return (
    <>
      <div className="checkout-details-wrapper-bills">
        <div className="confirm-payment-wrapper">
          <div className="confirm-detail-wrap">
            <div className="confirm-text-head">paying to</div>
            <span className="confirm-text">citi bank...0809</span>
          </div>
          <div className="confirm-detail-wrap">
            <span className="confirm-text-head">paying with</span>
            <span className="confirm-text">boa...0101</span>
          </div>
          <div className="confirm-detail-wrap">
            <span className="confirm-text-head">currency</span>
            <span className="confirm-text">dollar</span>
          </div>
          <div className="confirm-detail-wrap">
            <span className="confirm-text-head">pay on</span>
            <span className="confirm-text">{dateValue}</span>
          </div>
          <div className="confirm-detail-wrap">
            <span className="confirm-text-head">payable amount</span>
            <span className="confirm-text">$ {amountPayable}</span>
          </div>
        </div>
      </div>

      <div className="cart-checkout-btn-wrapper">
        <button
          className="starry-btn my-4"
          type="button"
          onClick={handleShowPaymentThankyou}
        >
          <span className="btn-text">confirm</span>
          <div id="container-stars">
            <div id="stars"></div>
          </div>

          <div id="glow">
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </button>
      </div>
    </>
  );
};

export default ConfirmPaymentCard;
