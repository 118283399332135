export const tiltAnimation = {
  animate: {
    y: -5,
    transition: {
      duration: 0.5,
      delay: 0,
      ease: "easeOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
  initial: {
    y: 0,
  },
};

export const hideCurrencyAnim = {
  animate: {
    opacity: "0",
    transition: {
      duration: 0.1,
      delay: 2,
    },
  },
};

export const payIconAnim = {
  animate: {
    left: "calc(50% - 35px)",
    opacity: "0",
    transition: {
      duration: 0.5,
      delay: 2,
      ease: "easeInOut",
    },
  },
  initial: {
    opacity: "1",
  },
};

export const payIconScaleDown = {
  animate: {
    scale: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 2.5,
      ease: "easeInOut",
    },
  },
  initial: {
    opacity: 0,
    left: "calc(50% - 35px)",
  },
};

export const drawCheckFill = {
  animate: {
    scale: 1,
    transition: {
      duration: 0.2,
      delay: 3,
      ease: "easeIn",
    },
  },
  initial: {
    scale: 0,
  },
};

export const drawCheck = {
  initial: { pathLength: 0, opacity: 0 },
  animate: {
    pathLength: 1,
    opacity: 1,
    transition: {
      pathLength: { delay: 3, type: "spring", duration: 0.5, bounce: 0 },
      opacity: { delay: 3, duration: 0.01 },
    },
  },
};

export const hideTickSvg = {
  animate: {
    scale: 0,
    transition: {
      duration: 0.1,
      delay: 4,
      ease: "easeIn",
    },
    transitionEnd: {
      scale: 0,
    },
  },
  initial: {
    scale: 1,
  },
};

export const confettiAnimationShow = {
  animate: {
    visibility: "visible",
    // scale: 1,
    transition: {
      duration: 4,
      delay: 4,
      ease: "easeIn",
      repeat: 0,
    },
    transitionEnd: {
      opacity: 0,
      // visibility: "hidden",
    },
  },
  initial: {
    visibility: "hidden",
    // scale: 0,
  },
};
export const confettiAnimationFadeOut = {
  animate: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 9,
      ease: "easeInOut",
    },
  },
  initial: {
    opacity: 1,
  },
};
export const showWinningText = {
  animate: {
    scale: 1,
    transition: {
      duration: 1,
      delay: 5,
      ease: "easeOut",
    },
  },
  initial: {
    scale: 0,
  },
};

export const showWinningIcons = {
  animate: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 5,
      ease: "easeOut",
    },
  },
  initial: {
    opacity: 0,
  },
};

export const iconFlyTop = {
  animate: {
    y: -195,
    x: 40,
    opacity: 0,
    transition: {
      duration: 0.8,
      delay: 6,
      ease: "easeOut",
    },
  },
  initial: {
    y: 0,
    x: 0,
    opacity: 1,
  },
};
export const iconFlyLeft = {
  animate: {
    y: -195,
    x: -130,
    opacity: 0,
    transition: {
      duration: 1,
      delay: 6,
      ease: "easeOut",
    },
  },
  initial: {
    y: 0,
    x: 0,
    opacity: 1,
  },
};
export const iconFlyRight = {
  animate: {
    y: -250,
    x: 140,
    opacity: 0,
    scale: 0,
    transition: {
      duration: 1,
      delay: 7,
      ease: "easeIn",
    },
  },
  initial: {
    y: 0,
    x: 0,
    opacity: 1,
    scale: 1,
  },
};
export const iconPopOut = {
  animate: {
    scale: 1.3,
    transition: {
      duration: 0.5,
      delay: 6.6,
      repeat: 1,
      repeatType: "reverse",
      ease: "easeInOut",
    },
    transitionEnd: {
      scale: 1,
    },
  },
  initial: {
    scale: 1,
  },
};
export const fadeOut = {
  animate: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 8.5,
      ease: "easeInOut",
    },
  },
  initial: {
    opacity: 1,
  },
};
export const showRedirectionText = {
  animate: {
    opacity: 1,
    transition: {
      duration: 2,
      delay: 9,
      ease: "easeInOut",
    },
  },
  initial: {
    opacity: 0,
  },
};
