import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { convertDate } from "../../data/custom";

import { vendorLogos } from "../../components/vendorLogos";
import { walletBankLogos } from "../../components/walletBankLogos";
import { currencyIcons } from "../../components/currencyIcons";

const CheckoutDetailsCard = ({
  handleShowConfirmPayment,
  dateValue,
  setDateValue,
  amountPayable,
  setAmountPayable,
}) => {
  const onChange = (nextValue) => {
    setDateValue(convertDate(nextValue));
  };

  return (
    <>
      <div className="checkout-details-wrapper-bills">
        <div className="paying-to-wrapper">
          <div className="checkout-details-head">paying to</div>
          <span className="paying-to-card">citi bank...0809</span>
          <span className="payment-text">$34.00 minimum due on May 2</span>
          <div className="autopay-wrapper">
            <span className="autopay-text">autopay off</span>
            <span className="autopay-text">setup autopay</span>
          </div>
        </div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="header-wraper-text">
                <span className="payment-text">paying with</span>
                <span className="payment-text">boa...0101</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="payment-mode-wrapper">
                <span className="mode-head">
                  <span className="head-text">bank</span>
                  <button type="button">
                    <span className="">
                      <span className="material-symbols-outlined">add</span>
                    </span>
                  </button>
                </span>
                <div className="mode-info-wrapper default">
                  <span className="mode-logo">
                    <img src={vendorLogos.boa} alt="" />
                  </span>

                  <span className="mode-creds">(0101)</span>
                </div>
                <div className="mode-info-wrapper">
                  <span className="mode-logo">
                    <img src={vendorLogos.chase} alt="" />
                  </span>
                  <span className="mode-creds">(4040)</span>
                </div>
                <div className="mode-info-wrapper">
                  <span className="mode-logo">
                    <img src={vendorLogos.citi} alt="" />
                  </span>
                  <span className="mode-creds">(6060)</span>
                </div>
              </div>

              {/* end of banks */}

              <div className="payment-mode-wrapper">
                <span className="mode-head">
                  <span className="head-text">debit cards</span>
                  <button type="button">
                    <span className="">
                      <span className="material-symbols-outlined">add</span>
                    </span>
                  </button>
                </span>
                <div className="mode-info-wrapper">
                  <span className="mode-logo">
                    <img src={vendorLogos.boa} alt="" />
                  </span>

                  <span className="mode-creds">(0101)</span>
                </div>
                <div className="mode-info-wrapper">
                  <span className="mode-logo">
                    <img src={vendorLogos.chase} alt="" />
                  </span>

                  <span className="mode-creds">(4040)</span>
                </div>
              </div>
              {/* end of debit cards */}

              <div className="payment-mode-wrapper">
                <span className="mode-head">
                  <span className="head-text">wallets</span>
                  <button type="button">
                    <span className="">
                      <span className="material-symbols-outlined">add</span>
                    </span>
                  </button>
                </span>
                <div className="mode-info-wrapper">
                  <span className="wallet-info-wrapper">
                    <span className="mode-logo wallets">
                      <img src={walletBankLogos.metamask} alt="" />
                    </span>
                    <span className="mode-name">metamask</span>
                  </span>

                  <span className="mode-creds">bg3bgh...</span>
                </div>
                <div className="mode-info-wrapper">
                  <span className="wallet-info-wrapper">
                    <span className="mode-logo wallets">
                      <img src={walletBankLogos.trustwallet} alt="" />
                    </span>
                    <span className="mode-name">trust wallet</span>
                  </span>
                  <span className="mode-creds">tb88ed...</span>
                </div>
                <div className="mode-info-wrapper">
                  <span className="wallet-info-wrapper">
                    <span className="mode-logo wallets">
                      <img src={walletBankLogos.coinbase} alt="" />
                    </span>
                    <span className="mode-name">coin base</span>
                  </span>
                  <span className="mode-creds">coh36d...</span>
                </div>
              </div>
              {/* end of wallets */}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="header-wraper-text">
                <span className="payment-text">currency</span>
                <span className="payment-text">dollar</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="currency-info-wrapper">
                <div className="currency-info default">
                  <span className="currency-logo">
                    <img src={currencyIcons.dollarIcon} alt="" />
                  </span>
                  <span className="currency-name">Dollar</span>
                </div>
                <div className="currency-info">
                  <span className="currency-logo">
                    <img src={currencyIcons.rupeeIcon} alt="" />
                  </span>
                  <span className="currency-name">e-rupee</span>
                </div>
                <div className="currency-info">
                  <span className="currency-logo">
                    <img src={currencyIcons.ethIcon} alt="" />
                  </span>
                  <span className="currency-name">etherium</span>
                </div>
                <div className="currency-info">
                  <span className="currency-logo">
                    <img src={currencyIcons.dodgecoinIcon} alt="" />
                  </span>
                  <span className="currency-name">dodge coin</span>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="header-wraper-text">
                <span className="payment-text">pay on</span>
                <span className="payment-text">{dateValue}</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="calender-info-wrapper">
                <Calendar onChange={onChange} value={dateValue} />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <div className="header-wraper-text">
                <span className="payment-text">amount</span>
                <span className="payment-text">${amountPayable}</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="amount-info-wrapper">
                <div className="amount-info">
                  <span className="amount-name">pay full</span>
                  <span className="amount-name">$134.00</span>
                </div>
                <div className="amount-info">
                  <span className="amount-name">minimum</span>
                  <span className="amount-name">$34.00</span>
                </div>
                <div className="amount-info default">
                  <span className="amount-name">enter amount</span>
                  <span className="amount-field">
                    <Form.Group className="main-form-group">
                      <Form.Control
                        type="number"
                        name="number"
                        min="0"
                        placeholder="0.00"
                        // pattern="[\d| ]{1,6}"
                        autoComplete="off"
                        onChange={(e) => {
                          setAmountPayable(Number(e.target.value).toFixed(2));
                        }}
                      />
                    </Form.Group>
                  </span>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="cart-checkout-btn-wrapper">
        <button
          className="starry-btn my-4"
          type="button"
          onClick={handleShowConfirmPayment}
        >
          <span className="btn-text">pay now</span>
          <div id="container-stars">
            <div id="stars"></div>
          </div>

          <div id="glow">
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </button>
      </div>
    </>
  );
};

export default CheckoutDetailsCard;
