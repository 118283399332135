import React from "react";
import unauthorized from "../assets/img/unauthorized.png";
const Unauthorized = () => {
  return (
    <>
      <section className="page-not-found-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3">
              <img src={unauthorized} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Unauthorized;
