import React from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../../assets/img/img-line-main-2.png";

/* comp imports */
import MainWrapper from "../MainWrapper";
import MainCareerNavbar from "./MainCareerNavbar";
import Footer from "../Footer";
import FooterCareer from "./FooterCareer";
import CareerForm from "./CareerForm";

const FullstackDeveloper = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        fullstack developer
                      </h3>
                      <div className="section-content">
                        <span className="comp-name">ChaChing</span> is
                        transforming the financial technology (FinTech)
                        landscape with our innovative payment processing
                        platform that rewards users for their responsible
                        financial behavior. We are seeking a talented Full Stack
                        Developer to join our team and contribute to the design,
                        development, and maintenance of both frontend and
                        backend components of our platform. As a Full Stack
                        Developer at <span className="comp-name">ChaChing</span>
                        , you will have the opportunity to work on diverse and
                        challenging projects, collaborate with cross-functional
                        teams, and play a key role in delivering seamless and
                        engaging financial experiences to our users.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section white-color-section speckle-bg career-details-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 mb-5 mb-md-1">
                    <div className="section-content-wrapper">
                      <div className="content-head mt-0">responsibilities</div>

                      <ul>
                        <li>
                          Design, develop, and maintain both frontend and
                          backend components of the{" "}
                          <span className="comp-name">ChaChing</span> platform,
                          including web applications, APIs, and databases.
                        </li>
                        <li>
                          Collaborate with product managers, designers, and
                          other developers to define requirements, prioritize
                          features, and implement solutions that meet business
                          objectives.
                        </li>
                        <li>
                          Implement responsive and user-friendly interfaces
                          using modern web technologies, such as HTML5, CSS3,
                          JavaScript, and frontend frameworks/libraries like
                          React, Angular, or Vue.js.
                        </li>
                        <li>
                          Develop backend services, APIs, and databases using
                          server-side technologies such as Node.js, Python,
                          Java, or Go, and integrate them with frontend
                          components.
                        </li>
                        <li>
                          Ensure the security, scalability, and performance of
                          the platform by implementing best practices for
                          authentication, authorization, data storage, and
                          optimization.
                        </li>
                        <li>
                          Optimize code and database queries for performance,
                          scalability, and reliability, considering factors such
                          as concurrency, latency, and resource utilization.
                        </li>
                        <li>
                          Implement automated tests, continuous
                          integration/delivery (CI/CD) pipelines, and deployment
                          strategies to maintain code quality and facilitate
                          rapid iteration and deployment.
                        </li>
                        <li>
                          Monitor, analyze, and troubleshoot production issues
                          and performance bottlenecks, and implement timely
                          solutions to minimize downtime and ensure system
                          stability.
                        </li>
                        <li>
                          Collaborate with DevOps engineers to deploy and
                          maintain infrastructure in cloud environments, such as
                          AWS, Azure, or Google Cloud Platform.
                        </li>
                        <li>
                          Stay updated with the latest trends, technologies, and
                          best practices in full-stack development and FinTech
                          industry, and share knowledge with the team.
                        </li>
                      </ul>

                      <div className="content-head mt-0">requirements</div>
                      <ul>
                        <li>
                          Bachelor's degree in Computer Science, Engineering, or
                          a related field (or equivalent work experience).
                        </li>
                        <li>
                          Proven experience as a full stack developer, with a
                          strong portfolio of web-based projects or
                          applications.
                        </li>
                        <li>
                          Proficiency in frontend technologies such as HTML5,
                          CSS3, JavaScript, and frontend frameworks/libraries
                          like React, Angular, or Vue.js.
                        </li>
                        <li>
                          Experience with backend technologies and programming
                          languages such as Node.js, Python, Java, or Go, and
                          familiarity with server-side frameworks and libraries.
                        </li>
                        <li>
                          Solid understanding of relational and non-relational
                          databases, SQL and NoSQL query languages, and database
                          design principles.
                        </li>
                        <li>
                          Knowledge of RESTful APIs, GraphQL, and other web
                          services protocols, and familiarity with API design
                          principles and best practices.
                        </li>
                        <li>
                          Experience with version control systems, such as Git,
                          and collaborative development workflows (e.g.,
                          GitFlow).
                        </li>
                        <li>
                          Strong problem-solving skills, analytical thinking,
                          and attention to detail.
                        </li>
                        <li>
                          Excellent communication skills and the ability to work
                          effectively in a team environment.
                        </li>
                        <li>
                          Ability to manage multiple tasks concurrently and
                          thrive in a fast-paced, dynamic environment.
                        </li>
                      </ul>
                      <div className="content-head mt-0">
                        Preferred Qualifications
                      </div>
                      <ul>
                        <li>
                          Experience with financial services, payment
                          processing, or related domains.
                        </li>
                        <li>
                          Knowledge of microservices architecture,
                          containerization, and orchestration tools such as
                          Docker and Kubernetes.
                        </li>
                        <li>
                          Familiarity with cloud computing platforms,
                          infrastructure-as-code tools, and DevOps practices.
                        </li>
                        <li>
                          Experience with automated testing frameworks, CI/CD
                          pipelines, and continuous deployment strategies.
                        </li>
                        <li>
                          Understanding of security best practices, encryption
                          algorithms, and common vulnerabilities and mitigation
                          techniques.
                        </li>
                        <li>
                          Contributions to open-source projects, participation
                          in developer communities, or attendance at tech
                          conferences or meetups.
                        </li>
                      </ul>
                      <p>
                        Join <span className="comp-name">ChaChing</span> and be
                        part of a collaborative team that is shaping the future
                        of finance by empowering users to make smarter financial
                        decisions and rewarding responsible behavior. If you are
                        passionate about full-stack development and eager to
                        work on innovative projects in the FinTech space, apply
                        now and help us build a brighter financial future for
                        everyone!
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="section-content-wrapper">
                      <CareerForm />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default FullstackDeveloper;
