const monthsName = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};
// const weekName = {
//   0: "Sun",
//   1: "Mon",
//   2: "Tue",
//   3: "Wed",
//   4: "Thu",
//   5: "Fri",
//   6: "Sat",
// };

export const convertDate = (dateValueRaw) => {
  if (dateValueRaw) {
    let month = dateValueRaw.getMonth();
    let day = dateValueRaw.getDate();
    let year = dateValueRaw.getFullYear();

    let formatedDate = `${monthsName[month]}, ${day}, ${year}`;

    return formatedDate;
  }
};

export const drawCheckFill = {
  animate: {
    scale: 1,
    transition: {
      duration: 0.2,
      delay: 0.5,
      ease: "easeIn",
    },
  },
  initial: {
    scale: 0,
  },
};

export const drawCheck = {
  initial: { pathLength: 0, opacity: 0 },
  animate: {
    pathLength: 1,
    opacity: 1,
    transition: {
      pathLength: { delay: 1, type: "spring", duration: 0.5, bounce: 0 },
      opacity: { delay: 1, duration: 0.01 },
    },
  },
};

export const hideTickSvg = {
  animate: {
    scale: 0,
    transition: {
      duration: 0.1,
      delay: 1.5,
      ease: "easeIn",
    },
    transitionEnd: {
      scale: 0,
    },
  },
  initial: {
    scale: 1,
  },
};

export const confettiAnimationShow = {
  animate: {
    visibility: "visible",
    transition: {
      duration: 4,
      delay: 1.6,
      ease: "easeIn",
      repeat: 0,
    },
    transitionEnd: {
      opacity: 0,
    },
  },
  initial: {
    visibility: "hidden",
  },
};
export const confettiAnimationFadeOut = {
  animate: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 6,
      ease: "easeInOut",
    },
  },
  initial: {
    opacity: 1,
  },
};

export const showWinningText = {
  animate: {
    scale: 1,
    transition: {
      duration: 1,
      delay: 5.5,
      ease: "easeOut",
    },
  },
  initial: {
    scale: 0,
  },
};

export const iconFlyTop = {
  animate: {
    y: -195,
    x: 40,
    opacity: 0,
    transition: {
      duration: 0.8,
      delay: 6.5,
      ease: "easeOut",
    },
  },
  initial: {
    y: 0,
    x: 0,
    opacity: 1,
  },
};

export const fadeOut = {
  animate: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 7.5,
      ease: "easeInOut",
    },
  },
  initial: {
    opacity: 1,
  },
};

export const sentence = {
  initial: { opacity: 1 },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.1,
      staggerChildren: 0.04,
      delayChildren: 0.02,
    },
  },
};

export const words = {
  initial: { scaleY: 0, y: 50, x: -30, opacity: 0.5 },
  animate: {
    scaleY: 1,
    y: 0,
    x: 0,
    opacity: 1,
  },
};

export const riseUp = {
  initial: { scaleY: 0.3, scaleX: 0.3, y: 50, x: 0, opacity: 0.2 },
  animate: {
    scaleY: 1,
    scaleX: 1,
    y: 0,
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 0.5,
    },
  },
};

export const bounceAnimation = {
  animate: {
    y: -5,
    transition: {
      duration: 0.5,
      delay: 0,
      ease: "easeOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
  initial: {
    y: 0,
  },
};

export const checkAreaCode = (code) => {
  let areaCodes = [
    907, 205, 251, 256, 334, 479, 501, 870, 480, 520, 602, 623, 928, 209, 213,
    310, 323, 408, 415, 510, 530, 559, 562, 619, 626, 650, 661, 707, 714, 760,
    805, 818, 831, 858, 909, 916, 925, 949, 951, 303, 719, 970, 203, 860, 202,
    302, 239, 305, 321, 352, 386, 407, 561, 727, 772, 813, 850, 863, 904, 941,
    954, 229, 404, 478, 706, 770, 912, 808, 319, 515, 563, 641, 712, 208, 217,
    309, 312, 618, 630, 708, 773, 815, 847, 219, 260, 317, 574, 765, 812, 316,
    620, 785, 913, 270, 502, 606, 859, 225, 318, 337, 504, 985, 413, 508, 617,
    781, 978, 301, 410, 207, 231, 248, 269, 313, 517, 586, 616, 734, 810, 906,
    989, 218, 320, 507, 612, 651, 763, 952, 314, 417, 573, 636, 660, 816, 228,
    601, 662, 406, 252, 336, 704, 828, 910, 919, 701, 308, 402, 603, 201, 609,
    732, 856, 908, 973, 505, 575, 702, 775, 212, 315, 516, 518, 585, 607, 631,
    716, 718, 845, 914, 216, 330, 419, 440, 513, 614, 740, 937, 405, 580, 918,
    503, 541, 215, 412, 570, 610, 717, 724, 814, 401, 803, 843, 864, 605, 423,
    615, 731, 865, 901, 931, 210, 214, 254, 281, 325, 361, 409, 432, 512, 713,
    806, 817, 830, 903, 915, 936, 940, 956, 972, 979, 435, 801, 276, 434, 540,
    703, 757, 804, 802, 206, 253, 360, 425, 509, 262, 414, 608, 715, 920, 304,
    307,
  ];

  let result = areaCodes.includes(code);
  return result;
};

export const authorizedPathsArr = [
  "personal",
  "rewards",
  "shopping",
  "refer",
  "edit-profile",
  "account",
  "transaction-report",
  "activity",
  "notification-settings",
  "credit-score",
  "lifetime-rewards",
];

export const daysFromDate = (dateStr) => {
  const pastDate = new Date(dateStr);
  const currentDate = new Date();
  const timeDifference = pastDate - currentDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference + 1;
};

export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
