import amazon from "../assets/img/brand-logos/amazon.png";
import apple from "../assets/img/brand-logos/apple.png";
import lego from "../assets/img/brand-logos/lego.png";
import marshall from "../assets/img/brand-logos/marshall.png";
import moltonBrown from "../assets/img/brand-logos/molton-brown.png";
import nintendo from "../assets/img/brand-logos/nintendo.png";
import panasonic from "../assets/img/brand-logos/panasonic.png";
import samsung from "../assets/img/brand-logos/samsung.png";
import target from "../assets/img/brand-logos/target.png";

export const brandLogos = {
  amazon: amazon,
  apple: apple,
  lego: lego,
  marshall: marshall,
  moltonBrown: moltonBrown,
  nintendo: nintendo,
  panasonic: panasonic,
  samsung: samsung,
  target: target,
};
