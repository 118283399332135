import React, { useState, useEffect, useContext } from "react";
import { gsap, Sine } from "gsap";
import { getRandomNumber } from "../../data/custom";
import { UserContext } from "../../context/UserContextProvider";
import axios from "axios";
import { endPoints, baseUrl } from "../../data/endPoints";

const PhantonFind = ({
  setRewardsAmount,
  setShowRewardsPfWon,
  setActiveArr,
}) => {
  const {
    userCognito,
    getUserCognitoData,
    handleGetRewards,
    deductStandardCoin,
  } = useContext(UserContext);

  useEffect(() => {
    getUserCognitoData();
  }, []);

  const [updatedPfCoin, setUpdatedPfCoin] = useState(0);
  const [pfCoinsWon, setPfCoinsWon] = useState(0);
  const [dbUpdatedPfRewards, setDbUpdatedPfRewards] = useState([false, false]);

  const updatePfCoins = (coin) => {
    setPfCoinsWon(coin);
    let tempRewards = JSON.parse(localStorage.getItem("rewards"));
    if (tempRewards) {
      tempRewards.coin = tempRewards.coin + coin;
      localStorage.setItem("rewards", JSON.stringify(tempRewards));
      setUpdatedPfCoin(tempRewards.coin);
    }
  };

  const handleUpdatePfRewards = (
    mobile,
    type,
    transactionType,
    reason,
    value,
    winValue
  ) => {
    let configUpdateRewards = {
      method: "post",
      url: `${baseUrl}${endPoints.updateRewards}`,
      data: {
        mobile: mobile,
        toUpdate: {
          [type]: value,
        },
      },
      withCredentials: true,
    };
    setDbUpdatedPfRewards([false, false]);
    let configUpdateAllRewards = {
      method: "post",
      url: `${baseUrl}${endPoints.addLifetimeReward}`,
      data: {
        mobile: mobile,
        rewardType: type,
        transactionType: transactionType,
        reason: reason,
        details: "",
        voucherCode: "",
        redeemed: false,
        value: winValue,
      },
      withCredentials: true,
    };

    const updateUserPfRewardsData = async () => {
      try {
        const response = await axios.request(configUpdateRewards);
        if (response.data.status) {
          setDbUpdatedPfRewards([true, false]);
        } else {
          setDbUpdatedPfRewards([false, false]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const updateAllUserPfRewardsData = async () => {
      try {
        const response = await axios.request(configUpdateAllRewards);
        if (response.data.status) {
          setDbUpdatedPfRewards([true, true]);
        } else {
          setDbUpdatedPfRewards([false, false]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    updateUserPfRewardsData();
    updateAllUserPfRewardsData();
    setTimeout(() => {
      handleGetRewards(mobile, false);
    }, 1000);
  };

  useEffect(() => {
    if (updatedPfCoin !== 0 && userCognito.mobile && pfCoinsWon) {
      handleUpdatePfRewards(
        userCognito.mobile,
        "coin",
        "received",
        "game - phantom find",
        updatedPfCoin,
        pfCoinsWon
      );
      setRewardsAmount(pfCoinsWon);
      setShowRewardsPfWon(true);
      // console.log("updatedPfCoin", updatedPfCoin, userCognito.mobile, pfCoinsWon);
    }
  }, [updatedPfCoin]);

  useEffect(() => {
    if (dbUpdatedPfRewards[0] && dbUpdatedPfRewards[1]) {
      setTimeout(() => {
        setShowRewardsPfWon(false);
        setRewardsAmount(0);
      }, 2000);

      document.getElementById("pfBtnWrapper").style.display = "block";
    }
  }, [dbUpdatedPfRewards[0], dbUpdatedPfRewards[1]]);

  // const [intMoves] = useState(5);
  const [speed, setSpeed] = useState(0.15);
  const [maxSpeed] = useState(0.15);
  const [objMovePattern, setObjMovePattern] = useState([]);
  const [firstCup, setFirstCup] = useState(null);

  let ballStyleLeft = "";
  let ballLeftSpace = {};

  if (window.matchMedia("(max-width: 767px)").matches) {
    ballStyleLeft = "155px";
    ballLeftSpace = { sm: 30, lg: 45 };
  } else {
    ballStyleLeft = "235px";
    ballLeftSpace = { sm: 40, lg: 60 };
  }

  useEffect(() => {
    generateMovePatterns();
    // resetGame();
  }, []);

  const startGame = () => {
    /* deduct 1000 reward coins here */
    deductStandardCoin();
    setActiveArr([false, true, false, false]);
    document.getElementById("pfBtnWrapper").style.display = "none";
    const randomFirstCup = Math.round(Math.random() * 2 + 1);
    setFirstCup(randomFirstCup);
    const cup = document.querySelector(".cup-" + randomFirstCup);
    gsap.to(cup, { y: -80, duration: 0.5 });
    const ball = document.getElementById("ball");
    const widthBall = ball.offsetWidth / 2;
    gsap.to(ball, {
      duration: 0.5,
      left: cup.offsetLeft + widthBall + ballLeftSpace.sm,
      y: -80,
      onComplete: () => {
        ball.style.zIndex = "1";
        document.querySelectorAll(".cup").forEach((cup) => {
          cup.style.zIndex = "3";
          cup.style.pointerEvents = "all";
          cup.style.cursor = "pointer";
        });
        gsap.to(cup, { duration: 0.5, y: 0, onComplete: shakeCups });
      },
    });
  };

  const shakeCups = () => {
    // console.log("objMovePattern", objMovePattern);
    document.getElementById("ball").style.display = "none";
    const aPos = [
      document.querySelector(".cup-" + objMovePattern[0][0]).offsetLeft,
      document.querySelector(".cup-" + objMovePattern[0][1]).offsetLeft,
      document.querySelector(".cup-" + objMovePattern[0][2]).offsetLeft,
    ];
    objMovePattern.forEach((pattern, i) => {
      const speedVal = speed > maxSpeed ? speed / 1.1 : maxSpeed;
      gsap.to(".cup-" + pattern[0], {
        duration: speedVal,
        left: aPos[0],
        delay: speedVal * i,
        ease: Sine.easeOut,
      });
      gsap.to(".cup-" + pattern[1], {
        duration: speedVal,
        left: aPos[1],
        delay: speedVal * i,
        ease: Sine.easeOut,
      });
      if (i === objMovePattern.length - 1) {
        gsap.to(".cup-" + pattern[2], {
          duration: speedVal,
          left: aPos[2],
          delay: speedVal * i,
          ease: Sine.easeOut,
        });
      } else {
        gsap.to(".cup-" + pattern[2], {
          duration: speedVal,
          left: aPos[2],
          delay: speedVal * i,
          ease: Sine.easeOut,
        });
      }
    });
  };
  const [winMessage, setWinMessage] = useState("");
  const [selectedCup, setSelectedCup] = useState("");

  const [isCupClicked, setIsCupClicked] = useState(false);

  const clickCup = (event) => {
    setIsCupClicked(true);
    const currentCup = event.currentTarget;
    const iCup = currentCup.id.split("cup")[1];
    setSelectedCup(iCup);
    const ball = document.getElementById("ball");
    const widthBall = ball.offsetWidth / 2;
    ball.style.left =
      document.querySelector(".cup-" + firstCup).offsetLeft +
      widthBall +
      ballLeftSpace.lg +
      "px";

    ball.style.display = "block";
    gsap.to(".cup-" + iCup, {
      duration: 0.5,
      y: -80,
      ease: Sine.easeIn,
      delay: 0.1,
    });
  };

  const resetGame = () => {
    setIsCupClicked(false);
    generateMovePatterns();
    setTimeout(() => {
      setWinMessage(" ");
      document.getElementById("pfBtnStart").innerHTML =
        "<span>play again</span>";
      document.querySelectorAll(".cup").forEach((cup) => {
        gsap.to(cup, { y: 0, duration: 0.5 });
        cup.style.pointerEvents = "none";
        cup.style.cursor = "default";
      });
      const ball = document.getElementById("ball");
      ball.style.transform = "none";
      ball.style.left = ballStyleLeft;
      gsap.to(ball, {
        duration: 0.5,
        y: 0,
      });
    }, 500);
    setTimeout(() => {
      setActiveArr([true, true, true, true]);
    }, 1000);
  };

  useEffect(() => {
    if (isCupClicked) {
      if (Number(selectedCup) === firstCup) {
        setWinMessage("you win");
        /* winning update */
        let randomCoin = getRandomNumber(1500, 2000);
        updatePfCoins(randomCoin);
        setTimeout(() => {
          document.querySelectorAll(".cup").forEach((cup) => {
            gsap.to(cup, { y: -80, duration: 0.5, onComplete: resetGame });
          });
        }, 1000);
      } else {
        setWinMessage("you lose");
        /* losing update */
        let randomCoin = getRandomNumber(100, 750);
        updatePfCoins(randomCoin);
        setTimeout(() => {
          document.querySelectorAll(".cup").forEach((cup) => {
            gsap.to(cup, { y: -80, duration: 0.5, onComplete: resetGame });
          });
        }, 1000);
      }
    }
  }, [isCupClicked]);

  const generateMovePatterns = () => {
    const uniquePatterns = generateAllUniquePatterns();
    const shuffledPatterns = shuffle([...uniquePatterns]);
    setObjMovePattern(shuffledPatterns);
  };

  const generateAllUniquePatterns = () => {
    return [
      [1, 2, 3],
      [1, 3, 2],
      [2, 1, 3],
      [2, 3, 1],
      [3, 1, 2],
      [3, 2, 1],
    ];
  };

  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  return (
    <>
      <div className="cups-container">
        <div className="win-message">
          <span
            className={`glitch ${
              winMessage == "you lose" ? "lost-glitch" : ""
            }`}
            data-glitch={winMessage}
          ></span>
        </div>
        <div id="ball" className="pf-ball" />
        <div className="cup cup-1" id="cup1" onClick={firstCup && clickCup} />
        <div className="cup cup-2" id="cup2" onClick={firstCup && clickCup} />
        <div className="cup cup-3" id="cup3" onClick={firstCup && clickCup} />
      </div>
      <div className="game-btn-wrapper" id="pfBtnWrapper">
        <button id="pfBtnStart" onClick={startGame}>
          <span> Start Game </span>
        </button>
        <span className="game-price">1000</span>
      </div>
    </>
  );
};

export default PhantonFind;
