import React from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

/* comp imports */
// import FaqsSection from "./FaqsSection";

/* img imports */

const LegalMain = () => {
  return (
    <>
      <main>
        <section className="content-section white-color-section speckle-bg extra-section-margin extra-section-padding extra-section-margin-bott extra-section-padding-bott">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">legal</h3>

                      {/* <div className="section-content pay-page">

                      </div> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        <section className="content-section white-color-section legal-content-section speckle-bg extra-section-margin extra-section-padding extra-section-padding-bott">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Row>
                        <Col md={3} className="tabs-col">
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="first">
                                privacy policy
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second">
                                terms & conditions
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col md={9} className="ps-0 ps-md-5">
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <div className="section-content-wrapper">
                                <div className="section-heading mt-0 pay-page">
                                  privacy policy
                                </div>
                                <p>
                                  This Privacy Policy outlines how{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  ("we", "our", or "us") collects, uses,
                                  maintains, and discloses information collected
                                  from users ("you" or "your") of the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  application.
                                </p>
                                <p>
                                  This Privacy Policy ("Privacy Policy" or
                                  "Policy") outlines the methods and principles
                                  governing our collection, use, processing, and
                                  disclosure of your Personal Data in connection
                                  with your use of our products, services, and
                                  our website. Please note that this Policy does
                                  not apply to our partners, each of whom may
                                  maintain their own privacy policy. In
                                  situations where you interact with such
                                  partners, we strongly encourage you to review
                                  the privacy policy applicable to that
                                  particular site, service or interaction.
                                </p>

                                <p>
                                  <strong>
                                    By using our products, services, and
                                    website, you hereby acknowledge that you
                                    have read, understood, and agree to the
                                    processing of your Personal Data in
                                    accordance with the terms of this Privacy
                                    Policy and our Terms of Use.
                                  </strong>
                                </p>

                                <div className="content-head">
                                  Information Collection:
                                </div>
                                <p>
                                  As per this Policy,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  collects and processes the following types of
                                  information:
                                </p>
                                <p>
                                  <strong>A. User-Provided Information:</strong>
                                </p>
                                <ul>
                                  <li>
                                    To utilize the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    application, it is necessary for you to
                                    provide specific data during the
                                    registration process and for your activities
                                    within the application.
                                  </li>
                                  <li>
                                    During registration, we may collect personal
                                    details such as your name, mobile number,
                                    email address, date of birth, and Social
                                    Security Number.
                                  </li>
                                  <li>
                                    For certain services, additional information
                                    may be required, including but not limited
                                    to your residential address, financial
                                    details, Cha-Ching score, Cha-Ching/debit
                                    card information, and any other officially
                                    valid documents (OVDs).
                                  </li>
                                  <li>
                                    Failure to provide the required data may
                                    result in certain features being restricted,
                                    unavailable, or unusable. For example, not
                                    providing your address would prevent the
                                    completion of e-commerce transactions
                                    requiring delivery.
                                  </li>
                                  <li>
                                    If you choose to grant device permissions
                                    such as access to your contact list, photos,
                                    camera, location, microphone, SMS, storage,
                                    phone calls, and NFC,
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    may access, store, and utilize the data
                                    obtained through such permissions.
                                  </li>
                                  <li>
                                    You have the right to revoke the
                                    aforementioned access by adjusting access
                                    permissions within your device's settings.
                                  </li>
                                </ul>
                                <p>
                                  <strong>
                                    B. Information Generated Through the Use of
                                    the
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    Application:
                                  </strong>
                                </p>
                                <p>
                                  When you utilize our application or website,
                                  we collect information such as:
                                </p>
                                <ul>
                                  <li>
                                    Details regarding the services you utilize
                                    via the
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    application, your interactions with the
                                    application, rewards redeemed, and
                                    transactional details related to your use of
                                    our services or services offered through our
                                    partners. This includes the types of
                                    services you request, your chosen payment
                                    method, transaction amounts, and other
                                    related transactional and financial data.
                                    Additionally, data is generated during your
                                    general interactions with
                                    <span className="comp-name">ChaChing</span>,
                                    including instances of customer support.
                                  </li>
                                  <li>
                                    Upon accessing our application or websites,
                                    we may log specific information, including
                                    but not limited to your IP address, browser
                                    type, mobile operating system, manufacturer
                                    and model of your mobile device,
                                    geolocation, preferred language, access
                                    time, and duration of use
                                  </li>
                                </ul>
                                <p>
                                  <strong>
                                    C. Information we collect from third parties
                                  </strong>
                                </p>
                                <p>
                                  Upon receiving your explicit consent, we may
                                  request certain third parties to provide
                                  information about you to furnish specific
                                  services and authenticate your information.
                                </p>
                                <ul>
                                  <li>
                                    To verify your eligibility to use the{" "}
                                    <span className="comp-name">ChaChing</span>
                                    application, you will be asked to provide
                                    explicit consent for procuring your
                                    Cha-Ching information from our bureau
                                    partners during the onboarding process.
                                  </li>
                                  <li>
                                    To access{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    Crest ,{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    Bucks, or{" "}
                                    <span className="comp-name">ChaChing</span>
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    WheelDeal services, it may be necessary to
                                    undertake the Know Your Customer (KYC)
                                    process, which may entail{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    and its partners procuring your KYC data
                                    from one or more KYC registries.
                                  </li>
                                  <li>
                                    To effectuate a financial transaction, we
                                    may disseminate financial information
                                    provided by you (such as Cha-Ching card
                                    details, tokens or other payment mode
                                    particulars) to authorized third parties,
                                    for instance, our business associates,
                                    financial institutions, or government
                                    authorities involved in the fulfillment of
                                    the said transactions.
                                  </li>
                                  <li>
                                    Blockchain Data to ensure parties using our
                                    Services are not engaged in illegal or
                                    prohibited activity and to analyze
                                    transaction trends for research and
                                    development purposes.
                                  </li>
                                  <li>
                                    To access certain{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    Garage services, we may provide your name,
                                    phone number, and financial information to
                                    our authorized third parties, who may access
                                    information about you and your vehicles
                                    (such as challan details, insurance details,
                                    etc.) from government sources. For detailed
                                    terms, please see the terms of use and
                                    privacy policy available at www.daspl.co.in.
                                  </li>
                                  <li>
                                    To assist in login and user verification on
                                    third-party applications.
                                  </li>
                                </ul>
                                <div className="content-head">
                                  Utilization of information
                                </div>
                                <p>
                                  The information collected may be utilized,
                                  stored, and processed by us to
                                </p>
                                <ul>
                                  <li>
                                    deliver, personalize, measure, and enhance
                                    our products and services and,
                                  </li>
                                  <li>
                                    establish and maintain a secure and trusted
                                    environment on{" "}
                                    <span className="comp-name">ChaChing</span>,
                                    including compliance with our legal
                                    obligations and adherence to our policies.
                                  </li>
                                </ul>
                                <p>
                                  <strong>
                                    A. Delivery, Personalization, Measurement,
                                    and Enhancement of Services Offered through
                                    the{" "}
                                    <span className="comp-name">ChaChing</span>
                                    Application:
                                  </strong>
                                </p>
                                <p>
                                  We may use your personal data to fulfill the
                                  contract of services via the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  application, for legitimate business
                                  interests, and for regulatory compliance
                                  purposes.
                                </p>
                                <p>
                                  Your Personal Data is essential for creating
                                  and updating your account, delivering
                                  services, processing transactions, and for
                                  crucial internal functions such as software
                                  bug troubleshooting, operational problem
                                  resolution, data analysis, testing, research,
                                  and monitoring usage and activity trends.
                                  Additionally, we may utilize your Personal
                                  Data for data analytics to enhance the user
                                  experience, improve performance, and achieve
                                  desired outcomes. We will
                                  pseudonymize/anonymize your data wherever
                                  possible to uphold your privacy.
                                </p>
                                <p>
                                  With your explicit and prior consent, we may
                                  utilize the information generated by your
                                  usage of our application, excluding data
                                  collected from other sources such as emails,
                                  for promotional purposes. This consent is
                                  entirely voluntary, and you can opt-out of
                                  receiving marketing materials from us at any
                                  time by following the unsubscribe instructions
                                  provided, indicating your preference when we
                                  contact you, or by directly contacting us. If
                                  you wish to remove your contact information
                                  from all our lists and newsletters, please
                                  click the unsubscribe link provided in the
                                  emails or send an email request to{" "}
                                  <a href="mailto:support@chaching.club">
                                    support@ChaChing.club
                                  </a>
                                  .
                                </p>
                                <p>
                                  <strong>
                                    B. Establishing and Maintaining a Secure and
                                    Trusted Environment on{" "}
                                    <span className="comp-name">ChaChing</span>:
                                  </strong>
                                </p>
                                <ul>
                                  <li>
                                    We may utilize your Personal Data, generated
                                    from the payment services you utilize, to
                                    ensure compliance with our legal obligations
                                    (such as anti-money laundering regulations)
                                    regarding your access and use of payment
                                    services. This information may be shared
                                    with our advisors, third-party service
                                    partners, and providers to facilitate a
                                    seamless user experience.
                                  </li>
                                  <li>
                                    This information may be employed to identify
                                    and prevent fraud, spam, abuse, security
                                    incidents, and other harmful activities.
                                  </li>
                                  <li>
                                    The information we gather (including
                                    recordings of customer support calls and
                                    chats) may be utilized to assist you during
                                    interactions with our customer support
                                    services, investigate and address your
                                    queries, and monitor and enhance our
                                    customer support responses. Additionally,
                                    this information may be utilized for staff
                                    training, quality assurance, or to maintain
                                    evidence of specific transactions or
                                    interactions.
                                  </li>
                                </ul>
                                <p>
                                  <strong>
                                    C. Disclosure and Sharing of Data with Third
                                    Parties:
                                  </strong>
                                </p>
                                <p>
                                  Several products offered by{" "}
                                  <span className="comp-name">ChaChing</span>,
                                  including but not limited to{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Cash,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Mint,{" "}
                                  <span className="comp-name">ChaChing</span>
                                  Garage, and insurance policies or covers
                                  available through the{" "}
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  application, are provided in collaboration
                                  with{" "}
                                  <span className="comp-name">ChaChing</span>'s
                                  commercial partners. When you utilize such
                                  products or services, any data you provide and
                                  data collected from your app usage will be
                                  shared with the respective third parties
                                  partnered with
                                  <span className="comp-name">ChaChing</span>.
                                  Their use of this data will be governed by
                                  their terms and conditions and privacy
                                  policies, including sharing with their
                                  subcontractors, if any.
                                </p>
                                <p>
                                  In compliance with legal requirements, we may
                                  be required to disclose your Personal Data to
                                  relevant regulatory authorities. With your
                                  explicit consent, we may disclose certain
                                  information generated by your app usage to{" "}
                                  <span className="comp-name">ChaChing</span>'s
                                  group entities and partners that are not
                                  acting as our suppliers or business partners.
                                  It's important to note that we do not sell or
                                  lease such information. Some of our reward
                                  promotions, campaigns, programs, or related
                                  events may be co-branded or sponsored by third
                                  parties in collaboration with us.
                                </p>
                                <p>
                                  If you choose to participate in such
                                  promotions, campaigns, programs, or events,
                                  please be aware that your information may be
                                  collected and shared with those third parties.
                                  We recommend reviewing their privacy policies
                                  to understand how they will handle your
                                  information.
                                </p>
                                <p>
                                  We may display targeted or non-targeted
                                  third-party online advertisements within the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  app. Additionally, we may collaborate with
                                  other website/app operators. We encourage you
                                  to familiarize yourself with their advertising
                                  practices, including the types of information
                                  they may collect. No Personal Data is shared
                                  with any third-party online advertiser, and{" "}
                                  <span className="comp-name">ChaChing</span>
                                  does not provide any information about your
                                  app usage to such third-party online
                                  advertisers.
                                </p>
                                <div className="content-head">
                                  Cross border transfers
                                </div>
                                <p>
                                  <span className="comp-name">ChaChing</span> is
                                  an international business with operations in
                                  countries including the UK, the EU, the US,
                                  Asia etc This means we may transfer to
                                  locations outside of your country. When we
                                  transfer your personal information to another
                                  country, we will ensure that any transfer of
                                  your personal information is compliant with
                                  applicable data protection law.
                                </p>
                                <div className="content-head">top of form</div>
                                <div className="content-head">cookies</div>
                                <p>
                                  Cookies are small blocks of data created by a
                                  web server while a user is browsing a website
                                  and placed on the user's device.
                                </p>
                                <p>
                                  We utilize cookies to accumulate small
                                  fragments of information on designated
                                  portions of the application to facilitate
                                  analysis of application Services, user
                                  engagement, and measurement of promotional
                                  effectiveness. Kindly note that specific
                                  features offered through the application are
                                  only available via a "cookie."
                                </p>
                                <p>
                                  Cookies are used to reduce the frequency of
                                  password entry during a session. Cookies
                                  further assist us in obtaining information
                                  beneficial for tailoring Services more
                                  precisely to your interests. You have the
                                  discretion to decline our cookies if your
                                  device allows, although this may limit your
                                  ability to utilize certain features on the
                                  application, and you may need to provide your
                                  password more frequently during a session.
                                </p>
                                <div className="content-head">security</div>
                                <p>
                                  We adopt reasonable safeguards to protect your
                                  personal data from unauthorized access, use
                                  and disclosure.
                                </p>
                                <p>
                                  We are committed to preserving the integrity
                                  of your Personal Data and maintaining its
                                  accuracy. We adopt reasonable physical,
                                  administrative, and technical safeguards to
                                  protect your Personal Data from unauthorized
                                  access, use, and disclosure. For instance,
                                  sensitive personal data such as Cha-Ching card
                                  information, is encrypted when transmitted
                                  over the internet. Furthermore, we ensure that
                                  our commercial partners and vendors safeguard
                                  such information. We anonymize or pseudonymize
                                  your data wherever feasible to uphold your
                                  privacy.
                                </p>
                                <p>
                                  We integrate security measures at multiple
                                  levels within our products and employ
                                  state-of-the-art technology to ensure robust
                                  security measures in our systems. This
                                  comprehensive security and privacy design
                                  allows us to defend our systems from potential
                                  threats. More details regarding this can be
                                  found here.
                                </p>
                                <p>
                                  When using the application, you may encounter
                                  links to third-party websites/apps not
                                  affiliated with{" "}
                                  <span className="comp-name">ChaChing</span>.
                                  Please note that{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  holds no responsibility for their privacy
                                  practices, content of those other websites, or
                                  any acts/omissions by such third parties
                                  during your transaction with them.
                                </p>
                                <p>
                                  If you are a security enthusiast or researcher
                                  and detect a potential security vulnerability
                                  within
                                  <span className="comp-name">ChaChing</span>'s
                                  products, we urge you to responsibly report
                                  the issue to us. Kindly submit a detailed bug
                                  report, including steps required to reproduce
                                  the vulnerability, to us at{" "}
                                  <a href="mailto:support@chaching.club">
                                    support@ChaChing.club
                                  </a>
                                  . We pledge our best efforts to investigate
                                  and rectify legitimate issues within a
                                  reasonable timeframe while requesting you not
                                  to disclose it publicly.
                                </p>
                                <div className="content-head">
                                  account termination
                                </div>
                                <p>
                                  We provide an option to all our users to
                                  delete or terminate their account, as well as
                                  reactivating it when required.
                                </p>
                                <p>
                                  We extend an option to all our Users to
                                  petition for the deletion of their account via
                                  the support section of the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  application. Upon receiving such a request,
                                  all information corresponding to the specific
                                  account, including but not limited to profile
                                  details, card data, reward specifics, referral
                                  data, statement particulars, Google OAuth
                                  sessions, will be eradicated.
                                </p>
                                <p>
                                  There might be scenarios where we may not be
                                  able to execute account deletion, such as if
                                  there exists an outstanding dispute, Cha-Ching
                                  products availed through the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  application, transactions suspected to be
                                  fraudulent, unresolved claims attached to your
                                  account. Notwithstanding, upon resolution of
                                  the obstruction preventing deletion, the
                                  relevant information is promptly deleted and
                                  cannot be retrieved thereafter. We may
                                  continue to retain certain information if
                                  deemed necessary for regulatory compliance,
                                  legitimate business interests like fraud
                                  prevention, enhancing user safety and
                                  security, or to fulfilll our legal and
                                  contractual obligations.
                                </p>
                                <p>
                                  In addition to this, you have the opportunity
                                  to request an account deactivation/archival.
                                  This will provisionally inhibit your access to{" "}
                                  <span className="comp-name">ChaChing</span>
                                  application until you forward a reactivation
                                  request to{" "}
                                  <a href="mailto:support@chaching.club">
                                    support@ChaChing.club
                                  </a>{" "}
                                  and successfully reactivate your account.
                                </p>
                                <div className="content-head">
                                  access and queries
                                </div>
                                <p>
                                  We support and encourage all users to contact
                                  us for questions, concerns, or suggestions
                                  relating to our privacy policy.
                                </p>
                                <p>
                                  Under certain circumstances, you might be able
                                  to view or modify your personal data online.
                                  If your information is not accessible online,
                                  and you desire to procure a copy of specific
                                  information you provided to us, or if you
                                  become aware of inaccuracies in the data, we
                                  encourage you to reach out to us forthwith for
                                  correction.
                                </p>
                                <p>
                                  Before we can supply you with any information
                                  or rectify any inaccuracies, we may ask you to
                                  validate your identity and provide additional
                                  details to confirm your identity and
                                  facilitate our response to your request. We
                                  commit to reaching out to you within 30 days
                                  of your request.
                                </p>
                                <p>
                                  For questions, concerns, or suggestions
                                  relating to our Privacy Policy, we can be
                                  contacted via the details on our "Contact Us"
                                  page or at{" "}
                                  <a href="mailto:support@chaching.club">
                                    support@ChaChing.club
                                  </a>
                                  .
                                </p>
                                <div className="content-head">retention</div>
                                <p>
                                  Retention of personal data is only for the
                                  duration necessary for us to fulfil the
                                  purposes mentioned in the privacy policy.
                                </p>
                                <p>
                                  Personal Data will be retained only for the
                                  duration necessary to fulfill the purposes
                                  elucidated in this Privacy Policy, unless a
                                  longer retention period is necessitated by law
                                  or for directly related legitimate business
                                  purposes. Once the Personal Data is no longer
                                  required, it will be disposed of securely.
                                </p>
                                <div className="content-head">
                                  modifications to privacy policy
                                </div>
                                <p>
                                  <span className="comp-name">ChaChing</span>{" "}
                                  reserves the right to alter, modify and amend
                                  this policy at any point of time, taking
                                  effect immediately after updating.
                                </p>
                                <p>
                                  <span className="comp-name">ChaChing</span>{" "}
                                  reserves the right to modify this policy at
                                  its discretion from time to time. Any
                                  amendments shall take effect immediately upon
                                  posting the revised Privacy Policy. We advise
                                  you to periodically review this page for the
                                  most recent information on our privacy
                                  practices. Your usage of the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  application shall be deemed to be consent to
                                  the Privacy Policy as modified from time to
                                  time.
                                </p>
                                <div className="content-head">compliance</div>
                                <p>
                                  We ensure adherence to industry-standard best
                                  practices and applicable controls in line with
                                  the required certifications.
                                </p>
                                <p>
                                  Our company is in full compliance with ISO
                                  27701: 2019[4] and 27001:2013 certifications,
                                  demonstrating our commitment to maintaining
                                  and implementing requisite Privacy and
                                  Information Security policies and procedures.
                                  We ensure adherence to industry-standard best
                                  practices and applicable controls in line with
                                  these certifications.
                                </p>
                                <p>
                                  We have successfully complied with "Data
                                  localization" regulations in accordance with
                                  applicable American laws. All payment data is
                                  securely stored within the territorial
                                  boundaries of the United States, ensuring
                                  compliance with domestic regulations and
                                  standards regarding data protection and
                                  security.
                                </p>
                                <div className="content-head">Contact us</div>
                                <p>
                                  In the event of any grievance pertaining to
                                  our privacy policy or practices involving the
                                  usage of data, the privacy@ChaChing.club may
                                  be contacted.
                                </p>
                                <p>
                                  If you have any questions or concerns about
                                  this Privacy Policy or our privacy practices
                                  regarding cryptocurrency and cross-currency
                                  transactions, please contact us at{" "}
                                  <a href="mailto:support@chaching.club">
                                    support@ChaChing.club
                                  </a>
                                  .
                                </p>
                                <p>
                                  By using{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  for cryptocurrency and cross-currency
                                  transactions, you acknowledge and agree to the
                                  terms of this Privacy Policy.
                                </p>
                                <div className="content-head">
                                  Section A.{" "}
                                  <span className="comp-name">ChaChing</span> IT
                                  PRODUCTS
                                </div>
                                <p>
                                  This section pertains solely to users who
                                  avail of the services of{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Cash and/or{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Flash facilitated through the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  application. The
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  application operates as a digital lending
                                  application (DLA) with{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  functioning as a lending service provider
                                  (LSP) to diverse banks and non-banking
                                  financial companies (Lenders). This
                                  facilitates users' access to personal loans or
                                  similar products from the Lenders (Cha-Ching
                                  Products). A comprehensive list of lenders is
                                  available at{" "}
                                  <span className="comp-name">ChaChing</span>
                                  .club/terms. Be advised that, in addition to
                                  this privacy policy, you may be subject to the
                                  privacy policies of the Lenders as well as
                                  other obligations stipulated in the loan
                                  documents.
                                </p>
                                <div className="content-head">
                                  A. Data Collection and Usage
                                </div>
                                <ul>
                                  <li>
                                    As a constituent of the Cha-Ching Product
                                    application process,{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    may share data you have previously provided
                                    with the Lender(s) and / or certain insurers
                                    (if you avail of insurance with your
                                    Cha-Ching Product). Additional information
                                    may be required during the application
                                    process. Information specifically provided
                                    to access Cha-Ching Products or generated
                                    upon successful receipt of Cha-Ching from
                                    the Lenders will be retained to the extent
                                    required by
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    to fulfill its obligations.
                                  </li>
                                  <li>
                                    Pertaining to the information specifically
                                    provided during the Cha-Ching Product
                                    application process,
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    and the lender shall collectively decide and
                                    adhere to a clear policy guideline regarding
                                    the storage of customer data, including data
                                    type, storage duration, data usage
                                    restrictions, data destruction protocol,
                                    security breach handling standards, and
                                    more.
                                  </li>
                                  <li>
                                    <span className="comp-name">ChaChing</span>{" "}
                                    will not collect or store biometric data
                                    within the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    application.
                                  </li>
                                  <li>
                                    <span className="comp-name">ChaChing</span>{" "}
                                    application will not access mobile phone
                                    resources such as files and media, contact
                                    lists, call logs, telephony functions, etc.,
                                    in relation to the Cha-Ching Products or as
                                    part of its role as a DLA/LSP. However, with
                                    your explicit prior consent,
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    may seek one-time access to facilities like
                                    the camera, microphone, location, or any
                                    other necessity for onboarding/KYC
                                    requirements in connection with Cha-Ching
                                    Products.
                                  </li>
                                </ul>
                                <div className="content-head">
                                  B. Data Storage
                                </div>
                                <p>
                                  All data will be housed in servers located
                                  within the USA.
                                </p>
                                <div className="content-head">
                                  C. Access Revocation and Data Deletion
                                </div>
                                <p>
                                  You may revoke access to various access
                                  permissions such as contacts permission and
                                  SMS permission via your mobile operating
                                  system's app settings or similar functions.
                                  You may also request deletion of your data in
                                  accordance with the account termination
                                  section as set out above. However,{" "}
                                  <span className="comp-name">ChaChing</span> as
                                  LSP and the Lenders may still retain data as
                                  required by applicable law, to the extent that
                                  any amounts are outstanding under any
                                  Cha-Ching Products or till the time approved
                                  in-principle Cha-Ching limit is available to
                                  You, or to the extent any fraud or fraudulent
                                  transactions are suspected in connection with
                                  the Cha-Ching Products.
                                </p>
                                <div className="content-head">
                                  D. Data Sharing
                                </div>
                                <p>
                                  1. <span className="comp-name">ChaChing</span>{" "}
                                  uses the following sub-contractors/technology
                                  service providers to offer Cha-Ching Products.
                                  Some of your data/data concerning Cha-Ching
                                  Products may be shared with these entities
                                  and/or stored in their systems:
                                </p>
                                <ul>
                                  <li>
                                    Amazon Web Services - Indian servers, US
                                    servers and
                                  </li>
                                  <li>more...</li>
                                </ul>
                                <p>
                                  2. As an LSP,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  provides the Lender's collections/recovery
                                  services. To perform this function, if any
                                  amount is overdue with respect to any{" "}
                                  <span className="comp-name">ChaChing</span> it
                                  Product, your data may be shared with the
                                  following sub-contractors, who may contact you
                                  for debt counselling and to encourage
                                  repayment of outstanding amounts:
                                </p>
                                <ul>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                  <li></li>
                                </ul>
                                <p>
                                  3. When you utilize Cha-Ching Products to
                                  execute payments to merchants, relevant
                                  transaction data may be shared and stored by
                                  the pertinent payment aggregators and/or the
                                  merchants.
                                </p>
                                <p>
                                  4. When you utilize{" "}
                                  <span className="comp-name">ChaChing</span> it
                                  Products to execute payments to merchants,
                                  relevant transaction data may be shared and
                                  stored by the pertinent payment aggregators
                                  and/or the merchants.
                                </p>
                                <div className="content-head">
                                  ISSUED IN MEMBER INTEREST BY{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  2024
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="section-content-wrapper">
                                <div className="section-heading mt-0 pay-page">
                                  Terms & conditions
                                </div>
                                <p>Last updated on 7th march 2024</p>
                                <p>
                                  Welcome to the{" "}
                                  <span className="comp-name">ChaChing</span>!
                                  At <span className="comp-name">ChaChing</span>{" "}
                                  we are creating a system that strives to
                                  reward high trust and Fiscally responsible
                                  individuals of the USA, inspiring others to be
                                  like them.
                                </p>
                                <div className="content-head">Eligibility</div>
                                <p>
                                  By registering with{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  and accessing our services, you are
                                  representing the following:
                                </p>
                                <ul>
                                  <li>You are 18 years of age or older;</li>
                                  <li>
                                    You are capable of entering into a legally
                                    binding agreement; and
                                  </li>
                                  <li>
                                    You are not barred or otherwise legally
                                    prohibited from accessing or using the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App and{" "}
                                    <span className="comp-name">ChaChing</span>
                                    Services.
                                  </li>
                                  <li>
                                    You reside in a country in which the
                                    relevant{" "}
                                    <span className="comp-name">ChaChing</span>
                                    Services are accessible. the list of
                                    non-supported countries can be found{" "}
                                    <a
                                      href="/nolink"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      here
                                    </a>
                                  </li>
                                  <li>
                                    you are willing to provide to us any current
                                    valid personal identification documents that
                                    we request{" "}
                                  </li>
                                </ul>
                                <p>
                                  You agree to seek your credit score from
                                  Credit Information Companies using the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App and disclose the said credit score to{" "}
                                  <span className="comp-name">ChaChing</span>.{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  may use the said credit score to determine
                                  your eligibility for access to various
                                  Services.{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  may at its sole discretion prevent Users from
                                  accessing the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App and the other Platforms in part or in
                                  whole if the credit score of a User is lower
                                  than a minimum entry threshold set by{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  from time to time.
                                </p>
                                <p>
                                  If you allow anyone to use your account,
                                  including individuals under 18 years of age,
                                  you will still be responsible for ensuring
                                  that such individuals comply with these Terms
                                  of Use. You will be responsible for all
                                  actions these individuals take in and/or
                                  through your account. You also acknowledge
                                  that{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  does not have the responsibility of ensuring
                                  that You meet the aforesaid eligibility
                                  requirements.
                                </p>
                                <p>
                                  Please read the terms and conditions carefully
                                  before registering, accessing or using the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Services (defined below) offered through the{" "}
                                  <span className="comp-name">ChaChing</span>
                                  .club website,{" "}
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  App (defined below) or through applications or
                                  These terms and conditions are a legal
                                  contract ("Terms of Use") between You and{" "}
                                  <span className="comp-name">ChaChing</span>
                                  having its registered office in the United
                                  State of America.
                                </p>
                                <p>
                                  You agree and acknowledge that you have read
                                  the terms and conditions set forth below. If
                                  you do not agree to these Terms of Use or do
                                  not wish to be bound by these Terms of Use,
                                  you may not use the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Services and/or immediately terminate the
                                  Services and/or uninstall the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App.
                                </p>
                                <div className="content-head">definitions</div>
                                <p>
                                  "<span className="comp-name">ChaChing</span>",
                                  "We", "Us", "Our" refer to{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  and its affiliated entities.
                                </p>
                                <p>
                                  "You", "Yours", "Yourself", "User" refer to
                                  any potential or registered user of{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  and its affiliated entities, including but not
                                  limited to customers and Commercial Partners.
                                </p>
                                <p>
                                  "<span className="comp-name">ChaChing</span>{" "}
                                  App" denotes mobile application(s) provided by
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  and its affiliated entities for delivering
                                  services to Users and Commercial Partners.
                                </p>
                                <p>
                                  "<span className="comp-name">ChaChing</span>{" "}
                                  Group Entities" encompasses subsidiaries and
                                  affiliates of{" "}
                                  <span className="comp-name">ChaChing</span>.
                                </p>
                                <p>
                                  "<span className="comp-name">ChaChing</span>{" "}
                                  Platform" encompasses any platform owned,
                                  subscribed to, or used by{" "}
                                  <span className="comp-name">ChaChing</span> or
                                  its affiliated entities, including but not
                                  limited to the{" "}
                                  <span className="comp-name">ChaChing</span>
                                  Website, the{" "}
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  App, other mobile applications, devices,
                                  URLs/links, notifications, chatbots,{" "}
                                  <span className="comp-name">ChaChing</span>’s
                                  social media channels, or any other
                                  communication medium or mechanism used by{" "}
                                  <span className="comp-name">ChaChing</span> or
                                  its affiliated entities to provide services to
                                  Users.
                                </p>
                                <p>
                                  "<span className="comp-name">ChaChing</span>{" "}
                                  Website" refers to https://
                                  <span className="comp-name">ChaChing</span>
                                  .club or any other website hosted or
                                  registered by{" "}
                                  <span className="comp-name">ChaChing</span> or
                                  its affiliated entities.
                                </p>
                                <p>
                                  "<span className="comp-name">ChaChing</span>{" "}
                                  Services" encompass all services offered or to
                                  be offered by{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  and its affiliated entities, either directly
                                  or through its Commercial Partners, including
                                  but not limited to{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Max Services,
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  Cash Services, Credit Card Bill Payment
                                  Services,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Pay Services, Rewards, Crypto Fiat Currency
                                  Conversion, Crypto Wallets, and{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Store Services; and in relation to Merchants,
                                  also include Payment Aggregator Services.
                                </p>
                                <p>
                                  "Commercial Partner" refers to any individual
                                  or entity with whom{" "}
                                  <span className="comp-name">ChaChing</span> or
                                  its affiliated entities have a contractual
                                  relationship, including but not limited to
                                  entities offering rewards on the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App, Sellers, Lenders,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Pay Merchants, etc.
                                </p>
                                <p>
                                  "Sellers" refers to entities offering goods
                                  for sale to Users via the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Store.
                                </p>
                                <p>
                                  "Terms of Use" / "Terms and Conditions" are
                                  used interchangeably and mean these Terms of
                                  Service, including the Supplemental Terms.
                                </p>
                                <div className="content-head">
                                  Your Use of{" "}
                                  <span className="comp-name">ChaChing</span>
                                </div>
                                <p>You agree that:</p>
                                <ul>
                                  <li>
                                    Unless otherwise permitted by{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    in writing, You may only possess one
                                    account.
                                  </li>
                                  <li>
                                    Your use of the App shall not violate any
                                    applicable law or regulation.
                                  </li>
                                  <li>
                                    Your use of the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App, and{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    Platforms shall be only for personal
                                    purposes.
                                  </li>
                                  <li>
                                    All registration information you submit is
                                    truthful, complete and accurate and you
                                    agree to maintain accurate, complete and
                                    up-to-date account information in your
                                    account.
                                  </li>
                                  <li>
                                    You are responsible for all activity that
                                    occurs under your account.
                                  </li>
                                  <li>
                                    You are responsible for maintaining the
                                    confidentiality of the access credentials of
                                    your account and are fully responsible for
                                    all activities that occur under your
                                    account. You agree to immediately notify{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    of any unauthorized use of your passcode or
                                    account or any other breach of security.
                                  </li>
                                  <li>
                                    You agree that You are aware and responsible
                                    for all transactions taking place through
                                    your Account. You shall continue to be
                                    responsible for the transactions in your
                                    account, if you knowingly or negligently (i)
                                    grant any other person access to your{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App, (ii) permit them to transact on Your
                                    account, or (iii) transact on any other
                                    person's behalf or directions; whether by
                                    sharing one-time passwords or in any other
                                    manner.{" "}
                                  </li>
                                  <li>
                                    You shall not indulge in decompiling,
                                    reverse engineering, disassembling content,
                                    removing any intellectual property right
                                    associated with the
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    App, including our copyright, trademarks,
                                    trade secrets, designs, and patents, or
                                    other proprietary notices.
                                  </li>
                                  <li>
                                    You shall not access or use the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App in any manner that (i) may be harmful to
                                    the operation of the
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    App or its content; (ii) may be unlawful;
                                    (iii) maybe harmful to{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    or to any other User; (iv) may hinder the
                                    other User's enjoyment of the
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    App; or (v) to defraud other Users,{" "}
                                    <span className="comp-name">ChaChing</span>
                                    or any Commercial Partner.
                                  </li>
                                  <li>
                                    You shall not post, distribute, or otherwise
                                    transmit or make available any software or
                                    other computer files that contain a virus,
                                    other harmful component or malicious
                                    content, or otherwise impair or damage the
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    App or any connected network, or otherwise
                                    interfere with any person or entity's use or
                                    enjoyment of the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App.
                                  </li>
                                  <li>
                                    You shall not delete or modify any content
                                    of the
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    App.
                                  </li>
                                  <li>
                                    Your use of the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App shall indicate that you have provided
                                    consent to automatically receive updates
                                    such as bug fixes, patches, enhanced
                                    functions, missing plug-ins and new versions
                                    (collectively, 'Updates'), for the purpose
                                    of effective delivery of the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    Services. Please note that your continued
                                    use of the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App following such Updates would mean deemed
                                    acceptance by you of the same.
                                  </li>
                                  <li>
                                    You understand and accept that not all
                                    products, services and rewards offered on
                                    the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App are available in all geographic areas
                                    and you may not be eligible for all the
                                    products, services and rewards offered by{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    on the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App or other
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    Platforms.{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    reserves the right to determine the
                                    availability and eligibility for any
                                    product, services and rewards offered on the{" "}
                                    <span className="comp-name">ChaChing</span>
                                    App or any{" "}
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    Platform.
                                  </li>
                                  <li>
                                    You shall request{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    , to block the Account and change the
                                    passcode immediately for the account, if
                                    your device has been lost or stolen.
                                  </li>
                                  <li>
                                    You agree that if you purchase any insurance
                                    policies via the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App, the nominee for such policy shall be
                                    Your legal heirs (if stated therein, and if
                                    not stated, the same shall be read to mean
                                    that no nominees are listed), and You will
                                    contact the respective insurer who has
                                    issued you the policy to make any updates to
                                    the nominees.{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    and the
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    Group Entities shall not be responsible for
                                    any change required to be made in the list
                                    of nominees to Your insurance policies.
                                  </li>
                                  <li>
                                    When utilizing the Services, you are
                                    purchasing Cryptocurrency from or selling
                                    Cryptocurrency to
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    directly.{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    does not serve as an intermediary or
                                    marketplace between other Cryptocurrency
                                    buyers and sellers.
                                  </li>
                                  <li>
                                    <span className="comp-name">ChaChing</span>{" "}
                                    will dispatch/deliver Cryptocurrency to the
                                    Wallet address specified during the process,
                                    subject to the terms of this Agreement.
                                  </li>
                                  <li>
                                    <span className="comp-name">ChaChing</span>{" "}
                                    will not have possession or control of
                                    client funds at any point during the
                                    transaction.
                                  </li>
                                  <li>
                                    <span className="comp-name">ChaChing</span>{" "}
                                    does not act as a custodian of your funds or
                                    assets.
                                  </li>
                                  <li>
                                    Transactions done through{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    are executed individually, one by one.
                                  </li>
                                </ul>
                                <div className="content-head">
                                  Cryptocurrency orders
                                </div>
                                <ul>
                                  <li>
                                    You will be able to swap. Cryptocurrencies
                                    (if in a supported region) from and to us,
                                    through our Site and through Partner Sites
                                    subject to the applicable fees and limits
                                    displayed during the purchase and sale flow.
                                    The price, exchange rate and amount of the
                                    Cryptocurrency that you wish to purchase or
                                    sell will be confirmed at the time that you
                                    place an Order with us.
                                  </li>
                                  <li>
                                    Acceptance by us of an Order does not
                                    guarantee that you will receive the
                                    corresponding amount of Cryptocurrency or
                                    fiat currency. The Cryptocurrency Order is
                                    conditional upon actual receipt by us of the
                                    funds from your credit or debit card (or in
                                    the case of a swap, the applicable
                                    Cryptocurrency), as well as payment of any
                                    applicable fees.
                                  </li>
                                  <li>
                                    The Order being honoured by the Customer’s
                                    bank, card provider or other relevant party,
                                    Cryptocurrency purchases and swaps shall be
                                    credited to any Cryptocurrency Wallet, as
                                    provided by you at the time of the Order, as
                                    soon as possible once the Cryptocurrency
                                    purchase has been confirmed by the
                                    Cryptocurrency network. Once submitted to a
                                    Cryptocurrency network, a Cryptocurrency
                                    purchase will be unconfirmed for a period of
                                    time pending sufficient confirmation of the
                                    Order by the Cryptocurrency network.
                                    Cryptocurrency Orders that are in a pending
                                    state will not be credited to the Wallet.
                                  </li>
                                  <li>
                                    Cryptocurrency sales shall be debited to any
                                    Cryptocurrency Wallet, as provided by you at
                                    the time of the Order, as soon as possible
                                    once the Cryptocurrency sale has been
                                    confirmed by the Cryptocurrency network.
                                    Once submitted to a Cryptocurrency network,
                                    a Cryptocurrency sale will be unconfirmed
                                    for a period of time pending sufficient
                                    confirmation of the Order by the
                                    Cryptocurrency network. Cryptocurrency
                                    Orders that are in a pending state will not
                                    be debited to the Wallet. Once the
                                    Cryptocurrency sale is confirmed, fiat funds
                                    will be credited to the Customer's bank,
                                    card provider or other relevant party.
                                  </li>
                                  <li>
                                    Recurring Transactions. If you set up a
                                    recurring purchase or sale of
                                    Cryptocurrencies (a "Future Transaction"),
                                    you authorise us to initiate recurring
                                    electronic payments in accordance with your
                                    selected Cryptocurrency. Your Future
                                    Transactions will occur in identical,
                                    periodic instalments, based on your period
                                    selection (e.g., daily, weekly, monthly),
                                    until either you or{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    cancels the Future Transaction. This
                                    authorisation will remain in full force and
                                    effect until you change your Future
                                    Transaction settings or until you provide us
                                    with written notice via{" "}
                                    <a href="mailto:support@chaching.club">
                                      support@ChaChing.club
                                    </a>
                                  </li>
                                  <li>
                                    Please note that as part of accessing our
                                    Services, you may be required to sign up to
                                    separate and independent terms when using
                                    the Partner Sites.
                                  </li>
                                </ul>
                                <div className="content-head">
                                  Cryptocurrency storage
                                </div>
                                <ul>
                                  <li>
                                    When using the Services, you will be asked
                                    to provide us with the address for your
                                    Wallet by either:
                                  </li>
                                  <ul>
                                    <li>
                                      providing a QR code which represents your
                                      Wallet address;
                                    </li>
                                    <li>
                                      manually typing your Wallet address;
                                    </li>
                                    <li>
                                      using a Wallet address automatically
                                      provided by a
                                      <span className="comp-name">
                                        ChaChing
                                      </span>{" "}
                                      partner.
                                    </li>
                                  </ul>

                                  <li>
                                    It is your responsibility to provide us with
                                    a true, accurate and complete Wallet address
                                    when carrying out an Order. It is therefore
                                    important that the Wallet address that you
                                    provide for an Order is correct. You
                                    understand and agree that{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    accepts no liability for you providing an
                                    incorrect or inaccurate Wallet address as
                                    part of an Order. By providing a Wallet
                                    address to us, you confirm that this is the
                                    Wallet address that should be used for the
                                    relevant Order and we will not, and have no
                                    responsibility to, check whether you have
                                    provided a correct and accurate Wallet
                                    address for the Order.
                                  </li>

                                  <li>
                                    You acknowledge and agree, without prejudice
                                    to any other terms in this Agreement, that
                                    you bear all of the risk of any loss of
                                    access to your Wallet(s) and any
                                    Cryptocurrencies contained in your
                                    Wallet(s).
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    does not control and is not responsible for
                                    the Cryptocurrencies in your Wallet(s). You
                                    are solely responsible for any
                                    Cryptocurrencies transferred to or from your
                                    Wallet(s) and we make no, and hereby
                                    disclaim all, representations, warranties,
                                    claims and assurances as to any such
                                    transactions. If you lose your keys to your
                                    Wallet(s), you may lose access to your
                                    Wallet(s) and any Cryptocurrencies contained
                                    in your Wallet(s).{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    is not liable for fluctuations in the fiat
                                    currency value of Cryptocurrencies in your
                                    Wallet.
                                  </li>
                                </ul>
                                <div className="content-head">
                                  Violation of Terms of Use / Suspicious
                                  Activity
                                </div>
                                <p>
                                  If We believe that you have violated any of
                                  the conditions as mentioned under these Terms
                                  of Use, our Privacy Policy, Supplemental Terms
                                  applicable to certain services or any
                                  agreements consented to via the{" "}
                                  <span className="comp-name">ChaChing</span>
                                  App, we reserve the right to suspend your
                                  access to the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  App and/or delete your account without prior
                                  notice to You. If We have reason to believe
                                  that there is suspicious or unusual activity
                                  being carried out through your account, we may
                                  temporarily or permanently suspend your access
                                  to the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Services. If we suspect that any feature,
                                  offer or reward promotion is being availed by
                                  You in a manner (i) that is disingenuous (for
                                  example: placing orders without intending to
                                  take delivery or intending to subsequently
                                  cancel all or substantially all of them); (ii)
                                  that it is not intended to be availed; (iii)
                                  that purports to artificially increase (or
                                  decrease) your transaction volume or usage of
                                  the App (for example: paying rent or credit
                                  card bills by breaking them down into numerous
                                  smaller transactions); (iv) that is designed
                                  to deliberately side step fair use
                                  restrictions; (v) that may result in you
                                  gaining an undue, unfair or unethical
                                  advantage over other Users or Commercial
                                  Partners; (vi) that is intended to cause
                                  losses or other harm to Commercial Partners,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  reserves the right to reverse the relevant
                                  transaction, suspend the account or restrict
                                  you from using the App in its entirety or any
                                  specific feature(s) thereof. You may reach out
                                  to the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  support team to assist you with any query or
                                  question arising as a result of the
                                  aforementioned suspension/ deletion.
                                </p>
                                <div className="content-head">
                                  Termination / Deletion of Your Account
                                </div>
                                <p>
                                  You may end your legal agreement with{" "}
                                  <span className="comp-name">ChaChing</span> at
                                  any time by deactivating your account and
                                  discontinuing the use of the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Services. We provide all our Users an option
                                  through which a User may request archive of a
                                  specific card stored on his/her account or
                                  deletion/deactivation of the complete account
                                  through the support section on the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App.
                                </p>
                                <p>
                                  Following a request for deletion, or
                                  termination of account by{" "}
                                  <span className="comp-name">ChaChing</span>,
                                  We consider the agreement to be terminated and
                                  take appropriate steps. Provided however,
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  may continue to maintain transaction records
                                  for record keeping purposes and/or regulatory
                                  reasons. Deletion of the account or
                                  Termination also does not terminate Your
                                  obligations undertaken prior to deletion or
                                  termination of the account including but not
                                  limited to the obligation to repay any loan or
                                  credit line availed by You.{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  may continue to retain a record of your
                                  transactions made on the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App for regulatory and archival purposes.
                                </p>
                                <div className="content-head">fees</div>
                                <p>
                                  You are responsible for any fees that may be
                                  applicable to certain transactions or use of
                                  the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App; where you will be notified of such
                                  applicable fees, prior to the completion of
                                  any transaction. By clicking 'Proceed' /
                                  'Submit' / 'Continue' or any other similar
                                  button on the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App, You shall be deemed to have consented to
                                  the fees displayed on the said page.
                                </p>
                                <div className="content-head">
                                  Loyalty Points
                                </div>
                                <p>
                                  <span className="comp-name">ChaChing</span>{" "}
                                  offers its users loyalty points in various
                                  forms for their regular and continuous use of
                                  the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  App. These loyalty points are provided for
                                  performing various actions on the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App. Each user who makes his/her credit card
                                  payments through the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  App earns loyalty points ("{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Bucks" or "bucks"), and the number of{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  bucks earned may vary per transaction. These
                                  loyalty points /{" "}
                                  <span className="comp-name">ChaChing</span>
                                  bucks are rewarded purely for the regular use
                                  of the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  App and the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App does not permit any purchase or accrual of
                                  these loyalty points in any other way. These
                                  loyalty points may be redeemed by Users to
                                  participate in various rewards/offers that are
                                  run by the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App for all users from time to time.
                                </p>
                                <p>
                                  <span className="comp-name">ChaChing</span>{" "}
                                  reserves the right, at its sole discretion, to
                                  change the mode of the accrual of one or any
                                  of these types of loyalty points, including
                                  the number of such loyalty points received in
                                  proportion to the User's activities. Further,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  reserves the right to disqualify any User, who
                                  does not meet the offer requirements or for
                                  any other reason including but not limited to
                                  any misuse of the offer or fraud or suspicious
                                  transaction/activity or under any legal
                                  requirement or applicable rules and
                                  regulations, from receiving any loyalty
                                  points.{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  also reserves the right to discontinue or
                                  change or issue any new form of loyalty points
                                  offered at any time, at its sole discretion.{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  may also at its discretion specify an expiry
                                  period for the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Bucks.
                                </p>
                                <div className="content-head">Privacy</div>
                                <p>
                                  We collect, hold, use and transfer your
                                  personal data in accordance with our Privacy
                                  Policy. By agreeing to the Terms of Use, you
                                  also hereby agree to our Privacy Policy, which
                                  may be updated and/or modified by us from time
                                  to time. You understand and agree that, to the
                                  extent permitted by applicable law, any data
                                  provided by you in connection with the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Services may be shared with our subsidiaries,
                                  afﬁliates or partners, and/ or used by us for
                                  enhancing the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Services, including but not limited to
                                  creating new products. Please refer to the
                                  Privacy Policy for further clarity on the
                                  subject.
                                </p>
                                <div className="content-head">Disclaimer</div>
                                <p>
                                  The{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Services, including all content, software,
                                  functions, material, and information made
                                  available or accessible through the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Platform are provided "as is".{" "}
                                  <span className="comp-name">ChaChing</span>,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Group Entities and their respective agents,
                                  co-branders or partners, make no
                                  representation and warranty of any kind for
                                  the content, software, functions, material,
                                  and information available/accessible through
                                  the Services.
                                </p>
                                <p>
                                  <span className="comp-name">ChaChing</span>{" "}
                                  does not warrant that the functions contained
                                  in content, information and materials on the{" "}
                                  <span className="comp-name">ChaChing</span>
                                  App, including, without limitation any
                                  third-party sites or services linked to the{" "}
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  App or any{" "}
                                  <span className="comp-name">ChaChing</span>
                                  Platform will be uninterrupted, timely or
                                  error-free, that the defects will be
                                  rectified, or that the{" "}
                                  <span className="comp-name">ChaChing</span>
                                  App or the servers that make such content,
                                  information, and materials available are free
                                  of viruses or other harmful components.
                                </p>
                                <p>
                                  Further, You understand that a payment
                                  transaction is solely between the User who
                                  uses the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Services to make his payment ("Sender") and
                                  the User who receives such payment from the
                                  Sender ("Recipient") and that
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  does not provide any guarantees or warranties
                                  to any User with respect any service, goods,
                                  or delivery level commitment provided by the
                                  Recipient.{" "}
                                  <span className="comp-name">ChaChing</span>
                                  cannot assure that Commercial Partners or
                                  other Users are or will be complying with the
                                  foregoing Terms of Use or any other provisions
                                  mentioned here. You assume all risk of harm or
                                  injury resulting from any such lack of
                                  compliance by any other User or Commercial
                                  Partner. You should ensure that you have
                                  undertaken adequate due diligence prior to
                                  transferring payments using the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  App.
                                </p>
                                <div className="content-head">
                                  Limitation of Liability
                                </div>
                                <p>
                                  In no event shall{" "}
                                  <span className="comp-name">ChaChing</span>,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Group Entities, their officers, shareholders,
                                  subsidiaries, associate companies, directors,
                                  employees and agents, partners, co-branders,
                                  licensors, licensees, consultants, or
                                  contractors be liable to you or any third
                                  party for any special, incidental, indirect,
                                  consequential or punitive damages or losses
                                  whatsoever, or damages for loss of data or
                                  profits, goodwill, and/ or other intangible
                                  loss, whether or not foreseeable and
                                  regardless of whether{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  has been advised of the possibility of such
                                  damages, or based on any theory of liability,
                                  including breach of contract or warranty,
                                  negligence or other tortious action, or any
                                  other claim arising out of or in connection
                                  with your use of, or access to, the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  Platforms,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App or{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Services.
                                </p>
                                <p>
                                  In no event shall{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  and{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Group Entities' total cumulative liability to
                                  You in connection with the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  Services for all damages, losses and causes of
                                  action (whether arising under contract or
                                  otherwise), arising from or relating to Your
                                  use of the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App and{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Platforms or arising from these Terms of Use
                                  exceed $10,000 ( ten thousand dollars only).
                                </p>
                                <div className="content-head">Indemnity</div>
                                <p>
                                  You agree to defend, indemnify and hold
                                  harmless
                                  <span className="comp-name">
                                    ChaChing
                                  </span>,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Group Entities, its officers, owners,
                                  directors, employees and agents, partners,
                                  co-branders, licensors, licensees,
                                  consultants, contractors and other applicable
                                  third parties (collectively 'Indemnified
                                  Parties') from and against any and all claims,
                                  demands, damages, obligations, losses,
                                  liabilities, cause of action, costs or debt,
                                  and expenses (including any legal fees)
                                  arising from:
                                </p>
                                <ul>
                                  <li>
                                    your use of and access to the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App and the
                                    <span className="comp-name">
                                      ChaChing
                                    </span>{" "}
                                    Platforms;
                                  </li>
                                  <li>
                                    your violation / breach of any of these
                                    Terms of Use;
                                  </li>
                                  <li>
                                    your violation of any third party right,
                                    including any intellectual property right or
                                    privacy right;
                                  </li>
                                  <li>
                                    the committing of any of the prohibited
                                    activities as stated herein;
                                  </li>
                                  <li>
                                    your failure to be in compliance with
                                    applicable law, including tax laws and cyber
                                    security laws; or
                                  </li>
                                  <li>
                                    any claim that your use of the{" "}
                                    <span className="comp-name">ChaChing</span>{" "}
                                    App caused damage to a third party.
                                  </li>
                                </ul>
                                <div className="content-head">
                                  Disputes & Arbitration
                                </div>
                                <p>
                                  In consideration for{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  granting you access to and use of the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Platforms and{" "}
                                  <span className="comp-name">ChaChing</span>
                                  Services, you agree that in case of any
                                  dispute between You and any Commercial Partner
                                  or other User or recipient of the funds
                                  transferred by You,{" "}
                                  <span className="comp-name">ChaChing</span>
                                  shall not be a party to the same. While{" "}
                                  <span className="comp-name">ChaChing</span> is
                                  not obligated to mediate or resolve disputes,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  will assist Users in communicating with each
                                  other regarding a dispute.{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  may at its own discretion however, without
                                  having any obligation to do so, assist in
                                  resolving the disputes between the Users and
                                  the Commercial Partners.
                                </p>
                                <p>
                                  Except as otherwise set forth in these Terms
                                  of Use, these Terms of Use shall be
                                  exclusively governed by and construed in
                                  accordance with the laws of India. Any
                                  dispute, conflict, claim or controversy
                                  arising out of or broadly in connection with
                                  or relating to the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  Services or these Terms, including those
                                  relating to its validity, its construction or
                                  its enforceability, but excluding those
                                  provisions where it has been specified that{" "}
                                  <span className="comp-name">ChaChing</span>'s
                                  decision shall be final, (any "Dispute") shall
                                  be, if initiated by the User, first raised by
                                  the user to{" "}
                                  <a href="mailto:support@chaching.club">
                                    support@ChaChing.club
                                  </a>
                                  . If such Dispute has not been settled within
                                  sixty (60) days after the User reaches out to
                                  the aforementioned email IDs, such Dispute can
                                  be referred to and shall be exclusively and
                                  finally resolved by arbitration under the
                                  Arbitration and Conciliation Act, 1996
                                  ("Act"). Provided however when{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  raises any dispute,
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  may directly initiate arbitration in
                                  accordance with this clause. The Dispute shall
                                  be resolved by one (1) arbitrator to be
                                  appointed by{" "}
                                  <span className="comp-name">ChaChing</span>.
                                  The place the arbitration shall be Bangalore,
                                  India. The language of the arbitration shall
                                  be English. The existence and content of the
                                  arbitration proceedings, including documents
                                  and briefs submitted by the parties, and
                                  correspondence, orders and awards issued by
                                  the sole arbitrator, shall remain strictly
                                  confidential and shall not be disclosed to any
                                  third party without the express written
                                  consent from the other party unless: (i) the
                                  disclosure to the third party is reasonably
                                  required in the context of conducting
                                  arbitration proceedings; and (ii) the third
                                  party agrees unconditionally in writing to be
                                  bound by the confidentiality obligation
                                  stipulated herein.
                                </p>
                                <div className="content-head">
                                  Use and Protection of Intellectual Property
                                  Rights
                                </div>
                                <p>
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Website,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  logos and the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  Services are protected by copyright,
                                  trademarks, patents, trade secret and/or other
                                  intellectual property laws. No information,
                                  content or material from the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App including, without limitation, all of the
                                  page headers, images, illustrations, graphics,
                                  audio clips, video clips or text, reports
                                  generated, trademarks, tradenames may be
                                  copied, reproduced, republished, uploaded,
                                  posted, transmitted or distributed in any way
                                  without Our express written permission. You
                                  are hereby given a limited licence to use the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App and the{" "}
                                  <span className="comp-name">ChaChing</span>
                                  Platforms, subject to your agreement of these
                                  Terms of Use.
                                </p>
                                <p>
                                  <span className="comp-name">ChaChing</span>,{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Group Entities, and its licensors, if any, are
                                  the sole owners of the underlying software and
                                  source code associated with the Chachin App
                                  and{" "}
                                  <span className="comp-name">ChaChing</span>
                                  Platforms as well as any other intellectual
                                  property rights of any other nature associated
                                  with the{" "}
                                  <span className="comp-name">ChaChing</span>
                                  App,{" "}
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  Platforms and their content.
                                </p>
                                <p>
                                  When you upload, submit, store, send or
                                  receive content that may include feedback to
                                  or through the{" "}
                                  <span className="comp-name">ChaChing</span>
                                  App, you give{" "}
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  and{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Group Entities a worldwide, perpetual licence
                                  to use, host, store, reproduce, modify, create
                                  derivative works, communicate, publish,
                                  publicly perform, publicly display and
                                  distribute such content. This licence shall
                                  not expire even if it is not used by{" "}
                                  <span className="comp-name">ChaChing</span> or{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Group Entities. The rights you grant in this
                                  licence are for the limited purpose of
                                  operating, promoting, and improving the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  Services, and to develop new ones. This
                                  licence continues even if you stop using the
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  Services.
                                </p>
                                <div className="content-head">Taxes</div>
                                <p>
                                  You are responsible for determining whether,
                                  and to what extent, any taxes apply to any
                                  transactions associated with these Services.
                                  You must withhold, collect, report and remit
                                  the correct amounts of taxes to the
                                  appropriate tax authorities.
                                </p>
                                <div className="content-head">General</div>
                                <p>
                                  No joint venture, partnership, employment or
                                  agency relationship exists between you,{" "}
                                  <span className="comp-name">ChaChing</span>,{" "}
                                  <span className="comp-name">ChaChing</span>
                                  Group Entities or any Third Party as a result
                                  of the contract contained in these Terms of
                                  Use. If any provision of these Terms of Use is
                                  held to be illegal, invalid or unenforceable,
                                  in whole or in part, under any law, such
                                  provision or part thereof shall to that extent
                                  be deemed not to form part of these Terms of
                                  Use but the legality, validity and
                                  enforceability of the other provisions in
                                  these Terms of Use shall not be affected. In
                                  that event,{" "}
                                  <span className="comp-name">
                                    ChaChing
                                  </span>{" "}
                                  shall replace the illegal, invalid or
                                  unenforceable provision or part thereof with a
                                  provision or part thereof that is legal, valid
                                  and enforceable and that has, to the greatest
                                  extent possible, a similar effect as the
                                  illegal, invalid or unenforceable provision or
                                  part thereof, given the contents and purpose
                                  of these Terms of Use. These Terms of Use
                                  (together with any additional terms displayed
                                  in specific sections of the{" "}
                                  <span className="comp-name">ChaChing</span>{" "}
                                  App or{" "}
                                  <span className="comp-name">ChaChing</span>
                                  Website) constitute the entire agreement and
                                  understanding of the parties with respect to
                                  its subject matter and replaces and supersedes
                                  all prior or contemporaneous agreements or
                                  undertakings regarding such subject matter.
                                </p>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section white-color-section">
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default LegalMain;
