import React from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../../assets/img/img-line-main-2.png";

/* comp imports */
import MainWrapper from "../MainWrapper";
import MainCareerNavbar from "./MainCareerNavbar";
import Footer from "../Footer";
import FooterCareer from "./FooterCareer";
import CareerForm from "./CareerForm";

const PerformanceMarketingSpecialist = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        performance marketing specialist
                      </h3>
                      <div className="section-content">
                        we're seeking a savvy performance marketing specialist
                        to lead our efforts in driving user engagement,
                        acquisition, and retention through innovative marketing
                        strategies.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section white-color-section speckle-bg career-details-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 mb-5 mb-md-1">
                    <div className="section-content-wrapper">
                      <div className="content-head mt-0">responsibilities</div>

                      <ul>
                        <li>
                          develop and implement performance marketing campaigns
                          to maximize user acquisition and engagement for{" "}
                          <span className="comp-name">ChaChing</span> rewards.
                        </li>
                        <li>
                          utilize data-driven insights to optimize campaign
                          performance and roi.
                        </li>
                        <li>
                          collaborate with cross-functional teams to ensure
                          alignment and maximize impact.
                        </li>
                        <li>
                          stay ahead of industry trends and identify new
                          opportunities to enhance our marketing efforts.
                        </li>
                        <li>
                          monitor and analyze campaign metrics to track
                          performance and identify areas for improvement.
                        </li>
                      </ul>

                      <div className="content-head mt-0">requirements</div>
                      <ul>
                        <li>
                          proven experience in performance marketing, with a
                          track record of driving results.
                        </li>
                        <li>
                          strong analytical skills and the ability to translate
                          data into actionable insights.
                        </li>
                        <li>
                          creative thinker with a passion for experimenting with
                          new ideas and strategies.
                        </li>
                        <li>
                          excellent communication and collaboration skills.
                        </li>
                        <li>
                          deep understanding of gen z consumer behavior and
                          preferences.
                        </li>
                      </ul>
                      <p>
                        if you're ready to make a significant impact and help us
                        create unforgettable experiences for our users, apply
                        now!
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="section-content-wrapper">
                      <CareerForm />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default PerformanceMarketingSpecialist;
