import React from "react";
import { currencyIcons } from "../../components/currencyIcons";

const AvailableCurrencyListReceive = ({
  showCurrencyDropdownReceive,
  handleShowCurrencyDropdown,
  currencyData,
  selectedReceiveCurrency,
  setSelectedReceiveCurrency,
  setShowCurrencyDropdownReceive,
}) => {
  return (
    <>
      <div className="selected-currency-wrapper">
        {Object.keys(selectedReceiveCurrency).length && (
          <div
            className="currency-block"
            onClick={() => {
              handleShowCurrencyDropdown("receive");
            }}
          >
            <span className="icon-wrap">
              <img src={currencyIcons[selectedReceiveCurrency.img]} alt="" />
            </span>
            <span className="curr-text">{selectedReceiveCurrency.text}</span>
          </div>
        )}
      </div>
      {showCurrencyDropdownReceive && (
        <div className="currency-list-wrapper scrollbar-success">
          {currencyData.length
            ? currencyData.map((currVal, index) => {
                return (
                  <div
                    className="currency-block"
                    key={index}
                    onClick={() => {
                      setSelectedReceiveCurrency((prev) => ({
                        ...prev,
                        img: currVal.img,
                        text: currVal.text,
                      }));
                      setShowCurrencyDropdownReceive(false);
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={currencyIcons[currVal.img]} alt="" />
                    </span>
                    <span className="curr-text">{currVal.text}</span>
                  </div>
                );
              })
            : ""}
        </div>
      )}
    </>
  );
};

export default AvailableCurrencyListReceive;
