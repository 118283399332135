import React, { useState, useEffect, useContext } from "react";
import PixelBrainQuestion from "./PixelBrainQuestion";
import axios from "axios";
import { quizBaseUrl, endPoints, baseUrl } from "../../data/endPoints";
import pbLoadingScreen from "../../assets/img/gaming/pb-loading-screen.gif";
import { UserContext } from "../../context/UserContextProvider";

const PixelBrain = ({
  setRewardsAmount,
  setShowRewardsPbWon,
  setActiveArr,
}) => {
  const {
    userCognito,
    getUserCognitoData,
    handleGetRewards,
    deductStandardCoin,
  } = useContext(UserContext);

  useEffect(() => {
    getUserCognitoData();
  }, []);

  const [updatedPbCoin, setUpdatedPbCoin] = useState(0);
  const [pbCoinsWon, setPbCoinsWon] = useState(0);
  const [updatedPbMoneyback, setUpdatedPbMoneyback] = useState(0);
  const [moneybackWon, setMoneybackWon] = useState(0);
  const [dbUpdatedPbRewards, setDbUpdatedPbRewards] = useState([false, false]);

  const updatePbCoins = (coin) => {
    setPbCoinsWon(coin);
    let tempRewards = JSON.parse(localStorage.getItem("rewards"));
    if (tempRewards) {
      tempRewards.coin = tempRewards.coin + coin;
      localStorage.setItem("rewards", JSON.stringify(tempRewards));
      setUpdatedPbCoin(tempRewards.coin);
    }
  };

  const updatePbMoneyback = (moneyback) => {
    console.log("moneyback", moneyback);
    setMoneybackWon(moneyback);
    let tempRewards = JSON.parse(localStorage.getItem("rewards"));
    if (tempRewards) {
      tempRewards.moneyback = tempRewards.moneyback + moneyback;
      localStorage.setItem("rewards", JSON.stringify(tempRewards));
      setUpdatedPbMoneyback(tempRewards.moneyback);
    }
  };

  const handleUpdatePbRewards = (
    mobile,
    type,
    transactionType,
    reason,
    value,
    winValue
  ) => {
    let configUpdateRewards = {
      method: "post",
      url: `${baseUrl}${endPoints.updateRewards}`,
      data: {
        mobile: mobile,
        toUpdate: {
          [type]: value,
        },
      },
      withCredentials: true,
    };
    setDbUpdatedPbRewards([false, false]);
    let configUpdateAllRewards = {
      method: "post",
      url: `${baseUrl}${endPoints.addLifetimeReward}`,
      data: {
        mobile: mobile,
        rewardType: type,
        transactionType: transactionType,
        reason: reason,
        details: "",
        voucherCode: "",
        redeemed: false,
        value: winValue,
      },
      withCredentials: true,
    };

    const updateUserNlRewardsData = async () => {
      try {
        const response = await axios.request(configUpdateRewards);
        if (response.data.status) {
          setDbUpdatedPbRewards([true, false]);
        } else {
          setDbUpdatedPbRewards([false, false]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const updateAllUserNlRewardsData = async () => {
      try {
        const response = await axios.request(configUpdateAllRewards);
        if (response.data.status) {
          setDbUpdatedPbRewards([true, true]);
        } else {
          setDbUpdatedPbRewards([false, false]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    updateUserNlRewardsData();
    updateAllUserNlRewardsData();
    setTimeout(() => {
      handleGetRewards(mobile, false);
    }, 1000);
  };

  useEffect(() => {
    if (updatedPbCoin !== 0 && userCognito.mobile && pbCoinsWon) {
      handleUpdatePbRewards(
        userCognito.mobile,
        "coin",
        "received",
        "game - pixel brain",
        updatedPbCoin,
        pbCoinsWon
      );
      setRewardsAmount(pbCoinsWon);
      setShowRewardsPbWon(true);
      console.log(
        "updatedPbCoin",
        updatedPbCoin,
        userCognito.mobile,
        pbCoinsWon
      );
    }
  }, [updatedPbCoin]);

  useEffect(() => {
    if (updatedPbMoneyback !== 0 && userCognito.mobile && moneybackWon) {
      handleUpdatePbRewards(
        userCognito.mobile,
        "moneyback",
        "received",
        "game - pixel brain",
        updatedPbMoneyback,
        moneybackWon
      );
      // setRewardsAmount(moneybackWon);
      // setShowRewardsPbWon(true);
      console.log(
        "updatedPbMoneyback",
        updatedPbMoneyback,
        userCognito.mobile,
        moneybackWon
      );
    }
  }, [updatedPbMoneyback]);

  useEffect(() => {
    if (dbUpdatedPbRewards[0] && dbUpdatedPbRewards[1]) {
      setTimeout(() => {
        setShowRewardsPbWon(false);
        setRewardsAmount(0);
      }, 2500);
    }
  }, [dbUpdatedPbRewards[0], dbUpdatedPbRewards[1]]);

  const [isGameStart, setIsGameStart] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [fetchQuestions, setFetchQuestions] = useState(false);
  const [isRightAnswer, setIsRightAnswer] = useState(true);
  const [isResettingGame, setIsResettingGame] = useState(false);
  const [questionNum, setQuestionNum] = useState("0");

  const showCountdownText = () => {
    setShowCountdown(true);
    setFetchQuestions(true);
    setQuestionNum("0");
    // setIsResettingGame(false);
    setTimeout(() => {
      setShowCountdown(false);
      setIsRightAnswer(true);
    }, 5000);
  };

  const handleGameStart = () => {
    deductStandardCoin();
    setActiveArr([false, false, false, true]);
    showCountdownText();
    document.querySelector("#btnWrapper").style.display = "none";
    setTimeout(() => {
      setIsGameStart(true);
    }, 5000);
  };

  const [responseData, setResponseData] = useState([]);

  let config = {
    method: "get",
    url: `${quizBaseUrl}${endPoints.getQuestion}`,
  };

  const getQuestionsCall = async () => {
    try {
      const response = await axios.request(config);
      if (response.status === 200) {
        if (response.data.results.length) {
          setResponseData(response.data.results);
          setFetchQuestions(false);
        } else {
          showCountdownText();
        }
      } else {
        console.log("Error: API error");
      }
    } catch (error) {
      console.log(error);
      showCountdownText();
    }
  };

  useEffect(() => {
    if (fetchQuestions) {
      getQuestionsCall();
    }
  }, [fetchQuestions]);

  const [questionAnswer, setQuestionAnswer] = useState({});

  useEffect(() => {
    if (responseData.length) {
      //   console.log("questionNum", questionNum);
      if (questionNum < 10) {
        setQuestionAnswer(responseData[questionNum]);
      }
    }
  }, [responseData, questionNum]);

  //   console.log("responseData", responseData);

  return (
    <>
      <div className="pixel-brain-main-wrapper speckle-bg">
        {showCountdown && (
          <div className="message-before-game-start">
            <img src={pbLoadingScreen} alt="" />
            {/* <span>
              <CountUp
                end={0}
                start={5}
                decimals={1}
                duration={5}
                suffix=" sec"
              />
            </span> */}
          </div>
        )}

        {isGameStart && (
          <PixelBrainQuestion
            questionAnswer={questionAnswer}
            setQuestionNum={setQuestionNum}
            questionNum={questionNum}
            handleGameStart={handleGameStart}
            setIsRightAnswer={setIsRightAnswer}
            isRightAnswer={isRightAnswer}
            isResettingGame={isResettingGame}
            setIsResettingGame={setIsResettingGame}
            updatePbCoins={updatePbCoins}
            updatePbMoneyback={updatePbMoneyback}
            setActiveArr={setActiveArr}
          />
        )}

        <div className="game-btn-wrapper" id="btnWrapper">
          <button onClick={handleGameStart}>
            <span> start quiz </span>
          </button>
          <span className="game-price">1000</span>
        </div>
      </div>
    </>
  );
};

export default PixelBrain;
