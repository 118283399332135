import React from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../../assets/img/img-line-main-2.png";

/* comp imports */
import CareerForm from "./CareerForm";

const UiUxDesigner = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        ui/ux designer
                      </h3>
                      <div className="section-content">
                        We are seeking a creative and talented ui/ux Designer to
                        join our growing team. The ideal candidate will have a
                        strong passion for design and a keen eye for detail. As
                        a ui/ux Designer at{" "}
                        <span className="comp-name">ChaChing</span>, you will be
                        responsible for creating visually stunning graphics that
                        effectively communicate our brand message and engage our
                        target audience.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section white-color-section speckle-bg career-details-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 mb-5 mb-md-1">
                    <div className="section-content-wrapper">
                      <div className="content-head mt-0">responsibilities</div>

                      <ul>
                        <li>
                          designing and producing various types of digital and
                          print materials, including but not limited to:
                          marketing collateral, social media graphics, website
                          assets, presentations, and infographics.
                        </li>
                        <li>
                          collaborating with the marketing team to conceptualize
                          and execute creative campaigns that align with our
                          brand identity and objectives.
                        </li>
                        <li>
                          generating original ideas and concepts while also
                          adhering to brand guidelines and design standards.
                        </li>
                        <li>
                          working closely with other team members to ensure
                          timely delivery of design projects and maintaining
                          consistency across all platforms.
                        </li>
                        <li>
                          keeping up-to-date with industry trends and best
                          practices to continuously improve the quality of our
                          visual content.
                        </li>
                      </ul>

                      <div className="content-head mt-0">requirements</div>
                      <ul>
                        <li>
                          bachelor's degree in graphic design or related field.
                        </li>
                        <li>
                          proven experience as a graphic / ui/ux designer,
                          preferably in a fast-paced startup environment or
                          creative agency.
                        </li>
                        <li>
                          proficiency in adobe creative suite (photoshop,
                          illustrator, indesign) and other graphic design tools.
                        </li>
                        <li>
                          strong understanding of typography, color theory,
                          layout, and composition.
                        </li>
                        <li>
                          excellent communication skills and ability to
                          effectively collaborate with cross-functional teams.
                        </li>
                        <li>
                          detail-oriented with a high level of accuracy and a
                          commitment to meeting deadlines.
                        </li>
                        <li>
                          portfolio showcasing a diverse range of design
                          projects and styles.
                        </li>
                      </ul>
                      <p>
                        if you're passionate about design and looking to make an
                        impact in the fintech industry, we'd love to hear from
                        you! please submit your resume and portfolio for
                        consideration.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="section-content-wrapper">
                      <CareerForm />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default UiUxDesigner;
