import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import { baseUrl, endPoints } from "../data/endPoints";

// import { voucherDataList } from "../data/voucherData";

/* comp imports */
import Voucher from "./Voucher";

const Vouchers = () => {
  const [voucherDataList, setVoucherDataList] = useState([]);
  let configGetAllVouchers = {
    method: "post",
    url: `${baseUrl}${endPoints.getAllVouchers}`,
    headers: {},
    data: {},
    withCredentials: true,
  };

  const getAllVouchers = async () => {
    try {
      const response = await axios.request(configGetAllVouchers);
      if (response.data.status) {
        setVoucherDataList(response.data.data);
      } else {
        console.log("Error: Cannot get vouchers");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVouchers();
  }, []);

  return (
    <div className="col-12 col-md-6 vouchers-col">
      <Tabs defaultActiveKey="coupons" id="coupons-tab-comp" fill>
        <Tab eventKey="coupons" title="coupons">
          <div className="vouchers-main-wrapper">
            {voucherDataList && voucherDataList.length
              ? voucherDataList
                  .filter((val) => {
                    return val.type === "coupon";
                  })
                  .map((value) => {
                    return (
                      <Voucher
                        key={value.voucherId}
                        voucherData={value && value}
                      />
                    );
                  })
              : "No coupons yet, better luck is coming!"}
          </div>
        </Tab>
        <Tab eventKey="tickets" title="tickets">
          <div className="vouchers-main-wrapper">
            <div className="vouchers-main-wrapper">
              {voucherDataList && voucherDataList.length
                ? voucherDataList
                    .filter((val) => {
                      return val.type === "ticket";
                    })
                    .map((value) => {
                      return (
                        <Voucher
                          key={value.voucherId}
                          voucherData={value && value}
                        />
                      );
                    })
                : "No tickets yet, better luck is coming!"}
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Vouchers;
