import React from "react";

import imgLineMain from "../assets/img/img-line-main.png";

const FaqsSection = () => {
  return (
    <>
      <section className="faqs-section speckle-bg">
        <img src={imgLineMain} alt="" className="img-line" />
        <div className="shape"></div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="faq-wrapper">
                <div className="faq-question">
                  about <span className="comp-name">ChaChing</span>
                </div>
                <div className="faq-answer">
                  <span className="comp-name">ChaChing</span> is a members-only
                  credit card bill payment platform that rewards users for
                  clearing their credit card bills on time. Members gain access
                  to exclusive rewards from premier brands.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  how does <span className="comp-name">ChaChing</span> reward
                  their users?
                </div>
                <div className="faq-answer">
                  <span className="comp-name">ChaChing</span> partners with top
                  brands to provide unmatched experiences at the end of every
                  payment cycle.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  how to earn rewards on{" "}
                  <span className="comp-name">ChaChing</span>?
                </div>
                <div className="faq-answer">
                  Members earn <span className="comp-name">ChaChing</span> Coins
                  and Candies.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  <span className="comp-name">ChaChing</span> coins:
                </div>
                <div className="faq-answer">
                  - Earn a coin for every dollar paid. <br />- Redeem coins for
                  exclusive brand rewards.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  <span className="comp-name">ChaChing</span> Candies:
                </div>
                <div className="faq-answer">
                  - Earn for successful referrals. <br />- Use candies for
                  special offers.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  Security Measures - Is{" "}
                  <span className="comp-name">ChaChing</span> Secure?
                </div>
                <div className="faq-answer">
                  - Advanced encryption protects transactions and personal
                  information. <br />- Ensures data safety and zero errors.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  what do you get as a{" "}
                  <span className="comp-name">ChaChing</span> member?
                </div>
                <div className="faq-answer">
                  - Seamless payment experience via net banking, debit cards,
                  and crypto wallets. <br />
                  - Timely bill payment reminders. <br />
                  - Automate payments. <br />
                  - Expose hidden charges with ChaChing Shield. <br />
                  - Real-time alerts on duplicate transactions. <br />
                  - Manage multiple credit cards on one portal. <br />-
                  Real-time credit score checks.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  how does one become a member of{" "}
                  <span className="comp-name">ChaChing</span>?
                </div>
                <div className="faq-answer">
                  Sign up on <span className="comp-name">ChaChing</span> with
                  your name and valid mobile number (issued within the USA).
                  Membership requires a credit score of 750 or above.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  how to know your credit score?
                </div>
                <div className="faq-answer">
                  Upon membership, check and refresh your credit score with a
                  single click. Access updated credit scores anytime without
                  extra charges, helping you maintain a healthy credit status.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">Additional Member Benefits</div>
                <div className="faq-answer">
                  - Exclusive Store Access: Members-only store with curated
                  products and experiences at special prices. <br />- Payment
                  Flexibility: Use net banking, debit cards, crypto wallets, and
                  more for a hassle-free experience.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  What if I don't have a 750 credit score?
                </div>
                <div className="faq-answer">
                  <span className="comp-name">ChaChing</span> is designed for
                  members with a credit score of 750 or above. Consider
                  improving your score to qualify.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  How do I refer friends and earn Candies?
                </div>
                <div className="faq-answer">
                  Refer friends using only your mobile number. Earn 10 Candies
                  for each successful referral.
                </div>
              </div>
              <div className="faq-wrapper">
                <div className="faq-question">
                  What if my credit score decreases?
                </div>
                <div className="faq-answer">
                  Don't worry; you won't be removed. A minimum score of 750 is
                  required only at registration.
                </div>
              </div>
              {/* <div className="calculators-wrapper">
                calculators: <a href="/">sip calculator</a>,{" "}
                <a href="/">emi calculator</a>, <a href="/">ppf calculator</a>,{" "}
                <a href="/">home loan calculator</a>,{" "}
                <a href="/">car loan calculator</a>,{" "}
                <a href="/">fixed deposit calculator</a>,{" "}
                <a href="/">recurring deposit calculator</a>,{" "}
                <a href="/">simple loan calculator</a>,{" "}
                <a href="/">compound interest calculator</a>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqsSection;
