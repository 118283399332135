import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../../context/CartContextProvider";

/* img imports */
import dollarIcon from "../../assets/img/currency-icons/dollarIcon.svg";
import desoIcon from "../../assets/img/currency-icons/desoIcon.svg";
import ImagesSlider from "./ImagesSlider";

const ProductBlock = ({ productData, handleProductRedirect, desoRate }) => {
  const { images, offerMrp, skuName, skuId } = productData;
  const { getIsAdded, dispCartNumber, addToCartHandler, btnVariants } =
    useContext(CartContext);

  // console.log("images", images);

  const [cartRandomNum, setCartRandomNum] = useState(0);
  const [isAdded, setIsAdded] = useState(false);

  let imgUrlFinal = `https://www.everythingstore.io/static/www/images/productImages/370x370/${images[0]}`;
  useEffect(() => {
    let randonNum = Math.floor(Math.random() * 5);
    setCartRandomNum(randonNum);
  }, [productData]);

  useEffect(() => {
    if ((dispCartNumber, skuId)) {
      if (getIsAdded(skuId)) {
        setIsAdded(true);
      }
    }
  }, [dispCartNumber, skuId]);

  return (
    <>
      <div className="product-wrapper">
        <div
          className="img-container"
          onClick={() => {
            handleProductRedirect(skuId, cartRandomNum);
          }}
        >
          <ImagesSlider images={images.length && images} />
          {/* <picture>
            <img src={imgUrlFinal} alt="" />
          </picture> */}
        </div>

        <div className="product-info">
          <span
            className="prod-title"
            onClick={() => {
              handleProductRedirect(skuId, cartRandomNum);
            }}
          >
            {skuName}
          </span>
          <span className="prod-price-wrap">
            <span>
              <img src={dollarIcon} alt="" />
              <span className="prod-price">
                {(desoRate * offerMrp).toFixed(2)}
              </span>
            </span>
            <button
              type="button"
              className={`prod-cart ${
                btnVariants.length && btnVariants[cartRandomNum]
              } ${isAdded ? "variant-added disabled" : ""}`}
              data-text={isAdded ? "Added" : "Add to Cart"}
              onClick={(e) => {
                addToCartHandler(e, skuId, offerMrp);
              }}
            ></button>
          </span>
        </div>
      </div>
    </>
  );
};

export default ProductBlock;
