import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";

const Refer = () => {
  return (
    <>
      <main>
        <section className="content-section white-color-section speckle-bg extra-section-margin">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <h2>Refer</h2>
              </div>
              <div className="col-12 mt-5">
                referrall code - jwdjkdb | share
              </div>
              <div className="col-12 mt-5">
                <div className="logged-in-tabs mt-2">
                  <Tabs
                    defaultActiveKey="successfulSignUps"
                    id="userAccountTabs"
                    className="mt-2"
                  >
                    <Tab
                      eventKey="successfulSignUps"
                      title="Successful Sign Ups"
                    >
                      <Table striped bordered responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>shared via</th>
                            <th>signup by</th>
                            <th>status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>3 may 2024</td>
                            <td>whatsapp</td>
                            <td>anurag khona</td>
                            <td>active</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>5 may 2024</td>
                            <td>email</td>
                            <td>anurag khona</td>
                            <td>active</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>7 may 2024</td>
                            <td>sms</td>
                            <td>anurag khona</td>
                            <td>active</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>

                    <Tab
                      eventKey="totalRewardsEarned "
                      title="Total Rewards Earned "
                    >
                      <Table striped bordered responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>reward type</th>
                            <th>reward reason</th>
                            <th>reward value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>3 may 2024</td>
                            <td>candies</td>
                            <td>successfull signup</td>
                            <td>1200</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>3 may 2024</td>
                            <td>moneyback</td>
                            <td>1st transaction</td>
                            <td>$7</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>3 may 2024</td>
                            <td>boost</td>
                            <td>5th transaction</td>
                            <td>5000</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                </div>
              </div>

              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default Refer;
