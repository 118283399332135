import React from "react";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";
import PayMain from "../components/PayMain";

const Pay = () => {
  return (
    <>
      <PayMain />
    </>
  );
};

export default Pay;
