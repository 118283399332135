import React from "react";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";
import CheckCreditScoreMain from "../components/CheckCreditScoreMain";

const CheckCreditScore = () => {
  return (
    <>
      <CheckCreditScoreMain />
    </>
  );
};

export default CheckCreditScore;
