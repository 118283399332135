import React, { useState, useEffect } from "react";
import { gsap, Sine } from "gsap";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { walletBankLogos } from "../../components/walletBankLogos";
import { currencyIcons } from "../../components/currencyIcons";

/* img imports */
import closeIconCurve from "../../assets/img/close-icon-curve.svg";
import closeIcon from "../../assets/img/close-icon.svg";
import SwapIcon from "../../assets/img/icons/swap-icon.svg";
import confettiAnimation from "../../assets/img/confetti-animation.gif";

/* comp imports */
import SwapWalletList from "./SwapWalletList";
import AvailableCurrencyListPay from "./AvailableCurrencyListPay";
import AvailableCurrencyListReceive from "./AvailableCurrencyListReceive";

const Swap = ({
  swapModalshow,
  handleSwapClose,
  handleShowSwapWalletList,
  handleCloseSwapWalletList,
  showSwapWallets,
  selectingCurrencyFor,
  setShowSwapWallets,
  isWalletSelectedPay,
  isWalletSelectedReceive,
  setIsWalletSelectedPay,
  setIsWalletSelectedReceive,
  handleShowCurrencyDropdown,
  showCurrencyDropdownPay,
  showCurrencyDropdownReceive,
  selectedPayCurrency,
  selectedReceiveCurrency,
  setSelectedPayCurrency,
  setShowCurrencyDropdownPay,
  setSelectedReceiveCurrency,
  setShowCurrencyDropdownReceive,
  dollarRateConverted,
  setDollarRateConverted,
  receiveCrytoRateConverted,
  setReceiveCrytoRateConverted,
  inputValuePay,
  setInputValuePay,
  swapTransactionCost,
  setSwapTransactionCost,
  swapStarted,
  setSwapStarted,
  setSelectingCurrencyFor,
  swapFinalScreen,
  setSwapFinalScreen,
  isConfirmSwap,
  setIsConfirmSwap,
}) => {
  const handleWalletSelected = (type) => {
    if (type === "pay") {
      setIsWalletSelectedPay(true);
      setShowSwapWallets(false);
    } else if (type === "receive") {
      setIsWalletSelectedReceive(true);
      setShowSwapWallets(false);
    } else {
      setIsWalletSelectedPay(false);
      setIsWalletSelectedReceive(false);
    }
  };

  const [currencyData, setCurrencyData] = useState([]);

  useEffect(() => {
    setCurrencyData([
      { img: "desoIcon", text: "deso" },
      { img: "ethIcon", text: "etherium" },
      { img: "bitcoinIcon", text: "bitcoin" },
      { img: "rippleIcon", text: "ripple" },
      { img: "solanaIcon", text: "solana" },
      { img: "nearIcon", text: "near" },
    ]);
  }, []);

  const [walletBalance, setWalletBalance] = useState(89.45);
  const [walletBalanceReceive, setWalletBalanceReceive] = useState(147.78);

  const setMaxValueHandlerPay = () => {
    setInputValuePay(walletBalance);
  };

  useEffect(() => {
    setDollarRateConverted((Number(inputValuePay) * 3257.86).toFixed(2));
  }, [inputValuePay]);

  useEffect(() => {
    setSwapTransactionCost(
      ((Number(dollarRateConverted) * 1) / 100).toFixed(2)
    );
  }, [dollarRateConverted]);
  useEffect(() => {
    setReceiveCrytoRateConverted((Number(inputValuePay) / 0.0067).toFixed(2));
  }, [swapTransactionCost]);

  const swapStartedHandler = () => {
    setSwapStarted(true);
  };

  useEffect(() => {
    if (swapStarted) {
      let goingUp = {
        duration: 1,
        bottom: "500%",
        opacity: 0,
        ease: Sine.easeOut,
      };
      let goingDown = {
        duration: 1,
        top: "500%",
        opacity: 0,
        ease: Sine.easeOut,
      };
      let moveUp = {
        duration: 1,
        y: "-200%",
        delay: 0.5,
        opacity: 0,
        ease: Sine.easeOut,
      };
      let moveDown = {
        duration: 1,
        y: "200%",
        delay: 0.5,
        opacity: 0,
        ease: Sine.easeOut,
      };
      gsap.to(".up-icon", goingUp);
      gsap.to(".down-icon, .seperator-line", goingDown);
      gsap.to(".seperator-circle", {
        duration: 0.5,
        opacity: 0,
        top: "500%",
        ease: "none",
      });
      gsap.to(".moving-up", moveUp);
      gsap.to(".moving-down", {
        ...moveDown,
        onComplete: () => {
          setSwapFinalScreen(true);
        },
      });
    }
  }, [swapStarted]);

  useEffect(() => {
    if (swapFinalScreen) {
      gsap.fromTo(
        ".swap-complete-wrapper",
        {
          opacity: 0,
        },
        {
          duration: 0.5,
          opacity: 1,
          ease: "none",
        }
      );
      let swapEndAnimation = {
        duration: 0.5,
        delay: 0.1,
        opacity: 1,
        ease: "none",
      };
      gsap.fromTo(
        ".confetti-wrapper",
        {
          opacity: 0,
        },
        { ...swapEndAnimation, opacity: 0.5, delay: 2 }
      );
      gsap.fromTo(
        ".swap-completed-anim,.swap-end-buttons",
        {
          opacity: 0,
        },
        { ...swapEndAnimation, delay: 2.5 }
      );
    }
  }, [swapFinalScreen]);

  const resetSwapWindow = () => {
    setShowSwapWallets(false);
    setSelectingCurrencyFor("");
    setIsWalletSelectedPay(false);
    setIsWalletSelectedReceive(false);
    setShowCurrencyDropdownPay(false);
    setShowCurrencyDropdownReceive(false);
    setDollarRateConverted(0);
    setInputValuePay("");
    setSwapTransactionCost(0);
    setSwapStarted(false);
    setSwapFinalScreen(false);
    setIsConfirmSwap(false);
    gsap.killTweensOf(
      ".up-icon,.down-icon,.seperator-circle,.moving-up,.moving-down,.swap-complete-wrapper,.confetti-wrapper,.swap-completed-anim,.swap-end-buttons"
    );
    gsap.set(".moving-up,.moving-down", {
      opacity: 1,
      y: "0%",
    });
    gsap.set(".seperator-line", {
      opacity: 1,
      top: "50%",
    });
    gsap.set(".seperator-circle", {
      opacity: 1,
      top: "-10px",
    });
    gsap.set(".up-icon", {
      opacity: 1,
      bottom: "25%",
      scale: 1,
    });
    gsap.set(".down-icon", {
      opacity: 1,
      top: "25%",
      scale: 1,
    });
    gsap.set(
      ".swap-complete-wrapper,.confetti-wrapper,.swap-completed-anim,.swap-end-buttons",
      {
        opacity: 0,
      }
    );
  };

  return (
    <Modal
      className="swap-modal"
      show={swapModalshow}
      onHide={handleSwapClose}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <img src={SwapIcon} alt="" />
          Swap
        </Modal.Title>
        <div onClick={handleSwapClose} className="swap-modal-close">
          <img src={closeIconCurve} alt="" />
        </div>
      </Modal.Header>
      <Modal.Body>
        {showSwapWallets && (
          <div className="wallet-list-wrapper">
            <div
              onClick={handleCloseSwapWalletList}
              className="wallet-list-close"
            >
              <img src={closeIcon} alt="" />
            </div>
            <div className="filter-list">
              <Form.Group className="mb-4 main-form-group">
                <Form.Control type="text" placeholder="search wallet" />
              </Form.Group>
            </div>
            <div className="wallet-list">
              <SwapWalletList
                walletBankLogos={walletBankLogos}
                handleWalletSelected={handleWalletSelected}
                selectingCurrencyFor={selectingCurrencyFor}
              />
            </div>
          </div>
        )}

        <div className="swap-currency-section text-center moving-up">
          <span className="swap-heads">You Pay</span>

          {isWalletSelectedPay ? (
            <div className="swap-currency-main-wrapper">
              <div className="swap-currency-dropdown">
                {!isConfirmSwap ? (
                  <AvailableCurrencyListPay
                    handleShowCurrencyDropdown={handleShowCurrencyDropdown}
                    showCurrencyDropdownPay={showCurrencyDropdownPay}
                    currencyData={currencyData}
                    selectedPayCurrency={selectedPayCurrency}
                    setSelectedPayCurrency={setSelectedPayCurrency}
                    setShowCurrencyDropdownPay={setShowCurrencyDropdownPay}
                  />
                ) : (
                  <div className="selected-currency-wrapper selected-currency-wrapper-sc">
                    {Object.keys(selectedPayCurrency).length && (
                      <div className="currency-block -cnfrm">
                        <span className="icon-wrap">
                          <img
                            src={currencyIcons[selectedPayCurrency.img]}
                            alt=""
                          />
                        </span>
                        <span className="curr-text">
                          {selectedPayCurrency.text}
                        </span>
                      </div>
                    )}
                  </div>
                )}

                <div className="currency-balance-block">
                  balance {walletBalance}
                </div>
              </div>
              <div className="swap-currency-amounts">
                <div className="currency-fields main-form-group">
                  {!isConfirmSwap ? (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="0.00"
                      maxLength="6"
                      value={inputValuePay}
                      onChange={(e) => setInputValuePay(e.target.value)}
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control form-control-sc"
                      placeholder="0.00"
                      readOnly={true}
                      defaultValue={inputValuePay}
                    />
                  )}

                  <div className="converted-amount">${dollarRateConverted}</div>
                </div>
                <div className="max-amount">
                  {!isConfirmSwap ? (
                    <button
                      className="starry-btn"
                      type="button"
                      onClick={setMaxValueHandlerPay}
                    >
                      <span className="btn-text">max</span>
                      <div id="container-stars">
                        <div id="stars"></div>
                      </div>

                      <div id="glow">
                        <div className="circle"></div>
                        <div className="circle"></div>
                      </div>
                    </button>
                  ) : (
                    " "
                  )}
                </div>
              </div>
            </div>
          ) : (
            <button
              className="starry-btn mt-4"
              type="button"
              onClick={() => {
                handleShowSwapWalletList("pay");
              }}
            >
              <span className="btn-text">connect wallet</span>
              <div id="container-stars">
                <div id="stars"></div>
              </div>

              <div id="glow">
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
            </button>
          )}
        </div>

        <div className="swap-currency-divider my-4">
          <span className="seperator-line"></span>
          <span className="seperator-circle"></span>
          <span className="swap-icon-block up-icon d-block">
            <span className="material-symbols-outlined">arrow_upward</span>
          </span>
          <span className="swap-icon-block down-icon d-block">
            <span className="material-symbols-outlined">arrow_downward</span>
          </span>
        </div>

        <div className="swap-currency-section text-center moving-down">
          <span className="swap-heads">You receive</span>

          {isWalletSelectedReceive ? (
            <div className="swap-currency-main-wrapper">
              <div className="swap-currency-dropdown">
                {!isConfirmSwap ? (
                  <AvailableCurrencyListReceive
                    handleShowCurrencyDropdown={handleShowCurrencyDropdown}
                    showCurrencyDropdownReceive={showCurrencyDropdownReceive}
                    currencyData={currencyData}
                    selectedReceiveCurrency={selectedReceiveCurrency}
                    setSelectedReceiveCurrency={setSelectedReceiveCurrency}
                    setShowCurrencyDropdownReceive={
                      setShowCurrencyDropdownReceive
                    }
                  />
                ) : (
                  <div className="selected-currency-wrapper selected-currency-wrapper-sc">
                    {Object.keys(selectedReceiveCurrency).length && (
                      <div
                        className="currency-block"
                        onClick={() => {
                          handleShowCurrencyDropdown("receive");
                        }}
                      >
                        <span className="icon-wrap">
                          <img
                            src={currencyIcons[selectedReceiveCurrency.img]}
                            alt=""
                          />
                        </span>
                        <span className="curr-text">
                          {selectedReceiveCurrency.text}
                        </span>
                      </div>
                    )}
                  </div>
                )}

                <div className="currency-balance-block">
                  balance {walletBalanceReceive}
                </div>
              </div>
              <div className="swap-currency-amounts">
                <div className="currency-fields">
                  <div className="receive-amount">
                    {receiveCrytoRateConverted}
                  </div>

                  <div className="converted-amount">
                    $
                    {(
                      dollarRateConverted -
                      (Number(dollarRateConverted) * 1) / 100
                    ).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <button
              className="starry-btn mt-4"
              type="button"
              onClick={() => {
                handleShowSwapWalletList("receive");
              }}
            >
              <span className="btn-text">connect wallet</span>
              <div id="container-stars">
                <div id="stars"></div>
              </div>

              <div id="glow">
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
            </button>
          )}
        </div>

        {inputValuePay != "" && inputValuePay != 0 && (
          <>
            <div className="text-center moving-down">
              {!isConfirmSwap ? (
                <button
                  className="starry-btn my-4"
                  type="button"
                  onClick={() => {
                    setIsConfirmSwap(true);
                  }}
                >
                  <span className="btn-text">swap now</span>
                  <div id="container-stars">
                    <div id="stars"></div>
                  </div>

                  <div id="glow">
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                </button>
              ) : (
                <button
                  className="starry-btn my-4"
                  type="button"
                  onClick={swapStartedHandler}
                >
                  <span className="btn-text">confirm swap</span>
                  <div id="container-stars">
                    <div id="stars"></div>
                  </div>

                  <div id="glow">
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                </button>
              )}
            </div>
            <div className="moving-down">
              <span className="swap-heads">
                Transaction Cost <br />
                1%{" "}
                <span className="highglight-purple">
                  {" "}
                  ($
                  {swapTransactionCost})
                </span>
              </span>
            </div>
          </>
        )}

        {swapFinalScreen && (
          <div className="swap-complete-wrapper">
            <div className="swap-completed-anim">
              <div className="swap-completed-text">swap completed</div>
              <div className="track-link">track on blockchain</div>
            </div>

            <div className="confetti-wrapper">
              <img src={confettiAnimation} alt="" />
            </div>
            <div className="swap-end-buttons">
              <button
                className="starry-btn my-4"
                type="button"
                onClick={resetSwapWindow}
              >
                <span className="btn-text">swap again</span>
                <div id="container-stars">
                  <div id="stars"></div>
                </div>

                <div id="glow">
                  <div className="circle"></div>
                  <div className="circle"></div>
                </div>
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Swap;
