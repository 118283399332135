import React from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../../assets/img/img-line-main-2.png";

/* comp imports */
import MainWrapper from "../MainWrapper";
import MainCareerNavbar from "./MainCareerNavbar";
import Footer from "../Footer";
import FooterCareer from "./FooterCareer";
import CareerForm from "./CareerForm";

const BackendDeveloper = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        backend developer
                      </h3>
                      <div className="section-content">
                        <span className="comp-name">ChaChing</span> is
                        disrupting the financial technology (FinTech) industry
                        with our innovative payment processing platform that
                        rewards users for their responsible financial behavior.
                        We are seeking a talented Backend Developer to join our
                        team and play a pivotal role in designing, developing,
                        and maintaining the backend infrastructure that powers
                        our platform. As a Backend Developer at{" "}
                        <span className="comp-name">ChaChing</span>, you will
                        have the opportunity to work on complex systems,
                        integrate with third-party APIs, and contribute to the
                        development of scalable, secure, and high-performance
                        backend solutions.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section white-color-section speckle-bg career-details-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 mb-5 mb-md-1">
                    <div className="section-content-wrapper">
                      <div className="content-head mt-0">responsibilities</div>

                      <ul>
                        <li>
                          Design, develop, and maintain backend services, APIs,
                          and databases that support the functionality of the
                          <span className="comp-name">ChaChing</span> platform.
                        </li>
                        <li>
                          Collaborate with cross-functional teams, including
                          frontend developers, iOS/Android developers, and
                          product managers, to define requirements and implement
                          features.
                        </li>
                        <li>
                          Implement secure authentication, authorization, and
                          data protection mechanisms to ensure the integrity and
                          confidentiality of user data.
                        </li>
                        <li>
                          Integrate third-party APIs and services, such as
                          payment gateways, banking APIs, and data analytics
                          platforms, into the{" "}
                          <span className="comp-name">ChaChing</span> platform.
                        </li>
                        <li>
                          Optimize backend code and database queries for
                          performance, scalability, and reliability, considering
                          factors such as concurrency, latency, and resource
                          utilization.
                        </li>
                        <li>
                          Monitor, analyze, and troubleshoot production issues
                          and performance bottlenecks, and implement timely
                          solutions to minimize downtime and ensure system
                          stability.
                        </li>
                        <li>
                          Implement automated tests, continuous
                          integration/delivery (CI/CD) pipelines, and deployment
                          strategies to maintain code quality and facilitate
                          rapid iteration and deployment.
                        </li>
                        <li>
                          Ensure compliance with industry regulations and
                          standards, such as PCI DSS, GDPR, and PSD2, and
                          implement best practices for data security and
                          privacy.
                        </li>
                        <li>
                          Collaborate with DevOps engineers to deploy and
                          maintain backend infrastructure in cloud environments,
                          such as AWS, Azure, or Google Cloud Platform.
                        </li>
                        <li>
                          Stay updated with the latest trends, technologies, and
                          best practices in backend development and FinTech
                          industry, and share knowledge with the team.
                        </li>
                      </ul>

                      <div className="content-head mt-0">requirements</div>
                      <ul>
                        <li>
                          Bachelor's degree in Computer Science, Engineering, or
                          a related field (or equivalent work experience).
                        </li>
                        <li>
                          Proven experience as a backend developer, with a
                          strong portfolio of backend projects or services.
                        </li>
                        <li>
                          Proficiency in one or more backend programming
                          languages, such as Python, Node.js, Java, or Go.
                        </li>
                        <li>
                          Experience with backend frameworks and libraries, such
                          as Flask, Django, Express.js, Spring Boot, or Gin.
                        </li>
                        <li>
                          Solid understanding of relational and non-relational
                          databases, such as MySQL, PostgreSQL, MongoDB, or
                          Redis.
                        </li>
                        <li>
                          Experience with RESTful APIs, GraphQL, and other web
                          services protocols, and familiarity with API design
                          principles and best practices.
                        </li>
                        <li>
                          Knowledge of cloud computing platforms,
                          containerization, and orchestration tools, such as
                          Docker, Kubernetes, AWS ECS, or Azure Kubernetes
                          Service.
                        </li>
                        <li>
                          Familiarity with version control systems, such as Git,
                          and collaborative development workflows (e.g.,
                          GitFlow).
                        </li>
                        <li>
                          Strong problem-solving skills, analytical thinking,
                          and attention to detail.
                        </li>
                        <li>
                          Excellent communication skills and the ability to work
                          effectively in a team environment.
                        </li>
                      </ul>
                      <div className="content-head mt-0">
                        Preferred Qualifications
                      </div>
                      <ul>
                        <li>
                          Experience with financial services, payment
                          processing, or related domains.
                        </li>
                        <li>
                          Knowledge of microservices architecture, serverless
                          computing, and event-driven architecture patterns.
                        </li>
                        <li>
                          Familiarity with message brokers, such as RabbitMQ,
                          Kafka, or Amazon SQS, and asynchronous processing
                          techniques.
                        </li>
                        <li>
                          Experience with data modeling, SQL optimization, and
                          performance tuning in large-scale distributed systems.
                        </li>
                        <li>
                          Understanding of security best practices, encryption
                          algorithms, and common vulnerabilities and mitigation
                          techniques.
                        </li>
                        <li>
                          Experience with automated testing frameworks, CI/CD
                          pipelines, and infrastructure-as-code tools, such as
                          Jenkins, CircleCI, Terraform, or Ansible.
                        </li>
                        <li>
                          Contributions to open-source projects, participation
                          in developer communities, or attendance at tech
                          conferences or meetups.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="section-content-wrapper">
                      <CareerForm />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default BackendDeveloper;
