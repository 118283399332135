import React from "react";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";
import FaqsSection from "../components/FaqsSection";

const AboutUs = () => {
  return (
    <>
      <main>
        <section className="content-section white-color-section extra-section-margin speckle-bg about-us-section">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <div className="section-heading pay-page">
                        about <span className="comp-name">ChaChing</span>
                      </div>
                      <p>
                        <span className="comp-name">ChaChing</span> is a premium
                        membership club that rewards timely financial actions
                        with unparalleled access to exclusive offers and premium
                        experiences. As a platform,{" "}
                        <span className="comp-name">ChaChing</span> enables
                        users to seamlessly manage their finances, offering a
                        detailed analysis of spending patterns and credit
                        health. Eligibility for{" "}
                        <span className="comp-name">ChaChing</span>'s rewards
                        program is determined by a user's credit standing, with
                        high scorers gaining access to a diverse array of
                        rewards for every bill paid through the app — be it with
                        US dollars or cryptocurrencies like Bitcoin, all without
                        transaction fees.
                      </p>
                      <p>
                        <span className="comp-name">ChaChing</span> stands out
                        with its advanced spend tracking and management tools,
                        providing users with insightful analytics on their
                        financial behavior and card usage efficiency. At its
                        core, <span className="comp-name">ChaChing</span>{" "}
                        leverages an AI-driven system,{" "}
                        <span className="comp-name">ChaChing</span> Shield, to
                        monitor the nuances of credit card payments, including
                        due date reminders and spend patterns, ensuring users
                        are always on top of their finances.
                      </p>
                      <p>
                        Rewards for using{" "}
                        <span className="comp-name">ChaChing</span> are vast and
                        varied, ranging from exclusive event tickets and unique
                        experiences to gift cards and premium upgrades from
                        leading brands. Membership into this elite club begins
                        by signing up with your name, a valid US mobile number,
                        date of birth, current address & social security number.
                        Following a quick credit score verification from leading
                        bureaus, eligible users are welcomed into the{" "}
                        <span className="comp-name">ChaChing</span> community,
                        where they can immediately start enjoying the curated
                        rewards and privileges that set{" "}
                        <span className="comp-name">ChaChing</span> apart.
                      </p>
                      <p>
                        In essence, <span className="comp-name">ChaChing</span>{" "}
                        is more than just a rewards service; it's a
                        comprehensive financial companion that enhances your
                        credit card usage with smart insights, top-tier
                        security, and a reward system that celebrates financial
                        diligence and innovation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
        {/* <FaqsSection /> */}
      </main>
      {/* <AboutUsMain /> */}
    </>
  );
};

export default AboutUs;
