import React from "react";

const CreditScore = () => {
  return (
    <>
      <main>
        <section className="content-section white-color-section speckle-bg extra-section-margin">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <h2>Credit Score</h2>
              </div>
              <div className="col-12 mt-5">
                Credit score and credit history details will come here
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default CreditScore;
