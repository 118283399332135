import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { UserContext } from "../context/UserContextProvider";
import axios from "axios";
import { baseUrl, endPoints } from "../data/endPoints";
import md5 from "md5";
import { checkAreaCode, authorizedPathsArr } from "../data/custom";

/* img imports */
import mainLogo from "../assets/img/logo/main-logo-site.png";
import userIcon from "../assets/img/icons/user-icon.png";
import userIconIn from "../assets/img/icons/user-icon-in.png";
import confettiAnimation from "../assets/img/confetti-animation.gif";
import closeIcon from "../assets/img/close-icon.svg";

/* comp imports */
import RewardBalances from "./RewardBalances";

const MainNavbar = ({
  isShoppingPage,
  handleSwapModalShow,
  pathName,
  absolutePathName,
}) => {
  const {
    isLoggedIn,
    checkIsLoggedIn,
    isTopBarActive,
    handleCloseTopBar,
    confirmLoginHandler,
    setIsDesktopLoginActive,
    isDesktopLoginActive,
    handleShowLoginScreen,
    showOtpBox,
    otpValue,
    setOtpValue,
    displayOtpBox,
    isMenuActive,
    setIsMenuActive,
    deleteLoginData,
    userCognito,
    getUserCognitoData,
  } = useContext(UserContext);

  useEffect(() => {
    checkIsLoggedIn();
    getUserCognitoData();
  }, [isLoggedIn]);

  const navigate = useNavigate();

  const unAuthorizedRedirect = (pageName) => {
    if (authorizedPathsArr && authorizedPathsArr.length) {
      let result = authorizedPathsArr.includes(pageName);
      if (result) {
        navigate("/unauthorized");
      }
    }
  };

  // console.log("absolutePathName", absolutePathName);

  useEffect(() => {
    if (!isLoggedIn) {
      if (absolutePathName != "") {
        unAuthorizedRedirect(absolutePathName);
      }
    }
  }, [absolutePathName, isLoggedIn]);

  const handleHomePageRedirect = () => {
    if (isLoggedIn) {
      navigate("/personal");
    } else {
      navigate("/");
    }
  };

  const handleShowMenu = (e) => {
    let elem = e.target;
    let menuIconIsChecked = elem.checked;
    if (menuIconIsChecked) {
      setIsMenuActive(true);
    } else {
      setIsMenuActive(false);
    }
  };

  // const handleHideMenu = (e) => {
  //   setIsMenuActive(false);
  //   document.querySelector("#mobileHamburgerIcon").checked = false;
  // };

  useEffect(() => {
    let body = document.getElementsByTagName("body")[0];
    if (isMenuActive || isDesktopLoginActive) {
      body.classList.add("body-no-scroll");
      // body.classList.add("body-iphone-no-scroll");
    } else {
      body.classList.remove("body-no-scroll");
      // body.classList.remove("body-iphone-no-scroll");
    }
  }, [isMenuActive, isDesktopLoginActive]);

  window.onscroll = function (e) {
    // console.log("absolutePathName", absolutePathName);
    // print "false" if direction is down and "true" if up
    let menuBarElem = document.getElementById("menuBar");

    if (absolutePathName === "rewards") {
      if (this.scrollY < 50) {
        if (menuBarElem) {
          menuBarElem.className = "site-header";
        }
      } else {
        if (menuBarElem) {
          menuBarElem.className = "site-header sticky-header";
        }
      }
    } else {
      if (this.oldScroll > this.scrollY) {
        if (this.scrollY < 50) {
          if (menuBarElem) {
            menuBarElem.className = "site-header";
          }
        } else {
          if (menuBarElem) {
            menuBarElem.className = "site-header sticky-header";
          }
        }
      } else {
        if (menuBarElem) {
          menuBarElem.className = "site-header";
        }
      }
    }

    this.oldScroll = this.scrollY;
  };

  /* login registration process */

  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [loginErrorMessagePass, setLoginErrorMessagePass] = useState("");
  const [loginRegisterMainData, setLoginRegisterMainData] = useState({
    mobile: "",
    password: "",
  });
  const [checkRegistrationMainData, setCheckRegistrationMainData] = useState({
    mobile: "",
  });

  const [isUserExist, setIsUserExist] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [loginFormHead, setLoginFormHead] = useState("login/sign up");

  const validateMobileNumber = (number) => {
    let numRegex = new RegExp(/^\d{10}$/);
    return numRegex.test(number);
  };

  // console.log("loginRegisterMainData", loginRegisterMainData);

  const mobileNumberHandler = (e) => {
    let { value } = e.target;
    setCheckRegistrationMainData((prev) => ({
      ...prev,
      mobile: value,
    }));
    setLoginRegisterMainData((prev) => ({
      ...prev,
      mobile: value,
    }));
  };
  const passwordHandler = (e) => {
    let { value } = e.target;
    let hashedPassword = md5(value);
    setLoginRegisterMainData((prev) => ({
      ...prev,
      password: hashedPassword,
    }));
  };

  let configCheckUser = {
    method: "post",
    url: `${baseUrl}${endPoints.checkUser}`,
    headers: {},
    data: checkRegistrationMainData,
  };

  const checkUser = async () => {
    try {
      setLoginErrorMessage("");
      let numberValidated = validateMobileNumber(
        checkRegistrationMainData.mobile
      );
      if (numberValidated) {
        if (
          loginRegisterMainData.mobile === "8609872899" ||
          loginRegisterMainData.mobile === "9867473742"
        ) {
          const response = await axios.request(configCheckUser);
          if (response.data.status) {
            // setLoginResponse(response.data);
            setIsUserExist(true);
            setShowPasswordInput(true);
            setLoginFormHead("please login");
          } else {
            setIsUserExist(false);
            setShowPasswordInput(true);
            setLoginFormHead("Please sign up");
          }
        } else {
          setLoginErrorMessage(
            "You are not a member yet, please join the waitlist!"
          );
        }
      } else {
        setLoginErrorMessage("Should be a 10 digit number");
      }
    } catch (error) {
      console.log(error);
    }
  };

  let configRegisterUser = {
    method: "post",
    url: `${baseUrl}${endPoints.register}`,
    headers: {},
    data: loginRegisterMainData,
  };

  const registerUser = async () => {
    try {
      setLoginErrorMessage("");
      let numberValidated = validateMobileNumber(loginRegisterMainData.mobile);
      if (numberValidated) {
        if (
          loginRegisterMainData.mobile === "8609872899" ||
          loginRegisterMainData.mobile === "9867473742"
        ) {
          if (loginRegisterMainData.password != "") {
            const response = await axios.request(configRegisterUser);
            console.log("response.data.status", response.data.status);
            if (response && response.data.status) {
              setIsUserExist(true);
              setShowPasswordInput(true);
              setLoginFormHead("you are registered successfully, please login");
              // console.log(response.data.message);
              setLoginErrorMessagePass("");
              setLoginErrorMessage("");
              document.querySelector(".loginPassword").value = "";
            } else {
              // console.log(response.data.message);
              setLoginErrorMessage(response.data.message);
            }
          } else {
            setLoginErrorMessagePass("Password is required!");
          }
        } else {
          setLoginErrorMessage(
            "You are not a member yet, please join the waitlist!"
          );
        }
      } else {
        setLoginErrorMessage("Should be a 10 digit number");
      }
    } catch (error) {
      console.log("Error: ", error);
      if (error.response.data.errorCode == 11000) {
        setLoginErrorMessage("User already exists!");
      } else {
        console.log("Error: ", error.response.data.message);
      }
    }
  };

  /* registartion done */

  /* login start */

  let configLoginUser = {
    method: "post",
    url: `${baseUrl}${endPoints.login}`,
    headers: {},
    data: loginRegisterMainData,
    withCredentials: true,
  };

  const loginUser = async () => {
    try {
      setLoginErrorMessage("");
      let numberValidated = validateMobileNumber(loginRegisterMainData.mobile);
      if (numberValidated) {
        if (loginRegisterMainData.password != "") {
          const response = await axios.request(configLoginUser);
          if (response.data.status) {
            // console.log(response.data.data);
            localStorage.setItem(
              "userCognito",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem(
              "rewards",
              JSON.stringify(response.data.data.rewards)
            );
            setLoginErrorMessagePass("");
            setLoginErrorMessage("");
            confirmLoginHandler();
            setLoginRegisterMainData({
              mobile: "",
              password: "",
            });
            setCheckRegistrationMainData({
              mobile: "",
            });
            setLoginFormHead("login/sign up");
            setShowPasswordInput(false);
          } else {
            // console.log(response.data.message);
            setLoginErrorMessagePass(response.data.message);
          }
        } else {
          setLoginErrorMessagePass("Password is required!");
        }
      } else {
        setLoginErrorMessage("Should be a 10 digit number");
      }
    } catch (error) {
      console.log("Error: ", error);
      if (error.response.data.errorCode == 11000) {
        setLoginErrorMessage("User already exists!");
      } else {
        console.log("Error: ", error.response.data.message);
      }
    }
  };

  /* end login registration process */

  return (
    <>
      {/* {isTopBarActive && !isLoggedIn && (
        <div className="top-highlight d-flex justify-content-center align-items-center">
          <img src={confettiAnimation} alt="" />
          <img
            src={confettiAnimation}
            className="d-none d-md-inline-block"
            alt=""
          />
          <img
            src={confettiAnimation}
            className="d-none d-md-inline-block"
            alt=""
          />
          <span>
            exclusive rewards: unmatched in the multiverse. for members only!
          </span>
          <img
            src={confettiAnimation}
            className="d-none d-md-inline-block"
            alt=""
          />
          <img
            src={confettiAnimation}
            className="d-none d-md-inline-block"
            alt=""
          />
          <img src={confettiAnimation} alt="" />
          <span className="top-bar-close" onClick={handleCloseTopBar}>
            <img src={closeIcon} alt="" />
          </span>
        </div>
      )} */}
      <header
        className={`site-header ${
          absolutePathName === "rewards" ? "sticky-header" : ""
        }`}
        id="menuBar"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 main-header-col">
              <span className="brand-img" onClick={handleHomePageRedirect}>
                <img src={mainLogo} alt="" />
              </span>

              <div
                className={`desktop-menu d-none d-lg-flex ${
                  isShoppingPage ? "shopping-menu" : ""
                }`}
              >
                <div>
                  {isLoggedIn && (
                    <>
                      <Link
                        className={`menu-bar-link ${
                          pathName === "/personal" ? "active" : ""
                        } `}
                        to="/personal"
                      >
                        bills
                      </Link>
                      <Link
                        className={`menu-bar-link ${
                          pathName === "/rewards" ? "active" : ""
                        } `}
                        to="/rewards"
                      >
                        rewards
                      </Link>

                      <Link
                        className={`menu-bar-link ${
                          pathName === "/shopping" ? "active" : ""
                        } `}
                        to="/shopping"
                      >
                        shopping
                      </Link>
                      <span
                        className="menu-bar-link"
                        onClick={handleSwapModalShow}
                      >
                        swap
                      </span>
                    </>
                  )}
                </div>
                <div>
                  {isLoggedIn && (
                    <RewardBalances isShoppingPage={isShoppingPage} />
                  )}
                  {!isLoggedIn && (
                    <Link className="menu-bar-link" to="/check-credit-score">
                      check credit score
                    </Link>
                  )}

                  <span className="user-icon" onClick={handleShowLoginScreen}>
                    <img src={isLoggedIn ? userIconIn : userIcon} alt="" />
                  </span>
                  {!isLoggedIn && (
                    <Link className="menu-bar-link ms-4" to="/pay">
                      pay
                    </Link>
                  )}
                </div>
              </div>

              {isLoggedIn && (
                <div className="d-lg-none reward-balance-main-wrapper">
                  <RewardBalances isShoppingPage={isShoppingPage} />
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <label className="hamburger d-lg-none" id="hamburgerIcon">
        <input
          type="checkbox"
          onChange={(e) => {
            handleShowMenu(e);
          }}
          id="mobileHamburgerIcon"
        />
        <svg viewBox="0 0 32 32">
          <path
            className={`line line-top-bottom ${
              isShoppingPage ? "shopping-line" : ""
            }`}
            d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
          ></path>
          <path
            className={`line ${isShoppingPage ? "shopping-line" : ""}`}
            d="M7 16 27 16"
          ></path>
        </svg>
      </label>
      {isDesktopLoginActive && (
        <div className="menu-bar-container">
          <div className="menu-bar-wrapper">
            {isLoggedIn ? (
              <>
                <div className="menu-bar-front">
                  <div className="form_details">
                    welcome {userCognito?.firstName}
                  </div>
                  <Link className="menu-bar-link" to="/refer">
                    Refer
                  </Link>
                  <Link className="menu-bar-link" to="/edit-profile">
                    edit profile
                  </Link>
                  <Link className="menu-bar-link" to="/account">
                    account
                  </Link>
                  <Link className="menu-bar-link" to="/transaction-report">
                    transaction report
                  </Link>
                  <Link className="menu-bar-link" to="/activity">
                    activity
                  </Link>
                  <Link className="menu-bar-link" to="/notification-settings">
                    settings
                  </Link>
                  <Link className="menu-bar-link" to="/credit-score">
                    credit score
                  </Link>
                  <Link className="menu-bar-link" to="/lifetime-rewards">
                    lifetime rewards
                  </Link>
                  <span className="menu-bar-link" onClick={deleteLoginData}>
                    signout
                  </span>
                  <span className="signup_tog" onClick={handleShowLoginScreen}>
                    <img src={closeIcon} alt="" />
                    {/* <span className="material-symbols-outlined">close</span> */}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="menu-bar-back main-login-screen">
                  <div className="form_details">{loginFormHead}</div>
                  <div>
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter Mobile"
                      maxLength="10"
                      value={checkRegistrationMainData.mobile}
                      onChange={mobileNumberHandler}
                    />
                    <span className="error-text mt-2">{loginErrorMessage}</span>
                  </div>
                  {showPasswordInput ? (
                    <div>
                      <input
                        type="password"
                        className="input loginPassword"
                        placeholder="Enter Password"
                        maxLength="15"
                        name="password"
                        onChange={passwordHandler}
                      />
                      <span className="error-text">
                        {loginErrorMessagePass}
                      </span>
                    </div>
                  ) : null}

                  {showPasswordInput ? (
                    isUserExist ? (
                      <button
                        className="starry-btn mt-1"
                        type="button"
                        onClick={loginUser}
                      >
                        <span className="btn-text">login</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    ) : (
                      <button
                        className="starry-btn mt-1"
                        type="button"
                        onClick={registerUser}
                      >
                        <span className="btn-text">register</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    )
                  ) : (
                    <button
                      className="starry-btn mt-1"
                      type="button"
                      // onClick={displayOtpBox}
                      onClick={checkUser}
                    >
                      <span className="btn-text">submit</span>
                      <div id="container-stars">
                        <div id="stars"></div>
                      </div>

                      <div id="glow">
                        <div className="circle"></div>
                        <div className="circle"></div>
                      </div>
                    </button>
                  )}

                  {/* {showOtpBox && (
                    <>
                      <OtpInput
                        value={otpValue}
                        onChange={setOtpValue}
                        numInputs={4}
                        inputStyle="otp-box"
                        containerStyle="otp-boxes-wrapper"
                        inputType="tel"
                        renderInput={(props) => <input {...props} />}
                      />
                      <button
                        className="starry-btn mt-3"
                        type="button"
                        onClick={confirmLoginHandler}
                      >
                        <span className="btn-text">verify</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </>
                  )} */}

                  <span className="signup_tog" onClick={handleShowLoginScreen}>
                    <img src={closeIcon} alt="" />
                    {/* <span className="material-symbols-outlined">close</span> */}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {isMenuActive && (
        <div className="menu-bar-container">
          <input id="signup_toggle" type="checkbox"></input>
          <div className="menu-bar-wrapper">
            <div className="menu-bar-front">
              <label htmlFor="signup_toggle">
                {isLoggedIn ? "profile" : "login/sign Up"}
              </label>
              {!isLoggedIn && (
                <Link className="menu-bar-link mb-3" to="/check-credit-score">
                  check credit score
                </Link>
              )}

              {isLoggedIn && (
                <>
                  <Link className="menu-bar-link" to="/personal">
                    bills
                  </Link>
                  <Link className="menu-bar-link" to="/rewards">
                    rewards
                  </Link>
                  <Link className="menu-bar-link" to="/shopping">
                    shopping
                  </Link>
                  <span className="menu-bar-link" onClick={handleSwapModalShow}>
                    swap
                  </span>
                </>
              )}
              {!isLoggedIn && (
                <Link className="menu-bar-link" to="/pay">
                  pay
                </Link>
              )}

              {/* <span className="signup_tog" onClick={handleHideMenu}>
                <img src={closeIcon} alt="" />
              </span> */}
            </div>

            <div className="menu-bar-back">
              {isLoggedIn ? (
                <>
                  <div className="form_details">welcome anurag</div>
                  <Link className="menu-bar-link" to="/refer">
                    Refer
                  </Link>
                  <Link className="menu-bar-link" to="/edit-profile">
                    edit profile
                  </Link>
                  <Link className="menu-bar-link" to="/account">
                    account
                  </Link>
                  <Link className="menu-bar-link" to="/transaction-report">
                    transaction report
                  </Link>
                  <Link className="menu-bar-link" to="/activity">
                    activity
                  </Link>
                  <Link className="menu-bar-link" to="/notification-settings">
                    settings
                  </Link>
                  <Link className="menu-bar-link" to="/credit-score">
                    credit score
                  </Link>
                  <Link className="menu-bar-link" to="/lifetime-rewards">
                    lifetime rewards
                  </Link>
                  <span className="menu-bar-link" onClick={deleteLoginData}>
                    signout
                  </span>
                </>
              ) : (
                <>
                  <div className="form_details">{loginFormHead}</div>
                  <div>
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter Mobile"
                      maxLength="10"
                      value={checkRegistrationMainData.mobile}
                      onChange={mobileNumberHandler}
                    />
                    <span className="error-text mt-2">{loginErrorMessage}</span>
                  </div>
                  {showPasswordInput ? (
                    <div>
                      <input
                        type="password"
                        className="input loginPassword"
                        placeholder="Enter Password"
                        maxLength="15"
                        name="password"
                        onChange={passwordHandler}
                      />
                      <span className="error-text">
                        {loginErrorMessagePass}
                      </span>
                    </div>
                  ) : null}

                  {showPasswordInput ? (
                    isUserExist ? (
                      <button
                        className="starry-btn mt-1"
                        type="button"
                        onClick={loginUser}
                      >
                        <span className="btn-text">login</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    ) : (
                      <button
                        className="starry-btn mt-1"
                        type="button"
                        onClick={registerUser}
                      >
                        <span className="btn-text">register</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    )
                  ) : (
                    <button
                      className="starry-btn mt-1"
                      type="button"
                      // onClick={displayOtpBox}
                      onClick={checkUser}
                    >
                      <span className="btn-text">submit</span>
                      <div id="container-stars">
                        <div id="stars"></div>
                      </div>

                      <div id="glow">
                        <div className="circle"></div>
                        <div className="circle"></div>
                      </div>
                    </button>
                  )}

                  {/* {showOtpBox && (
                    <>
                      <OtpInput
                        value={otpValue}
                        onChange={setOtpValue}
                        numInputs={4}
                        inputStyle="otp-box"
                        containerStyle="otp-boxes-wrapper"
                        // renderSeparator={" "}
                        inputType="tel"
                        renderInput={(props) => <input {...props} />}
                      />
                      <button
                        className="starry-btn mt-3"
                        type="button"
                        onClick={confirmLoginHandler}
                      >
                        <span className="btn-text">verify</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </>
                  )}
                  {!showOtpBox && (
                    <button
                      className="starry-btn mt-3"
                      type="button"
                      onClick={displayOtpBox}
                    >
                      <span className="btn-text">submit</span>
                      <div id="container-stars">
                        <div id="stars"></div>
                      </div>

                      <div id="glow">
                        <div className="circle"></div>
                        <div className="circle"></div>
                      </div>
                    </button>
                  )} */}
                </>
              )}

              <label htmlFor="signup_toggle" className="signup_tog">
                <img src={closeIcon} alt="" />

                {/* <span className="material-symbols-outlined">close</span> */}
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainNavbar;
