import React from "react";

const SelectedWalletList = ({ walletBankLogos }) => {
  const changeActiveHandler = (e) => {
    let elements = document.querySelectorAll(".selected-payment-icon");
    let clickedElem = e.target;

    for (let i = 0; i < elements.length; i++) {
      let curElem = elements[i];
      curElem.classList.contains("active") &&
        curElem.classList.remove("active");
    }
    clickedElem.parentElement.classList.add("active");
  };

  return (
    <>
      <div
        className="selected-payment-icon active"
        onClick={(e) => {
          changeActiveHandler(e);
        }}
      >
        <img src={walletBankLogos.metamask} alt="" />
      </div>
      <div
        className="selected-payment-icon"
        onClick={(e) => {
          changeActiveHandler(e);
        }}
      >
        <img src={walletBankLogos.coinbase} alt="" />
      </div>
      <div
        className="selected-payment-icon"
        onClick={(e) => {
          changeActiveHandler(e);
        }}
      >
        <img src={walletBankLogos.ambire} alt="" />
      </div>
      <div
        className="selected-payment-icon"
        onClick={(e) => {
          changeActiveHandler(e);
        }}
      >
        <img src={walletBankLogos.luno} alt="" />
      </div>
      <div
        className="selected-payment-icon"
        onClick={(e) => {
          changeActiveHandler(e);
        }}
      >
        <img src={walletBankLogos.crypterium} alt="" />
      </div>
    </>
  );
};

export default SelectedWalletList;
