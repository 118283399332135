import React from "react";
import { currencyIcons } from "../../components/currencyIcons";

const AvailableCurrencyListPay = ({
  showCurrencyDropdownPay,
  handleShowCurrencyDropdown,
  currencyData,
  selectedPayCurrency,
  setSelectedPayCurrency,
  setShowCurrencyDropdownPay,
}) => {
  return (
    <>
      <div className="selected-currency-wrapper">
        {Object.keys(selectedPayCurrency).length && (
          <div
            className="currency-block"
            onClick={() => {
              handleShowCurrencyDropdown("pay");
            }}
          >
            <span className="icon-wrap">
              <img src={currencyIcons[selectedPayCurrency.img]} alt="" />
            </span>
            <span className="curr-text">{selectedPayCurrency.text}</span>
          </div>
        )}
      </div>
      {showCurrencyDropdownPay && (
        <div className="currency-list-wrapper scrollbar-success">
          {currencyData.length
            ? currencyData.map((currVal, index) => {
                return (
                  <div
                    className="currency-block"
                    key={index}
                    onClick={() => {
                      setSelectedPayCurrency((prev) => ({
                        ...prev,
                        img: currVal.img,
                        text: currVal.text,
                      }));
                      setShowCurrencyDropdownPay(false);
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={currencyIcons[currVal.img]} alt="" />
                    </span>
                    <span className="curr-text">{currVal.text}</span>
                  </div>
                );
              })
            : ""}
        </div>
      )}
    </>
  );
};

export default AvailableCurrencyListPay;
