import React from "react";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";
import RewardsHourlyPayMain from "../components/RewardsHourlyPayMain";

const RewardsHourlyPay = () => {
  return (
    <>
      <RewardsHourlyPayMain />
    </>
  );
};

export default RewardsHourlyPay;
