import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { motion } from "framer-motion";
import CountUp from "react-countup";

/* comp imports */
// import "./checkout.scss";

/* img imports */
import merchantLogo from "../assets/img/merchant-logo.png";
import closeIcon from "../assets/img/close-icon.svg";
import paymentCompeletedIcon from "../assets/img/payment-completed-icon.svg";
import paymentTransferAnim from "../assets/img/epay-money-transfer.gif";
import confettiAnimation from "../assets/img/confetti-animation.gif";
import coinIconSmall from "../assets/img/coin-icon-small.png";
import currencyIconSmall from "../assets/img/currency-icon-small.png";
import voucherIconSmall from "../assets/img/voucher-icon-small.png";
import giftVoucherDemo from "../assets/img/gift-voucher-demo.png";

/* function imports */
import { walletBankLogos } from "../components/walletBankLogos";
import WalletList from "../components/WalletList";
// import BanksList from "../components/BanksList";
import { currencyIcons } from "../components/currencyIcons";
import CurrencyList from "../components/CurrencyList";
import SelectedWalletList from "../components/SelectedWalletList";
import AvailableWalletList from "../components/AvailableWalletList";

import {
  tiltAnimation,
  payIconAnim,
  payIconScaleDown,
  hideCurrencyAnim,
  drawCheck,
  drawCheckFill,
  hideTickSvg,
  confettiAnimationShow,
  confettiAnimationFadeOut,
  showWinningText,
  showWinningIcons,
  iconFlyTop,
  iconFlyLeft,
  iconFlyRight,
  iconPopOut,
  fadeOut,
  showRedirectionText,
} from "../components/checkoutAnimation";

const Checkout = () => {
  const [headerIsActive, setHeaderIsActive] = useState(true);
  const [loginIsActive, setLoginIsActive] = useState(true);
  const [termsIsActive, setTermsIsActive] = useState(false);
  const [otpIsActive, setOtpIsActive] = useState(false);
  const [addWalletIsActive, setAddWalletIsActive] = useState(false);
  const [moneybackBalanceIsActive, setMoneybackBalanceIsActive] =
    useState(false);
  const [walletListIsActive, setWalletListIsActive] = useState(false);
  const [mainPaymentIsActive, setMainPaymentIsActive] = useState(false);
  const [paymentSuccessIsActive, setPaymentSuccessIsActive] = useState(false);

  const [termsContentIsActive, setTermsContentIsActive] = useState(false);
  const [privacyContentIsActive, setPrivacyContentIsActive] = useState(false);

  const [mainCheckoutWrapper, setMainCheckoutWrapper] = useState(true);

  const [payBtnBgClass, setPayBtnBgClass] = useState("commGrayBg");

  const mainWindowCloseHandler = () => {
    setMainCheckoutWrapper(false);
  };

  const termsContentHandler = (e, window) => {
    let elem = e.target;
    console.log(e.target);
    elem.parentElement.classList.add("active", "main-btn-wo-anim");

    window === "terms"
      ? setTermsContentIsActive(true)
      : setPrivacyContentIsActive(true);
  };

  const closeTermsContentHandler = (window) => {
    window === "terms"
      ? setTermsContentIsActive(false)
      : setPrivacyContentIsActive(false);

    let termsBtn = document.getElementById("termsBtn");
    let privacyBtn = document.getElementById("privacyBtn");
    let agreeContBtn = document.getElementById("agreeContBtn");

    termsBtn.classList.contains("active") &&
    privacyBtn.classList.contains("active")
      ? agreeContBtn.classList.remove("main-btn-wo-anim")
      : agreeContBtn.classList.add("main-btn-wo-anim");
  };

  const [currentCurrencyAmount, setCurrentCurrencyAmount] = useState(73);
  const [newCurrencyAmount, setNewCurrencyAmount] = useState(73);

  const onIconFlyComplete = () => {
    setNewCurrencyAmount(93);
  };

  const redirectToMerchant = () => {
    // window.location.href = "https://www.everythingstore.io/";
  };

  const [usingBalance, setUsingBalance] = useState(false);
  const [payableAmount, setPayableAmount] = useState(1000.0);
  const [newPayableAmount, setNewpayableAmount] = useState(927.0);

  const usingBalanceHandler = () => {
    setUsingBalance(!usingBalance);
  };

  return (
    <>
      <div
        className={`overlay-screen ${!mainCheckoutWrapper && "d-none"}`}
      ></div>
      <div className="overlay-bg"></div>
      <div
        className={`main-checkout-wrapper ${!mainCheckoutWrapper && "d-none"}`}
      >
        <div className="main-checkout-window">
          <div className="main-brand-section">
            <h3>
              checkout with <span className="comp-name">ChaChing</span>
            </h3>
            <span className="close-icon" onClick={mainWindowCloseHandler}>
              <span className="material-symbols-outlined">close</span>
            </span>
          </div>
          {/* end of main brand */}

          {moneybackBalanceIsActive && (
            <div
              className={`use-balance-blk ${usingBalance && "active"}`}
              id="useBalance"
              onClick={usingBalanceHandler}
            >
              <motion.span
                className="img-blk"
                variants={tiltAnimation}
                initial="initial"
                animate="animate"
              >
                <img src={currencyIconSmall} alt="" />
              </motion.span>
              <span className="text-blk">{`${usingBalance ? "0" : "73"}`}</span>
            </div>
          )}

          {headerIsActive && (
            <header className="checkout-header">
              <div className="merchant-logo">
                <img src={merchantLogo} alt="" className="img-fluid" />
              </div>
              <div className="merchant-info">
                <span className="name">merchant name</span>
                <span className="amount-text">payment for</span>
                <span className="amount">
                  {
                    <CountUp
                      end={usingBalance ? newPayableAmount : payableAmount}
                      // start={payableAmount}
                      start={usingBalance ? payableAmount : newPayableAmount}
                      decimals={2}
                      duration={1}
                      prefix="$"
                    />
                  }
                </span>
                {usingBalance && (
                  <div className="balance-amount">
                    <span className="img-blk">
                      <img src={currencyIconSmall} alt="" />
                    </span>
                    <span className="text-blk">
                      {
                        <CountUp
                          end={usingBalance && "73"}
                          start={0}
                          duration={1}
                        />
                      }
                    </span>
                  </div>
                )}
              </div>
            </header>
          )}

          {/* end of header */}

          {loginIsActive && (
            <div className="login-screen">
              <div className="form-section">
                <div className="form-header-text">signup or login</div>
                {/* <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    type="text"
                    placeholder="enter first name"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    type="text"
                    placeholder="enter last name"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    type="email"
                    placeholder="enter email"
                    required
                  />
                </Form.Group> */}
                <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    type="text"
                    placeholder="enter mobile number"
                    required
                  />
                </Form.Group>
                <div className="d-flex d-sm-block justify-content-end">
                  <button
                    className="starry-btn mt-2"
                    type="button"
                    onClick={() => {
                      setTermsIsActive(true);
                      setLoginIsActive(false);
                    }}
                  >
                    <span className="btn-text">sign up</span>
                    <div id="container-stars">
                      <div id="stars"></div>
                    </div>

                    <div id="glow">
                      <div className="circle"></div>
                      <div className="circle"></div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* end of login screen */}

          {termsIsActive && (
            <div className="terms-screen">
              <div className="terms-heading">
                <span className="main-head">
                  <span className="comp-name">ChaChing</span> terms
                </span>
                <span className="sub-head">by continuing, you agree to:</span>
              </div>

              <div className="terms-btns">
                <span
                  className="main-btn-2"
                  onClick={(e) => termsContentHandler(e, "terms")}
                  id="termsBtn"
                >
                  <span>
                    <span className="comp-name">ChaChing</span>'s terms of use
                  </span>
                </span>

                <span
                  className="main-btn-3"
                  onClick={(e) => termsContentHandler(e, "privacy")}
                  id="privacyBtn"
                >
                  <span>
                    <span className="comp-name">ChaChing</span>'s privacy policy
                  </span>
                </span>
                <span className="marketing-opt-in">
                  <span className="main-text">
                    Marketing Opt In{" "}
                    <small>You have the right to opt out at any time</small>
                  </span>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      required
                      defaultChecked
                    />
                  </div>
                </span>
              </div>

              {termsContentIsActive && (
                <>
                  <div className="terms-screen-content">
                    <h4>terms of use</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of
                    </p>
                    <p>
                      Lorem Ipsum. Why do we use it? It is a long established
                      fact that a reader will be distracted by the readable
                      content of a page when looking at its layout. The point of
                      using Lorem Ipsum is that it has a more-or-less normal
                      distribution of letters, as opposed to using 'Content
                      here, content here', making it look like readable English.
                      Many desktop publishing packages and web page editors now
                      use Lorem Ipsum as their default model text, and a search
                      for 'lorem ipsum' will uncover many web sites still in
                      their infancy. Various versions have evolved over the
                      years, sometimes by accident, sometimes on purpose
                      (injected humour and the like).
                    </p>
                  </div>
                  <span
                    className="curved-close-icon"
                    onClick={() => {
                      closeTermsContentHandler("terms");
                    }}
                  >
                    <img src={closeIcon} alt="" />
                  </span>
                </>
              )}

              {privacyContentIsActive && (
                <>
                  <div className="terms-screen-content">
                    <h4>privacy policy</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining
                    </p>
                    <p>
                      essentially unchanged. It was popularised in the 1960s
                      with the release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of
                    </p>
                    <p>
                      Lorem Ipsum. Why do we use it? It is a long established
                      fact that a reader will be distracted by the readable
                      content of a page when looking at its layout. The point of
                      using Lorem Ipsum is that it has a more-or-less normal
                      distribution of letters, as opposed to using 'Content
                      here, content here', making it look like readable English.
                      Many desktop publishing packages and web page editors now
                      use Lorem Ipsum as their default model text, and a search
                      for 'lorem ipsum' will uncover many web sites still in
                      their infancy. Various versions have evolved over the
                      years, sometimes by accident, sometimes on purpose
                      (injected humour and the like).
                    </p>
                  </div>
                  <span
                    className="curved-close-icon"
                    onClick={() => {
                      closeTermsContentHandler("privacy");
                    }}
                  >
                    <img src={closeIcon} alt="" />
                  </span>
                </>
              )}
              <div className="d-flex d-sm-block justify-content-end">
                <button
                  className="starry-btn mt-2"
                  type="button"
                  id="agreeContBtn"
                  onClick={() => {
                    setTermsIsActive(false);
                    setOtpIsActive(true);
                  }}
                >
                  <span className="btn-text">agree & continue</span>
                  <div id="container-stars">
                    <div id="stars"></div>
                  </div>

                  <div id="glow">
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                </button>
              </div>
            </div>
          )}

          {/* end of terms screen */}
          {otpIsActive && (
            <div className="otp-screen">
              <div className="otp-text">
                Please enter the 4 digit code we've sent to xxxxxxx678 to
                continue.
              </div>
              <div className="otp-inputs">
                <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    type="text"
                    placeholder=""
                    required
                    maxLength="1"
                  />
                </Form.Group>
                <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    type="text"
                    placeholder=""
                    required
                    maxLength="1"
                  />
                </Form.Group>
                <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    type="text"
                    placeholder=""
                    required
                    maxLength="1"
                  />
                </Form.Group>
                <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    type="text"
                    placeholder=""
                    required
                    maxLength="1"
                  />
                </Form.Group>
              </div>
              <div className="otp-text bottom">
                <span>Haven't received? resend code.</span>
              </div>
              <div className="d-flex d-sm-block justify-content-end">
                <button
                  className="starry-btn mt-2"
                  type="button"
                  onClick={() => {
                    setOtpIsActive(false);
                    setMoneybackBalanceIsActive(true);
                    setAddWalletIsActive(true);
                  }}
                >
                  <span className="btn-text">verify</span>
                  <div id="container-stars">
                    <div id="stars"></div>
                  </div>

                  <div id="glow">
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                </button>
              </div>
            </div>
          )}

          {/* end of otp screen */}

          {addWalletIsActive && (
            <div className="adding-screen">
              <div className="adding-text">
                You dont have any wallet saved with us!
              </div>
              <div className="add-btns-wrapper d-flex d-sm-block justify-content-end">
                <button
                  className="starry-btn mt-2"
                  type="button"
                  onClick={() => {
                    setWalletListIsActive(true);
                  }}
                >
                  <span className="btn-text">add a wallet</span>
                  <div id="container-stars">
                    <div id="stars"></div>
                  </div>

                  <div id="glow">
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                </button>
              </div>

              {walletListIsActive && (
                <div className="wallet-bank-list-wrapper">
                  <div className="filter-list">
                    <Form.Group className="mb-4 main-form-group">
                      <Form.Control
                        type="text"
                        placeholder="search wallet"
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="wallet-list">
                    <WalletList
                      walletBankLogos={walletBankLogos}
                      setAddWalletIsActive={setAddWalletIsActive}
                      setWalletListIsActive={setWalletListIsActive}
                      setMainPaymentIsActive={setMainPaymentIsActive}
                    />
                  </div>
                </div>
              )}

              {/* <div className="wallet-bank-list-wrapper">
              <div className="filter-list">
                <Form.Group className="mb-4 main-form-group">
                  <Form.Control
                    type="text"
                    placeholder="search bank"
                    required
                  />
                </Form.Group>
              </div>
              <div className="wallet-list bank-list">
                <BanksList walletBankLogos={walletBankLogos} />
              </div>
            </div> */}
            </div>
          )}

          {/* end of adding screen */}

          {mainPaymentIsActive && (
            <div className="main-checkout-screen">
              <span
                className="curved-close-icon"
                onClick={() => {
                  setMainPaymentIsActive(false);
                  setAddWalletIsActive(true);
                }}
              >
                <img src={closeIcon} alt="" />
              </span>
              <div className="selected-payment">
                <div className="selected-payment-icon-wrapper">
                  <SelectedWalletList walletBankLogos={walletBankLogos} />
                </div>

                <div className="available-payments-dropdown">
                  <AvailableWalletList />
                </div>

                <span className="add-new-payment">
                  no wallet found for selected currency click here to a add
                  payment method
                </span>
              </div>

              <div className="currency-select">
                <CurrencyList
                  currencyIcons={currencyIcons}
                  setPayBtnBgClass={setPayBtnBgClass}
                />
              </div>

              <div className="text-center">
                <span
                  className={`main-btn ${payBtnBgClass}`}
                  onClick={() => {
                    setMainPaymentIsActive(false);
                    setPaymentSuccessIsActive(true);
                    setHeaderIsActive(false);
                    setMoneybackBalanceIsActive(false);
                  }}
                >
                  <span>pay</span>
                </span>
              </div>
            </div>
          )}

          {/* end of main checkout screen */}

          {paymentSuccessIsActive && (
            <div className="payment-completed-screen">
              <div className="payment-transfer-wrapper">
                <motion.div
                  initial="initial"
                  animate="animate"
                  variants={payIconAnim}
                  className="mt-merchant-icon"
                >
                  <span>AK</span>
                </motion.div>
                <motion.div
                  initial="initial"
                  animate="animate"
                  variants={payIconAnim}
                  className="mt-merchant-icon r-icon"
                >
                  <span className="et">
                    <img src={merchantLogo} alt="" />
                  </span>
                </motion.div>
                <motion.div
                  initial="initial"
                  animate="animate"
                  variants={payIconScaleDown}
                  className="mt-merchant-icon r-icon"
                >
                  <span className="et">
                    <img src={merchantLogo} alt="" />
                  </span>
                </motion.div>
                <motion.div
                  className="payment-transfer-img"
                  variants={hideCurrencyAnim}
                  animate="animate"
                >
                  <img src={paymentTransferAnim} alt="Payment Completed" />
                </motion.div>
                <div className="payment-completed-img">
                  <motion.svg
                    // width="190"
                    // height="190"
                    viewBox="0 0 190 190"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    initial="initial"
                    animate="animate"
                    variants={hideTickSvg}
                  >
                    <motion.rect
                      x="2.5"
                      y="2.5"
                      width="185"
                      height="185"
                      rx="92.5"
                      stroke="#1DAB87"
                      strokeWidth="5"
                      strokeLinejoin="round"
                      variants={drawCheck}
                      initial="initial"
                      animate="animate"
                    />
                    <motion.circle
                      cx="95"
                      cy="95"
                      r="90"
                      fill="#1DAB87"
                      variants={drawCheckFill}
                      initial="initial"
                      animate="animate"
                      // onAnimationComplete="onAnimationComplete"
                    />
                    <motion.path
                      d="M48 102.5L74.4999 131L142 57"
                      stroke="white"
                      strokeWidth="20"
                      strokeLinecap="round"
                      variants={drawCheck}
                      initial="initial"
                      animate="animate"
                    />
                  </motion.svg>
                </div>
                <motion.div
                  className="confetti-wrapper"
                  variants={confettiAnimationFadeOut}
                  initial="initial"
                  animate="animate"
                >
                  <motion.img
                    src={confettiAnimation}
                    alt=""
                    variants={confettiAnimationShow}
                    initial="initial"
                    animate="animate"
                  />
                </motion.div>
                <motion.div
                  className="current-balances-wrapper"
                  variants={fadeOut}
                  initial="initial"
                  animate="animate"
                >
                  <motion.div
                    className="curr-bal-block"
                    variants={showWinningIcons}
                    initial="initial"
                    animate="animate"
                  >
                    <span className="img-blk">
                      <motion.img
                        src={coinIconSmall}
                        alt=""
                        // variants={iconPopOut}
                        initial="initial"
                        animate="animate"
                      />
                    </span>
                    <span className="text-blk">12,500</span>
                  </motion.div>
                  <motion.div
                    className="curr-bal-block"
                    variants={showWinningIcons}
                    initial="initial"
                    animate="animate"
                  >
                    <span className="img-blk">
                      <motion.img
                        src={currencyIconSmall}
                        alt=""
                        variants={iconPopOut}
                        initial="initial"
                        animate="animate"
                      />
                    </span>
                    <span className="text-blk">
                      <CountUp
                        end={newCurrencyAmount}
                        start={currentCurrencyAmount}
                      />
                    </span>
                  </motion.div>
                  <motion.div
                    className="curr-bal-block"
                    variants={showWinningIcons}
                    initial="initial"
                    animate="animate"
                  >
                    <span className="img-blk">
                      <motion.img
                        src={voucherIconSmall}
                        alt=""
                        // variants={iconPopOut}
                        initial="initial"
                        animate="animate"
                      />
                    </span>
                    <span className="text-blk">3</span>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="gift-received-wrapper"
                  variants={fadeOut}
                  initial="initial"
                  animate="animate"
                >
                  <motion.span
                    className="gift-main-head"
                    variants={showWinningText}
                  >
                    you won
                  </motion.span>
                  <motion.span className="gift-won" variants={showWinningText}>
                    <motion.img
                      src={currencyIconSmall}
                      // src={coinIconSmall}
                      // src={voucherIconSmall}
                      alt=""
                      variants={iconFlyTop}
                      // variants={iconFlyLeft}
                      // className="d-none"
                      onAnimationComplete={() => {
                        onIconFlyComplete();
                      }}
                    />
                    <strong>$20</strong>
                    {/* <motion.span
                      className="voucher-brand"
                      variants={iconFlyRight}
                      initial="initial"
                      animate="animate"
                    >
                      <img src={giftVoucherDemo} alt="" />
                    </motion.span> */}
                  </motion.span>
                </motion.div>
                <motion.div
                  className="screen-redirection-text"
                  variants={showRedirectionText}
                  initial="initial"
                  animate="animate"
                  onAnimationComplete={() => {
                    redirectToMerchant();
                  }}
                >
                  <p>
                    Your payment is being submitted. Please do not close this
                    window or click the Back button.
                  </p>
                  <p>We will redirect you shortly.</p>
                </motion.div>
              </div>

              {/* <div className="payment-completed-img">
                <img src={paymentCompeletedIcon} alt="Payment Completed" />
              </div> */}
            </div>
          )}

          {/* end of payment completed */}

          <div className="main-brand-section footer">
            <h3>
              powered by <span className="comp-name">ChaChing</span>
            </h3>
          </div>
          {/* end of powered by */}
        </div>
      </div>
    </>
  );
};

export default Checkout;
