import React from "react";
import Form from "react-bootstrap/Form";
import { HashLink } from "react-router-hash-link";

/* comp imports */
import FaqsSection from "./FaqsSection";
import { vendorLogos } from "./vendorLogos";

/* img imports */
import cashRegister from "../assets/img/banner/cash-register.gif";
import chachingLogoText from "../assets/img/logo/chaching-logo-text.png";
import chachingAnywhereBgMobile from "../assets/img/banner/chaching-anywhere-bg-mobile.png";
import imgLineMain from "../assets/img/img-line-main.png";
import imgLineMain2 from "../assets/img/img-line-main-2.png";
import FaqsSectionHelp from "./FaqsSectionHelp";

const HelpMain = () => {
  return (
    <>
      <main>
        <section className="content-section white-color-section documents-section extra-section-padding extra-section-margin">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">help</h3>

                      <div className="section-content pay-page">
                        please refer below faqs
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        <FaqsSectionHelp />
      </main>
    </>
  );
};

export default HelpMain;
