import trustwallet from "../assets/img/vendor-logos-white/trustwallet.svg";
import metamask from "../assets/img/vendor-logos-white/metamask.svg";
import bitcoin from "../assets/img/vendor-logos-white/bitcoin.svg";
import exodus from "../assets/img/vendor-logos-white/exodus.svg";
import zengo from "../assets/img/vendor-logos-white/zengo.svg";
import lobstr from "../assets/img/vendor-logos-white/lobstr.svg";
import phantom from "../assets/img/vendor-logos-white/phantom.svg";
import edge from "../assets/img/vendor-logos-white/edge.svg";
import ledger from "../assets/img/vendor-logos-white/ledger.svg";
import xportal from "../assets/img/vendor-logos-white/xportal.svg";
import opensea from "../assets/img/vendor-logos-white/opensea.svg";
import sorare from "../assets/img/vendor-logos-white/sorare.svg";
import gamestop from "../assets/img/vendor-logos-white/gamestop.svg";
import immutableX from "../assets/img/vendor-logos-white/immutable-x.svg";
import niftys from "../assets/img/vendor-logos-white/niftys.svg";
import bbva from "../assets/img/bank-logos/bbva.svg";
import boa from "../assets/img/bank-logos/boa.svg";
import boaDark from "../assets/img/bank-logos/boa-dark.svg";
import chase from "../assets/img/bank-logos/chase.svg";
import chaseDark from "../assets/img/bank-logos/chase-dark.svg";
import citi from "../assets/img/bank-logos/citi.svg";
import citiDark from "../assets/img/bank-logos/citi-dark.svg";
import tdBank from "../assets/img/bank-logos/td-bank.svg";
import truist from "../assets/img/bank-logos/truist.svg";
import usBank from "../assets/img/bank-logos/us-bank.svg";
import wellsFargo from "../assets/img/bank-logos/wells-fargo.svg";

export const vendorLogos = {
  trustwallet: trustwallet,
  metamask: metamask,
  bitcoin: bitcoin,
  exodus: exodus,
  zengo: zengo,
  lobstr: lobstr,
  phantom: phantom,
  edge: edge,
  ledger: ledger,
  xportal: xportal,
  opensea: opensea,
  sorare: sorare,
  gamestop: gamestop,
  immutableX: immutableX,
  niftys: niftys,
  bbva: bbva,
  boa: boa,
  boaDark: boaDark,
  chase: chase,
  chaseDark: chaseDark,
  citi: citi,
  citiDark: citiDark,
  tdBank: tdBank,
  truist: truist,
  usBank: usBank,
  wellsFargo: wellsFargo,
};
