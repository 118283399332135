import React, { useEffect } from "react";
import { gsap, Sine } from "gsap";

const MeteorScaling = ({ multiplier, gameStatus }) => {
  useEffect(() => {
    if (gameStatus === "Crashed" || gameStatus === "Stopped") {
      gsap.killTweensOf(".scale-wrapper");
      gsap.set(".scale-wrapper", {
        bottom: "5%",
        opacity: 0,
      });
    }
    if (gameStatus === "Running") {
      gsap.set(".scale-wrapper", {
        opacity: 1,
        onComplete: () => {
          gsap.to(".scale-wrapper", {
            duration: 1.1,
            bottom: "5%",
            ease: Sine.easeOut,
          });
        },
      });
    }
  }, [gameStatus]);

  useEffect(() => {
    // console.log("multiplier top", multiplier);
    let meteorElem = document.querySelector(".meteor");
    if (gameStatus === "Running" && multiplier == 1.1) {
      gsap.fromTo(
        ".scale-wrapper",
        {
          bottom: "5%",
        },
        {
          duration: 10,
          bottom: "-22%",
          ease: "none",
        }
      );
    } else if (gameStatus === "Running" && multiplier == 2.1) {
      gsap.fromTo(
        ".scale-wrapper",
        {
          bottom: "-22%",
        },
        {
          duration: 14,
          bottom: "-60%",
          ease: "none",
        }
      );
    } else if (gameStatus === "Running" && multiplier == 4.0) {
      gsap.fromTo(
        ".scale-wrapper",
        {
          bottom: "-60%",
        },
        {
          duration: 20,
          bottom: "-108%",
          ease: "none",
        }
      );
    } else if (gameStatus === "Running" && multiplier == 10.0) {
      gsap.fromTo(
        ".scale-wrapper",
        {
          bottom: "-108%",
        },
        {
          duration: 18,
          bottom: "-145%",
          ease: "none",
        }
      );
    } else if (gameStatus === "Running" && multiplier == 20.0) {
      gsap.fromTo(
        ".scale-wrapper",
        {
          bottom: "-145%",
        },
        {
          duration: 15,
          bottom: "-183%",
          ease: "none",
        }
      );
    } else if (gameStatus === "Running" && multiplier == 30.0) {
      gsap.fromTo(
        ".scale-wrapper",
        {
          bottom: "-183%",
        },
        {
          duration: 5,
          bottom: "-193%",
          ease: "none",
        }
      );
    } else if (gameStatus === "Running" && multiplier == 40.0) {
      gsap.fromTo(
        ".scale-wrapper",
        {
          bottom: "-193%",
        },
        {
          duration: 2.5,
          bottom: "-215%",
          ease: "none",
        }
      );
    }
  }, [gameStatus, multiplier]);

  return (
    <div className="scale-wrapper">
      <span>50.00x</span>
      <span>48.00x</span>
      <span>46.00x</span>
      <span>44.00x</span>
      <span>42.00x</span>
      <span>40.00x</span>
      <span>38.00x</span>
      <span>36.00x</span>
      <span>34.00x</span>
      <span>32.00x</span>
      <span>30.00x</span>
      <span>29.00x</span>
      <span>28.00x</span>
      <span>27.00x</span>
      <span>26.00x</span>
      <span>25.00x</span>
      <span>24.00x</span>
      <span>23.00x</span>
      <span>22.00x</span>
      <span>21.00x</span>
      <span>20.00x</span>
      <span>19.00x</span>
      <span>18.00x</span>
      <span>17.00x</span>
      <span>16.00x</span>
      <span>15.00x</span>
      <span>14.00x</span>
      <span>13.00x</span>
      <span>12.00x</span>
      <span>11.00x</span>
      <span>10.00x</span>
      <span>9.50x</span>
      <span>9.00x</span>
      <span>8.50x</span>
      <span>8.00x</span>
      <span>7.50x</span>
      <span>7.00x</span>
      <span>6.50x</span>
      <span>6.00x</span>
      <span>5.50x</span>
      <span>5.00x</span>
      <span>4.50x</span>
      <span>4.00x</span>
      <span>3.80x</span>
      <span>3.60x</span>
      <span>3.40x</span>
      <span>3.20x</span>
      <span>3.00x</span>
      <span>2.80x</span>
      <span>2.60x</span>
      <span>2.40x</span>
      <span>2.20x</span>
      <span>2.00x</span>
      <span>1.90x</span>
      <span>1.80x</span>
      <span>1.70x</span>
      <span>1.60x</span>
      <span>1.50x</span>
      <span>1.40x</span>
      <span>1.30x</span>
      <span>1.20x</span>
      <span>1.10x</span>
      <span>1.00x</span>
    </div>
  );
};

export default MeteorScaling;
