import React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

const NotificationSettings = () => {
  return (
    <>
      <main>
        <section className="content-section white-color-section speckle-bg extra-section-margin">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <h2>Notification Settings</h2>
              </div>
              <div className="col-12 mt-5 setting-cards-col">
                <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title className="mb-2">Take a break</Card.Title>
                    <Card.Text>
                      <Form>
                        <Form.Check // prettier-ignore
                          type="radio"
                          id="custom-switch1"
                          label="off"
                          name="takeabreak"
                          defaultChecked
                        />
                        <Form.Check // prettier-ignore
                          type="radio"
                          id="custom-switch2"
                          label="8 hours"
                          name="takeabreak"
                        />
                        <Form.Check // prettier-ignore
                          type="radio"
                          id="custom-switch3"
                          label="1 day"
                          name="takeabreak"
                        />
                        <Form.Check // prettier-ignore
                          type="radio"
                          id="custom-switch4"
                          label="1 week"
                          name="takeabreak"
                        />
                      </Form>
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title className="mb-2">Security Alerts</Card.Title>
                    <Card.Text>
                      <Form>
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="alerts-check1"
                          label="push"
                          name="alerts"
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="alerts-check2"
                          label="email"
                          name="alerts"
                          defaultChecked
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="alerts-check3"
                          label="sms"
                          name="alerts"
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="alerts-check4"
                          label="in-app"
                          name="alerts"
                        />
                      </Form>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title className="mb-2">Account Activity</Card.Title>
                    <Card.Text>
                      <Form>
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="activity-check1"
                          label="push"
                          name="activity"
                          defaultChecked
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="activity-check2"
                          label="email"
                          name="activity"
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="activity-check3"
                          label="sms"
                          name="activity"
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="activity-check4"
                          label="in-app"
                          name="activity"
                        />
                      </Form>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title className="mb-2">
                      Offers & Annoucements
                    </Card.Title>
                    <Card.Text>
                      <Form>
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="offers-check1"
                          label="push"
                          name="offers"
                          defaultChecked
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="offers-check2"
                          label="email"
                          name="offers"
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="offers-check3"
                          label="sms"
                          name="offers"
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="offers-check4"
                          label="in-app"
                          name="offers"
                        />
                      </Form>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card style={{ width: "18rem" }}>
                  <Card.Body>
                    <Card.Title className="mb-2">news</Card.Title>
                    <Card.Text>
                      <Form>
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="news-check1"
                          label="push"
                          name="news"
                          defaultChecked
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="news-check2"
                          label="email"
                          name="news"
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="news-check3"
                          label="sms"
                          name="news"
                        />
                        <Form.Check // prettier-ignore
                          type="checkbox"
                          id="news-check4"
                          label="in-app"
                          name="news"
                        />
                      </Form>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default NotificationSettings;
