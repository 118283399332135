import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";

const LifetimeRewards = () => {
  return (
    <>
      <main>
        <section className="content-section white-color-section speckle-bg extra-section-margin">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <h2>Lifetime Rewards</h2>
              </div>
              <div className="col-12">
                <div className="logged-in-tabs mt-4">
                  <Tabs
                    defaultActiveKey="moneyback"
                    id="userActivityTabs"
                    className="mt-2"
                  >
                    <Tab eventKey="moneyback" title="moneyback">
                      <Table striped bordered responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>transaction type</th>
                            <th>received / spent on</th>
                            <th>value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>1 may 2024</td>
                            <td>received</td>
                            <td>bill payment</td>
                            <td>$1.4</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>3 may 2024</td>
                            <td>spent</td>
                            <td>bill payment</td>
                            <td>$1</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>7 may 2024</td>
                            <td>received</td>
                            <td>bill payment</td>
                            <td>$3.4</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>11 may 2024</td>
                            <td>received</td>
                            <td>bill payment</td>
                            <td>$2.5</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>

                    <Tab eventKey="boost" title="boost">
                      <Table striped bordered responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>transaction type</th>
                            <th>received / spent on</th>
                            <th>value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>9 may 2024</td>
                            <td>received</td>
                            <td>bill payment</td>
                            <td>1000</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>11 may 2024</td>
                            <td>spent</td>
                            <td>game - meteor rise</td>
                            <td>1000</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>13 may 2024</td>
                            <td>received</td>
                            <td>bill payment</td>
                            <td>579</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>15 may 2024</td>
                            <td>received</td>
                            <td>bill payment</td>
                            <td>357</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>

                    <Tab eventKey="vouchers" title="vouchers">
                      <Table striped bordered responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>received by</th>
                            <th>details</th>
                            <th>redeemed</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>17 may 2024</td>
                            <td>bill payment</td>
                            <td>amazon - ...09876</td>
                            <td>yes</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>19 may 2024</td>
                            <td>bill payment</td>
                            <td>flipkart - ...12345</td>
                            <td>yes</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>21 may 2024</td>
                            <td>bill payment</td>
                            <td>apple - ...67890</td>
                            <td>yes</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>23 may 2024</td>
                            <td>bill payment</td>
                            <td>gucci - ...102938</td>
                            <td>yes</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>

                    <Tab eventKey="candies" title="candies">
                      <Table striped bordered responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>transaction type</th>
                            <th>received / spent on</th>
                            <th>value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>25 may 2024</td>
                            <td>received</td>
                            <td>bill payment</td>
                            <td>1000</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>27 may 2024</td>
                            <td>spent</td>
                            <td>game - meteor rise</td>
                            <td>1000</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>29 may 2024</td>
                            <td>received</td>
                            <td>bill payment</td>
                            <td>579</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>31 may 2024</td>
                            <td>received</td>
                            <td>bill payment</td>
                            <td>357</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default LifetimeRewards;
