import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContextProvider";
import axios from "axios";
import { endPoints, baseUrl } from "../../data/endPoints";

/* comp imports */
import Meteor from "./Meteor";

/* img imports */
import coinIconSmall from "../../assets/img/coin-icon-small.png";
import winUpto50k from "../../assets/img/gaming/win-upto-50k.png";

const MeteorRise = ({
  setRewardsAmount,
  setShowRewardsMrWon,
  setActiveArr,
}) => {
  const {
    userCognito,
    getUserCognitoData,
    handleGetRewards,
    deductStandardCoin,
    // handleUpdateRewards,
    // updatedMrCoin,
    // updateMrCoins,
    // mrCoinsWon,
    // dbUpdatedMrRewards,
  } = useContext(UserContext);

  useEffect(() => {
    getUserCognitoData();
  }, []);

  const [showWinUpto, setShowWinUpto] = useState(true);
  const calculateCrashPoint = () => {
    // return 50.0;
    const rnd = Math.random();
    if (rnd < 0.859) return Math.random() * (3 - 1) + 1; // Between 1x to 3x
    else if (rnd < 0.964)
      return Math.random() * (10 - 3) + 3; // Between 3x to 10x
    else if (rnd < 0.991)
      return Math.random() * (25 - 10) + 10; // Between 10x to 25x
    else if (rnd < 0.998)
      return Math.random() * (35 - 25) + 25; // Between 25x to 35x
    else if (rnd < 0.999)
      return Math.random() * (45 - 35) + 35; // Between 35x to 45x
    else return Math.random() * (50 - 45) + 45; // Between 45x to 50x
  };
  const [multiplier, setMultiplier] = useState(1.0);
  const [crashPoint, setCrashPoint] = useState(0.0);

  const [gameStatus, setGameStatus] = useState("Stopped");
  const [cashoutValue, setCashoutValue] = useState((1.0).toFixed(2));
  const [isCashedOut, setIsCashedOut] = useState(false);
  const [cashoutValues, setCashoutValues] = useState([]);

  useEffect(() => {
    document.getElementById("cashoutBtn").style.display = "none";
  }, []);

  const getUpdateInterval = (multiplier) => {
    if (multiplier < 2) return 100; // Slower updates until 3x 3000
    if (multiplier < 4) return 70; // Slower updates until 3x 3000 ==2.0
    if (multiplier < 10) return 35; // Increase speed between 3x and 10x == 4.0
    if (multiplier < 20) return 18; // Faster updates between 10x and 20x == 10.0
    if (multiplier < 30) return 15; // And so on... == 20.0
    if (multiplier < 40) return 10; // == 30.0
    return 5; // Fastest updates for 40x to 50x == 40.0
  };

  /*
1 - 2 - 10
2 to 4 - 14
from 4 to 10 - 21
10 to 20 - 18
20 - 30 - 15
30 to 40 - 10
40 to 50 - 5

80.5

*/

  useEffect(() => {
    if (gameStatus == "Running" || gameStatus == "Cashedout") {
      const updateMultiplier = () => {
        setMultiplier((prevMultiplier) => {
          const newMultiplier = parseFloat((prevMultiplier + 0.01).toFixed(2));
          if (newMultiplier >= crashPoint) {
            setGameStatus("Crashed");
            setShowWinUpto(true);
            if (isCashedOut) {
              setCashoutValue((1.0).toFixed(2));
            }
            document.getElementById("cashoutBtn").style.display = "none";
            document.getElementById("startBtn").style.display = "inline-flex";
            document.getElementById("gamePriceMr").style.display = "block";
            setTimeout(() => {
              setActiveArr([true, true, true, true]);
            }, 1000);
            return crashPoint; // Ensure it stops at the crash point
          }
          return newMultiplier;
        });
      };

      let intervalId = setInterval(
        updateMultiplier,
        getUpdateInterval(multiplier)
      );

      return () => clearInterval(intervalId);
    }
  }, [gameStatus, multiplier]);

  const resetGame = () => {
    /* deduct 1000 reward coins here */
    deductStandardCoin();
    setActiveArr([false, false, true, false]);
    setShowWinUpto(false);
    setGameStatus("Running");
    setCrashPoint(calculateCrashPoint());
    setMultiplier(1.0);
    setCashoutValue((1.0).toFixed(2));
    document.getElementById("cashoutBtn").style.display = "inline-flex";
    document.getElementById("startBtn").style.display = "none";
    document.getElementById("gamePriceMr").style.display = "none";
  };

  const cashOut = (e, value) => {
    setIsCashedOut(true);
    setCashoutValue(value);
    document.getElementById("cashoutBtn").style.display = "none";
    cashoutValues.push(value);
    updateMrCoins(value * 1000);
  };

  const [updatedMrCoin, setUpdatedMrCoin] = useState(0);
  const [mrCoinsWon, setMrCoinsWon] = useState(0);
  const [dbUpdatedMrRewards, setDbUpdatedMrRewards] = useState([false, false]);

  const updateMrCoins = (coin) => {
    setMrCoinsWon(coin);
    let tempRewards = JSON.parse(localStorage.getItem("rewards"));
    if (tempRewards) {
      tempRewards.coin = tempRewards.coin + coin;
      localStorage.setItem("rewards", JSON.stringify(tempRewards));
      setUpdatedMrCoin(tempRewards.coin);
    }
  };

  const handleUpdateMrRewards = (
    mobile,
    type,
    transactionType,
    reason,
    value,
    winValue
  ) => {
    let configUpdateRewards = {
      method: "post",
      url: `${baseUrl}${endPoints.updateRewards}`,
      data: {
        mobile: mobile,
        toUpdate: {
          [type]: value,
        },
      },
      withCredentials: true,
    };
    setDbUpdatedMrRewards([false, false]);
    let configUpdateAllRewards = {
      method: "post",
      url: `${baseUrl}${endPoints.addLifetimeReward}`,
      data: {
        mobile: mobile,
        rewardType: type,
        transactionType: transactionType,
        reason: reason,
        details: "",
        voucherCode: "",
        redeemed: false,
        value: winValue,
      },
      withCredentials: true,
    };

    const updateUserMrRewardsData = async () => {
      try {
        const response = await axios.request(configUpdateRewards);
        if (response.data.status) {
          setDbUpdatedMrRewards([true, false]);
        } else {
          setDbUpdatedMrRewards([false, false]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const updateAllUserMrRewardsData = async () => {
      try {
        const response = await axios.request(configUpdateAllRewards);
        if (response.data.status) {
          setDbUpdatedMrRewards([true, true]);
        } else {
          setDbUpdatedMrRewards([false, false]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    updateUserMrRewardsData();
    updateAllUserMrRewardsData();
    setTimeout(() => {
      handleGetRewards(mobile, false);
    }, 1000);
  };

  useEffect(() => {
    if (updatedMrCoin !== 0 && userCognito.mobile && mrCoinsWon) {
      handleUpdateMrRewards(
        userCognito.mobile,
        "coin",
        "received",
        "game - meteor rise",
        updatedMrCoin,
        mrCoinsWon
      );
      setRewardsAmount(mrCoinsWon);
      setShowRewardsMrWon(true);
      // console.log("updatedMrCoin", updatedMrCoin, userCognito.mobile, mrCoinsWon);
    }
  }, [updatedMrCoin]);

  useEffect(() => {
    if (dbUpdatedMrRewards[0] && dbUpdatedMrRewards[1]) {
      // setTimeout(() => {
      //   document.getElementById("cashoutBtn").style.display = "none";
      //   document.getElementById("startBtn").style.display = "inline-flex";
      //   document.getElementById("gamePriceMr").style.display = "block";
      // }, 2000);

      setTimeout(() => {
        setShowRewardsMrWon(false);
        setRewardsAmount(0);
      }, 2000);
    }
  }, [dbUpdatedMrRewards[0], dbUpdatedMrRewards[1]]);

  return (
    <>
      <div className="pt-3 h-100">
        {showWinUpto && (
          <div className="mr-win-upto">
            <img src={winUpto50k} alt="" />
          </div>
          // <div className="mr-win-upto">
          //   <span>win upto</span> <img src={coinIconSmall} alt="" />{" "}
          //   <span>50,000</span>
          // </div>
        )}
        <Meteor multiplier={multiplier} gameStatus={gameStatus} />
        {/* <div className="crash-point">
          {crashPoint.toFixed(2)} -- {gameStatus}
        </div> */}
        <div className="multiplier">
          <span
            className={`multiplier-text ${
              gameStatus == "Crashed" && "color-red-light"
            }`}
          >
            {multiplier.toFixed(2)}x
          </span>
          <span
            className={`payout-text ${
              gameStatus == "Crashed" && "color-red-light"
            }`}
          >
            {gameStatus == "Crashed"
              ? "game over"
              : `+${(multiplier * 1000).toFixed(2)}`}
          </span>
        </div>

        <div className="game-btn-wrapper">
          <button
            type="button"
            onClick={(e) => {
              cashOut(e, multiplier.toFixed(2));
            }}
            id="cashoutBtn"
          >
            <span> cashout </span>
          </button>

          <button type="button" onClick={resetGame} id="startBtn">
            <span> new game </span>
          </button>
          <span className="game-price" id="gamePriceMr">
            1000
          </span>
        </div>

        <div className="previous-cashout">
          {/* <span>cashouts: </span> */}
          {cashoutValues.length
            ? cashoutValues.map((val, index) => {
                return <span key={index}>{val}</span>;
              })
            : ""}
        </div>
      </div>
    </>
  );
};

export default MeteorRise;
