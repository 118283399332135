import React, { useState, useContext, useEffect } from "react";
import { CartContext } from "../../context/CartContextProvider";
import { motion } from "framer-motion";
import CountUp from "react-countup";

import { tiltAnimation } from "../checkoutAnimation";

/* img imports */
import currencyIconSmall from "../../assets/img/currency-icon-small.png";

const CartPricingDetails = ({ desoRate }) => {
  const { totalCartValue } = useContext(CartContext);
  const [usingBalance, setUsingBalance] = useState(false);
  const [moneybackBalance, setMoneybackBalance] = useState(73);
  const [payableAmount, setPayableAmount] = useState(0);
  const [newPayableAmount, setNewPayableAmount] = useState(0);

  const usingBalanceHandler = () => {
    setUsingBalance(!usingBalance);
  };

  useEffect(() => {
    if (totalCartValue && moneybackBalance) {
      setPayableAmount(desoRate * totalCartValue);
      setNewPayableAmount(desoRate * totalCartValue - moneybackBalance);
    }
  }, [totalCartValue]);

  return (
    <>
      <div className="cart-pricing-details">
        <div className="price-details-wrapper">
          <span className="price-details-head">Price Details</span>
          <div
            className="use-balance-blk"
            id="useBalance"
            onClick={usingBalanceHandler}
          >
            <motion.span
              className="img-blk"
              variants={tiltAnimation}
              initial="initial"
              animate="animate"
            >
              <img src={currencyIconSmall} alt="" />
            </motion.span>
            <span className="text-blk">{`${usingBalance ? "0" : "73"}`}</span>
          </div>
        </div>

        <div className="charges-wrap">
          <span className="charge-prop">Price</span>
          <span className="charge-value">
            <span className="prod-price">{`$ ${payableAmount.toFixed(
              2
            )}`}</span>
          </span>
        </div>

        {/* <div className="charges-wrap">
          <span className="charge-prop">Delivery charges</span>
          <span className="charge-value">
            Free <small>($0.00)</small>
          </span>
        </div> */}
        <div className="charges-wrap">
          <span className="charge-prop">Tax</span>
          <span className="charge-value">TBD</span>
        </div>

        <div className="total-wrapper">
          {usingBalance && (
            <div className="balance-amount">
              <span className="img-blk">
                <img src={currencyIconSmall} alt="" />
              </span>
              <span className="text-blk">
                {
                  <CountUp
                    end={usingBalance && moneybackBalance}
                    start={0}
                    duration={1}
                  />
                }
              </span>
            </div>
          )}
          <span className="total-text">Total</span>
          <span className="total-value">
            <CountUp
              end={usingBalance ? newPayableAmount : payableAmount}
              //   start={payableAmount}
              start={usingBalance ? payableAmount : newPayableAmount}
              decimals={2}
              duration={1}
              prefix="$"
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default CartPricingDetails;
