import React, { useState } from "react";

/* comp imports */
import HomeMain from "../components/HomeMain";

const Home = ({ handleShowLoginScreen }) => {
  return (
    <>
      <HomeMain handleShowLoginScreen={handleShowLoginScreen} />
    </>
  );
};

export default Home;
