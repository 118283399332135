import React from "react";
import Accordion from "react-bootstrap/Accordion";

import { vendorLogos } from "../../components/vendorLogos";
import { walletBankLogos } from "../../components/walletBankLogos";
import { currencyIcons } from "../../components/currencyIcons";

const CheckoutDetails = () => {
  return (
    <>
      <div className="checkout-details-wrapper">
        <div className="checkout-details-head">shipping information</div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="main-text">Anurag Khona</div>
              <div className="add-address">
                Test Address, North Haven, Connecticut, United States, 06473,
                Test Town
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {/* <div className="address-main-wrapper scrollbar-success"> */}
              <div className="cart-address-block">
                <div className="address-wrapper">
                  <div className="add-name">Anurag Khona</div>
                  <div className="add-address">
                    Test Address, North Haven, Connecticut, United States,
                    06473, Test Town
                  </div>
                  <div className="add-phone">Mobile: 1234567890</div>
                </div>
                <div className="btn-wrapper">
                  <button type="button" className="default">
                    <span className="material-symbols-outlined">home</span>
                  </button>
                </div>
              </div>

              <div className="cart-address-block">
                <div className="address-wrapper">
                  <div className="add-name">Office - anurag</div>
                  <div className="add-address">
                    New, California US, California, New York, United States,
                    1101, Some
                  </div>
                  <div className="add-phone">Mobile: 2323232324</div>
                </div>
                <div className="btn-wrapper">
                  <button type="button" className="">
                    <span className="material-symbols-outlined">apartment</span>
                  </button>
                </div>
              </div>

              <div className="cart-address-block">
                <div className="address-wrapper">
                  <div className="add-name">uncle - anurag</div>
                  <div className="add-address">
                    California US, California, New York, United States, 1101
                  </div>
                  <div className="add-phone">Mobile: 9175029140</div>
                </div>
                <div className="btn-wrapper">
                  <button type="button" className="">
                    <span className="material-symbols-outlined">home_work</span>
                  </button>
                </div>
              </div>
              <div className="cart-address-block">
                <div className="address-wrapper">
                  <div className="add-name">Office 2 - anurag</div>
                  <div className="add-address">
                    New, California US, California, New York, United States,
                    1101, Some
                  </div>
                  <div className="add-phone">Mobile: 2323232324</div>
                </div>
                <div className="btn-wrapper">
                  <button type="button" className="">
                    <span className="material-symbols-outlined">apartment</span>
                  </button>
                </div>
              </div>
              {/* </div> */}

              <button
                className="prod-cart"
                data-text="add new address"
              ></button>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="checkout-details-wrapper">
        <div className="checkout-details-head">payment information</div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="header-wraper-text">
                <span className="payment-text">paying with</span>
                <span className="payment-text">citi bank...6060</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="payment-mode-wrapper">
                <span className="mode-head">
                  <span className="head-text">bank</span>
                  <button type="button">
                    <span className="">
                      <span className="material-symbols-outlined">add</span>
                    </span>
                  </button>
                </span>
                <div className="mode-info-wrapper">
                  <span className="mode-logo">
                    <img src={vendorLogos.boaDark} alt="" />
                  </span>

                  <span className="mode-creds">(0101)</span>
                </div>
                <div className="mode-info-wrapper">
                  <span className="mode-logo">
                    <img src={vendorLogos.chaseDark} alt="" />
                  </span>
                  <span className="mode-creds">(4040)</span>
                </div>
                <div className="mode-info-wrapper default">
                  <span className="mode-logo">
                    <img src={vendorLogos.citiDark} alt="" />
                  </span>
                  <span className="mode-creds">(6060)</span>
                </div>
              </div>

              {/* end of banks */}

              <div className="payment-mode-wrapper">
                <span className="mode-head">
                  <span className="head-text">debit cards</span>
                  <button type="button">
                    <span className="">
                      <span className="material-symbols-outlined">add</span>
                    </span>
                  </button>
                </span>
                <div className="mode-info-wrapper">
                  <span className="mode-logo">
                    <img src={vendorLogos.boaDark} alt="" />
                  </span>

                  <span className="mode-creds">(0101)</span>
                </div>
                <div className="mode-info-wrapper">
                  <span className="mode-logo">
                    <img src={vendorLogos.chaseDark} alt="" />
                  </span>

                  <span className="mode-creds">(4040)</span>
                </div>
              </div>
              {/* end of debit cards */}

              <div className="payment-mode-wrapper">
                <span className="mode-head">
                  <span className="head-text">wallets</span>
                  <button type="button">
                    <span className="">
                      <span className="material-symbols-outlined">add</span>
                    </span>
                  </button>
                </span>
                <div className="mode-info-wrapper">
                  <span className="wallet-info-wrapper">
                    <span className="mode-logo wallets">
                      <img src={walletBankLogos.metamask} alt="" />
                    </span>
                    <span className="mode-name">metamask</span>
                  </span>

                  <span className="mode-creds">bg3bgh...</span>
                </div>
                <div className="mode-info-wrapper">
                  <span className="wallet-info-wrapper">
                    <span className="mode-logo wallets">
                      <img src={walletBankLogos.trustwallet} alt="" />
                    </span>
                    <span className="mode-name">trust wallet</span>
                  </span>
                  <span className="mode-creds">tb88ed...</span>
                </div>
                <div className="mode-info-wrapper">
                  <span className="wallet-info-wrapper">
                    <span className="mode-logo wallets">
                      <img src={walletBankLogos.coinbase} alt="" />
                    </span>
                    <span className="mode-name">coin base</span>
                  </span>
                  <span className="mode-creds">coh36d...</span>
                </div>
              </div>
              {/* end of wallets */}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="header-wraper-text">
                <span className="payment-text">currency</span>
                <span className="payment-text">dollar</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="currency-info-wrapper">
                <div className="currency-info default">
                  <span className="currency-logo">
                    <img src={currencyIcons.dollarIcon} alt="" />
                  </span>
                  <span className="currency-name">Dollar</span>
                </div>
                <div className="currency-info">
                  <span className="currency-logo">
                    <img src={currencyIcons.rupeeIcon} alt="" />
                  </span>
                  <span className="currency-name">e-rupee</span>
                </div>
                <div className="currency-info">
                  <span className="currency-logo">
                    <img src={currencyIcons.ethIcon} alt="" />
                  </span>
                  <span className="currency-name">etherium</span>
                </div>
                <div className="currency-info">
                  <span className="currency-logo">
                    <img src={currencyIcons.dodgecoinIcon} alt="" />
                  </span>
                  <span className="currency-name">dodge coin</span>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="cart-checkout-btn-wrapper">
        <button
          type="button"
          className="prod-cart"
          data-text="Checkout"
        ></button>
      </div>
    </>
  );
};

export default CheckoutDetails;
