import React, { useState, useEffect, useContext } from "react";
import { gsap, Sine } from "gsap";
import { convertDate } from "../data/custom";
import { UserContext } from "../context/UserContextProvider";

/* img imports */
import billsLock from "../assets/img/bill-payments/bill-lock.png";
import imgLineMain from "../assets/img/img-line-main.png";
import imgLineMain2 from "../assets/img/img-line-main-2.png";
import creditCard1 from "../assets/img/bill-payments/credit-card-1.png";
import creditCard2 from "../assets/img/bill-payments/credit-card-2.png";
import creditCard3 from "../assets/img/bill-payments/credit-card-3.png";
import closeIcon from "../assets/img/close-icon.svg";

// import paymentCompletedIcon from "../assets/img/payment-completed-icon.svg";
/* comp imports */
import CreditCardForm from "../components/billpayments/CreditCardForm";
import CheckoutDetailsCard from "../components/billpayments/CheckoutDetailsCard";
import ConfirmPaymentCard from "../components/billpayments/ConfirmPaymentCard";
import PaymentThankyouCard from "../components/billpayments/PaymentThankyouCard";

const Personal = () => {
  const { setNewMoneyBackBalance } = useContext(UserContext);
  const [creditCardInfo, setCreditCardInfo] = useState({
    number: "",
    expiry: "",
    cvc: "",
    issuer: "",
    name: "",
    focus: "",
  });
  const [dateValue, setDateValue] = useState(convertDate(new Date()));
  const [amountPayable, setAmountPayable] = useState(0);

  const [creditCardIsButtons, setCreditCardIsButtons] = useState(true);
  const [creditCardIsAdd, setCreditCardIsAdd] = useState(false);
  const [creditCardIsEdit, setCreditCardIsEdit] = useState(false);
  const [creditCardIsPayment, setCreditCardIsPayment] = useState(false);
  const [creditCardIsConfirm, setCreditCardIsConfirm] = useState(false);
  const [creditCardIsThankyou, setCreditCardIsThankyou] = useState(false);
  const [creditCardIsEditMode, setCreditCardIsEditMode] = useState(false);

  const handleCardIsAdd = () => {
    setCreditCardIsEditMode(false);
    setCreditCardIsButtons(false);
    setCreditCardIsAdd(true);
  };
  const handleCardIsEdit = () => {
    setCreditCardIsEditMode(!creditCardIsEditMode);
  };
  const handleShowCardPayment = () => {
    setCreditCardIsPayment(true);
    setCreditCardIsButtons(false);
    setCreditCardIsAdd(false);
    setCreditCardIsEdit(false);
    setCreditCardIsConfirm(false);
    setCreditCardIsThankyou(false);
  };
  const handleShowCardEdit = () => {
    if (creditCardIsEditMode) {
      setCreditCardIsEditMode(false);
      setCreditCardIsButtons(false);
      setCreditCardIsEdit(true);
    } else {
      handleShowCardPayment();
    }
  };

  const handleShowConfirmPayment = () => {
    if (creditCardIsPayment) {
      setCreditCardIsPayment(false);
      setCreditCardIsConfirm(true);
    }
  };
  const handleShowPaymentThankyou = () => {
    if (creditCardIsConfirm) {
      setCreditCardIsConfirm(false);
      setCreditCardIsThankyou(true);
    }
  };
  // const handleShowCardAdd = () => {};

  const handleCloseCardSreens = () => {
    setCreditCardIsButtons(true);
    setCreditCardIsAdd(false);
    setCreditCardIsEdit(false);
    setCreditCardIsPayment(false);
    setCreditCardIsConfirm(false);
    setCreditCardIsThankyou(false);
  };

  const defauldWrapperHeight = {
    duration: 0.5,
    height: "340px",
    ease: "Sine.easeOut",
  };
  const expandedWrapperHeight = {
    duration: 0.5,
    height: "550px",
    ease: "Sine.easeOut",
  };

  useEffect(() => {
    if (
      creditCardIsAdd ||
      creditCardIsEdit ||
      creditCardIsPayment ||
      creditCardIsConfirm
    ) {
      gsap.fromTo(
        "#billsCardWrapper",
        {
          height: "340px",
        },
        expandedWrapperHeight
      );
    } else {
      gsap.fromTo(
        "#billsCardWrapper",
        {
          height: "550px",
        },
        defauldWrapperHeight
      );
    }
  }, [
    creditCardIsAdd,
    creditCardIsEdit,
    creditCardIsPayment,
    creditCardIsConfirm,
  ]);

  const onIconFlyComplete = () => {
    // setNewCurrencyAmount(93);
    setCreditCardIsAdd(false);
    setCreditCardIsEdit(false);
    setCreditCardIsPayment(false);
    setCreditCardIsConfirm(false);
    setCreditCardIsThankyou(false);
    setCreditCardIsButtons(true);
    setNewMoneyBackBalance((newMoneyBackBalance) => newMoneyBackBalance + 20);
    let moneyBackIcon = document.getElementById("moneyBackIcon");
    gsap.fromTo(
      moneyBackIcon,
      {
        scale: 1,
      },
      {
        duration: 0.5,
        scale: 1.3,
        delay: 0.3,
        ease: Sine.easeOut,
        onComplete: () => {
          gsap.to(moneyBackIcon, { duration: 0.2, scale: 1 });
        },
      }
    );
  };

  return (
    <>
      <main>
        <section className="content-section white-color-section bill-payments-section speckle-bg">
          <div className="shape"></div>
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12 col-lg-6 offset-lg-3 offset-xl-0 col-xl-4">
                <div className="row">
                  <div className="col-12 bills-card-col">
                    <div className="bill-pay-head">Credit Card</div>
                    <div
                      className="bills-main-card-wrapper active"
                      id="billsCardWrapper"
                    >
                      {creditCardIsButtons && (
                        <div className="cc-buttons-wrapper">
                          <button
                            className="starry-btn me-4"
                            type="button"
                            onClick={handleCardIsAdd}
                          >
                            <span className="btn-text">add</span>
                            <div id="container-stars">
                              <div id="stars"></div>
                            </div>

                            <div id="glow">
                              <div className="circle"></div>
                              <div className="circle"></div>
                            </div>
                          </button>
                          <button
                            className="starry-btn"
                            type="button"
                            onClick={handleCardIsEdit}
                          >
                            <span className="btn-text">edit</span>
                            <div id="container-stars">
                              <div id="stars"></div>
                            </div>

                            <div id="glow">
                              <div className="circle"></div>
                              <div className="circle"></div>
                            </div>
                          </button>
                        </div>
                      )}
                      {creditCardIsEdit && (
                        <div className="credit-card-edit-wrapper">
                          <span
                            className="close-cc-screens"
                            onClick={handleCloseCardSreens}
                          >
                            <img src={closeIcon} alt="" />
                          </span>
                          <CreditCardForm
                            creditCardInfo={creditCardInfo}
                            setCreditCardInfo={setCreditCardInfo}
                          />

                          <button className="starry-btn my-4" type="button">
                            <span className="btn-text">remove</span>
                            <div id="container-stars">
                              <div id="stars"></div>
                            </div>

                            <div id="glow">
                              <div className="circle"></div>
                              <div className="circle"></div>
                            </div>
                          </button>
                        </div>
                      )}
                      {/* end of edit card */}
                      {creditCardIsAdd && (
                        <div className="credit-card-add-wrapper">
                          <span
                            className="close-cc-screens"
                            onClick={handleCloseCardSreens}
                          >
                            <img src={closeIcon} alt="" />
                          </span>
                          <CreditCardForm
                            creditCardInfo={creditCardInfo}
                            setCreditCardInfo={setCreditCardInfo}
                          />
                          <button className="starry-btn my-4" type="button">
                            <span className="btn-text">add</span>
                            <div id="container-stars">
                              <div id="stars"></div>
                            </div>

                            <div id="glow">
                              <div className="circle"></div>
                              <div className="circle"></div>
                            </div>
                          </button>
                        </div>
                      )}
                      {/* end of add card */}
                      {creditCardIsPayment && (
                        <>
                          <span
                            className="close-cc-screens payment-screen-close"
                            onClick={handleCloseCardSreens}
                          >
                            <img src={closeIcon} alt="" />
                          </span>
                          <div className="credit-card-payment-wrapper scrollbar-success">
                            <CheckoutDetailsCard
                              handleShowConfirmPayment={
                                handleShowConfirmPayment
                              }
                              dateValue={dateValue}
                              setDateValue={setDateValue}
                              amountPayable={amountPayable}
                              setAmountPayable={setAmountPayable}
                            />
                          </div>
                        </>
                      )}
                      {creditCardIsConfirm && (
                        <>
                          <span
                            className="close-cc-screens payment-screen-close"
                            onClick={handleCloseCardSreens}
                          >
                            <img src={closeIcon} alt="" />
                          </span>
                          <div className="credit-card-payment-wrapper scrollbar-success">
                            <ConfirmPaymentCard
                              handleShowPaymentThankyou={
                                handleShowPaymentThankyou
                              }
                              dateValue={dateValue}
                              amountPayable={amountPayable}
                            />
                          </div>
                        </>
                      )}
                      {creditCardIsThankyou && (
                        <>
                          <span
                            className="close-cc-screens payment-screen-close"
                            onClick={handleCloseCardSreens}
                          >
                            <img src={closeIcon} alt="" />
                          </span>
                          <div className="payment-thankyou-wrapper">
                            <PaymentThankyouCard
                              onIconFlyComplete={onIconFlyComplete}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="saved-card-wrapper scrollbar-success">
                      <span className="saved-card" onClick={handleShowCardEdit}>
                        <img
                          src={creditCard1}
                          alt=""
                          className={creditCardIsEditMode ? "shake-anime" : ""}
                        />
                        <span className="card-text have-time">15 days</span>
                      </span>
                      <span className="saved-card" onClick={handleShowCardEdit}>
                        <img
                          src={creditCard2}
                          alt=""
                          className={creditCardIsEditMode ? "shake-anime" : ""}
                        />
                        <span className="card-text nearby">2 days</span>
                      </span>
                      <span className="saved-card" onClick={handleShowCardEdit}>
                        <img
                          src={creditCard3}
                          alt=""
                          className={creditCardIsEditMode ? "shake-anime" : ""}
                        />
                        <span className="card-text past-due">- 3 days</span>
                      </span>
                    </div>
                  </div>
                  {/* end of col */}
                </div>
              </div>
              <div className="col-12 col-xl-8">
                <div className="row">
                  <div className="col-12 col-lg-6 bills-card-col">
                    <div className="bill-pay-head">Car Loan</div>
                    <div className="bills-main-card-wrapper locked-card">
                      <picture className="lock-picture">
                        <img src={billsLock} alt="" />
                      </picture>
                    </div>
                    <div className="locked-sub-text">
                      (unlock after 3 consecutive payment on any same credit
                      card)
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 bills-card-col">
                    <div className="bill-pay-head">Mortgage</div>
                    <div className="bills-main-card-wrapper locked-card">
                      <picture className="lock-picture">
                        <img src={billsLock} alt="" />
                      </picture>
                    </div>
                    <div className="locked-sub-text">
                      (unlock after 3 consecutive payment on any same credit
                      card)
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 bills-card-col">
                    <div className="bill-pay-head">Student Loan</div>
                    <div className="bills-main-card-wrapper locked-card">
                      <picture className="lock-picture">
                        <img src={billsLock} alt="" />
                      </picture>
                    </div>
                    <div className="locked-sub-text">
                      (unlock after 3 consecutive payment on any same credit
                      card)
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 bills-card-col">
                    <div className="bill-pay-head">Tax</div>
                    <div className="bills-main-card-wrapper locked-card">
                      <picture className="lock-picture">
                        <img src={billsLock} alt="" />
                      </picture>
                    </div>
                    <div className="locked-sub-text">
                      (unlock 1st Jan ,2025)
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 bills-card-col">
                    <div className="bill-pay-head">Utility Bill</div>
                    <div className="bills-main-card-wrapper locked-card">
                      <picture className="lock-picture">
                        <img src={billsLock} alt="" />
                      </picture>
                    </div>
                    <div className="locked-sub-text">
                      (unlock after 3 consecutive payment on any same credit
                      card)
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 bills-card-col">
                    <div className="bill-pay-head">Rent</div>
                    <div className="bills-main-card-wrapper locked-card">
                      <picture className="lock-picture">
                        <img src={billsLock} alt="" />
                      </picture>
                    </div>
                    <div className="locked-sub-text">
                      (unlock 1st Jan ,2025)
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default Personal;
