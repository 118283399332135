import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { bounceAnimation } from "../data/custom";

/* comp imports */
import FaqsSection from "./FaqsSection";
import SecurityShieldRadar from "./SecurityShieldRadar";

/* video imports */
import everyPaymentCount from "../assets/img/video-assets/everyPaymentCount.mp4";
import walletPortal from "../assets/img/video-assets/walletsPortal.mp4";
import securityBeyondMeasure from "../assets/img/video-assets/securityBeyondMeasure.mp4";
import flauntBadges from "../assets/img/video-assets/flauntBadges.mp4";
import chachingTurbo from "../assets/img/video-assets/chachingTurbo.mp4";
import arrivalOfChaching from "../assets/img/video-assets/arrivalOfChaching.mp4";

/* img imports */
// import imgLineMain from "../assets/img/img-line-main.png";
// import imgLineMain2 from "../assets/img/img-line-main-2.png";
import swipeWayToRewards from "../assets/img/video-assets/swipeWayToRewards.mp4";
import craftedWYouMobile from "../assets/img/banner/crafted-w-you-mobile.png";
import instantlyYours from "../assets/img/banner/instantly-yours.jpg";
import arrivalOfChachingPoster from "../assets/img/banner/arrivalOfChachingPoster.jpg";

const PayMain = () => {
  const navigate = useNavigate();

  const handleRedirect = (path) => {
    if (path) {
      navigate(`/${path}`);
    }
  };

  const videoRefEveryPaymentCount = useRef(undefined);
  const videoRefSwipeWayToRewards = useRef(undefined);
  const videoRefWalletPortal = useRef(undefined);
  const videoRefSecurityBeyondMeasure = useRef(undefined);
  const videoRefFlauntBadges = useRef(undefined);
  const videoRefChachingTurbo = useRef(undefined);
  const videoRefArrivalOfChaching = useRef(undefined);
  useEffect(() => {
    videoRefEveryPaymentCount.current.defaultMuted = true;
    videoRefSwipeWayToRewards.current.defaultMuted = true;
    videoRefWalletPortal.current.defaultMuted = true;
    videoRefSecurityBeyondMeasure.current.defaultMuted = true;
    videoRefFlauntBadges.current.defaultMuted = true;
    videoRefChachingTurbo.current.defaultMuted = true;
    videoRefArrivalOfChaching.current.defaultMuted = true;
  });

  return (
    <>
      <main>
        <section className="comm-section pay-banner-section">
          {/* <div className="shape right"></div> */}
          <div className="section-overlay-dark"></div>

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefArrivalOfChaching}
              poster={arrivalOfChachingPoster}
            >
              <source src={arrivalOfChaching} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-left text-md-center">
                    <h3 className="section-heading pay-page">The arrival of</h3>

                    <h1 className="section-heading pay-page">
                      <span className="comp-name">ChaChing</span> Pay
                    </h1>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of main banner section 1 */}
        <section className="comm-section white-color-section speckle-bg">
          <div className="section-overlay-dark"></div>

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefEveryPaymentCount}
              // poster={everyPaymentCountPoster}
            >
              <source src={everyPaymentCount} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper text-center">
                      <h3 className="section-heading pay-page">
                        make every payment count, <br /> hear that{" "}
                        <motion.span
                          className="comp-name comp-name-anime color-logo-yellow"
                          variants={bounceAnimation}
                          initial="initial"
                          animate="animate"
                        >
                          ChaChing
                        </motion.span>{" "}
                        sound!
                      </h3>

                      <div className="section-content pay-page">
                        pay. earn assured rewards. claim collectibles. all of
                        this in a secure payment experience the creditworthy
                        deserve.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 2 */}
        <section className="comm-section white-color-section wallets-portal-section">
          <div className="section-overlay-dark"></div>

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefWalletPortal}
              // poster={walletPortalPoster}
            >
              <source src={walletPortal} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <Lottie
              options={defaultOptionsWalletPortal}
              className="lottieVideo"
            /> */}
          </div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page mt-0 mt-md-5">
                        make your wallet sing with every{" "}
                        <span className="comp-name">ChaChing</span>!
                      </h3>

                      <div className="section-content pay-page">
                        add any wallet. all wallets are welcome.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 3 */}
        <section className="comm-section white-color-section speckle-bg height-auto-section">
          <div className="container">
            <div className="row align-content-start align-content-md-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="mobile-img-wrapper">
                      <img src={instantlyYours} alt="" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        Instantly Yours
                      </h3>

                      <div className="section-content pay-page">
                        waiting is a thing of the past in our instant world. pay
                        online instantly with us.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 4 */}
        <section className="comm-section white-color-section speckle-bg height-auto-section">
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-lg-6 d-flex align-items-center flex-wrap order-1 order-lg-0">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        swipe your way to rewards - every time you{" "}
                        <span className="comp-name">ChaChing</span>!
                      </h3>

                      <div className="section-content pay-page">
                        there are rewards beyond just the experience. earn
                        assured moneyback, coins, and more on every transaction.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 d-flex align-items-center order-0 order-lg-1">
                    <div className="content-video-wrapper mb-lg-0">
                      <video
                        preload="true"
                        muted
                        autoPlay
                        loop
                        playsInline
                        ref={videoRefSwipeWayToRewards}
                        // poster={payRewardsVideoPoster}
                      >
                        <source src={swipeWayToRewards} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 5 */}
        <section className="comm-section white-color-section shop-smarter-section">
          <div className="section-overlay-dark above-main-bg"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        Shop smarter, not harder
                      </h3>

                      <div className="section-content pay-page">
                        discover the ease of shopping with{" "}
                        <span className="comp-name">ChaChing</span> at your
                        fingertips, checkout with{" "}
                        <span className="comp-name">ChaChing</span> pay, and
                        score 2x money back— your ultimate companion
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 6 */}
        <section className="comm-section white-color-section">
          <div className="section-overlay-dark"></div>

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefSecurityBeyondMeasure}
              // poster={everyPaymentCountPoster}
            >
              <source src={securityBeyondMeasure} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <Lottie
              options={defaultOptionsSecurityBeyondMeasure}
              className="lottieVideo"
            /> */}
          </div>

          <div className="container  speckle-bg">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap justify-content-center text-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        security beyond measure. at every step of the way.
                      </h3>

                      <div className="section-content pay-page mb-4">
                        switch to anonymity. create an alias ID.
                      </div>
                      <div className="section-content pay-page">
                        Keep your personal information secure during
                        transactions by shielding it from merchants' view—
                        because what's yours is always yours.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 7 */}
        <section className="comm-section white-color-section security-shield-section speckle-bg">
          <SecurityShieldRadar />
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper text-center">
                      <div className="section-content pay-page">
                        a tender embrace of security providing you with a shield
                        of security and confidence. <br />
                        <br /> If a payment doesn't go through, just try again.
                        And if you're charged for the first attempt,{" "}
                        <span className="comp-name">ChaChing</span> will refund
                        you right away.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 8 */}
        <section className="comm-section white-color-section crafted-personalisation height-auto-section pt-0">
          <div className="section-overlay-dark above-main-bg"></div>
          <div className="container pt-5">
            <div className="row align-content-start align-content-md-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap order-1 order-md-0">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page mt-2 mt-md-0">
                        crafted with you in mind.
                      </h3>

                      <div className="section-content pay-page">
                        <span className="comp-name">ChaChing</span> Pay is your
                        personal stage, spotlighting your essence - your taste,
                        your successes, your favourite things. explore all the
                        different looks your window can try on. It might be the
                        key to unlocking the door to your true self
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 order-0 order-md-1">
                    <div className="mobile-img-wrapper">
                      <img src={craftedWYouMobile} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 9 */}
        <section className="comm-section white-color-section position-relative overflow-hidden height-auto-section">
          <div className="section-overlay-dark"></div>
          <div className="lottie-video-wrapper-content">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefFlauntBadges}
              // poster={everyPaymentCountPoster}
            >
              <source src={flauntBadges} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <Lottie
              options={defaultOptionsFlauntBadges}
              className="lottieVideo"
            /> */}
          </div>
        </section>

        {/* end of section 10 */}
        {/* <section className="comm-section white-color-section">
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        flaunt your creativity
                      </h3>

                      <div className="section-content pay-page">
                        redeem coins to collect badges. pin them to your payment
                        window. flaunt your good taste.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>

            </div>

          </div>
        </section> */}
        {/* end of section 11 */}
        <section className="comm-section white-color-section chaching-everywhere-section">
          <div className="section-overlay-dark above-main-bg"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper text-center">
                      <h3 className="section-heading pay-page">
                        <span className="comp-name">ChaChing</span> everywhere
                        and anywhere!
                      </h3>

                      <div className="section-content pay-page">
                        select <span className="comp-name">ChaChing</span> pay
                        while checking out from your favourite online stores.
                        the exclusive rewards you'll unlock is not the only
                        reason to do it.
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-6"></div> */}
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 12 */}
        <section className="comm-section white-color-section speckle-bg chaching-turbo-section">
          <div className="section-overlay-dark"></div>

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefChachingTurbo}
              // poster={everyPaymentCountPoster}
            >
              <source src={chachingTurbo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <Lottie
              options={defaultOptionsChachingTurbo}
              className="lottieVideo"
            /> */}
          </div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper text-center">
                      <h3 className="section-heading pay-page">
                        <span className="comp-name">ChaChing</span> Turbo
                      </h3>

                      <div className="section-content pay-page mb-4">
                        Rev up your experience with{" "}
                        <span className="comp-name">ChaChing</span> Turbo
                      </div>
                      <div className="section-content pay-page">
                        get 120 minutes to shop at up to 50% less on your
                        favorite brands.
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-6"></div> */}
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 13 */}
        <section className="comm-section white-color-section moneyback-section">
          <div className="section-overlay-dark above-main-bg"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper text-center">
                      <h3 className="section-heading pay-page">
                        100% moneyback. every hour. Only via payment made
                        through us.
                      </h3>

                      {/* <div className="section-content pay-page">

                      </div> */}

                      <button
                        className="starry-btn me-3"
                        type="button"
                        onClick={() => {
                          handleRedirect("rewards-hourly-pay");
                        }}
                      >
                        <span className="btn-text">know more</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-6"></div> */}
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 14 */}
        <FaqsSection />
      </main>
    </>
  );
};

export default PayMain;
