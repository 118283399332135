import React, { useState, useEffect } from "react";
import { daysFromDate } from "../data/custom";

/* img imports */
import sampleVoucher from "../assets/img/vouchers/sample-voucher.jpg";

const Voucher = ({ voucherData }) => {
  const [btnVariants, setBtnVariants] = useState([
    "variant-1",
    "variant-2",
    "variant-3",
    "variant-4",
    "variant-5",
    "variant-6",
  ]);
  const [btnRandomNum, setBtnRandomNum] = useState(0);
  const [btnRandomNum2, setBtnRandomNum2] = useState(0);
  useEffect(() => {
    let randonNum = Math.floor(Math.random() * 6);
    let randonNum2 = Math.floor(Math.random() * 6);
    setBtnRandomNum(randonNum);
    if (randonNum !== randonNum2) {
      setBtnRandomNum2(randonNum2);
    } else {
      randonNum2 = Math.floor(Math.random() * 6);
      setBtnRandomNum2(randonNum2);
    }
  }, [voucherData]);
  // window.open(URL, "_blank");
  return (
    <div className="voucher">
      <span className="expiry-block">
        expires in {daysFromDate(voucherData?.expiry)} days
      </span>
      <div className="voucher-img">
        <img
          src={`${voucherData.imgUrl ? voucherData.imgUrl : sampleVoucher}`}
          alt=""
        />
      </div>

      <div
        className={`voucher-text ${
          voucherData.type === "ticket" ? "for-location" : ""
        }`}
      >
        {voucherData.type === "ticket" && (
          <span className="location">
            <span className="material-symbols-outlined">location_on</span>
            <span>{voucherData?.location}</span>
          </span>
        )}
        <div className="voucher-name">{voucherData?.name}</div>
        <div className="brand-name">{voucherData?.brand}</div>
      </div>
      <div className="voucher-btns">
        <button
          className={`${btnVariants.length && btnVariants[btnRandomNum]}`}
        >
          <span> details </span>
        </button>

        {voucherData?.isCoupon && voucherData?.type === "coupon" ? (
          <button
            className={`${btnVariants.length && btnVariants[btnRandomNum2]}`}
          >
            <span> get code </span>
          </button>
        ) : !voucherData?.isCoupon && voucherData?.type === "coupon" ? (
          <button
            className={`${btnVariants.length && btnVariants[btnRandomNum2]}`}
            onClick={() => {
              window.open(voucherData?.link, "_blank");
            }}
          >
            <span> redeem </span>
          </button>
        ) : (
          <button
            className={`${btnVariants.length && btnVariants[btnRandomNum2]}`}
            onClick={() => {
              window.open(voucherData?.link, "_blank");
            }}
          >
            <span> book </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Voucher;
