import React from "react";

import ProductBlock from "./ProductBlock";

const ProductListing = ({
  productList,
  handleProductRedirect,
  desoRate,
  btnVariants,
}) => {
  return (
    <>
      <div className="row product-listing-main-wrapper-row">
        <div className="col-12">
          <div className="prodlist-main-wrapper">
            {productList.length ? (
              productList.map((productData) => {
                return (
                  <ProductBlock
                    productData={
                      Object.keys(productData).length > 0 && productData
                    }
                    handleProductRedirect={handleProductRedirect}
                    desoRate={desoRate && desoRate}
                    key={
                      Object.keys(productData).length > 0 && productData.skuId
                    }
                    btnVariants={btnVariants}
                  />
                );
              })
            ) : (
              <>
                <div className="shopping-spinner-wrapper text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* end of row */}
    </>
  );
};

export default ProductListing;
