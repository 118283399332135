import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

/* video imports */
import confettiVideo from "../assets/img/video-assets/confetti-video.mp4";
import exclusivelyOnChaching from "../assets/img/video-assets/exclusivelyOnChaching.mp4";

/* comp imports */

/* img imports */
import imgLineMain from "../assets/img/img-line-main.png";
import imgLineMain2 from "../assets/img/img-line-main-2.png";
import mainLogoFooter from "../assets/img/logo/main-logo-site-foot.png";
import fbIcon from "../assets/img/icons/fb-icon.png";
import instaIcon from "../assets/img/icons/insta-icon.png";
import linkedInIcon from "../assets/img/icons/linked-in-icon.png";
import threadsIcon from "../assets/img/icons/threads-icon.png";
import twitterXIcon from "../assets/img/icons/twitter-x-icon.png";
import diamondAppIcon from "../assets/img/icons/diamond-app-icon.png";
import payRewardsVideoPoster from "../assets/img/banner/pay-rewards-video-poster.jpg";

const RewardsHourlyPayMain = () => {
  const navigate = useNavigate();

  const handleRedirect = (path) => {
    if (path) {
      navigate(`/${path}`);
    } else {
      navigate(`/`);
    }
  };

  const videoRefConfetti = useRef(undefined);
  const videoRefExclusivelyOnChaching = useRef(undefined);
  useEffect(() => {
    videoRefConfetti.current.defaultMuted = true;
    videoRefExclusivelyOnChaching.current.defaultMuted = true;
  });

  return (
    <>
      <main className="pay-rewards-main">
        <section className="comm-section pay-rewards-banner-section">
          {/* <div className="headerVideoOverlay"></div> */}
          <div className="lottie-video-wrapper">
            {/* <Lottie options={defaultOptions} className="lottieVideo" /> */}
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefConfetti}
              poster={payRewardsVideoPoster}
            >
              <source src={confettiVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-md-center">
                    <h3 className="section-heading">
                      <span>giving away</span>
                      <span>rewards</span>
                      <span>like</span>
                      <span>confetti</span>
                    </h3>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of main banner section 1 */}

        <section className="comm-section white-color-section speckle-bg extra-section-margin join-the-champions-section">
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper text-center">
                      <h3 className="section-heading pay-rewards-page">
                        join the champions
                      </h3>

                      <div className="section-content pay-rewards-page">
                        win 100% moneyback when you checkout via{" "}
                        <span className="comp-name">ChaChing</span> pay
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 2 */}

        <section className="comm-section white-color-section speckle-bg pay-reward-faq-section">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap d-lg-none mb-5">
                    <div className="section-content-wrapper text-center">
                      <h3 className="section-heading pay-rewards-page mb-3">
                        frequently asked questions
                      </h3>

                      <div className="section-content pay-rewards-page">
                        the rules
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 pay-reward-faq-col">
                    <div className="pay-reward-faq-wrapper">
                      <div className="faq-heading">winners</div>
                      <div className="faq-text">
                        every hour between 9am-9pm,{" "}
                        <span className="comp-name">ChaChing</span> selects a
                        lucky winner who gets 100% moneyback from their
                        transactions.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 pay-reward-faq-col">
                    <div className="pay-reward-faq-wrapper">
                      <div className="faq-heading">shoppers</div>
                      <div className="faq-text">
                        ready to test your luck? come on in, shop for your
                        heart's desires, and check out with{" "}
                        <span className="comp-name">ChaChing</span> for a chance
                        to win big!
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 pay-reward-faq-col">
                    <div className="pay-reward-faq-wrapper">
                      <div className="faq-heading">odds</div>
                      <div className="faq-text">
                        good fortune doesn't pick favorites, but chances do.
                        every time you make a transaction with us, your chances
                        of winning go up. just so it's fair, each member can
                        only win once a day.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 pay-reward-faq-col">
                    <div className="pay-reward-faq-wrapper">
                      <div className="faq-heading">gala</div>
                      <div className="faq-text">
                        if you win, we'll let you know, and we'll put the
                        moneyback right into your account. you'll get a message
                        telling you it's there
                      </div>
                    </div>
                  </div>

                  <div className="col-12 d-none d-lg-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper text-center">
                      <h3 className="section-heading pay-rewards-page mb-3">
                        frequently asked questions
                      </h3>

                      <div className="section-content pay-rewards-page">
                        the rules
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 3 */}

        <section className="comm-section white-color-section speckle-bg pay-rewards-exclusive-section">
          <div className="section-overlay-dark"></div>

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefExclusivelyOnChaching}
              // poster={everyPaymentCountPoster}
            >
              <source src={exclusivelyOnChaching} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <Lottie
              options={defaultOptionsEveryPaymentCount}
              className="lottieVideo"
            /> */}
          </div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center text-center flex-wrap">
                    <div className="section-content-wrapper">
                      <div className="section-content pay-rewards-page">
                        exclusively on
                        <br />
                        <span className="comp-name">ChaChing</span> pay <br />
                        <br />
                        a solemnly designed payment system made only for the
                        elite 1%. <br />
                        it's the latest way to pay and guarantees rewards every
                        time you make a payment..
                      </div>

                      <button
                        className="starry-btn me-3"
                        type="button"
                        onClick={() => {
                          handleRedirect("pay");
                        }}
                      >
                        <span className="btn-text">know more</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 4 */}

        {/* <section className="content-section white-color-section speckle-bg app-soon-section pb-1">
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="app-soon-main-wrapper">
                      <div className="app-soon-wrapper">
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                        <div>app soon</div>
                        <span>||</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* end of section 5 */}
      </main>
      <footer>
        <div className="footer-section speckle-bg">
          <div className="shape right"></div>
          <img src={imgLineMain} alt="" className="img-line" />

          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 join-the-club-col">
                <div className="footer-links-wrapper">
                  <h4 className="footer-title">
                    most generous reward system in multiverse
                  </h4>
                </div>
              </div>

              <div className="col-12 col-lg-4 footer-links-col mt-5 mt-lg-0 offset-lg-2">
                <div className="footer-links-wrapper">
                  <h4 className="footer-title">social</h4>
                  <div className="footer-content">
                    <div className="social-profile">
                      <ul className="social-link">
                        <li>
                          <a
                            href="https://www.facebook.com/profile.php?id=61560915095156"
                            target="_blank"
                          >
                            <img src={fbIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/chaching.club"
                            target="_blank"
                          >
                            <img src={instaIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="https://x.com/chachingclub/" target="_blank">
                            <img src={twitterXIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/chachingclub/"
                            target="_blank"
                          >
                            <img src={linkedInIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://diamondapp.com/u/ChaChing"
                            target="_blank"
                          >
                            <img src={diamondAppIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.threads.net/@chaching.club"
                            target="_blank"
                          >
                            <img src={threadsIcon} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 copyright-col copyright-career-col mt-4 mt-md-5">
              <h6 className="foot-copyright-text order-2 order-sm-1">
                <div className="footer-copyright-links">
                  <a href="/legal" target="_blank" rel="noopener noreferrer">
                    privacy policy
                  </a>{" "}
                  |{" "}
                  <a href="/legal" target="_blank" rel="noopener noreferrer">
                    terms & conditions
                  </a>{" "}
                  |{" "}
                  <a href="/security" target="_blank" rel="noopener noreferrer">
                    security
                  </a>
                </div>
                Copyright 2024{" "}
                <span className="comp-name">PeakPurse Technologies Inc.</span>{" "}
                All rights reserved.
              </h6>
              <div className="footer-logo order-1 order-sm-2">
                <span
                  onClick={() => {
                    handleRedirect("");
                  }}
                >
                  <img src={mainLogoFooter} alt="Logo" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default RewardsHourlyPayMain;
