import React from "react";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../assets/img/img-line-main-2.png";

import female1 from "../assets/img/pride-members/female-1.jpg";
import female2 from "../assets/img/pride-members/female-2.jpg";
import female3 from "../assets/img/pride-members/female-3.jpg";
import male1 from "../assets/img/pride-members/male-1.jpg";
import male2 from "../assets/img/pride-members/male-2.jpg";
import male3 from "../assets/img/pride-members/male-3.jpg";
import blankImg from "../assets/img/banner/blank-img.png";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainCareerNavbar from "../components/careers/MainCareerNavbar";
import FooterCareer from "../components/careers/FooterCareer";

const Pride = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">pride</h3>
                      <h3 className="section-content pay-page">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section extra-section-margin white-color-section speckle-bg career-pride-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="pride-wrapper">
                      <div className="pride-image">
                        <img src={female1} alt="" />
                      </div>
                      <div className="pride-content">
                        <div className="pride-content-wrapper">
                          <div className="pride-text">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                          <div className="pride-name-role">
                            jia kapoor <span>marketing manager</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pride-wrapper">
                      <div className="pride-image order-0 order-md-1">
                        <img src={male1} alt="" />
                      </div>
                      <div className="pride-content">
                        <div className="pride-content-wrapper">
                          <div className="pride-text">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                          <div className="pride-name-role">
                            anurag khona <span>founder/ceo</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pride-wrapper">
                      <div className="pride-image">
                        <img src={female2} alt="" />
                      </div>
                      <div className="pride-content">
                        <div className="pride-content-wrapper">
                          <div className="pride-text">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                          <div className="pride-name-role">
                            some personality <span>pr manager</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pride-wrapper">
                      <div className="pride-image order-0 order-md-1">
                        <img src={male2} alt="" />
                      </div>
                      <div className="pride-content">
                        <div className="pride-content-wrapper">
                          <div className="pride-text">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                          <div className="pride-name-role">
                            mrinal chakraborty <span>fullstack developer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pride-wrapper">
                      <div className="pride-image">
                        <img src={female3} alt="" />
                      </div>
                      <div className="pride-content">
                        <div className="pride-content-wrapper">
                          <div className="pride-text">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                          <div className="pride-name-role">
                            some personality <span>ios developer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pride-wrapper">
                      <div className="pride-image order-0 order-md-1">
                        <img src={male3} alt="" />
                      </div>
                      <div className="pride-content">
                        <div className="pride-content-wrapper">
                          <div className="pride-text">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                          <div className="pride-name-role">
                            some personality <span>backend developer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default Pride;
