import React from "react";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../assets/img/img-line-main-2.png";
import insightBg from "../assets/img/banner/insight-bg.png";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainCareerNavbar from "../components/careers/MainCareerNavbar";
import Footer from "../components/Footer";
import FooterCareer from "../components/careers/FooterCareer";

const Insights = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">insights</h3>
                      <h3 className="section-content pay-page">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section extra-section-margin extra-section-margin-bott white-color-section speckle-bg career-insights-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-7 pt-5 pe-5 pb-5 pb-md-0">
                    <img src={insightBg} alt="" />
                  </div>
                  <div className="col-12 col-md-5 career-insights-col">
                    <div className="section-grid-content">
                      <div className="grid-content-head">99%</div>
                      <div className="grid-content-text">
                        of team members are proud to be associated with{" "}
                        <span className="comp-name">ChaChing</span>.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">97%</div>
                      <div className="grid-content-text">
                        of team members believe that they work with talented
                        people at <span className="comp-name">ChaChing</span>.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">94%</div>
                      <div className="grid-content-text">
                        of team members agree that they are encouraged to be
                        audacious.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">93%</div>
                      <div className="grid-content-text">
                        of team members agree that they have enough autonomy in
                        their roles.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">93%</div>
                      <div className="grid-content-text">
                        of team members believe that their manager cares about
                        them.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">95%</div>
                      <div className="grid-content-text">
                        of team members believe that their opinions are valued
                        at <span className="comp-name">ChaChing</span>.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default Insights;
