import React from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";

/* img imports */
import mainLogoWhite from "../../assets/img/logo/logo-white.svg";
import mainLogoFooter from "../../assets/img/logo/main-logo-site-foot.png";
import imgLineMain from "../../assets/img/img-line-main.png";
import playStoreIcon from "../../assets/img/icons/play-store-icon.png";
import appStoreIcon from "../../assets/img/icons/app-store-icon.png";
import fbIcon from "../../assets/img/icons/fb-icon.png";
import instaIcon from "../../assets/img/icons/insta-icon.png";
import linkedInIcon from "../../assets/img/icons/linked-in-icon.png";
import threadsIcon from "../../assets/img/icons/threads-icon.png";
import twitterXIcon from "../../assets/img/icons/twitter-x-icon.png";
import diamondAppIcon from "../../assets/img/icons/diamond-app-icon.png";
const FooterCareer = () => {
  const navigate = useNavigate();
  const handleHomePageRedirect = () => {
    navigate("/careers");
  };
  return (
    <>
      <footer>
        <div className="footer-section">
          <div className="shape right"></div>
          <img src={imgLineMain} alt="" className="img-line" />

          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4 join-the-club-col">
                <div className="join-head">join the club</div>
                <div className="join-text">
                  join <span className="comp-name">chaching</span> and become
                  part of an elite group with exclusive benefits. level up your
                  team game as a member!
                </div>
              </div>

              <div className="col-12 col-lg-4 footer-links-col mt-5 mt-lg-0 offset-lg-4">
                <div className="footer-links-wrapper">
                  <h4 className="footer-title">social</h4>
                  <div className="footer-content">
                    <div className="social-profile">
                      <ul className="social-link">
                        <li>
                          <a
                            href="https://www.facebook.com/profile.php?id=61560915095156"
                            target="_blank"
                          >
                            <img src={fbIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/chaching.club"
                            target="_blank"
                          >
                            <img src={instaIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="https://x.com/chachingclub/" target="_blank">
                            <img src={twitterXIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/chachingclub/"
                            target="_blank"
                          >
                            <img src={linkedInIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://diamondapp.com/u/ChaChing"
                            target="_blank"
                          >
                            <img src={diamondAppIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.threads.net/@chaching.club"
                            target="_blank"
                          >
                            <img src={threadsIcon} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2 mb-5 mt-lg-5 mb-lg-2">
                <div className="app-coming-soon app-coming-soon-career">
                  <div>
                    <h3 className="main-sub-heading">soon!</h3>

                    <div className="main-icon-wrapper">
                      <span>
                        <img src={appStoreIcon} alt="" />
                      </span>
                      <span>
                        <img src={playStoreIcon} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 copyright-col copyright-career-col">
              <h6 className="text foot-copyright-text order-2 order-sm-1">
                Copyright 2024{" "}
                <span className="comp-name">PeakPurse Technologies Inc.</span>{" "}
                All rights reserved.
              </h6>
              <div className="footer-logo order-1 order-sm-2">
                <span onClick={handleHomePageRedirect}>
                  <img src={mainLogoFooter} alt="Logo" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterCareer;
