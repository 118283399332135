import React from "react";
import Form from "react-bootstrap/Form";
import { HashLink } from "react-router-hash-link";

/* comp imports */

/* img imports */
import cashRegister from "../assets/img/banner/cash-register.gif";
import chachingLogoText from "../assets/img/logo/chaching-logo-text.png";
import chachingAnywhereBgMobile from "../assets/img/banner/chaching-anywhere-bg-mobile.png";
import imgLineMain from "../assets/img/img-line-main.png";
import imgLineMain2 from "../assets/img/img-line-main-2.png";

const SecurityMain = () => {
  return (
    <>
      <main>
        <section className="comm-section white-color-section speckle-bg">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">Security</h3>
                      {/* <div className="section-content pay-page">

                      </div> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        <section className="content-section white-color-section speckle-bg">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <div className="content-head mt-0">
                        best practices to follow
                      </div>
                      <p>
                        We have always placed your security first on{" "}
                        <span className="comp-name">ChaChing</span>. To ensure
                        maximum security, we have made a simple list of security
                        to-do's you can follow:
                      </p>
                      <ul>
                        <li>
                          never divulge your personal bank details like card
                          number, CVV, PIN, and OTP in any medium, including
                          calls, texts, or emails.
                        </li>
                        <li>
                          we will never, ever ask you for any of the sensitive
                          details mentioned above.
                        </li>
                        <li>
                          we will never call you and ask to do any payment
                          transaction on the app or install any remote access
                          software such as teamviewer, anydesk, etc.
                        </li>
                        <li>
                          never respond to such emails, texts, or phone calls.
                        </li>
                        <li>
                          our customer support can only be reached via the app.
                          Please do not engage with phone numbers that claim to
                          be of our support team.
                        </li>
                      </ul>
                      <div className="content-head">responsible disclosure</div>
                      <p>
                        at <span className="comp-name">ChaChing</span>,
                        safeguarding our customers' data and privacy is
                        paramount. We integrate security measures throughout our
                        products using cutting-edge technology to ensure robust
                        protection at every level.
                      </p>

                      <p>
                        our comprehensive data and privacy security framework
                        enables us to defend against a wide range of threats,
                        from common vulnerabilities to sophisticated attacks.
                      </p>

                      <p>
                        if you are a security enthusiast or researcher who has
                        identified a potential security flaw in{" "}
                        <span className="comp-name">ChaChing</span>
                        products, we encourage you to report it to us
                        responsibly.
                      </p>

                      <p>
                        please submit a detailed bug report to
                        security@chaching.club outlining the steps needed to
                        replicate the vulnerability. We are committed to
                        promptly investigating and addressing legitimate issues
                        within a reasonable timeframe.
                      </p>

                      <p>
                        we kindly request that you refrain from publicly
                        disclosing the vulnerability while we work to resolve
                        it. Your cooperation helps us maintain the security and
                        integrity of our systems.
                      </p>

                      <p>
                        thank you for your dedication to security and for
                        assisting us in keeping{" "}
                        <span className="comp-name">ChaChing</span> safe for our
                        customers.
                      </p>
                      <div className="content-head">host security</div>
                      <p>
                        we use industry leading solutions around anti-virus,
                        anti-malware, intrusion prevention systems, intrusion
                        detection systems, file integrity monitoring,
                        application control, application and audit log
                        aggregation, and automated patchingAll our servers are
                        launched using the Center for Internet Security
                        Benchmarks for Amazon Linux.
                      </p>
                      <div className="content-head">cloud infrastructure</div>
                      <p>
                        <span className="comp-name">ChaChing</span> hosted on a
                        Virtual Private Cloud on Amazon Web Services which
                        provides a secure and scalable technology platform to
                        ensure we can provide you services securely and
                        reliably.
                      </p>
                      <div className="content-head">
                        Incident and Change Management
                      </div>

                      <p>
                        We have deployed mature processes around Change
                        Management which enables us to release thoroughly tested
                        features for you both reliably and securely enabling you
                        to enjoy the Cha-ching experience with maximum assurance
                      </p>
                      <p>
                        We have a very aggressive stance on Incident Management
                        on both Systems downtime and Security and have a Network
                        Operations Center and an Information Security Management
                        System in place which quickly reacts, remediates or
                        escalates any Incidents arising out of planned or
                        unplanned changes.
                      </p>
                      <div className="content-head">
                        vulnerability assessment and penetration testing
                      </div>
                      <p>
                        We have an inhouse network security team which uses
                        industry leading products to conduct manual and
                        automated VA/PT activities
                      </p>
                      <p>
                        We employ both static application security testing and
                        dynamic application security testing which is
                        incorporated into our continuous integration /
                        continuous deployment pipeline
                      </p>
                      <div className="content-head">
                        payment & customer data
                      </div>
                      <p>
                        All data sent to or from Cha-ching's infrastructure is
                        encrypted in transit using Transport Layer Security
                        (TLS) version 1.2 or later. All{" "}
                        <span className="comp-name">ChaChing</span> data is
                        encrypted at rest using AES-256 block-level storage
                        encryption and stored in ISO27001 and PCI DSS compliant
                        data centers.{" "}
                        <span className="comp-name">ChaChing</span> is compliant
                        to the General Data Protection Regulation (GDPR),
                        ensuring that all customer and employee personal
                        information is treated with the highest level of
                        security and in a lawful manner. All payment information
                        is processed and stored following the strict Payment
                        Card Industry Data Security Standards (PCI DSS).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
    </>
  );
};

export default SecurityMain;
