import React from "react";

const WalletList = ({
  walletBankLogos,
  setAddWalletIsActive,
  setWalletListIsActive,
  setMainPaymentIsActive,
}) => {
  const switchScreenHandler = () => {
    setAddWalletIsActive(false);
    setWalletListIsActive(false);
    setMainPaymentIsActive(true);
  };

  return (
    <>
      <div className="list-item" onClick={switchScreenHandler}>
        <span className="list-item-img">
          <img src={walletBankLogos.metamask} alt="" />
        </span>
        <span className="list-item-name">metamask</span>
      </div>
      <div className="list-item" onClick={switchScreenHandler}>
        <span className="list-item-img">
          <img src={walletBankLogos.crypterium} alt="" />
        </span>
        <span className="list-item-name">crypterium</span>
      </div>
      <div className="list-item" onClick={switchScreenHandler}>
        <span className="list-item-img">
          <img src={walletBankLogos.coinbase} alt="" />
        </span>
        <span className="list-item-name">coin base</span>
      </div>
      <div className="list-item" onClick={switchScreenHandler}>
        <span className="list-item-img">
          <img src={walletBankLogos.trustwallet} alt="" />
        </span>
        <span className="list-item-name">trustwallet</span>
      </div>
      <div className="list-item" onClick={switchScreenHandler}>
        <span className="list-item-img">
          <img src={walletBankLogos.luno} alt="" />
        </span>
        <span className="list-item-name">luno</span>
      </div>
      <div className="list-item" onClick={switchScreenHandler}>
        <span className="list-item-img">
          <img src={walletBankLogos.zengo} alt="" />
        </span>
        <span className="list-item-name">zengo</span>
      </div>
      <div className="list-item" onClick={switchScreenHandler}>
        <span className="list-item-img">
          <img src={walletBankLogos.bitgo} alt="" />
        </span>
        <span className="list-item-name">bitgo</span>
      </div>
    </>
  );
};

export default WalletList;
