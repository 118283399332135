import React from "react";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";

import HelpMain from "../components/HelpMain";

const Help = () => {
  return (
    <>
      <HelpMain />
    </>
  );
};

export default Help;
