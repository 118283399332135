import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { motion } from "framer-motion";
import InputMask from "react-input-mask";
import { endPoints, baseUrl } from "../data/endPoints";
import axios from "axios";
/* img imports */
import { tiltAnimation } from "./checkoutAnimation";
import closeIconCurve from "../assets/img/close-icon-curve.svg";
import confettiAnimation from "../assets/img/confetti-animation.gif";
import candiesIconSmall from "../assets/img/candies-icon-small.png";

const Waitlist = ({
  waitlistModalshow,
  handleWaitlistClose,
  setShowWaitlistThankyou,
  showWaitlistThankyou,
  setWaitlistErrorMessage,
  waitlistErrorMessage,
}) => {
  const [waitlistCount, setWaitlistCount] = useState(0);
  const [waitlistToken, setWaitlistToken] = useState();
  const [waitlistMainData, setWaitlistMainData] = useState({});

  let configGetTokenCount = {
    method: "get",
    url: `${baseUrl}${endPoints.getWaitlistCount}`,
    headers: {},
  };

  const getTokenCount = async () => {
    try {
      const response = await axios.request(configGetTokenCount);
      if (response.data.status) {
        setWaitlistCount(response.data.count);
        setWaitlistToken(response.data.count + 1120);
      } else {
        console.log("Error: Cannot get count");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTokenCount();
  }, []);

  const waitlistMainDataDefault = {
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    creditScore: "",
    dob: "",
    receiveMessage: "yes",
    tokenNumber: waitlistToken,
    referrerNumber: "",
  };

  useEffect(() => {
    if (waitlistToken) {
      setWaitlistMainData(waitlistMainDataDefault);
    }
  }, [waitlistToken]);

  const [isValidAge, setIsValidAge] = useState(true);

  const validateAge = (selectedDate) => {
    const today = new Date();
    const birthDate = new Date(selectedDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    setIsValidAge(age >= 18);
  };

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (name === "dob") {
      const selectedDate = e.target.value;
      validateAge(selectedDate);
    }

    setWaitlistMainData((waitlistMainData) => ({
      ...waitlistMainData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;
    return regex.test(email);
  };

  const validateMobile = (phoneNumber) => {
    let regex = /^\+1 \d{3}-\d{3}-\d{4}$/;
    return regex.test(phoneNumber);
  };

  const uploadWaitlist = async () => {
    try {
      setWaitlistErrorMessage("");
      let tempError = waitlistErrorMessage;
      if (
        waitlistMainData.firstName &&
        waitlistMainData.lastName &&
        waitlistMainData.mobile &&
        waitlistMainData.creditScore &&
        waitlistMainData.dob
      ) {
        let isMobileValidated = validateMobile(waitlistMainData.mobile);
        if (isMobileValidated) {
          if (waitlistMainData.creditScore != "no") {
            if (isValidAge) {
              const res = await axios.post(
                `${baseUrl}${endPoints.waitlist}`,
                waitlistMainData
              );
              if (res.data.status) {
                console.log(res.data.message);
                setShowWaitlistThankyou(true);
                setWaitlistErrorMessage("");
                setWaitlistMainData(waitlistMainDataDefault);
              }
            } else {
              tempError = "You must be 18 years or older.";
              setWaitlistErrorMessage(tempError);
            }
          } else {
            tempError =
              "only users with a credit score of 750 or higher are eligible!";
            setWaitlistErrorMessage(tempError);
          }
        } else {
          tempError = "Please enter a valid mobile number";
          setWaitlistErrorMessage(tempError);
        }
      } else {
        tempError = "Please provide all required fields";
        setWaitlistErrorMessage(tempError);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleWaitlistSubmit = (event) => {
    event.preventDefault();
    uploadWaitlist();
  };

  return (
    <Modal
      className="waitlist-modal"
      show={waitlistModalshow}
      onHide={handleWaitlistClose}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          {!showWaitlistThankyou ? "Join the waitlist" : ""}
        </Modal.Title>
        <div onClick={handleWaitlistClose} className="waitlist-modal-close">
          <img src={closeIconCurve} alt="" />
        </div>
      </Modal.Header>
      <Modal.Body>
        {showWaitlistThankyou ? (
          <div className="waitList-thankyou">
            <div className="confetti-wrapper">
              <img src={confettiAnimation} alt="" />
            </div>

            <h3>Thank you for joining the waitlist!</h3>
            <p>We will send you a text message when registration opens.</p>
            <p>
              <span>
                At registration, a soft credit score check will determine if you
                qualify as a member.
              </span>
            </p>
            <p>
              For each successful referral, you will receive{" "}
              <span className="num-candy">10</span>{" "}
              <img src={candiesIconSmall} alt="" /> (candies).
            </p>
          </div>
        ) : (
          <form onSubmit={handleWaitlistSubmit}>
            <div className="form-wrapper">
              <div className="form-sub-title">
                potential member no. <span>{waitlistToken}</span>
              </div>
              <div className="form-title">
                get{" "}
                <motion.span
                  variants={tiltAnimation}
                  initial="initial"
                  animate="animate"
                >
                  50,000
                </motion.span>{" "}
                coins upon approval.
              </div>
              <div className="form-title">
                (that's <span className="green-text">$750</span> value)
              </div>
              <div className="form-group-wrapper d-flex">
                <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    name="firstName"
                    type="text"
                    placeholder="first name*"
                    required
                    onChange={inputsHandler}
                  />
                </Form.Group>
                <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    name="lastName"
                    type="text"
                    placeholder="last name*"
                    required
                    onChange={inputsHandler}
                  />
                </Form.Group>
                {/* <Form.Group className="mb-3 main-form-group">
              <Form.Control
                type="text"
                placeholder="mobile number*"
                maxLength="10"
                required
              />
            </Form.Group> */}
                <Form.Group className="mb-3 main-form-group">
                  <InputMask
                    mask="+1 999-999-9999"
                    maskChar=" "
                    id="phone-input"
                    placeholder="mobile*"
                    onChange={inputsHandler}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        type="text"
                        name="mobile"
                        className="form-control"
                        required
                      />
                    )}
                  </InputMask>
                </Form.Group>
                <Form.Group className="mb-3 main-form-group">
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="email"
                    onChange={inputsHandler}
                    maxLength="100"
                  />
                </Form.Group>
                <Form.Select
                  className="mb-3 main-form-select"
                  name="creditScore"
                  required
                  onChange={inputsHandler}
                >
                  <option value="">credit score minimum 750?*</option>
                  <option value="yes">yes</option>
                  <option value="no">no</option>
                  <option value="maybe">maybe</option>
                </Form.Select>

                <Form.Group className="mb-3 main-form-group w-100 date-of-birth">
                  <Form.Control
                    type="date"
                    name="dob"
                    placeholder="your birthday*"
                    required
                    onChange={inputsHandler}
                    className={`${waitlistMainData.dob ? "not-empty" : ""}`}
                  />
                  <div className="w-100 mt-2 error-text d-block">
                    {!isValidAge && "You must be 18 years or older."}
                  </div>
                  {/* <span className="input-info">should be 18 years of age</span> */}
                </Form.Group>
                {/* <Form.Select className="mb-3 main-form-select">
              <option>are you a U.S.A citizen</option>
              <option value="yes">yes</option>
              <option value="no">no</option>
            </Form.Select> */}
              </div>
              <Form.Group className="mb-3 main-form-group">
                <Form.Control
                  type="text"
                  name="referrerNumber"
                  placeholder="referrer's mobile number"
                  maxLength="10"
                  onChange={inputsHandler}
                />
              </Form.Group>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="yes"
                  id="waitlistCheckbox"
                  name="receiveMessage"
                  defaultChecked
                />
                <label className="form-check-label" htmlFor="waitlistCheckbox">
                  Agree to receive text messages for registration once live.
                </label>
              </div>
              <div className="w-100 my-2 error-text d-block">
                {waitlistErrorMessage}
              </div>
              <div>
                <button className="starry-btn mt-3" type="submit">
                  <span className="btn-text">submit</span>
                  <div id="container-stars">
                    <div id="stars"></div>
                  </div>

                  <div id="glow">
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                </button>
              </div>
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Waitlist;
