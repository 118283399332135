import React from "react";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";
import SecurityMain from "../components/SecurityMain";

const Security = () => {
  return (
    <>
      <SecurityMain />
    </>
  );
};

export default Security;
