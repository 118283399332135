import React from "react";
import { useNavigate } from "react-router-dom";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";

const Contact = () => {
  const navigate = useNavigate();

  const handleRedirect = (path) => {
    if (path) {
      navigate(`/${path}`);
    }
  };
  return (
    <>
      <main>
        <section className="comm-section white-color-section contact-main-section">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap text-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">contact</h3>

                      <div className="row contact-cards-wrapper">
                        <div className="col-12 col-lg-4 contact-card-col">
                          <div className="contact-card">
                            <div>
                              <div className="contact-heading">email</div>
                              <div className="contact-text">
                                <a href="mailto:support@chaching.club">
                                  support@ChaChing.club
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 contact-card-col">
                          <div className="contact-card">
                            <div>
                              <div className="contact-heading">
                                become a partner
                              </div>
                              <div className="contact-text">
                                Teaming up with{" "}
                                <span className="comp-name">ChaChing</span>{" "}
                                offers businesses a nationwide sales
                                opportunity.
                              </div>
                            </div>
                            <button
                              className="starry-btn me-3"
                              type="button"
                              onClick={() => {
                                handleRedirect("contact-sales");
                              }}
                            >
                              <span className="btn-text">contact sales</span>
                              <div id="container-stars">
                                <div id="stars"></div>
                              </div>

                              <div id="glow">
                                <div className="circle"></div>
                                <div className="circle"></div>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 contact-card-col">
                          <div className="contact-card">
                            <div>
                              <div className="contact-heading">
                                get assitance
                              </div>
                              <div className="contact-text">
                                we are happy to help!
                              </div>
                            </div>
                            <button className="starry-btn me-3" type="button">
                              <span className="btn-text">help centre</span>
                              <div id="container-stars">
                                <div id="stars"></div>
                              </div>

                              <div id="glow">
                                <div className="circle"></div>
                                <div className="circle"></div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        <section className="content-section white-color-section">
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <div className="content-head">help centre</div>
                      <p>
                        <span className="comp-name">ChaChing</span> doesn't
                        offer phone support, but our experts are ready to help
                        with any issues. Just follow these steps to get in
                        touch.
                      </p>
                      <p>
                        Stay safe from scams. Always keep your bank details,
                        like account number, CVV, OTP, and other sensitive info
                        private, even if someone says they're from{" "}
                        <span className="comp-name">ChaChing</span>.
                      </p>

                      <p>
                        Need help? <span className="comp-name">ChaChing</span>{" "}
                        is a members-only app for managing and paying credit
                        card bills, rewarding you for on-time payments. With us,
                        you can handle all your cards in one place, pay bills,
                        check statements, set up autopay, and more. If you
                        encounter any issues, our dedicated support team is here
                        to assist you. While we don't offer phone support, you
                        can easily contact us through in-app chat or email,
                        24/7. Reach out for any help with your credit card,
                        payments, rewards, or just to give feedback. Connect
                        with us via email, DMs on Facebook, LinkedIn, Instagram,
                        Twitter, or in-app chat.
                      </p>

                      <div className="content-head">
                        How do I stay safe from fraud?
                      </div>
                      <p>
                        Protect your personal information at all times. Never
                        share your bank account number, CVV, OTP, or other
                        sensitive details with anyone, even if they claim to be
                        from <span className="comp-name">ChaChing</span>.
                      </p>
                      <div className="content-head">
                        How can I get in touch with{" "}
                        <span className="comp-name">ChaChing</span> customer
                        support?
                      </div>
                      <p>
                        <span className="comp-name">ChaChing</span> offers a
                        comprehensive credit card management and bill payment
                        service, rewarding timely payments. Manage all your
                        credit cards under one platform - from bill payments to
                        viewing statements and setting up autopay. Our customer
                        support team is on hand to assist with any issues you
                        may face, available 24/7 through in-app chat or email.
                        While we don't provide phone support, reaching us is
                        easy and efficient via email, direct messages on
                        Facebook, LinkedIn, Instagram, Twitter, or our in-app
                        chat. Whether it's inquiries about credit cards,
                        payments, rewards, or feedback, we're here to help.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
    </>
  );
};

export default Contact;
