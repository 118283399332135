import React, { useState, useEffect, useContext } from "react";
import { storeBaseUrl, storeEndpoints } from "../../data/endPoints";
import axios from "axios";
import { CartContext } from "../../context/CartContextProvider";

/* img imports */
import dollarIcon from "../../assets/img/currency-icons/dollarIcon.svg";
const CartProductItem = ({ price, desoRate, qty, productId }) => {
  const { incDecQty, deleteCartItem } = useContext(CartContext);
  const [productDetailsData, setProductDetailsData] = useState({});
  const [imageUrlBase, setImageUrlBase] = useState(
    "https://www.everythingstore.io/static/www/images/productImages/370x370/"
  );

  let getProductDetailsDataConfig = {
    method: "post",
    url: `${storeBaseUrl}/${storeEndpoints.getProductPageData}`,
    headers: {
      source: "MOBILE_SITE",
      // token: "d54374a9643892a036e2937f1ccebbf2877dad0c",
      // userId: 1,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: { prodId: productId },
  };

  const getProductDetailsData = async () => {
    try {
      const response = await axios.request(getProductDetailsDataConfig);

      if (response.data.status == "SUCCESS") {
        let tempProductDetailsData = response.data.response.productInputBean;
        if (Object.keys(tempProductDetailsData).length) {
          setProductDetailsData(tempProductDetailsData);
        }
        //
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (productId) {
      getProductDetailsData();
    }
  }, [productId]);

  return (
    <>
      <div className="cart-product-item">
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-wrap flex-md-nowrap">
              <div className="flex-shrink-0 mx-auto mx-md-0">
                <picture>
                  <img
                    src={
                      Object.keys(productDetailsData).length &&
                      productDetailsData.stagSkuImagesBeans.length &&
                      `${imageUrlBase}${productDetailsData.stagSkuImagesBeans[0].imageName}`
                    }
                    alt="DIGITNOW Record Player, Turntable Suitcase with Multi-Function Bluetooth/FM Radio/USB and SD Card Port/Vinyl to MP3 Converter"
                  />
                </picture>
              </div>
              <div className="flex-grow-1 ms-3 w-100 w-md-auto">
                <span className="product-title">
                  {productDetailsData.productName &&
                    productDetailsData.productName}
                </span>

                <div className="product-price">
                  {desoRate && (
                    <>
                      <img src={dollarIcon} alt="" />
                      <span className="prod-price">
                        {(desoRate * price).toFixed(2)}
                      </span>
                    </>
                  )}
                </div>

                <div className="quantity-wrapper cart-icons-block">
                  <button
                    type="button"
                    disabled={qty <= 1 ? true : false}
                    onClick={(e) => {
                      incDecQty(e, productId, "dec");
                    }}
                  >
                    <span className={qty <= 1 ? "disabledBtn" : ""}>
                      <span className="material-symbols-outlined">remove</span>
                    </span>
                  </button>
                  <input type="text" value={qty} maxLength="2" readOnly />
                  <button
                    type="button"
                    disabled={qty >= 10 ? true : false}
                    onClick={(e) => {
                      incDecQty(e, productId, "inc");
                    }}
                  >
                    <span className={qty >= 10 ? "disabledBtn" : ""}>
                      <span className="material-symbols-outlined">add</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="cart-icons-block cart-delete-icon">
            <button
              type="button"
              onClick={(e) => {
                deleteCartItem(e, productId);
              }}
            >
              <span>
                <span className="material-symbols-outlined">delete</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      {/* end of item */}
    </>
  );
};

export default CartProductItem;
