import ambire from "../assets/img/wallet-icons/ambire.jpg";
import argent from "../assets/img/wallet-icons/argent.jpg";
import binance from "../assets/img/wallet-icons/binance.jpg";
import bitgo from "../assets/img/wallet-icons/bitgo.jpg";
import coinbase from "../assets/img/wallet-icons/coinbase.png";
import coinomi from "../assets/img/wallet-icons/coinomi.jpg";
import crypterium from "../assets/img/wallet-icons/crypterium.jpg";
import electrum from "../assets/img/wallet-icons/electrum.jpg";
import ledgerdomain from "../assets/img/wallet-icons/ledgerdomain.jpg";
import luno from "../assets/img/wallet-icons/luno.jpg";
import metamask from "../assets/img/wallet-icons/metamask.jpg";
import mycelium from "../assets/img/wallet-icons/mycelium.jpg";
import nexo from "../assets/img/wallet-icons/nexo.jpg";
import shapeshift from "../assets/img/wallet-icons/shapeshift.png";
import simplehold from "../assets/img/wallet-icons/simplehold.jpg";
import trustwallet from "../assets/img/wallet-icons/trustwallet.jpg";
import zengo from "../assets/img/wallet-icons/zengo.jpg";

// import boa from "../assets/img/bank-logos/boa.png";
// import chase from "../assets/img/bank-logos/chase.png";
// import citi from "../assets/img/bank-logos/citi.png";
// import deutsche from "../assets/img/bank-logos/deutsche.png";
// import ing from "../assets/img/bank-logos/ing.png";
// import raiffeisen from "../assets/img/bank-logos/raiffeisen.png";
// import societeGeneral from "../assets/img/bank-logos/societe-general.png";
// import tdbank from "../assets/img/bank-logos/tdbank.png";
// import ubs from "../assets/img/bank-logos/ubs.png";
// import bbva from "../assets/img/bank-logos/bbva.svg";
import boa from "../assets/img/bank-logos/boa.svg";
import boaDark from "../assets/img/bank-logos/boa-dark.svg";
// import chase from "../assets/img/bank-logos/chase.svg";
// import citi from "../assets/img/bank-logos/citi.svg";
// import tdBank from "../assets/img/bank-logos/td-bank.svg";
import truist from "../assets/img/bank-logos/truist.svg";
import usBank from "../assets/img/bank-logos/us-bank.svg";
import fargo from "../assets/img/bank-logos/wells-fargo.svg";

export const walletBankLogos = {
  ambire: ambire,
  argent: argent,
  binance: binance,
  bitgo: bitgo,
  coinbase: coinbase,
  coinomi: coinomi,
  crypterium: crypterium,
  electrum: electrum,
  ledgerdomain: ledgerdomain,
  luno: luno,
  metamask: metamask,
  mycelium: mycelium,
  nexo: nexo,
  shapeshift: shapeshift,
  simplehold: simplehold,
  trustwallet: trustwallet,
  zengo: zengo,
  // bbva: bbva,
  boa: boa,
  boaDark: boaDark,
  // chase: chase,
  // citi: citi,
  // tdBank: tdBank,
  truist: truist,
  usBank: usBank,
  fargo: fargo,
};
