// export let baseUrl = "http://localhost:3001/api/";
export let baseUrl = "https://server.chaching.club/api/";

export let quizBaseUrl = "https://opentdb.com/api.php";
export let storeBaseUrl = "https://eqomos.everythingstore.io/api";

export const endPoints = {
  /* waitlist */
  waitlist: "waitlist",
  getWaitlistCount: "waitlist-count",

  /* users */
  checkUser: "check-user",
  register: "register",
  login: "login",
  logout: "logout",
  getProfile: "get-profile",
  updateProfile: "update-profile",
  addTransaction: "add-transaction",
  getTransactionReport: "get-transaction-report",
  getAllRewards: "get-all-rewards",
  updateAllRewards: "update-all-rewards",
  getNotificationSettings: "get-notification-settings",
  updateNotificationSetting: "update-notification-setting",

  /* rewards */
  getRewards: "get-rewards",
  updateRewards: "update-rewards",
  addLifetimeReward: "add-lifetime-reward",
  getQuizData: "get-quiz-data",
  getAllVouchers: "get-vouchers",

  /* forms */
  newsletter: "newsletter",
  concierge: "concierge",
  careerApplication: "career-application",
  contactSales: "contact-sales",
  refundForm: "refund-form",

  /* referrals */
  addReferral: "add-referral",
  getReferrals: "get-referrals",
  updateReferral: "update-referral",

  getQuestion: "?amount=10&type=multiple",
};

export const storeEndpoints = {
  getCurrentExchangeRatePrice: "getCurrentExchangeRatePrice",
  getAllCategories: "getAllCategories",
  getSearchPageData: "getSearchPageData",
  getProductByQuery: "getProductByQuery",
  autoSearchProdAndCategory: "autoSearchProdAndCategory",
  getProductPageData: "getProductPageData",
};
