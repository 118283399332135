import React from "react";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../assets/img/img-line-main-2.png";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainCareerNavbar from "../components/careers/MainCareerNavbar";
import Footer from "../components/Footer";
import FooterCareer from "../components/careers/FooterCareer";

const Discover = () => {
  const navigate = useNavigate();

  const handleCareerRedirect = (role) => {
    navigate(`/careers/discover/${role}`);
  };
  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">discover</h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 career-cards-col">
                    <div
                      className="card-container"
                      onClick={() => {
                        handleCareerRedirect("ui-ux-designer");
                      }}
                    >
                      <div className="career-card">
                        <span className="title">ui/ux designer</span>
                        <div>
                          <span className="careerBottom">
                            <span>full-time</span> <span>location</span>
                          </span>
                          <span className="apply-btn">apply</span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-container"
                      onClick={() => {
                        handleCareerRedirect("ios-developer");
                      }}
                    >
                      <div className="career-card">
                        <span className="title">ios developer</span>
                        <div>
                          <span className="careerBottom">
                            <span>full-time</span> <span>location</span>
                          </span>
                          <span className="apply-btn">apply</span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-container"
                      onClick={() => {
                        handleCareerRedirect("backend-developer");
                      }}
                    >
                      <div className="career-card">
                        <span className="title">backend developer</span>
                        <div>
                          <span className="careerBottom">
                            <span>full-time</span> <span>location</span>
                          </span>
                          <span className="apply-btn">apply</span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-container"
                      onClick={() => {
                        handleCareerRedirect("fullstack-developer");
                      }}
                    >
                      <div className="career-card">
                        <span className="title">fullstack developer</span>
                        <div>
                          <span className="careerBottom">
                            <span>full-time</span> <span>location</span>
                          </span>
                          <span className="apply-btn">apply</span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-container"
                      onClick={() => {
                        handleCareerRedirect("public-relationship-manager");
                      }}
                    >
                      <div className="career-card">
                        <span className="title">
                          public relationship manager
                        </span>
                        <div>
                          <span className="careerBottom">
                            <span>full-time</span> <span>location</span>
                          </span>
                          <span className="apply-btn">apply</span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-container"
                      onClick={() => {
                        handleCareerRedirect(
                          "performance-marketing-specialist"
                        );
                      }}
                    >
                      <div className="career-card">
                        <span className="title">
                          performance marketing specialist
                        </span>
                        <div>
                          <span className="careerBottom">
                            <span>full-time</span> <span>location</span>
                          </span>
                          <span className="apply-btn">apply</span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-container"
                      onClick={() => {
                        handleCareerRedirect("strategic-business-development");
                      }}
                    >
                      <div className="career-card">
                        <span className="title">
                          strategic business development
                        </span>
                        <div>
                          <span className="careerBottom">
                            <span>full-time</span> <span>location</span>
                          </span>
                          <span className="apply-btn">apply</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default Discover;
