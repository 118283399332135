import React from "react";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../assets/img/img-line-main-2.png";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainCareerNavbar from "../components/careers/MainCareerNavbar";
import Footer from "../components/Footer";
import FooterCareer from "../components/careers/FooterCareer";

const Perks = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">perks</h3>
                      <h3 className="section-content pay-page">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section extra-section-margin white-color-section speckle-bg career-perks-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 career-perks-col">
                    <div className="section-grid-content">
                      <div className="grid-content-head">esop allocation</div>
                      <div className="grid-content-text">
                        earn your share in what you build.{" "}
                        <span className="comp-name">ChaChing</span> team members
                        are granted ESOPs, rewarding their contributions to one
                        of the nation's rapidly growing startups.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        {" "}
                        <div className="grid-content-head">
                          Accelerated Wealth Program
                        </div>
                      </div>
                      <div className="grid-content-text">
                        Boost your financial growth. Opt for up to 50% of your
                        annual salary in ESOPs, fully vested in just one year,
                        to accelerate your financial progress.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        ESOP Buyback Program
                      </div>
                      <div className="grid-content-text">
                        Liquidate your ESOPs during special events. Your vested
                        options will be eligible for sale, providing liquidity
                        opportunities at intervals.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Advance Salary</div>
                      <div className="grid-content-text">
                        Get paid at the month's start, because that's what you
                        deserve. <span className="comp-name">ChaChing</span>{" "}
                        practices early salary credit, honoring our team's hard
                        work.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Flexible Benefits</div>
                      <div className="grid-content-text">
                        Maximize your earnings. Save on taxes with benefits like
                        internet and phone reimbursements and professional
                        development included in your total compensation.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Own Your Laptop</div>
                      <div className="grid-content-text">
                        Our members are our greatest asset. Departing members
                        keep their laptops as a memento of the time spent with
                        us.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Medical Insurance</div>
                      <div className="grid-content-text">
                        Your and your family's health is our priority. Our
                        BeniFlex program offers base coverage and customizable
                        plans with additional options.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        Personal Accident Insurance
                      </div>
                      <div className="grid-content-text">
                        Comprehensive protection with a $50,000 cover under this
                        policy, because we've got your back.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Mental Wellness</div>
                      <div className="grid-content-text">
                        Support for your mental health through workshops,
                        individual support, and more, in collaboration with Kaha
                        Mind.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        Comprehensive Term Life Insurance
                      </div>
                      <div className="grid-content-text">
                        All team members are insured with a life insurance
                        policy worth three times their annual salary, provided
                        by Go Digit.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        Immediate Family Allowance
                      </div>
                      <div className="grid-content-text">
                        In the tragic loss of a spouse, child, or parent, the
                        team member receives a $3,500 allowance (taxable
                        according to law).
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        Term Life Allowance
                      </div>
                      <div className="grid-content-text">
                        In the event of a team member's death, we provide a
                        12-month salary allowance, a one-time $7,000 grant, and
                        accelerated ESOP vesting.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        Emergency ESOP Liquidation
                      </div>
                      <div className="grid-content-text">
                        Liquidate vested ESOPs during medical emergencies for
                        you or your loved ones, ensuring financial support when
                        it matters most.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        AMA with Industry Experts
                      </div>
                      <div className="grid-content-text">
                        Gain insights from global industry leaders in sessions
                        led by Anurag Khona, covering topics from company
                        culture to data analytics.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Open Leave Policy</div>
                      <div className="grid-content-text">
                        Enjoy the freedom to manage your work and time off on
                        your terms, emphasizing work-life balance.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Scrumptious Food</div>
                      <div className="grid-content-text">
                        Enjoy diverse lunches at the{" "}
                        <span className="comp-name">ChaChing</span> office, from
                        Indian to Chinese, along with a fully stocked pantry for
                        those random cravings.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Flexi Work Hours</div>
                      <div className="grid-content-text">
                        Work on your schedule, focusing on results. Independent
                        work hours are also scheduled to foster productivity.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        Relocation Assistance
                      </div>
                      <div className="grid-content-text">
                        <span className="comp-name">ChaChing</span> supports
                        significant relocation expenses, making transitions
                        smoother for team members moving cities.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default Perks;
