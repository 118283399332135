import React from "react";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../assets/img/img-line-main-2.png";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainCareerNavbar from "../components/careers/MainCareerNavbar";
import Footer from "../components/Footer";
import FooterCareer from "../components/careers/FooterCareer";

const Principles = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        Our principles guide our path.
                      </h3>
                      <h3 className="section-content pay-page">
                        Our principles are at the heart of everything we do.
                        Understand them, and you'll understand our essence.
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section extra-section-margin white-color-section speckle-bg career-perks-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 career-perks-col">
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        Customer Obsession
                      </div>
                      <div className="grid-content-text">
                        Our dedication to our customers is profound and
                        all-encompassing. We intensely empathize with, think
                        about, and act upon their needs, tirelessly striving to
                        earn their trust at every turn.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Ownership Mindset</div>
                      <div className="grid-content-text">
                        As co-owners, we proactively address challenges to
                        safeguard our mission's longevity, choosing long-term
                        gains over short-term wins.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Boldness</div>
                      <div className="grid-content-text">
                        We're committed to making daring, unprecedented moves,
                        embracing the courage to act where there's no
                        established path.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Elegant Solutions</div>
                      <div className="grid-content-text">
                        We aim for excellence, crafting solutions with precision
                        and creativity, never settling for anything less than
                        the best.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Continuous Growth</div>
                      <div className="grid-content-text">
                        We're dedicated to self-improvement, embracing
                        reflection and perseverance to evolve constantly.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Seeking Truth</div>
                      <div className="grid-content-text">
                        Our culture of curiosity promotes transparency and open
                        dialogue, encouraging questioning to challenge and
                        refine our understanding.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Outcome-Driven</div>
                      <div className="grid-content-text">
                        We focus on key factors to achieve significant results,
                        fulfilling commitments and holding each other
                        accountable.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Building Trust</div>
                      <div className="grid-content-text">
                        Integrity, transparency, competence, and kindness are
                        how we build trust within and beyond our team.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        Informed Decision-Making
                      </div>
                      <div className="grid-content-text">
                        Our leaders make consistently sound decisions, relying
                        on strong instincts and judgment.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">Radical Candor</div>
                      <div className="grid-content-text">
                        We engage in honest, direct conversations, caring
                        personally while challenging directly, fostering mutual
                        support and growth.
                      </div>
                    </div>
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        <span className="comp-name">ChaChing</span> Unity
                      </div>
                      <div className="grid-content-text">
                        Putting <span className="comp-name">ChaChing</span>{" "}
                        first, we align our efforts towards the company's goals,
                        thinking and acting as one team, prioritizing the
                        collective success over individual achievements.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default Principles;
