import React, { useEffect, useContext } from "react";
import { gsap, Sine } from "gsap";
import { CartContext } from "../../context/CartContextProvider";

/* img imports */
import shoppingBag from "../../assets/img/icons/shopping-bag.png";

const ShoppingHeader = ({
  pageHeadDetails,
  handleMenuShow,
  setShowSearch,
  setShowSort,
  handleBrandShow,
  changeSearchTermHandler,
  searchCatData,
  searchProdData,
  searchTerm,
  displayProductListHandler,
  handleProductRedirect,
  showSearch,
  setSearchCatData,
  setSearchProdData,
  showCartHandler,
}) => {
  const { dispCartNumber } = useContext(CartContext);

  useEffect(() => {
    if (showSearch) {
      gsap.fromTo(
        "#searchBox",
        {
          top: "64px",
          opacity: 0,
          display: "none",
        },
        {
          duration: 0.5,
          top: "54px",
          display: "block",
          opacity: 1,
          ease: Sine.easeOut,
        }
      );
    } else {
      gsap.set("#searchBox", {
        top: "64px",
        opacity: 0,
        display: "none",
        ease: Sine.easeOut,
      });
      setSearchCatData([]);
      setSearchProdData([]);
      document.getElementById("searchInput").value = "";
    }
  }, [showSearch]);
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-8 shopping-menu-col d-flex">
          <span
            className={pageHeadDetails.rootId === 1 ? "active" : ""}
            onClick={() => {
              handleMenuShow("electronics");
            }}
          >
            Electronics
          </span>
          <span
            className={pageHeadDetails.rootId === 58 ? "active" : ""}
            onClick={() => {
              handleMenuShow("toysGames");
            }}
          >
            Toys & Games
          </span>
        </div>
        <div className="col-12 col-md-4 shopping-menu-col justify-content-end">
          <span
            // type="button"
            className="search-icon"
            onClick={() => {
              setShowSearch((prev) => !prev);
              setShowSort(false);
            }}
          >
            <span className="material-symbols-outlined">search</span>
          </span>
          <span onClick={handleBrandShow}>Brands</span>
          <span
            className="cart-btn me-0"
            data-num={dispCartNumber}
            onClick={showCartHandler}
          >
            <img src={shoppingBag} alt="" />
          </span>
          <div className="search-block" id="searchBox">
            <input
              type="text"
              id="searchInput"
              className="form-control"
              maxLength="20"
              placeholder="search for products"
              onChange={changeSearchTermHandler}
              autoComplete="false"
            />
          </div>
          {searchCatData.length || searchProdData.length ? (
            <div className="search-result-block">
              <div className="search-head">Search {searchTerm}</div>
              <div className="search-list">
                {searchCatData.length ? (
                  searchCatData.map((catVal, ind) => {
                    return (
                      <span
                        data-rootcatid={catVal.rootId}
                        data-parentcatid={catVal.parentId}
                        data-catid={catVal.id}
                        data-under={
                          catVal.rootId != 0 ? catVal.rootId : catVal.parentId
                        }
                        onClick={displayProductListHandler}
                        key={ind}
                      >
                        in {catVal.displayName}
                      </span>
                    );
                  })
                ) : (
                  <span>No categories to show</span>
                )}
              </div>
              <div className="search-head">best matching products</div>
              <div className="search-list mb-0">
                {searchProdData.length ? (
                  searchProdData.map((prodVal, ind) => {
                    return (
                      <span
                        key={ind}
                        onClick={() => {
                          handleProductRedirect(prodVal.key);
                        }}
                      >
                        {prodVal.value}
                      </span>
                    );
                  })
                ) : (
                  <span>No products to show</span>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* end of row */}
    </>
  );
};

export default ShoppingHeader;
