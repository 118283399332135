import { storeBaseUrl, storeEndpoints } from "./endPoints";
import axios from "axios";

const monthsName = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};
const weekName = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

export const today = new Date();

export const getDeliveryDate = (todayDate) => {
  var result;
  function addDays(date, days) {
    result = new Date(date);
    result.setDate(result.getDate() + days);
    todayDate = result;
  }
  addDays(todayDate, 7);

  // let year = todayDate.getFullYear();
  let month = todayDate.getMonth();
  let week = todayDate.getDay();
  let day = todayDate.getDate();

  let formatedDate = `${weekName[week]}, ${monthsName[month]} ${day}`;

  return formatedDate;
};

let getCurrentDesoRateConfig = {
  method: "post",
  url: `${storeBaseUrl}/${storeEndpoints.getCurrentExchangeRatePrice}`,
};

export const getCurrentDesoRate = async () => {
  let currentDesoRate;
  try {
    const response = await axios.request(getCurrentDesoRateConfig);
    if (response.data["message"] == "Success") {
      currentDesoRate = response.data.response[0].value;
    }
  } catch (error) {
    console.log(error);
  }
  return `${currentDesoRate}`;
};

/* end of getting current deso rate */
