import React from "react";
import pageNotFound from "../assets/img/404-page-not-found.png";
const NotFound = () => {
  return (
    <>
      <section className="page-not-found-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3">
              <img src={pageNotFound} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
