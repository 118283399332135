import React from "react";
import Table from "react-bootstrap/Table";

const TransactionReport = () => {
  return (
    <>
      <main>
        <section className="content-section white-color-section speckle-bg extra-section-margin">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <h2>Transaction Report</h2>
              </div>
              <div className="col-12 mt-5 text-end">export csv</div>
              <div className="col-12 mt-3">
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Token</th>
                      <th>Token Amount</th>
                      <th>Currency</th>
                      <th>Total Sale Amount</th>
                      <th>Payment Amount</th>
                      <th>Fee Amount</th>
                      <th>Account Name</th>
                      <th>Institution Payment</th>
                      <th>Network Hash</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>3 may 2024</td>
                      <td>meta mask</td>
                      <td>0.37</td>
                      <td>etherium</td>
                      <td>$357</td>
                      <td>$357</td>
                      <td>$3.57</td>
                      <td>Anurag Khona</td>
                      <td>Institution Payment</td>
                      <td>nsdjvh7y87y8qj</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>5 may 2024</td>
                      <td>coin base</td>
                      <td>0.37</td>
                      <td>solana</td>
                      <td>$357</td>
                      <td>$357</td>
                      <td>$3.57</td>
                      <td>Anurag Khona</td>
                      <td>Institution Payment</td>
                      <td>nsdjvh7y87y8qj</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>7 may 2024</td>
                      <td>bitcoin</td>
                      <td>0.37</td>
                      <td>bitcoin</td>
                      <td>$357</td>
                      <td>$357</td>
                      <td>$3.57</td>
                      <td>Anurag Khona</td>
                      <td>Institution Payment</td>
                      <td>nsdjvh7y87y8qj</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default TransactionReport;
