import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Account = () => {
  return (
    <>
      <main>
        <section className="content-section white-color-section speckle-bg extra-section-margin">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <h2>Account</h2>
              </div>
              <div className="col-12">
                <div className="logged-in-tabs mt-4">
                  <Tabs
                    defaultActiveKey="bankAccount"
                    id="userAccountTabs"
                    className="mt-2"
                  >
                    <Tab eventKey="bankAccount" title="Bank Account">
                      Add/Remove Bank Account
                    </Tab>

                    <Tab eventKey="debitCard" title="Debit Card">
                      Add/Remove Debit Card
                    </Tab>

                    <Tab eventKey="cryptoWallet" title="Crypto Wallet">
                      Add/Remove crypto wallet
                    </Tab>

                    <Tab eventKey="bills" title="Bills">
                      Add/Remove Bills
                    </Tab>
                  </Tabs>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default Account;
