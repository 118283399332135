import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  drawCheck,
  drawCheckFill,
  hideTickSvg,
  confettiAnimationFadeOut,
  confettiAnimationShow,
  showWinningText,
  fadeOut,
  iconFlyTop,
} from "../../data/custom";

/* img imports */
import confettiAnimation from "../../assets/img/confetti-animation.gif";
import coinIconSmall from "../../assets/img/coin-icon-small.png";
import currencyIconSmall from "../../assets/img/currency-icon-small.png";
import voucherIconSmall from "../../assets/img/voucher-icon-small.png";

const PaymentThankyouCard = ({ onIconFlyComplete }) => {
  return (
    <>
      <div className="payment-completed-img">
        <motion.svg
          // width="190"
          // height="190"
          viewBox="0 0 190 190"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          initial="initial"
          animate="animate"
          variants={hideTickSvg}
        >
          <motion.rect
            x="2.5"
            y="2.5"
            width="185"
            height="185"
            rx="92.5"
            stroke="#1DAB87"
            strokeWidth="5"
            strokeLinejoin="round"
            variants={drawCheck}
            initial="initial"
            animate="animate"
          />
          <motion.circle
            cx="95"
            cy="95"
            r="90"
            fill="#1DAB87"
            variants={drawCheckFill}
            initial="initial"
            animate="animate"
            // onAnimationComplete="onAnimationComplete"
          />
          <motion.path
            d="M48 102.5L74.4999 131L142 57"
            stroke="white"
            strokeWidth="20"
            strokeLinecap="round"
            variants={drawCheck}
            initial="initial"
            animate="animate"
          />
        </motion.svg>
      </div>
      <motion.div
        className="confetti-wrapper"
        variants={confettiAnimationFadeOut}
        initial="initial"
        animate="animate"
      >
        <motion.img
          src={confettiAnimation}
          alt=""
          variants={confettiAnimationShow}
          initial="initial"
          animate="animate"
        />
      </motion.div>
      <motion.div
        className="gift-received-wrapper"
        variants={fadeOut}
        initial="initial"
        animate="animate"
      >
        <motion.span className="gift-main-head" variants={showWinningText}>
          you won
        </motion.span>
        <motion.span className="gift-won" variants={showWinningText}>
          <motion.img
            src={currencyIconSmall}
            // src={coinIconSmall}
            // src={voucherIconSmall}
            alt=""
            variants={iconFlyTop}
            // variants={iconFlyTop}
            // className="d-none"
            onAnimationComplete={() => {
              onIconFlyComplete();
            }}
          />
          <strong>$20</strong>
          {/* <motion.span
                      className="voucher-brand"
                      variants={iconFlyRight}
                      initial="initial"
                      animate="animate"
                    >
                      <img src={giftVoucherDemo} alt="" />
                    </motion.span> */}
        </motion.span>
      </motion.div>
    </>
  );
};

export default PaymentThankyouCard;
