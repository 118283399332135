import React, { useEffect, useState } from "react";

const Pagination = ({
  pageNum,
  setPageNum,
  totalProducts,
  setIsProductByFilter,
}) => {
  const [pageCount, setPageCount] = useState(1);
  useEffect(() => {
    if (totalProducts) {
      setPageCount(Math.ceil(totalProducts / 24));
    }
  }, [totalProducts]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row mt-2 align-items-center">
            <div className="col-12 pagination-col text-end">
              <button
                type="button"
                className="nextPrevBtn"
                disabled={pageNum == 1 ? true : false}
                onClick={() => {
                  setIsProductByFilter(true);
                  setPageNum((pageNum) => Number(pageNum) - 1);
                }}
              >
                <span className={pageNum == 1 ? "disabledBtn" : ""}>
                  <span className="material-symbols-outlined">
                    arrow_back_ios_new
                  </span>
                </span>
              </button>
              <button
                type="button"
                className="nextPrevBtn"
                disabled={pageNum == pageCount ? true : false}
                onClick={() => {
                  setIsProductByFilter(true);
                  setPageNum((pageNum) => Number(pageNum) + 1);
                }}
              >
                <span className={pageNum == pageCount ? "disabledBtn" : ""}>
                  <span className="material-symbols-outlined">
                    arrow_forward_ios
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
