import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Activity = () => {
  return (
    <>
      <main>
        <section className="content-section white-color-section speckle-bg extra-section-margin">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <h2>Activity</h2>
              </div>
              <div className="col-12">
                <div className="logged-in-tabs mt-4">
                  <Tabs
                    defaultActiveKey="daily"
                    id="userActivityTabs"
                    className="mt-2"
                  >
                    <Tab eventKey="daily" title="daily">
                      daily activity listed here
                    </Tab>

                    <Tab eventKey="weekly" title="weekly">
                      weekly activity listed here
                    </Tab>

                    <Tab eventKey="monthly" title="monthly">
                      monthly activity listed here
                    </Tab>

                    <Tab eventKey="yearly" title="yearly">
                      yearly activity listed here
                    </Tab>
                  </Tabs>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        {/* <FaqsSection /> */}
      </main>
    </>
  );
};

export default Activity;
