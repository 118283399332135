import React from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../../assets/img/img-line-main-2.png";

/* comp imports */
import MainWrapper from "../MainWrapper";
import MainCareerNavbar from "./MainCareerNavbar";
import Footer from "../Footer";
import FooterCareer from "./FooterCareer";
import CareerForm from "./CareerForm";

const StrategicBusinessDevelopment = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        strategic business development
                      </h3>
                      <div className="section-content">
                        as the strategic business development at{" "}
                        <span className="comp-name">ChaChing</span>, you will
                        play a pivotal role in driving growth and expanding the
                        company's market reach through strategic partnerships
                        and alliances. you will be responsible for identifying,
                        negotiating, and establishing partnerships with key
                        organizations, platforms, and stakeholders to drive
                        revenue growth and create mutually beneficial
                        opportunities for{" "}
                        <span className="comp-name">ChaChing</span>.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section white-color-section speckle-bg career-details-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 mb-5 mb-md-1">
                    <div className="section-content-wrapper">
                      <div className="content-head mt-0">responsibilities</div>

                      <ul>
                        <li>
                          develop and execute a strategic partnership strategy
                          to identify and prioritize potential partners that
                          align with <span className="comp-name">ChaChing</span>
                          's business objectives and target markets.
                        </li>
                        <li>
                          cultivate relationships with key stakeholders,
                          including potential partners, industry associations,
                          and ecosystem players, to explore collaboration
                          opportunities and establish mutually beneficial
                          partnerships.
                        </li>
                        <li>
                          negotiate partnership agreements, terms, and pricing
                          structures to ensure alignment with{" "}
                          <span className="comp-name">ChaChing</span>'s
                          strategic goals and financial objectives.
                        </li>
                        <li>
                          collaborate with cross-functional teams, including
                          sales, marketing, product development, and legal, to
                          facilitate partnership integration, implementation,
                          and ongoing management.
                        </li>
                        <li>
                          drive revenue growth and market expansion through
                          strategic partnerships, leveraging partner channels,
                          networks, and resources to generate new business
                          opportunities and drive customer acquisition.
                        </li>
                        <li>
                          monitor and analyze market trends, competitor
                          activities, and industry developments to identify
                          partnership opportunities and inform strategic
                          decision-making.
                        </li>
                        <li>
                          track and measure partnership performance metrics,
                          including revenue growth, customer acquisition, and
                          partner satisfaction, to evaluate partnership
                          effectiveness and optimize outcomes.
                        </li>
                        <li>
                          represent <span className="comp-name">ChaChing</span>{" "}
                          at industry events, conferences, and networking forums
                          to promote the company's brand, build relationships,
                          and identify potential partnership opportunities.
                        </li>
                      </ul>

                      <div className="content-head mt-0">requirements</div>
                      <ul>
                        <li>
                          bachelor's degree in business administration,
                          marketing, or related field (master's degree
                          preferred).
                        </li>
                        <li>
                          proven track record of success in strategic
                          partnership development, business development, or
                          related roles, preferably in the fintech or technology
                          industry.
                        </li>
                        <li>
                          strong negotiation, communication, and interpersonal
                          skills, with the ability to build and maintain
                          relationships with key stakeholders.
                        </li>
                        <li>
                          strategic thinker with a results-oriented mindset and
                          a proactive approach to identifying and pursuing
                          partnership opportunities.
                        </li>
                        <li>
                          ability to understand partner needs, identify
                          synergies, and develop customized partnership
                          solutions that create mutual value.
                        </li>
                        <li>
                          experience in contract negotiation, partnership
                          structuring, and financial analysis.
                        </li>
                        <li>
                          knowledge of payment processing, financial services,
                          or related industries is a plus.
                        </li>
                        <li>
                          ability to travel as needed for partnership meetings,
                          industry events, and networking opportunities.
                        </li>
                      </ul>
                      <p>
                        join <span className="comp-name">ChaChing</span>'s
                        dynamic team and play a key role in driving strategic
                        partnerships and business development initiatives to
                        accelerate the company's growth and success!
                      </p>
                      <p>
                        to apply, please submit your resume, cover letter, and
                        any relevant portfolio or examples of successful
                        partnership development.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="section-content-wrapper">
                      <CareerForm />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default StrategicBusinessDevelopment;
