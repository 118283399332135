import React from "react";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";
import BuildCreditHistoryMain from "../components/BuildCreditHistoryMain";

const BuildCreditHistory = () => {
  return (
    <>
      <BuildCreditHistoryMain />
    </>
  );
};

export default BuildCreditHistory;
