import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { baseUrl, endPoints } from "../data/endPoints";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";

const RefundForm = () => {
  const [showCryptoFields, setShowCryptoFields] = useState(false);
  const [showBankFields, setShowBankFields] = useState(false);

  const handleTransactionMode = (e) => {
    let { name, value } = e.target;
    console.log("val", value);
    if (value === "crypto") {
      setShowCryptoFields(true);
      setShowBankFields(false);
    } else if (value === "bank") {
      setShowCryptoFields(false);
      setShowBankFields(true);
    } else {
      setShowCryptoFields(false);
      setShowBankFields(false);
    }
    setRefundMainData((refundMainData) => ({
      ...refundMainData,
      [name]: value,
    }));
  };

  const [refundMainData, setRefundMainData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showThankyou, setShowThankyou] = useState(false);

  const refundMainDataDefault = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    transactionMode: "",
    wallet: "",
    token: "",
    walletAddress: "",
    currency: "",
    routingNumber: "",
    bankAccountNumber: "",
    accountHoldersName: "",
    transactionDate: "",
    amount: "",
    refundReason: "",
  };

  useEffect(() => {
    setRefundMainData(refundMainDataDefault);
  }, []);

  const inputsHandler = (e) => {
    const { name, value, files } = e.target;
    let inputElem = files ? files[0] : "";
    setRefundMainData((refundMainData) => ({
      ...refundMainData,
      [name]: files ? inputElem : value,
    }));
    let fileName = document.getElementById("fileNameRefund");
    fileName.innerText = inputElem.name;
  };

  const validateEmail = (email) => {
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;
    return regex.test(email);
  };

  const validateMobileNumber = (number) => {
    let numRegex = new RegExp(/^\d{10}$/);
    return numRegex.test(number);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("firstName", refundMainData.firstName);
    data.append("lastName", refundMainData.lastName);
    data.append("phone", refundMainData.phone);
    data.append("email", refundMainData.email);
    data.append("transactionMode", refundMainData.transactionMode);
    data.append("wallet", refundMainData.wallet);
    data.append("token", refundMainData.token);
    data.append("walletAddress", refundMainData.walletAddress);
    data.append("currency", refundMainData.currency);
    data.append("routingNumber", refundMainData.routingNumber);
    data.append("bankAccountNumber", refundMainData.bankAccountNumber);
    data.append("accountHoldersName", refundMainData.accountHoldersName);
    data.append("transactionDate", refundMainData.transactionDate);
    data.append("amount", refundMainData.amount);
    data.append("refundReason", refundMainData.refundReason);

    data.append("refundFile", refundMainData.refundFile);

    let config = {
      method: "post",
      url: `${baseUrl}${endPoints.refundForm}`,
      headers: {},
      data: data,
    };

    try {
      setErrorMessage("");
      if (
        refundMainData.firstName != "" &&
        refundMainData.lastName != "" &&
        refundMainData.phone != "" &&
        refundMainData.email != "" &&
        refundMainData.transactionMode != "" &&
        refundMainData.transactionDate != "" &&
        refundMainData.amount != "" &&
        refundMainData.refundReason != ""
      ) {
        let numberValidated = validateMobileNumber(refundMainData.phone);
        if (numberValidated) {
          let emailValidated = validateEmail(refundMainData.email);
          if (emailValidated) {
            const response = await axios.request(config);
            if (response && response.data.status) {
              setShowThankyou(true);
              setRefundMainData(refundMainDataDefault);
            } else {
              console.log(response.data.message);
              setErrorMessage("Please try again later");
            }
          } else {
            setErrorMessage("Please enter a valid email id");
          }
        } else {
          setErrorMessage("phone number should be a 10 digit number");
        }
      } else {
        setErrorMessage("Please enter all required fields");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Please try again later");
    }
  };

  return (
    <>
      <main>
        <section className="content-section white-color-section extra-section-margin extra-section-margin-bott extra-section-padding extra-section-padding-bott">
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 col-xl-6 offset-md-2 offset-xl-3 d-flex align-items-center flex-wrap text-center">
                    <div className="section-content-wrapper">
                      <div className="section-heading pay-page mb-1">
                        refund form
                      </div>

                      <div className="form-wrapper">
                        <div className="form-title"></div>
                        {/* <div className="form-sub-title">

                          </div> */}
                        <span className="error-text">{errorMessage}</span>
                        {showThankyou ? (
                          <>
                            <h3 className="mt-5">
                              thank you for raising your concern.
                            </h3>
                            <h4 className="mb-5">
                              we will review and get back to you shortly!
                            </h4>
                          </>
                        ) : (
                          <form onSubmit={handleFormSubmit}>
                            <div className="form-group-wrapper d-flex">
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="text"
                                  placeholder="first name*"
                                  required
                                  name="firstName"
                                  onChange={inputsHandler}
                                  maxLength="20"
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="text"
                                  placeholder="last name*"
                                  required
                                  name="lastName"
                                  onChange={inputsHandler}
                                  maxLength="20"
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="text"
                                  placeholder="phone number*"
                                  required
                                  name="phone"
                                  onChange={inputsHandler}
                                  maxLength="10"
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="email"
                                  placeholder="email address*"
                                  required
                                  name="email"
                                  onChange={inputsHandler}
                                  maxLength="20"
                                />
                              </Form.Group>
                              <Form.Select
                                className="mb-3 main-form-select"
                                name="transactionMode"
                                required
                                onChange={(e) => {
                                  handleTransactionMode(e);
                                }}
                              >
                                <option value="">transaction mode*</option>
                                <option value="crypto">crypto wallet</option>
                                <option value="bank">bank account</option>
                              </Form.Select>
                              {/* if crypto is selected */}
                              {showCryptoFields && (
                                <>
                                  <Form.Select
                                    className="mb-3 main-form-select"
                                    name="wallet"
                                    onChange={inputsHandler}
                                  >
                                    <option value="">select wallet</option>
                                    <option value="metamask">metamask</option>
                                    <option value="trustwallet">
                                      trustwallet
                                    </option>
                                    <option value="bitcoin">bitcoin</option>
                                    <option value="phantom">phantom</option>
                                    <option value="ledger">ledger</option>
                                    <option value="coinbase">coinbase</option>
                                  </Form.Select>
                                  <Form.Select
                                    className="mb-3 main-form-select"
                                    name="token"
                                    onChange={inputsHandler}
                                  >
                                    <option value="">select crypto</option>
                                    <option value="bitcoin">bitcoin</option>
                                    <option value="deso">deso</option>
                                    <option value="polygon">polygon</option>
                                    <option value="ethereum">ethereum</option>
                                  </Form.Select>

                                  <Form.Group className="mb-3 main-form-group w-100">
                                    <Form.Control
                                      type="text"
                                      placeholder="Crypto wallet address"
                                      name="walletAddress"
                                      onChange={inputsHandler}
                                      maxLength="80"
                                    />
                                  </Form.Group>
                                </>
                              )}

                              {/* if bank is selected */}

                              {showBankFields && (
                                <>
                                  <Form.Select
                                    className="mb-3 main-form-select"
                                    name="currency"
                                    onChange={inputsHandler}
                                  >
                                    <option value="">select currency</option>
                                    <option value="dollars">dollars</option>
                                    <option value="rupees">rupees</option>
                                  </Form.Select>

                                  <Form.Group className="mb-3 main-form-group w-100">
                                    <Form.Control
                                      type="text"
                                      placeholder="routing number"
                                      name="routingNumber"
                                      onChange={inputsHandler}
                                      maxLength="50"
                                    />
                                  </Form.Group>

                                  <Form.Group className="mb-3 main-form-group w-100">
                                    <Form.Control
                                      type="text"
                                      placeholder="bank account number"
                                      name="bankAccountNumber"
                                      onChange={inputsHandler}
                                      maxLength="30"
                                    />
                                  </Form.Group>

                                  <Form.Group className="mb-3 main-form-group w-100">
                                    <Form.Control
                                      type="text"
                                      placeholder="account holders name"
                                      name="accountHoldersName"
                                      onChange={inputsHandler}
                                      maxLength="50"
                                    />
                                  </Form.Group>
                                </>
                              )}

                              <Form.Group className="mb-3 main-form-group w-100 transaction-date">
                                <Form.Control
                                  type="date"
                                  placeholder="transaction date*"
                                  name="transactionDate"
                                  onChange={inputsHandler}
                                  required
                                  className={`${
                                    refundMainData.transactionDate
                                      ? "not-empty"
                                      : ""
                                  }`}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="text"
                                  placeholder="transaction amount*"
                                  name="amount"
                                  onChange={inputsHandler}
                                  required
                                  max="10"
                                />
                              </Form.Group>

                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  placeholder="enter reason for refund*"
                                  required
                                  name="refundReason"
                                  onChange={inputsHandler}
                                  maxLength="200"
                                />
                              </Form.Group>
                              <Form.Group
                                controlId="refundFile"
                                className="mb-3 main-form-group w-100"
                              >
                                <label
                                  htmlFor="refundFile"
                                  className="custom-file-upload-label"
                                >
                                  <span className="material-symbols-outlined">
                                    picture_as_pdf
                                  </span>
                                  <Form.Control
                                    type="file"
                                    placeholder="upload refund detail"
                                    accept="application/pdf"
                                    name="refundFile"
                                    onChange={inputsHandler}
                                  />
                                  <span id="fileNameRefund">
                                    Reference File
                                  </span>
                                </label>
                              </Form.Group>
                            </div>

                            <div>
                              <button className="starry-btn mt-4" type="submit">
                                <span className="btn-text">submit</span>
                                <div id="container-stars">
                                  <div id="stars"></div>
                                </div>

                                <div id="glow">
                                  <div className="circle"></div>
                                  <div className="circle"></div>
                                </div>
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section form */}
      </main>
    </>
  );
};

export default RefundForm;
