import React, { useState, useEffect } from "react";
import { gsap, Sine } from "gsap";
import { motion } from "framer-motion";
import meteorIcon from "../../assets/img/gaming/meteror.png";
import explosionIcon from "../../assets/img/gaming/explosion.png";
import MeteorScaling from "./MeteorScaling";
const Meteor = ({ multiplier, gameStatus }) => {
  const [drawLine, setDrawLine] = useState({
    initial: { pathLength: 0 },
  });

  const [svgEndpoint, setSvgEndpoint] = useState(365);

  let interval1;
  let interval2;
  let interval3;
  let interval4;
  let interval5;
  let interval6;
  let interval7;

  useEffect(() => {
    gsap.to(".meteor", { duration: 0.1, y: "+=2", yoyo: true, repeat: -1 });
    gsap.to(".meteor", { duration: 0.1, y: "-=2", yoyo: true, repeat: -1 });
    if (gameStatus === "Crashed" || gameStatus === "Stopped") {
      gsap.killTweensOf(".meteor");
      gsap.set(".meteor", {
        left: "1%",
        top: "90%",
        rotation: 0,
        opacity: 0,
        onComplete: () => {
          setTimeout(() => {
            interval1 && clearInterval(interval1);
            interval2 && clearInterval(interval2);
            interval3 && clearInterval(interval3);
            interval4 && clearInterval(interval4);
            interval5 && clearInterval(interval5);
            interval6 && clearInterval(interval6);
            interval7 && clearInterval(interval7);
          }, 100);
        },
      });
      setDrawLine({
        ...drawLine,
        animate: {},
      });
    }
    if (gameStatus === "Running") {
      gsap.set(".meteor", {
        opacity: 1,
        onComplete: () => {
          gsap.to(".meteor", {
            duration: 1.1,
            left: "88%",
            top: "85%",
            rotation: -5,
            ease: Sine.easeOut,
            // ease: "none",
          });
        },
      });
      setDrawLine({
        ...drawLine,
        animate: {
          pathLength: 1,
          transition: {
            duration: 1.1,
            ease: "easeOut",
          },
        },
      });
    }
  }, [gameStatus]);

  useEffect(() => {
    // console.log("multiplier top", multiplier);
    if (gameStatus === "Running" && multiplier == 1.1) {
      interval1 = setInterval(() => {
        let meteorElem = document.querySelector(".meteor");
        if (meteorElem) {
          let topVal = meteorElem.style.top;
          setSvgEndpoint((parseFloat(topVal) / 100) * 400 + 22);
          // console.log(
          //   "meteor values 1.1 ",
          //   topVal,
          //   (parseFloat(topVal) / 100) * 400 + 22
          // );
        }
      }, 100);

      gsap.fromTo(
        ".meteor",
        {
          left: "88%",
          rotation: -5,
          top: "85%",
        },
        {
          duration: 10,
          left: "88%",
          rotation: -15,
          top: "65%",
          ease: "none",
          onComplete: () => {
            setTimeout(() => {
              clearInterval(interval1);
            }, 10);
          },
        }
      );
    } else if (gameStatus === "Running" && multiplier == 2.1) {
      interval2 = setInterval(() => {
        let meteorElem = document.querySelector(".meteor");
        if (meteorElem) {
          let topVal = meteorElem.style.top;
          setSvgEndpoint((parseFloat(topVal) / 100) * 400 + 22);
          // console.log(
          //   "meteor values 2.1",
          //   topVal,
          //   (parseFloat(topVal) / 100) * 400 + 22
          // );
        }
      }, 70);
      gsap.fromTo(
        ".meteor",
        {
          left: "88%",
          rotation: -15,
          top: "65%",
        },
        {
          duration: 14,
          left: "88%",
          rotation: -25,
          top: "55%",
          ease: "none",
          onComplete: () => {
            setTimeout(() => {
              // console.log("finished 2.1");
              clearInterval(interval2);
            }, 10);
          },
        }
      );
    } else if (gameStatus === "Running" && multiplier == 4.0) {
      interval3 = setInterval(() => {
        let meteorElem = document.querySelector(".meteor");
        if (meteorElem) {
          let topVal = meteorElem.style.top;
          setSvgEndpoint((parseFloat(topVal) / 100) * 400 + 22);
          // console.log(
          //   "meteor values 4.0",
          //   topVal,
          //   (parseFloat(topVal) / 100) * 400 + 22
          // );
        }
      }, 35);
      gsap.fromTo(
        ".meteor",
        {
          left: "88%",
          rotation: -25,
          top: "55%",
        },
        {
          duration: 20,
          left: "88%",
          rotation: -35,
          top: "45%",
          ease: "none",
          onComplete: () => {
            setTimeout(() => {
              // console.log("finished 4.0");
              clearInterval(interval3);
            }, 10);
          },
        }
      );
    } else if (gameStatus === "Running" && multiplier == 10.0) {
      interval4 = setInterval(() => {
        let meteorElem = document.querySelector(".meteor");
        if (meteorElem) {
          let topVal = meteorElem.style.top;
          setSvgEndpoint((parseFloat(topVal) / 100) * 400 + 22);
          // console.log(
          //   "meteor values 10.0",
          //   topVal,
          //   (parseFloat(topVal) / 100) * 400 + 22
          // );
        }
      }, 18);
      gsap.fromTo(
        ".meteor",
        {
          left: "88%",
          rotation: -35,
          top: "45%",
        },
        {
          duration: 18,
          left: "88%",
          rotation: -50,
          top: "35%",
          ease: "none",
          onComplete: () => {
            setTimeout(() => {
              // console.log("finished 10.0");
              clearInterval(interval4);
            }, 10);
          },
        }
      );
    } else if (gameStatus === "Running" && multiplier == 20.0) {
      interval5 = setInterval(() => {
        let meteorElem = document.querySelector(".meteor");
        if (meteorElem) {
          let topVal = meteorElem.style.top;
          setSvgEndpoint((parseFloat(topVal) / 100) * 400 + 22);
          // console.log(
          //   "meteor values 20.0",
          //   topVal,
          //   (parseFloat(topVal) / 100) * 400 + 22
          // );
        }
      }, 15);
      gsap.fromTo(
        ".meteor",
        {
          left: "88%",
          rotation: -50,
          top: "35%",
        },
        {
          duration: 15,
          left: "88%",
          rotation: -60,
          top: "25%",
          ease: "none",
          onComplete: () => {
            setTimeout(() => {
              // console.log("finished 20.0");
              clearInterval(interval5);
            }, 10);
          },
        }
      );
    } else if (gameStatus === "Running" && multiplier == 30.0) {
      interval6 = setInterval(() => {
        let meteorElem = document.querySelector(".meteor");
        if (meteorElem) {
          let topVal = meteorElem.style.top;
          setSvgEndpoint((parseFloat(topVal) / 100) * 400 + 22);
          // console.log(
          //   "meteor values 30.0",
          //   topVal,
          //   (parseFloat(topVal) / 100) * 400 + 22
          // );
        }
      }, 5);
      gsap.fromTo(
        ".meteor",
        {
          left: "88%",
          rotation: -60,
          top: "25%",
        },
        {
          duration: 5,
          left: "88%",
          rotation: -70,
          top: "10%",
          ease: "none",
          onComplete: () => {
            setTimeout(() => {
              // console.log("finished 30.0");
              clearInterval(interval6);
            }, 10);
          },
        }
      );
    } else if (gameStatus === "Running" && multiplier == 40.0) {
      interval7 = setInterval(() => {
        let meteorElem = document.querySelector(".meteor");
        if (meteorElem) {
          let topVal = meteorElem.style.top;
          setSvgEndpoint((parseFloat(topVal) / 100) * 400 + 22);
          // console.log(
          //   "meteor values 40.0",
          //   topVal,
          //   (parseFloat(topVal) / 100) * 400 + 22
          // );
        }
      }, 5);
      gsap.fromTo(
        ".meteor",
        {
          left: "88%",
          rotation: -70,
          top: "10%",
        },
        {
          duration: 2.5,
          left: "88%",
          rotation: -82,
          top: "5%",
          ease: "none",
          onComplete: () => {
            setTimeout(() => {
              // console.log("finished 40.0");
              clearInterval(interval7);
            }, 10);
          },
        }
      );
    }

    return () => {
      if (gameStatus === "Crashed" || gameStatus === "Stopped") {
        interval1 && clearInterval(interval1);
        interval2 && clearInterval(interval2);
        interval3 && clearInterval(interval3);
        interval4 && clearInterval(interval4);
        interval5 && clearInterval(interval5);
        interval6 && clearInterval(interval6);
        interval7 && clearInterval(interval7);
      }
    };
  }, [gameStatus, multiplier]);

  return (
    <div className="meteor-container">
      <MeteorScaling multiplier={multiplier} gameStatus={gameStatus} />

      <div className="meteor">
        <img src={meteorIcon} alt="" />
      </div>

      {gameStatus === "Crashed" && (
        <div className="explosion">
          <img src={explosionIcon} alt="" />
        </div>
      )}
      <span className="d-none d-md-block">
        <svg
          width="540"
          height="400"
          viewBox="0 0 560 400"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            id="meteorPath"
            className="meteor-path"
            d={`M10, 385 Q350,390 540,${svgEndpoint}`}
            stroke="#fac317"
            strokeWidth="2"
            fill="none"
            variants={drawLine}
            initial="initial"
            animate="animate"
          />
          Sorry, your browser does not support inline SVG.
        </svg>
      </span>
      <span className="d-block d-md-none">
        <svg
          width="340"
          height="400"
          viewBox="0 0 360 400"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            id="meteorPath"
            className="meteor-path"
            d={`M10, 385 Q200,380 345,${svgEndpoint + 3}`}
            stroke="#fac317"
            strokeWidth="2"
            fill="none"
            variants={drawLine}
            initial="initial"
            animate="animate"
          />
          Sorry, your browser does not support inline SVG.
        </svg>
      </span>
    </div>
  );
};

export default Meteor;
