import React, { useEffect } from "react";
import { gsap, Sine } from "gsap";

const HeadingSort = ({
  sortByHandler,
  pageHeadDetails,
  setShowSort,
  showSort,
  setShowSearch,
}) => {
  useEffect(() => {
    if (showSort) {
      gsap.fromTo(
        "#sortList",
        {
          top: "64px",
          opacity: 0,
          display: "none",
        },
        {
          duration: 0.5,
          top: "42px",
          display: "flex",
          opacity: 1,
          ease: Sine.easeOut,
        }
      );
    } else {
      gsap.set("#sortList", {
        top: "64px",
        display: "none",
        opacity: 0,
        ease: Sine.easeOut,
      });
    }
  }, [showSort]);
  return (
    <>
      <div className="row category-sort-row">
        <div className="col-12 col-lg-8">
          <div className="category-head">
            <span>
              {pageHeadDetails.displayName === "Electronics" ||
              pageHeadDetails.displayName === "Toys & Games"
                ? "all"
                : pageHeadDetails.displayName}
            </span>
          </div>
        </div>
        <div className="col-12 col-lg-4 d-flex justify-content-end flex-wrap sort-col">
          <button
            type="button"
            className="sort-icon"
            onClick={() => {
              setShowSort((prev) => !prev);
              setShowSearch(false);
            }}
          >
            <span className="material-symbols-outlined">sort</span>
          </button>
          <div className="sort-list" id="sortList">
            <span data-sort="pp" onClick={sortByHandler}>
              Popularity
            </span>
            <span data-sort="pricelth" onClick={sortByHandler}>
              Low to High
            </span>
            <span data-sort="pricehtl" onClick={sortByHandler}>
              High to Low
            </span>
            <span data-sort="new" onClick={sortByHandler}>
              Latest
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeadingSort;
