import React, { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";

import { CartContext } from "../../context/CartContextProvider";

/* img imports */
import dollarIcon from "../../assets/img/currency-icons/dollarIcon.svg";
import { getDeliveryDate, today } from "../../data/storeApiCalls";
import CartProductItem from "./CartProductItem";
import CheckoutDetails from "./CheckoutDetails";
import CartPricingDetails from "./CartPricingDetails";

const ShoppingCart = ({ desoRate }) => {
  const { cartProducts } = useContext(CartContext);

  //   console.log("cartProducts", cartProducts);

  return (
    <>
      <div className="row cart-details-row">
        <div className="col-12">
          <h3 className="cart-head">my cart</h3>
        </div>
        <div className="col-12 col-xl-8">
          <div className="cart-products-wrapper">
            {cartProducts && Object.keys(cartProducts).length ? (
              <div className="delivery-details d-flex justify-content-between flex-wrap flex-sm-nowrap">
                <div>
                  <span className="delivery-by">
                    Delivery by {getDeliveryDate(today)}
                  </span>
                  {/* <span className="replacement-text">7 Days Replacement Policy</span> */}
                </div>
              </div>
            ) : (
              ""
            )}

            {cartProducts && Object.keys(cartProducts).length ? (
              Object.keys(cartProducts).map((product, key) => {
                return (
                  <CartProductItem
                    key={cartProducts[product].skuId}
                    productId={cartProducts[product].skuId}
                    desoRate={desoRate}
                    price={cartProducts[product].price}
                    qty={cartProducts[product].quantity}
                  />
                );
              })
            ) : (
              <span className="no-products-cart">No Products In Cart</span>
            )}
          </div>
        </div>
        <div className="col-12 col-xl-4 mt-4 mt-xl-0">
          {/* pricing details */}
          <CartPricingDetails desoRate={desoRate} />

          {/* checkout details */}

          <CheckoutDetails />
        </div>
      </div>
    </>
  );
};

export default ShoppingCart;
