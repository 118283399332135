import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import { baseUrl, endPoints } from "../data/endPoints";

/* comp imports */
import FaqsSection from "./FaqsSection";

/* img imports */

import imgLineMain from "../assets/img/img-line-main.png";
import imgLineMain2 from "../assets/img/img-line-main-2.png";

const ConciergeMain = () => {
  const [conciergeMainData, setConciergeMainData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showThankyou, setShowThankyou] = useState(false);

  const conciergeMainDataDefault = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    instagramId: "",
    xId: "",
    likeToManage: "",
    referralCode: "",
    optOut: "yes",
  };

  useEffect(() => {
    setConciergeMainData(conciergeMainDataDefault);
  }, []);

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (name === "optOut") {
      let checked = e.target.checked;
      setConciergeMainData((conciergeMainData) => ({
        ...conciergeMainData,
        [name]: checked ? "yes" : "no",
      }));
    } else {
      setConciergeMainData((conciergeMainData) => ({
        ...conciergeMainData,
        [name]: value,
      }));
    }
  };

  const validateEmail = (email) => {
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;
    return regex.test(email);
  };

  const validateMobileNumber = (number) => {
    let numRegex = new RegExp(/^\d{10}$/);
    return numRegex.test(number);
  };

  let config = {
    method: "post",
    url: `${baseUrl}${endPoints.concierge}`,
    headers: {},
    data: conciergeMainData,
  };

  const conciergeMainForm = async () => {
    try {
      setErrorMessage("");
      if (
        conciergeMainData.firstName != "" &&
        conciergeMainData.lastName != "" &&
        conciergeMainData.phone != "" &&
        conciergeMainData.email != "" &&
        conciergeMainData.likeToManage != "" &&
        conciergeMainData.referralCode != ""
      ) {
        let numberValidated = validateMobileNumber(conciergeMainData.phone);
        if (numberValidated) {
          let emailValidated = validateEmail(conciergeMainData.email);
          if (emailValidated) {
            const response = await axios.request(config);
            if (response && response.data.status) {
              setShowThankyou(true);
              setConciergeMainData(conciergeMainDataDefault);
            } else {
              console.log(response.data.message);
              setErrorMessage("Please try again later");
            }
          } else {
            setErrorMessage("Please enter a valid email id");
          }
        } else {
          setErrorMessage("phone number should be a 10 digit number");
        }
      } else {
        setErrorMessage("Please enter all required fields");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Please try again later");
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    conciergeMainForm();
  };

  console.log("conciergeMainData", conciergeMainData);

  return (
    <>
      <main>
        <section className="comm-section white-color-section speckle-bg">
          <div className="shape"></div>
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">Concierge</h3>

                      <div className="section-content pay-page">
                        <span className="comp-name">ChaChing</span> Concierge
                        offers a hassle free way for high net worth individuals
                        to manage assets. By referral only.
                      </div>
                      <button className="starry-btn" type="button">
                        <span className="btn-text">
                          <HashLink smooth to="/concierge#ConciergeForm">
                            register
                          </HashLink>
                        </span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 1 */}

        <section
          className="content-section white-color-section"
          id="ConciergeForm"
        >
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 col-xl-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <div className="form-wrapper">
                        <div className="form-title">
                          <span className="comp-name">ChaChing</span> concierge
                          is by invitation only.
                        </div>
                        <div className="form-sub-title">
                          If you are interested, please complete the form below:
                        </div>
                        <span className="error-text">{errorMessage}</span>

                        {showThankyou ? (
                          <>
                            <h3 className="mt-5">
                              thank you for showing interest.
                            </h3>
                            <h4 className="mb-5">
                              we will get back to you shortly!
                            </h4>
                          </>
                        ) : (
                          <form onSubmit={handleFormSubmit}>
                            <div className="form-group-wrapper d-flex">
                              <Form.Group className="mb-3 main-form-group">
                                <Form.Control
                                  type="text"
                                  placeholder="first name"
                                  required
                                  name="firstName"
                                  onChange={inputsHandler}
                                  maxLength="20"
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group">
                                <Form.Control
                                  type="text"
                                  placeholder="last name"
                                  required
                                  name="lastName"
                                  onChange={inputsHandler}
                                  maxLength="20"
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group">
                                <Form.Control
                                  type="text"
                                  placeholder="phone number"
                                  required
                                  name="phone"
                                  onChange={inputsHandler}
                                  maxLength="10"
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group">
                                <Form.Control
                                  type="email"
                                  placeholder="email address"
                                  required
                                  name="email"
                                  onChange={inputsHandler}
                                  maxLength="150"
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group">
                                <Form.Control
                                  type="text"
                                  placeholder="instagram"
                                  name="instagramId"
                                  onChange={inputsHandler}
                                  maxLength="50"
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group">
                                <Form.Control
                                  type="text"
                                  placeholder="x (twitter)"
                                  name="xId"
                                  onChange={inputsHandler}
                                  maxLength="50"
                                />
                              </Form.Group>
                              <Form.Select
                                className="mb-3 main-form-select"
                                onChange={inputsHandler}
                                name="likeToManage"
                                required
                              >
                                <option>I'd like to manage my</option>
                                <option value="my personal finances">
                                  my personal finances
                                </option>
                                <option value="my crypto assets">
                                  my crypto assets
                                </option>
                                <option value="not sure">not sure</option>
                              </Form.Select>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="text"
                                  placeholder="referral code"
                                  required
                                  name="referralCode"
                                  onChange={inputsHandler}
                                  maxLength="20"
                                />
                              </Form.Group>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="optOut"
                                defaultChecked
                                name="optOut"
                                onChange={inputsHandler}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="optOut"
                              >
                                Opt-out to stop receiving communication at the
                                phone number provided. Message and data rates
                                may apply. Reply HELP for help or STOP to
                                cancel.{" "}
                                <a
                                  href="/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Terms of use
                                </a>
                                .
                              </label>
                            </div>
                            <div>
                              <button className="starry-btn mt-3" type="submit">
                                <span className="btn-text">submit</span>
                                <div id="container-stars">
                                  <div id="stars"></div>
                                </div>

                                <div id="glow">
                                  <div className="circle"></div>
                                  <div className="circle"></div>
                                </div>
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section form */}

        <FaqsSection />
      </main>
    </>
  );
};

export default ConciergeMain;
