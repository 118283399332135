import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { UserContext } from "../context/UserContextProvider";
import { motion } from "framer-motion";
import { riseUp, sentence, words } from "../data/custom";

/* video imports */
import homepageMainBanner from "../assets/img/video-assets/homepageMainBannerNew.mp4";
import chachingAnywhereHome from "../assets/img/video-assets/chachingAnywhereHomeNew.mp4";
import remindersHome from "../assets/img/video-assets/remindersHome.mp4";
import coveredHome from "../assets/img/video-assets/coveredHome.mp4";

/* comp imports */
import FaqsSection from "./FaqsSection";

/* img imports */
import mobileMockups from "../assets/img/banner/mobile-mockups-homepage.jpg";
import mobileMockupsMobile from "../assets/img/banner/mobile-mockups-homepage-mobile.jpg";
import { vendorLogos } from "../components/vendorLogos";
// import chachingAnywhere from "../assets/img/chaching-anywhere.jpg";
// import chachingLogoText from "../assets/img/logo/chaching-logo-text.png";
// import chachingAnywhereBgMobile from "../assets/img/banner/chaching-anywhere-bg-mobile.jpg";
// import storySectionTextBg from "../assets/img/banner/story-section-mobile-text-bg.png";
import rewardsMobileBg from "../assets/img/banner/rewards-mobile-bg.png";
import securityMobileBg from "../assets/img/banner/security-mobile-bg.jpg";
// import remindersHumanBg from "../assets/img/banner/reminders-human-bg.png";
import storeSectionMobileFigureBgWebP from "../assets/img/banner/store-section-mobile-figure-bg.webp";
import storeSectionMobileFigureBg from "../assets/img/banner/store-section-mobile-figure-bg.png";
import { brandLogos } from "../components/brandLogos";
import homepageMainBannerPoster from "../assets/img/banner/homepageMainBannerPoster.jpg";
import chachingAnywhereHomePoster from "../assets/img/banner/chachingAnywhereHomePoster.jpg";
import remindersHomePoster from "../assets/img/banner/remindersHomePoster.jpg";
import Waitlist from "./Waitlist";

const HomeMain = () => {
  const { handleShowLoginScreen, checkIsLoggedIn, isLoggedIn } =
    useContext(UserContext);
  const navigate = useNavigate();

  const handleRedirect = (path) => {
    if (path) {
      navigate(`/${path}`);
    }
  };

  useEffect(() => {
    checkIsLoggedIn();
  }, [isLoggedIn]);

  const videoRefHomepageMainBanner = useRef(undefined);
  const videoRefChachingAnywhereHome = useRef(undefined);
  const videoRefRemindersHome = useRef(undefined);
  const videoRefCoveredHome = useRef(undefined);
  useEffect(() => {
    videoRefHomepageMainBanner.current.defaultMuted = true;
    videoRefChachingAnywhereHome.current.defaultMuted = true;
    videoRefRemindersHome.current.defaultMuted = true;
    videoRefCoveredHome.current.defaultMuted = true;
  });

  const headingText =
    "the elite platform where timely payments elevate your financial lifestyle. Here, discipline meets luxury—earn exclusive rewards simply by settling your bills. Entrust your transactions to a system that values security as much as you do. Elevate your financial game with ChaChing, where every payment is a step towards greatness.";

  /* waitlist */
  const [waitlistErrorMessage, setWaitlistErrorMessage] = useState("");

  const [waitlistModalshow, setWaitlistModalShow] = useState(false);
  const handleWaitlistClose = () => {
    setWaitlistModalShow(false);
    setShowWaitlistThankyou(false);
    setWaitlistErrorMessage("");
  };
  const handleWaitlistModalShow = () => setWaitlistModalShow(true);
  const [showWaitlistThankyou, setShowWaitlistThankyou] = useState(false);

  return (
    <>
      <main>
        <section className="comm-section banner-section">
          <div className="section-overlay-dark"></div>

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefHomepageMainBanner}
              poster={homepageMainBannerPoster}
            >
              <source src={homepageMainBanner} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="container">
            <div className="row align-content-end align-content-md-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-left text-md-center">
                    <h1 className="main-heading">
                      rewards for paying your credit card bills
                    </h1>
                    <div className="d-inline-block">
                      <h1 className="main-heading d-block">
                        via <em>cash</em> or{" "}
                        <em className="grad-text">crypto</em>.
                      </h1>
                      <div className="main-content">0 gas/transaction fees</div>
                    </div>
                  </div>
                  <div className="col-12 text-left text-md-center mobile-margin-bott">
                    <button
                      className="starry-btn mt-5"
                      type="button"
                      onClick={handleWaitlistModalShow}
                    >
                      <span className="btn-text">join the wait</span>
                      <div id="container-stars">
                        <div id="stars"></div>
                      </div>

                      <div id="glow">
                        <div className="circle"></div>
                        <div className="circle"></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of main banner section */}

        <section className="comm-section white-color-section story-section">
          {/* <div className="section-overlay-dark"></div> */}
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  {/* <div className="col-12 col-lg-6"></div> */}
                  <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading story-section-heading">
                        not all make the cut.
                        <br />
                        <small>crafted for the creditworthy</small>
                      </h3>
                      <motion.div
                        className="section-content story-section-content"
                        variants={sentence}
                        initial="initial"
                        whileInView="animate"
                        viewport={{ once: true }}
                      >
                        {headingText.split(" ").map((word, key) => {
                          return (
                            <motion.span
                              key={`${word}+${key}`}
                              variants={words}
                            >
                              {word}
                            </motion.span>
                          );
                        })}
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of story section */}

        <section className="mobile-mockups-section">
          <div className="section-overlay-dark"></div>
          <div className="mockup-img-wrapper d-none d-md-block">
            <motion.img
              src={mobileMockups}
              alt=""
              variants={riseUp}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
            />
          </div>
          <div className="mockup-img-wrapper d-block d-md-none">
            <motion.img
              src={mobileMockupsMobile}
              alt=""
              variants={riseUp}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
            />
          </div>
        </section>
        {/* end of mockup section */}

        <section className="comm-section white-color-section rewards-section">
          <div className="section-overlay-dark"></div>
          <div className="container">
            <div className="row align-content-start align-content-md-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading">
                        enjoy special moments more. earn rewards for paying your
                        bills.
                      </h3>

                      <img src={rewardsMobileBg} alt="" className="d-md-none" />

                      <div className="section-content">
                        Choosing <span className="comp-name">ChaChing</span> for
                        your bill payments means earning special rewards like
                        cashback and discounts. Enjoy exclusive products, unique
                        experiences, and great opportunities with every use of{" "}
                        <span className="comp-name">ChaChing</span>. <br />
                        <br /> Unlock a wealth of opportunities with every{" "}
                        <span className="comp-name">ChaChing</span>!
                      </div>
                      <button
                        className="starry-btn me-3"
                        type="button"
                        onClick={handleShowLoginScreen}
                      >
                        <span className="btn-text">explore rewards</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of rewards section */}

        <section className="brand-logo-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="brand-logo-heading">
                  Snag rewards from top brands
                </div>
              </div>
              <div className="col-12 d-flex align-items-center justify-content-center brand-main-wrapper">
                <div className="brand-wrapper">
                  <img src={brandLogos.amazon} alt="" />
                </div>
                <div className="brand-wrapper">
                  <img src={brandLogos.apple} alt="" />
                </div>
                <div className="brand-wrapper">
                  <img src={brandLogos.lego} alt="" />
                </div>
                <div className="brand-wrapper">
                  <img src={brandLogos.marshall} alt="" />
                </div>
                <div className="brand-wrapper">
                  <img src={brandLogos.moltonBrown} alt="" />
                </div>
                <div className="brand-wrapper">
                  <img src={brandLogos.nintendo} alt="" />
                </div>
                <div className="brand-wrapper">
                  <img src={brandLogos.panasonic} alt="" />
                </div>
                <div className="brand-wrapper">
                  <img src={brandLogos.samsung} alt="" />
                </div>
                <div className="brand-wrapper">
                  <img src={brandLogos.target} alt="" />
                </div>
              </div>
              <div className="brand-logo-sub-heading">and more</div>
            </div>
          </div>
        </section>

        <section className="comm-section white-color-section chaching-anywhere-section">
          <div className="section-overlay-dark"></div>

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefChachingAnywhereHome}
              poster={chachingAnywhereHomePoster}
            >
              <source src={chachingAnywhereHome} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-lg-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading">
                        just <span className="comp-name">CHA-CHING</span>{" "}
                        anywhere
                      </h3>

                      <div className="section-content">
                        find us on countless apps and websites, simplifying the
                        process to accept, send, and spend using any dollar or
                        cryptocurrency. think of us as the express lane for your
                        transactions, available however you need us.
                      </div>
                      {/* <img
                        src={chachingAnywhereBgMobile}
                        alt=""
                        className="d-lg-none"
                      /> */}

                      <div className="tab-section mt-5">
                        <Tabs
                          defaultActiveKey="banks"
                          id="useChaChingTabs"
                          className="mt-2"
                        >
                          <Tab eventKey="banks" title="Banks">
                            <div className="vendor-logos-container">
                              <span>
                                <img src={vendorLogos.bbva} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.boa} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.chase} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.citi} alt="" />
                              </span>

                              <span>
                                <img src={vendorLogos.tdBank} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.truist} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.usBank} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.wellsFargo} alt="" />
                              </span>
                            </div>
                          </Tab>

                          <Tab eventKey="wallets" title="Wallets">
                            <div className="vendor-logos-container">
                              <span>
                                <img src={vendorLogos.edge} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.exodus} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.ledger} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.lobstr} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.metamask} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.phantom} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.trustwallet} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.zengo} alt="" />
                              </span>

                              {/* <span>
                                <img src={vendorLogos.xportal} alt="" />
                              </span> */}
                              {/* <span>
                              <img src={vendorLogos.bitcoin} alt="" />
                            </span> */}
                            </div>
                          </Tab>
                          <Tab eventKey="marketPlaces" title="Market Places">
                            <h4>Coming soon</h4>
                            {/* <div className="vendor-logos-container">
                              <span>
                                <img src={vendorLogos.opensea} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.sorare} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.gamestop} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.immutableX} alt="" />
                              </span>
                              <span>
                                <img src={vendorLogos.niftys} alt="" />
                              </span>
                            </div> */}
                          </Tab>
                        </Tabs>
                      </div>

                      <button
                        className="starry-btn me-3 mt-3"
                        type="button"
                        onClick={() => {
                          handleRedirect("checkout-counter");
                        }}
                      >
                        <span className="btn-text">checkout counter</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                      <button
                        className="starry-btn mt-3"
                        type="button"
                        onClick={() => {
                          handleRedirect("contact-sales");
                        }}
                      >
                        <span className="btn-text">become a partner</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>

                      {/* <span className="main-btn me-2">
                        <span></span>
                      </span>
                      <span className="main-btn-2">
                        <span>a</span>
                      </span> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of chaching anywhere section */}

        <section className="mobile-mockups-parallax-section">
          <div className="section-overlay-dark"></div>
        </section>
        {/* end of parallax section */}

        <section className="comm-section white-color-section security-section">
          <div className="container">
            <div className="row align-content-end align-content-lg-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-lg-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading mb-0">
                        security beyond measure. at every step of the way.
                      </h3>

                      <div className="section-content mb-4">
                        and everything that is yours always stays yours.
                      </div>

                      <img
                        src={securityMobileBg}
                        alt=""
                        className="d-md-none"
                      />

                      <div className="section-content mb-2 mb-lg-5">
                        <span className="comp-name">ChaChing</span> ensures the
                        security and encryption of all your transactions and
                        personal information, leaving no room for error.
                      </div>
                      <div className="section-content">
                        <em>
                          switch to <span>anonymity</span>. create an alias.
                        </em>
                      </div>
                      <button
                        className="starry-btn me-3"
                        type="button"
                        onClick={handleShowLoginScreen}
                      >
                        <span className="btn-text">become a member</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of security section */}

        <section className="mobile-mockups-parallax-section">
          <div className="section-overlay-dark"></div>
        </section>
        {/* end of parallax section */}

        <section className="comm-section white-color-section reminders-section">
          {/* <div className="section-overlay-dark"></div> */}

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefRemindersHome}
              poster={remindersHomePoster}
            >
              <source src={remindersHome} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="container">
            <div className="row align-content-start align-content-lg-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-lg-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading">
                        we are serious about your money.
                      </h3>

                      {/* <img
                        src={remindersHumanBg}
                        alt=""
                        className="d-lg-none"
                      /> */}
                      <h3 className="section-heading mb-0 mt-5">
                        <small>to save you trouble...</small>
                      </h3>

                      <div className="section-content">
                        offers reminders for timely payments, rapid settlements
                        to eliminate wait times, and statement analysis to track
                        your spending. you'll never forget a deadline.
                      </div>
                      <button
                        className="starry-btn me-3"
                        type="button"
                        onClick={handleShowLoginScreen}
                      >
                        <span className="btn-text">experience the upgrade</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of reminders section */}

        <section className="mobile-mockups-parallax-section">
          <div className="section-overlay-dark"></div>
        </section>
        {/* end of parallax section */}

        <section className="comm-section store-section">
          <div className="container">
            <div className="row align-content-start align-content-lg-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-lg-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading">
                        for your diverse preferences.
                      </h3>
                      <picture className="d-lg-none">
                        <source srcSet={storeSectionMobileFigureBgWebP} />
                        <img src={storeSectionMobileFigureBg} alt="" />
                      </picture>
                      {/* <img
                        src={storeSectionMobileFigureBg}
                        alt=""
                        className="d-lg-none"
                      /> */}

                      <div className="section-content">
                        unlock access to our exclusive member store, featuring a
                        curated selection of products and experiences tailored
                        to your tastes—all at special prices. this is the
                        epitome of the good life.
                      </div>

                      <button
                        className="starry-btn"
                        type="button"
                        onClick={handleShowLoginScreen}
                      >
                        <span className="btn-text">member-only store</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of store section */}

        <section className="comm-section white-color-section covered-section">
          {/* <div className="section-overlay-dark"></div> */}

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefCoveredHome}
              // poster={coveredHomePoster}
            >
              <source src={coveredHome} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="container">
            <div className="row align-content-end align-content-md-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading">
                        we've got you covered.
                      </h3>

                      <div className="section-content">
                        take charge of your credit card with{" "}
                        <span className="comp-name">ChaChing</span>.. enjoy
                        comprehensive oversight of your spending, identify
                        hidden fees, and monitor your credit ceiling instantly.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of covered section */}

        <FaqsSection />
      </main>
      <Waitlist
        waitlistModalshow={waitlistModalshow}
        handleWaitlistClose={handleWaitlistClose}
        setShowWaitlistThankyou={setShowWaitlistThankyou}
        showWaitlistThankyou={showWaitlistThankyou}
        waitlistErrorMessage={waitlistErrorMessage}
        setWaitlistErrorMessage={setWaitlistErrorMessage}
      />
    </>
  );
};

export default HomeMain;
