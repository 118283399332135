import React from "react";
import { useNavigate } from "react-router-dom";

/* comp imports */

const Careers = () => {
  const navigate = useNavigate();

  const handleDiscoverRedirect = () => {
    navigate("/careers/discover");
  };
  return (
    <>
      <main>
        <section className="comm-section white-color-section speckle-bg careers-main-section">
          <div className="section-overlay-dark above-main-bg"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap text-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        building paradise
                      </h3>

                      <div className="section-content pay-page">
                        join the elite 1% creating the america's most reliable
                        community.
                      </div>
                      <button
                        className="starry-btn"
                        type="button"
                        onClick={handleDiscoverRedirect}
                      >
                        <span className="btn-text">discover</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default Careers;
