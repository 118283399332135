import React, { useEffect, useContext } from "react";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import { UserContext } from "../context/UserContextProvider";

/* img imports */
import coinIconSmall from "../assets/img/coin-icon-small.png";
import currencyIconSmall from "../assets/img/currency-icon-small.png";
import voucherIconSmall from "../assets/img/voucher-icon-small.png";
import candiesIconSmall from "../assets/img/candies-icon-small.png";
import { tiltAnimation } from "./checkoutAnimation";

const RewardBalances = ({ isShoppingPage }) => {
  const {
    currentMoneyBackBalance,
    newMoneyBackBalance,
    userRewards,
    handleGetRewards,
    getUserCognitoData,
    userCognito,
    getLocalUserRewards,
    localUserRewards,
    getUserRewards,
  } = useContext(UserContext);

  useEffect(() => {
    getUserCognitoData();
  }, []);

  useEffect(() => {
    if (userCognito.mobile) {
      getLocalUserRewards();
    }
  }, [userCognito.mobile, userRewards]);

  useEffect(() => {
    if (userCognito.mobile) {
      handleGetRewards(userCognito.mobile, true);
    }
  }, [userCognito.mobile]);

  // console.log("localUserRewards", localUserRewards, userRewards);

  return (
    <>
      {localUserRewards && Object.keys(localUserRewards).length ? (
        <div
          className={`rewards-balances-wrapper ${
            isShoppingPage ? "shopping-balances-wrapper" : ""
          }`}
        >
          <div className="curr-bal-block">
            <span className="img-blk">
              <img
                src={coinIconSmall}
                alt="chaching coins"
                title="chaching coins"
              />
            </span>
            <span className="text-blk">
              <CountUp start={localUserRewards?.coin} end={userRewards?.coin} />
            </span>
          </div>
          <div className="curr-bal-block">
            <motion.span
              className="img-blk"
              variants={tiltAnimation}
              initial="initial"
              animate="animate"
            >
              <img
                src={currencyIconSmall}
                id="moneyBackIcon"
                title="moneyback"
              />
            </motion.span>
            {/* <span className="img-blk">
            <img src={currencyIconSmall} alt="" />
          </span> */}
            <span className="text-blk">
              <CountUp
                start={localUserRewards?.moneyback}
                end={userRewards?.moneyback}
              />
              {/* <CountUp end={newCurrencyAmount} start={currentCurrencyAmount} /> */}
            </span>
          </div>
          <div className="curr-bal-block">
            <span className="img-blk">
              <img src={voucherIconSmall} alt="vouchers" title="vouchers" />
            </span>
            <span className="text-blk">{localUserRewards?.voucher}</span>
          </div>
          <div className="curr-bal-block">
            <span className="img-blk">
              <img src={candiesIconSmall} alt="candies" title="candies" />
            </span>
            <span className="text-blk">{localUserRewards?.candies}</span>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default RewardBalances;
