import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { motion } from "framer-motion";
import { riseUp } from "../data/custom";

/* video imports */
import weAreTheKey from "../assets/img/video-assets/weAreTheKey.mp4";
import paymentsPoweredBy from "../assets/img/video-assets/paymentsPoweredBy.mp4";
import zeroChargebacks from "../assets/img/video-assets/zeroChargebacks.mp4";

/* comp imports */
import FaqsSection from "./FaqsSection";

/* img imports */
import cashRegister from "../assets/img/banner/cash-register.gif";
import imgLineMain from "../assets/img/img-line-main.png";
import imgLineMain2 from "../assets/img/img-line-main-2.png";
import swapCheckoutCounter from "../assets/img/swap-checkout-counter.png";
import sampleDashboardCheckout from "../assets/img/sample-dashboard-checkout.jpg";
import mobileMockupsCheckout from "../assets/img/banner/mobile-mockups-checkout.png";

const CheckoutCounterMain = () => {
  const navigate = useNavigate();
  const videoRefWeAreTheKey = useRef(undefined);
  const videoRefPaymentsPoweredBy = useRef(undefined);
  const videoRefZeroChargebacks = useRef(undefined);
  useEffect(() => {
    videoRefWeAreTheKey.current.defaultMuted = true;
    videoRefPaymentsPoweredBy.current.defaultMuted = true;
    videoRefZeroChargebacks.current.defaultMuted = true;
  });
  const [code, setCode] = useState(`
  /* Actual Code coming soon */
  /* *dummy Code below */
  <div className="Sample-list-wrapper">
    <div
      onClick={handleClose}
      className="Sample-list-close"
    >
      <img src={closeIcon} alt="" />
    </div>
    <div className="filter-list">
      <Form.Group className="mb-4 main-form-group">
        <Form.Control type="text" placeholder="search Sample" />
      </Form.Group>
    </div>
    <div className="Sample-list">
      <SwapSampleList
        SampleLogos={SampleLogos}
        handleSampleSelected={handleSampleSelected}
        selectingFor={selectingFor}
      />
    </div>
  </div>.............
  `);
  const handleRedirect = (path) => {
    if (path) {
      navigate(`/${path}`);
    }
  };
  return (
    <>
      <main>
        <section className="content-section white-color-section speckle-bg extra-section-padding extra-section-margin extra-section-padding-bott extra-section-margin-bott">
          <div className="shape right"></div>
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-lg-5 d-flex align-items-center flex-wrap order-1 order-lg-0">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        Easily process payments with convenience.
                      </h3>

                      <div className="section-content pay-page">
                        supercharge your business with the payment gateway that
                        fuels the biggest brands in the USA, and even make
                        online transactions go{" "}
                        <span className="comp-name">ChaChing</span>!
                      </div>
                      <button
                        className="starry-btn"
                        type="button"
                        onClick={() => {
                          handleRedirect("contact-sales");
                        }}
                      >
                        <span className="btn-text">become family</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7 d-flex align-items-center order-0 order-lg-1 mb-5 mb-lg-0">
                    <div className="checkout-main-img-wrapper">
                      <motion.img
                        src={mobileMockupsCheckout}
                        alt=""
                        variants={riseUp}
                        initial="initial"
                        whileInView="animate"
                        viewport={{ once: false }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 1 */}

        <section className="comm-section white-color-section speckle-bg">
          <div className="section-overlay-dark"></div>

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefPaymentsPoweredBy}
              // poster={everyPaymentCountPoster}
            >
              <source src={paymentsPoweredBy} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <Lottie
              options={defaultOptionsEveryPaymentCount}
              className="lottieVideo"
            /> */}
          </div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        payments powered by{" "}
                        <span className="comp-name">ChaChing</span>
                      </h3>

                      <div className="section-content pay-page">
                        millions of businesses, both small and large, in the USA
                        use <span className="comp-name">ChaChing</span> to
                        effortlessly process payments anywhere, anytime. with a
                        wide array of solutions that lets users pay in any
                        currency and receive in any, catering to all types of
                        merchants, <span className="comp-name">ChaChing</span>{" "}
                        is the go-to choice for seamless transactions.
                      </div>
                      <button
                        className="starry-btn"
                        type="button"
                        onClick={() => {
                          handleRedirect("contact-sales");
                        }}
                      >
                        <span className="btn-text">learn more</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 2 */}

        <section className="comm-section white-color-section speckle-bg height-auto-section">
          <div className="container">
            <div className="row align-content-start align-content-md-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper text-center">
                      <h3 className="section-heading pay-page grid-section-main-heading">
                        business tools to help your business grow
                      </h3>

                      <div className="section-grid-content mt-5">
                        <div className="row">
                          <div className="col-12 glass-card-wrapper">
                            <div
                              data-text="soon"
                              style={{ "--r": "-15" }}
                              className="glass"
                            >
                              <div className="grid-content-head">
                                pos billing software
                              </div>
                              <div className="grid-content-text">
                                warm welcome to smart retail business management
                              </div>
                            </div>
                            <div
                              data-text="soon"
                              style={{ "--r": "5" }}
                              className="glass"
                            >
                              <div className="grid-content-head">
                                <span className="comp-name">ChaChing</span> for
                                businesses
                              </div>
                              <div className="grid-content-text">
                                everything you need to manage your business on
                                your phone
                              </div>
                            </div>
                            <div
                              data-text="soon"
                              style={{ "--r": "25" }}
                              className="glass"
                            >
                              <div className="grid-content-head">
                                advertise on{" "}
                                <span className="comp-name">ChaChing</span>
                              </div>
                              <div className="grid-content-text">
                                grow your business by advertising on largest
                                mobile business
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 3 */}

        <section className="comm-section white-color-section speckle-bg height-auto-section">
          <div className="shape right"></div>

          <div className="container">
            <div className="row align-content-start align-content-md-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper text-center">
                      <h3 className="section-heading pay-page grid-section-main-heading">
                        Boost your revenue with the best in multiverse
                        <small>
                          <span className="comp-name">ChaChing</span>'s business
                          accounts are perfect for:
                        </small>
                      </h3>

                      <div className="section-grid-content mt-5">
                        <div className="row gradient-bg-text-row">
                          <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 d-flex justify-content-center align-items-start flex-wrap">
                            <div className="grid-content-text card">
                              warm welcome to smart retail business management.
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 d-flex justify-content-center align-items-start flex-wrap">
                            <div className="grid-content-text card">
                              professional traders receiving payouts in multiple
                              currencies.
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 d-flex justify-content-center align-items-start flex-wrap">
                            <div className="grid-content-text card">
                              teams or individual professionals in the u.s.
                              doing $50k+ transactions daily.
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0 d-flex justify-content-center align-items-start flex-wrap">
                            <div className="grid-content-text card">
                              real estate businesses with investments in crypto.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 4 */}

        <section className="comm-section white-color-section speckle-bg height-auto-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start align-content-md-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap text-center mb-5">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        Here's a small piece of code to accelerate the growth of
                        your business rapidly.
                      </h3>

                      <div className="section-content pay-page">
                        Our solutions can be integrated into your app or website
                        in 10 minutes — see for yourself.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 sample-swap-code-editor-col">
                    <CodeEditor
                      value={code}
                      language="js"
                      placeholder="Please enter JS code."
                      onChange={(evn) => setCode(evn.target.value)}
                      padding={15}
                      // data-color-mode="dark"
                      style={{
                        // backgroundColor: "#f5f5f5",
                        fontFamily:
                          "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="sample-swap-img-wrapper">
                      <img src={swapCheckoutCounter} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 5 */}

        <section className="comm-section white-color-section height-auto-section">
          <img src={imgLineMain} alt="" className="img-line" />
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row glass-frame-row">
                  <div className="col-12 col-lg-6 d-flex align-items-center flex-wrap text-center justify-content-center">
                    <div className="section-grid-content card">
                      <div className="content-video-wrapper">
                        <video
                          preload="true"
                          muted
                          autoPlay
                          loop
                          playsInline
                          ref={videoRefWeAreTheKey}
                          // poster={payRewardsVideoPoster}
                        >
                          <source src={weAreTheKey} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>

                      <div className="grid-content-head">
                        we are the key to your lock. seamlessly integrating with
                        your codebase.
                      </div>
                      <button
                        className="starry-btn"
                        type="button"
                        onClick={() => {
                          handleRedirect("contact-sales");
                        }}
                      >
                        <span className="btn-text">get started</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 d-flex align-items-center flex-wrap text-center justify-content-center">
                    <div className="section-grid-content card">
                      <div className="grid-content-head">
                        you're always on the drivers seat with our dashboard.
                      </div>
                      <div className="dashboard-img-wrapper">
                        <img
                          src={sampleDashboardCheckout}
                          alt=""
                          className="mt-0 mb-0"
                        />
                      </div>
                      <button
                        className="starry-btn mt-2"
                        type="button"
                        onClick={() => {
                          handleRedirect("contact-sales");
                        }}
                      >
                        <span className="btn-text">access dashboard</span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 6 */}

        <section className="comm-section white-color-section speckle-bg zero-charge-backs-section">
          <div className="section-overlay-dark"></div>

          <div className="lottie-video-wrapper">
            <video
              preload="true"
              muted
              autoPlay
              loop
              playsInline
              ref={videoRefZeroChargebacks}
              // poster={everyPaymentCountPoster}
            >
              <source src={zeroChargebacks} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <Lottie
              options={defaultOptionsEveryPaymentCount}
              className="lottieVideo"
            /> */}
          </div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap text-center mb-5">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        Zero worries, zero chargebacks - that's our promise.
                      </h3>

                      <div className="section-content pay-page">
                        Safeguard your business from chargebacks and fraud with
                        our reliable card processing solution, ensuring
                        worry-free transactions every time.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 7 */}

        <section className="comm-section white-color-section speckle-bg height-auto-section">
          <img src={imgLineMain} alt="" className="img-line" />
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row e-card-row">
                  {/* <div className="col-12 col-lg-6 mb-4 mb-lg-0 order-1 order-lg-0">
                    <div className="e-card playing">
                      <div className="image"></div>

                      <div className="wave"></div>
                      <div className="wave"></div>
                      <div className="wave"></div>

                      <div className="infotop">
                        <div className="section-grid-content">
                          <img
                            src=""
                            width="100%"
                            height="200"
                            alt=""
                            className="mb-3"
                          />
                          <div className="grid-content-head mb-3">
                            get setup in 10 minutes
                          </div>
                          <div className="grid-content-text">
                            Our test setup and instructions make it super simple
                            to connect with{" "}
                            <span className="comp-name">ChaChing</span>.
                          </div>
                          <button className="starry-btn" type="button">
                            <span className="btn-text">develop with us</span>
                            <div id="container-stars">
                              <div id="stars"></div>
                            </div>

                            <div id="glow">
                              <div className="circle"></div>
                              <div className="circle"></div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-lg-6 offset-lg-3 d-flex align-items-center flex-wrap text-center justify-content-center order-0 order-lg-1">
                    <div className="section-grid-content">
                      <div className="grid-content-head">
                        check out in under 10s
                      </div>

                      <img
                        src={cashRegister}
                        alt=""
                        className="cash-register"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 8 */}

        <section className="comm-section white-color-section speckle-bg height-auto-section">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start align-content-md-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap text-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        <span className="comp-name">ChaChing</span> was designed
                        to go beyond borders payments
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 bg-popup-card-col section-grid-content">
                    <div className="card">
                      <span className="card-number">5</span>
                      <span className="card-text grid-content-head">
                        supported crypto currencies
                      </span>
                    </div>
                    <div className="card">
                      <span className="card-number">2</span>
                      <span className="grid-content-head">
                        supported fiat currencies
                      </span>
                    </div>
                    <div className="card">
                      <span className="card-number">2</span>
                      <span className="grid-content-head">
                        supported countries
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section 9 */}

        <FaqsSection />
      </main>
    </>
  );
};

export default CheckoutCounterMain;
