import React from "react";

const AvailableWalletList = () => {
  return (
    <>
      <div className="select" tabIndex="1">
        <input
          className="selectopt"
          name="selectedAccount"
          type="radio"
          id="opt1"
          defaultChecked
        />
        <label htmlFor="opt1" className="option">
          <span>bc1t************86ns</span>
          <span>eth 15.80</span>
        </label>
        <input
          className="selectopt"
          name="selectedAccount"
          type="radio"
          id="opt2"
        />
        <label htmlFor="opt2" className="option">
          <span>34tg************wevw</span>
          <span>eth 20.80</span>
        </label>
        <input
          className="selectopt"
          name="selectedAccount"
          type="radio"
          id="opt3"
        />
        <label htmlFor="opt3" className="option">
          <span>df34************adca</span>
          <span>eth 30.55</span>
        </label>
        <input
          className="selectopt"
          name="selectedAccount"
          type="radio"
          id="opt4"
        />
        <label htmlFor="opt4" className="option">
          <span>dfbd************dfnf</span>
          <span>eth 40.70</span>
        </label>
        <input
          className="selectopt"
          name="selectedAccount"
          type="radio"
          id="opt5"
        />
        <label htmlFor="opt5" className="option">
          <span>asfc************adsd</span>
          <span>eth 50.80</span>
        </label>
      </div>
    </>
  );
};

export default AvailableWalletList;
