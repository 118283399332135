import React, { createContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { endPoints, baseUrl } from "../data/endPoints";

export const UserContext = createContext(null);

const UserContextProvider = ({ swapModalshow, children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [isTopBarActive, setIsTopBarActive] = useState(true);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [isMenuActive, setIsMenuActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [pathName, setPathName] = useState("");
  const [currentMoneyBackBalance, setCurrentMoneyBackBalance] = useState(73);
  const [newMoneyBackBalance, setNewMoneyBackBalance] = useState(73);
  const [userRewards, setUserRewards] = useState({});
  const [localUserRewards, setLocalUserRewards] = useState({});
  const [userCognito, setUserCognito] = useState({});

  useEffect(() => {
    setPathName(location.pathname);
    setIsDesktopLoginActive(false);
    setIsMenuActive(false);
    let hamburgerIcon = document.querySelector("#mobileHamburgerIcon");
    if (hamburgerIcon) {
      hamburgerIcon.checked = false;
    }
  }, [location]);

  useEffect(() => {
    if (swapModalshow) {
      setIsDesktopLoginActive(false);
      setIsMenuActive(false);
      let hamburgerIcon = document.querySelector("#mobileHamburgerIcon");
      if (hamburgerIcon) {
        hamburgerIcon.checked = false;
      }
    }
  }, [swapModalshow]);

  const handleCloseTopBar = () => {
    setIsTopBarActive(false);
  };

  const [isDesktopLoginActive, setIsDesktopLoginActive] = useState(false);
  const handleShowLoginScreen = () => {
    setIsDesktopLoginActive(!isDesktopLoginActive);
  };

  const checkIsLoggedIn = () => {
    let tempIsLoggedIn = localStorage.getItem("loggedIn");
    if (tempIsLoggedIn != undefined) {
      if (tempIsLoggedIn) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }
  };

  const getUserCognitoData = () => {
    let tempCognito = localStorage.getItem("userCognito");
    if (tempCognito != undefined) {
      if (tempCognito) {
        setUserCognito(JSON.parse(tempCognito));
      } else {
        setUserCognito({});
      }
    }
  };

  const getLocalUserRewards = () => {
    let tempCognito = localStorage.getItem("userCognito");
    if (tempCognito != undefined) {
      let tempCognitoParsed = JSON.parse(tempCognito);
      if (tempCognitoParsed) {
        setLocalUserRewards(tempCognitoParsed.rewards);
      }
    }
  };

  const getUserRewards = () => {
    let tempUserRewards = localStorage.getItem("rewards");
    if (tempUserRewards != undefined) {
      let tempUserRewardsParsed = JSON.parse(tempUserRewards);
      if (tempUserRewardsParsed) {
        setUserRewards(tempUserRewardsParsed);
      }
    }
  };

  const confirmLoginHandler = () => {
    localStorage.setItem("loggedIn", true);
    setIsDesktopLoginActive(false);
    setIsMenuActive(false);
    setOtpValue("");
    setShowOtpBox(false);
    let hamburgerIcon = document.querySelector("#mobileHamburgerIcon");
    if (hamburgerIcon) {
      hamburgerIcon.checked = false;
    }
    checkIsLoggedIn();
    navigate("/personal");
  };

  const displayOtpBox = () => {
    setShowOtpBox(true);
  };

  /* logout api call  */

  let logoutConfig = {
    method: "post",
    url: `${baseUrl}${endPoints.logout}`,
    withCredentials: true,
  };

  const deleteUserCookieData = async () => {
    try {
      const response = await axios.request(logoutConfig);
      if (response.data.status) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLoginData = () => {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("userCognito");
    localStorage.removeItem("rewards");
    setIsLoggedIn(false);
    deleteUserCookieData();
    setTimeout(() => {
      navigate("/");
    }, 3000);
  };

  /* reward system start */

  const deductStandardCoin = () => {
    let tempRewards = JSON.parse(localStorage.getItem("rewards"));
    if (tempRewards) {
      tempRewards.coin = tempRewards.coin - 1000;
      localStorage.setItem("rewards", JSON.stringify(tempRewards));
      setUserRewards(tempRewards);
    }
  };

  const handleGetRewards = (mobile, isLocalMainUpdate) => {
    let configGetRewards = {
      method: "post",
      url: `${baseUrl}${endPoints.getRewards}`,
      data: { mobile: mobile },
      withCredentials: true,
    };

    const getUserRewardsData = async () => {
      try {
        const response = await axios.request(configGetRewards);
        if (response.data.status) {
          if (response.data.data && Object.keys(response.data.data).length) {
            setUserRewards(response.data.data);
            /* adding rewards into local storage */
            localStorage.setItem(
              "rewards",
              JSON.stringify({
                coin: response.data.data.coin,
                candies: response.data.data.candies,
                moneyback: response.data.data.moneyback,
                voucher: response.data.data.voucher,
              })
            );

            if (isLocalMainUpdate) {
              let tempCognito = localStorage.getItem("userCognito");
              if (tempCognito != undefined) {
                let tempCognitoParsed = JSON.parse(tempCognito);
                if (tempCognitoParsed) {
                  tempCognitoParsed.rewards = {
                    coin: response.data.data.coin,
                    candies: response.data.data.candies,
                    moneyback: response.data.data.moneyback,
                    voucher: response.data.data.voucher,
                  };
                  localStorage.setItem(
                    "userCognito",
                    JSON.stringify(tempCognitoParsed)
                  );
                }
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUserRewardsData();
  };

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isTopBarActive,
        handleCloseTopBar,
        confirmLoginHandler,
        setIsDesktopLoginActive,
        isDesktopLoginActive,
        handleShowLoginScreen,
        showOtpBox,
        otpValue,
        setOtpValue,
        displayOtpBox,
        isMenuActive,
        setIsMenuActive,
        checkIsLoggedIn,
        deleteLoginData,
        currentMoneyBackBalance,
        setCurrentMoneyBackBalance,
        newMoneyBackBalance,
        setNewMoneyBackBalance,
        userRewards,
        handleGetRewards,
        pathName,
        userCognito,
        getUserCognitoData,
        getLocalUserRewards,
        localUserRewards,
        getUserRewards,
        deductStandardCoin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
