import React, { useState } from "react";
import Cards from "react-credit-cards-2";
import Form from "react-bootstrap/Form";

import "react-credit-cards-2/dist/lib/styles.scss";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./creditCardUtils";

const CreditCardForm = ({ creditCardInfo, setCreditCardInfo }) => {
  const [fieldErrorMessage, setFieldErrorMessage] = useState("");

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setCreditCardInfo((prev) => ({ ...prev, issuer: issuer }));
    }
  };

  const handleInputFocus = (evt) => {
    setCreditCardInfo((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const validateTextField = (text) => {
    var pattern = /^[^0-9][a-zA-Z\s]*$/;
    return pattern.test(text);
  };

  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    const { name, value } = target;

    if (target.name === "name") {
      let result = validateTextField(target.value);

      if (result) {
        setFieldErrorMessage("");
        setCreditCardInfo((prev) => ({ ...prev, [name]: value }));
      } else {
        setFieldErrorMessage("Not a valid value!");
        setCreditCardInfo((prev) => ({ ...prev, name: "" }));
      }
    } else {
      setCreditCardInfo((prev) => ({ ...prev, [name]: value }));
    }
  };

  // console.log("creditCardInfo", creditCardInfo);

  return (
    <div>
      <Cards
        number={creditCardInfo.number}
        name={creditCardInfo.name}
        expiry={creditCardInfo.expiry}
        cvc={creditCardInfo.cvc}
        focused={creditCardInfo.focus}
        callback={handleCallback}
      />
      <div className="main-cc-form-wrapper">
        <Form.Group className="mb-3 main-form-group">
          <Form.Control
            type="tel"
            name="number"
            placeholder="Card Number"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            pattern="[\d| ]{16,22}"
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="main-form-group">
          <Form.Control
            type="text"
            name="name"
            className="form-control"
            placeholder="Name"
            maxLength="25"
            required
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            autoComplete="off"
          />
          <span className="error-text mb-3">{fieldErrorMessage}</span>
        </Form.Group>
        <div className="row">
          <div className="col-6">
            <Form.Group className="mb-3 main-form-group">
              <Form.Control
                type="tel"
                name="expiry"
                className="form-control"
                placeholder="Valid Thru"
                pattern="\d\d/\d\d"
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                autoComplete="off"
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group className="mb-3 main-form-group">
              <Form.Control
                type="tel"
                name="cvc"
                className="form-control"
                placeholder="CVC"
                pattern="\d{3,4}"
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                autoComplete="off"
              />
            </Form.Group>
          </div>
        </div>

        <input type="hidden" name="issuer" value={creditCardInfo.issuer} />
      </div>
    </div>
  );
};

export default CreditCardForm;
