import React, { useEffect, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import { UserContext } from "../context/UserContextProvider";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, endPoints } from "../data/endPoints";

/* img imports */
import mainLogoWhite from "../assets/img/logo/logo-white.svg";
import mainLogoFooter from "../assets/img/logo/main-logo-site-foot.png";
import imgLineMain from "../assets/img/img-line-main.png";
import playStoreIcon from "../assets/img/icons/play-store-icon.png";
import appStoreIcon from "../assets/img/icons/app-store-icon.png";
import fbIcon from "../assets/img/icons/fb-icon.png";
import instaIcon from "../assets/img/icons/insta-icon.png";
import linkedInIcon from "../assets/img/icons/linked-in-icon.png";
import threadsIcon from "../assets/img/icons/threads-icon.png";
import twitterXIcon from "../assets/img/icons/twitter-x-icon.png";
import diamondAppIcon from "../assets/img/icons/diamond-app-icon.png";

const Footer = ({ isRewardPage, handleSwapModalShow }) => {
  const { isLoggedIn, handleShowLoginScreen, checkIsLoggedIn } =
    useContext(UserContext);
  useEffect(() => {
    checkIsLoggedIn();
  }, [checkIsLoggedIn]);
  // console.log("isLoggedIn", isLoggedIn);

  const navigate = useNavigate();

  const handleHomePageRedirect = () => {
    if (isLoggedIn) {
      navigate("/personal");
    } else {
      navigate("/");
    }
  };

  const [subscribeMainData, setSubscribeMainData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showSubscribeThankyou, setShowSubscribeThankyou] = useState(false);

  const subscribeMainDataDefault = {
    email: "",
    unsubscribeNewsletter: "yes",
  };

  useEffect(() => {
    setSubscribeMainData(subscribeMainDataDefault);
  }, []);

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (name === "unsubscribeNewsletter") {
      let checked = e.target.checked;
      setSubscribeMainData((subscribeMainData) => ({
        ...subscribeMainData,
        [name]: checked ? "yes" : "no",
      }));
    } else {
      setSubscribeMainData((subscribeMainData) => ({
        ...subscribeMainData,
        [name]: value,
      }));
    }
  };

  const validateEmail = (email) => {
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;
    return regex.test(email);
  };

  let configSubscribe = {
    method: "post",
    url: `${baseUrl}${endPoints.newsletter}`,
    headers: {},
    data: subscribeMainData,
  };

  const subscribeNewsletter = async () => {
    try {
      setErrorMessage("");
      let emailValidated = validateEmail(subscribeMainData.email);
      if (emailValidated) {
        const response = await axios.request(configSubscribe);
        if (response && response.data.status) {
          setShowSubscribeThankyou(true);
        } else {
          console.log(response.data.message);
          setErrorMessage("Please try again later");
        }
      } else {
        setErrorMessage("Please enter a valid email id");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Email already exist or cannot be added");
    }
  };

  return (
    <>
      <footer>
        <div className="footer-section">
          <div className="shape right"></div>
          <img src={imgLineMain} alt="" className="img-line" />

          <div className="container">
            <div
              className={`row ${isRewardPage && "d-none"} ${
                isLoggedIn && "d-none"
              }`}
            >
              <div className="col-12 col-lg-5">
                <h4 className="footer-title">
                  {showSubscribeThankyou
                    ? "thank you for subscribing to our newsletter!"
                    : "subscribe to our newsletter"}
                </h4>
                {!showSubscribeThankyou ? (
                  <div className="footer-content">
                    <div className="footer-form">
                      <Form.Group
                        className="mb-3 main-form-group"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="email"
                          placeholder="enter email here"
                          required
                          onChange={inputsHandler}
                          name="email"
                        />
                      </Form.Group>
                      <span className="error-text">{errorMessage}</span>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="unsubscribeNewsletter"
                          required
                          defaultChecked
                          onClick={inputsHandler}
                          name="unsubscribeNewsletter"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="unsubscribeNewsletter"
                        >
                          Un-check this box to stop receiving communications
                          from <span className="comp-name">ChaChing</span>. You
                          can unsubscribe at any time. We look after your data -
                          see our <Link to="/legal">legal document</Link>.
                        </label>
                      </div>
                      <div>
                        <button
                          className="starry-btn mt-3"
                          type="button"
                          onClick={subscribeNewsletter}
                        >
                          <span className="btn-text">submit</span>
                          <div id="container-stars">
                            <div id="stars"></div>
                          </div>

                          <div id="glow">
                            <div className="circle"></div>
                            <div className="circle"></div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-lg-7 mt-5 mt-lg-0 d-flex justify-content-between">
                <div className="app-coming-soon">
                  <div>
                    <h3 className="main-sub-heading">soon!</h3>

                    <div className="main-icon-wrapper">
                      <span>
                        <img src={appStoreIcon} alt="" />
                      </span>
                      <span>
                        <img src={playStoreIcon} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 footer-links-col">
                <div className="footer-links-wrapper">
                  <h4 className="footer-title">product</h4>
                  <div className="footer-content">
                    <ul className="link-list">
                      {isLoggedIn ? (
                        <li>
                          <Link to="/personal">bill payments</Link>
                        </li>
                      ) : (
                        <li>
                          <span onClick={handleShowLoginScreen}>
                            bill payments
                          </span>
                        </li>
                      )}
                      {!isLoggedIn && (
                        <li>
                          <Link to="/checkout-counter">checkout counter</Link>
                        </li>
                      )}

                      <li>
                        <Link to="/concierge">concierge</Link>
                      </li>
                      {isLoggedIn ? (
                        <>
                          <li>
                            <Link to="/shopping">shopping</Link>
                          </li>
                          <li>
                            <span onClick={handleSwapModalShow}>swap</span>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <span onClick={handleShowLoginScreen}>
                              shopping
                            </span>
                          </li>
                          <li>
                            <span onClick={handleShowLoginScreen}>swap</span>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="footer-links-wrapper">
                  <h4 className="footer-title">company</h4>
                  <div className="footer-content">
                    <ul className="link-list">
                      <li>
                        <Link to="/about-us">about us</Link>
                      </li>
                      <li>
                        <a href="/careers" target="_blank">
                          careers
                        </a>
                      </li>
                      <li>
                        <Link to="/investor-relations">investor relations</Link>
                      </li>
                      <li>
                        <Link to="/log">log (new updates)</Link>
                      </li>
                      <li>
                        <Link to="/newsroom">newsroom</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-links-wrapper">
                  <h4 className="footer-title">support</h4>
                  <div className="footer-content">
                    <ul className="link-list">
                      <li>
                        <Link to="/contact">contact</Link>
                      </li>
                      <li>
                        <Link to="/documents">documents</Link>
                      </li>
                      <li>
                        <Link to="/help">help</Link>
                      </li>

                      <li>
                        <Link to="/refund-form">refund form</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-links-wrapper">
                  <h4 className="footer-title">policies</h4>
                  <div className="footer-content">
                    <ul className="link-list">
                      <li>
                        <Link to="/legal">legal</Link>
                      </li>
                      <li>
                        <Link to="/security">security</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-links-wrapper">
                  <h4 className="footer-title">social</h4>
                  <div className="footer-content">
                    <div className="social-profile">
                      <ul className="social-link">
                        <li>
                          <a
                            href="https://www.facebook.com/profile.php?id=61560915095156"
                            target="_blank"
                          >
                            <img src={fbIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/chaching.club"
                            target="_blank"
                          >
                            <img src={instaIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="https://x.com/chachingclub/" target="_blank">
                            <img src={twitterXIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/chachingclub/"
                            target="_blank"
                          >
                            <img src={linkedInIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://diamondapp.com/u/ChaChing"
                            target="_blank"
                          >
                            <img src={diamondAppIcon} alt="" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.threads.net/@chaching.club"
                            target="_blank"
                          >
                            <img src={threadsIcon} alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 copyright-col">
              <div className="footer-logo" onClick={handleHomePageRedirect}>
                <span>
                  {" "}
                  <img src={mainLogoFooter} alt="Logo" />
                </span>
              </div>
              <h6 className="text foot-copyright-text">
                Copyright 2024{" "}
                <span className="comp-name">PeakPurse Technologies Inc.</span>{" "}
                All rights reserved.
              </h6>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
