import React from "react";

/* comp imports */
import MainWrapper from "../components/MainWrapper";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";
import LegalMain from "../components/LegalMain";

const Legal = () => {
  return (
    <>
      <LegalMain />
    </>
  );
};

export default Legal;
