import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";

/* img imports */
import noImage from "../../assets/img/noImage.jpg";

const ImagesSlider = ({ images }) => {
  const [imgBaseUrl, setImgBaseUrl] = useState(
    "https://www.everythingstore.io/static/www/images/productImages/370x370/"
  );

  const [sliderInterval, setSliderInterval] = useState(0);

  const getRandomValue = () => {
    return Math.floor(Math.random() * (4000 - 2000 + 1)) + 2000;
  };

  useEffect(() => {
    let randomValue = getRandomValue();
    setSliderInterval(randomValue);
  }, []);

  return (
    <Carousel
      autoPlay={true}
      interval={sliderInterval}
      controls={false}
      indicators={false}
      //   pause={false}
    >
      {images.length ? (
        images.map((imgVal, index) => {
          return (
            <Carousel.Item key={index}>
              <img src={`${imgBaseUrl}${imgVal}`} />
            </Carousel.Item>
          );
        })
      ) : (
        <Carousel.Item>
          <img src={noImage} />
        </Carousel.Item>
      )}
    </Carousel>
  );
};

export default ImagesSlider;
