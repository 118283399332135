import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { HashLink } from "react-router-hash-link";

/* video imports */
import checkYourCreditScore from "../assets/img/video-assets/checkYourCreditScore.mp4";

/* comp imports */
import FaqsSection from "./FaqsSection";
import { vendorLogos } from "./vendorLogos";

/* img imports */
import cashRegister from "../assets/img/banner/cash-register.gif";
import chachingLogoText from "../assets/img/logo/chaching-logo-text.png";
import chachingAnywhereBgMobile from "../assets/img/banner/chaching-anywhere-bg-mobile.png";
import imgLineMain from "../assets/img/img-line-main.png";
import imgLineMain2 from "../assets/img/img-line-main-2.png";

const CheckCreditScoreMain = () => {
  const videoRefCheckYourCreditScore = useRef(undefined);

  useEffect(() => {
    videoRefCheckYourCreditScore.current.defaultMuted = true;
  });

  return (
    <>
      <main>
        <section className="comm-section white-color-section speckle-bg credit-score-section height-auto-section">
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap order-1 order-md-0 mt-5 mb-mt-0">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        peek at your credit score, on us!
                      </h3>

                      <div className="section-content pay-page">
                        snap a quick look at your score and see how your
                        finances stack up.
                      </div>
                      <button className="starry-btn me-3" type="button">
                        <span className="btn-text">
                          <HashLink smooth to="/check-credit-score#cScoreForm">
                            check your credit score
                          </HashLink>
                        </span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 order-0 order-md-1">
                    <div className="lottie-video-wrapper-content">
                      <video
                        preload="true"
                        muted
                        autoPlay
                        loop
                        playsInline
                        ref={videoRefCheckYourCreditScore}
                        // poster={everyPaymentCountPoster}
                      >
                        <source src={checkYourCreditScore} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      {/* <Lottie
                        options={defaultOptionsCheckCreditScore}
                        className="lottieVideo"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        <section
          className="comm-section white-color-section speckle-bg"
          id="cScoreForm"
        >
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 col-xl-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <div className="form-wrapper">
                        <div className="form-title">
                          share your story with us
                        </div>
                        <div className="form-sub-title">
                          we'll use your details just to check your credit score
                          with the official bureau, promise.
                        </div>
                        <div className="form-group-wrapper d-flex">
                          <Form.Group className="mb-3 main-form-group">
                            <Form.Control
                              type="text"
                              placeholder="first name"
                              required
                            />
                          </Form.Group>
                          <Form.Group className="mb-3 main-form-group">
                            <Form.Control
                              type="text"
                              placeholder="last name"
                              required
                            />
                          </Form.Group>
                          <Form.Group className="mb-3 main-form-group">
                            <Form.Control
                              type="text"
                              placeholder="phone number"
                              required
                            />
                          </Form.Group>
                          <Form.Group className="mb-3 main-form-group">
                            <Form.Control
                              type="email"
                              placeholder="email address"
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="creditScoreCheckbox"
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="creditScoreCheckbox"
                          >
                            send me updates on my credit report on message
                          </label>
                        </div>
                        <div>
                          <button className="starry-btn mt-3" type="button">
                            <span className="btn-text">coming soon</span>
                            <div id="container-stars">
                              <div id="stars"></div>
                            </div>

                            <div id="glow">
                              <div className="circle"></div>
                              <div className="circle"></div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section form */}

        <section className="content-section white-color-section speckle-bg">
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <div className="content-head mt-0">
                        credit score checker
                      </div>
                      <p>
                        the credit score checker allows you to calculate your
                        credit score report. it is a three-digit numeric
                        expression that represents your creditworthiness.
                      </p>
                      <p>
                        if you are planning to be a member, then it is
                        recommended to have your credit score be at least 750 as
                        per transunion or experian. reviewing the history of
                        credit of the applicant is an essential part of the
                        screening process.
                      </p>
                      <div className="content-head">
                        what is a credit score and how is it calculated?
                      </div>
                      <p>
                        a credit score is a statistical representation of one's
                        creditworthiness. it helps in evaluating your ability to
                        pay back the amount you have borrowed. a person's credit
                        score usually ranges from 300-850, and the one with the
                        highest score is considered to be a trustworthy
                        applicant. always try to reach the highest in range as
                        it becomes very beneficial at the time of applying for a
                        loan or a credit card. whereas, if you have a low score
                        or you fall in lower range this displays you are an
                        irresponsible loan applicant and have not made timely
                        payments of your loans/dues.
                      </p>

                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th width="180">credit score range</th>
                              <th>what it means</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>300-579</td>
                              <td>
                                <strong>poor:</strong> this range is considered
                                very risky by lenders. it suggests a history of
                                credit payments that have been missed or late,
                                and the individual might find it difficult to
                                get approved for new credit.
                              </td>
                            </tr>
                            <tr>
                              <td>580-669</td>
                              <td>
                                <strong>fair:</strong> this score range is below
                                the average score of U.S consumers. while
                                individuals might still get approved for credit,
                                the interest rates may be higher than those with
                                good credit scores.
                              </td>
                            </tr>
                            <tr>
                              <td>670-739</td>
                              <td>
                                <strong>good:</strong> this score is near or
                                slightly above the average of U.S. consumers and
                                indicates a relatively low risk to lenders.
                                Individuals with scores in this range are likely
                                to be approved for credit at competitive
                                interest rates.
                              </td>
                            </tr>
                            <tr>
                              <td>740-799</td>
                              <td>
                                <strong>very good:</strong> scores in this range
                                are above the average of U.S consumers and
                                indicate an individual is very reliable. lenders
                                will likely offer lower interest rates.
                              </td>
                            </tr>
                            <tr>
                              <td>800-850</td>
                              <td>
                                <strong>exceptional:</strong> this is an
                                excellent score range and suggests an individual
                                is an exceptional borrower. people with scores
                                in this range usually experience easy approvals
                                and the best interest rates.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="content-head">
                        credit bureaus collect this information for calculating
                        a credit score, which includes
                      </div>
                      <p>
                        personal information <br />
                        <a href="/newsroom/check-credit-score/no-credit-score-history-heres-how-to-build-one">
                          credit history
                        </a>{" "}
                        length <br />
                        new credit <br />
                        some common factors that impact credit score are:
                      </p>
                      <ul>
                        <li>amounts owed</li>
                        <li>payment history</li>
                        <li>credit mix</li>
                      </ul>
                      <div className="content-head">
                        maintaining a good credit score is essential because:
                      </div>
                      <ul>
                        <li>
                          it makes getting loans easier: a strong credit score
                          signals to lenders that you're responsible with money,
                          making it more likely for you to secure loans swiftly.
                        </li>
                        <li>
                          speeds up loan processing: a high credit score often
                          leads to pre-approved loan offers and faster
                          approvals, as it reflects a solid credit history.
                        </li>
                        <li>
                          saves on interest: a better credit score typically
                          qualifies you for lower interest rates, reducing the
                          cost of borrowing.
                        </li>
                        <li>
                          unlocks better credit card offers: with a high credit
                          score, you'll have access to credit cards with more
                          perks and rewards.
                        </li>
                        <li>
                          increases credit card limits: demonstrating
                          creditworthiness with a high score can result in
                          access to credit cards with higher limits, giving you
                          more financial flexibility.
                        </li>
                      </ul>
                      <div className="content-head">
                        calculating a credit score in the u.s. involves looking
                        at:
                      </div>
                      <p>
                        <strong>payment history (35%):</strong> this is a record
                        of how consistently you pay your bills. on-time payments
                        boost your score, while missed payments can harm it.
                      </p>
                      <p>
                        <strong>amounts owed (30%):</strong> this is the total
                        amount of credit and loans you're using compared to your
                        total credit limit, also known as your credit
                        utilization ratio. lower utilization usually means a
                        higher score.
                      </p>
                      <p>
                        <strong>length of credit history (15%):</strong> a
                        longer credit history can lead to a higher score, as it
                        provides more data on your spending habits.
                      </p>
                      <p>
                        <strong> credit mix (10%):</strong> this reflects the
                        variety of credit products you have, such as credit
                        cards, student loans, and mortgages. a diverse mix can
                        benefit your score.
                      </p>
                      <p>
                        <strong>new credit (10%):</strong> opening several
                        credit accounts in a short time can represent greater
                        risk, especially for people who don't have a long credit
                        history.
                      </p>
                      <p>
                        a good credit score in the u.s. is typically considered
                        to be around 680 or higher. lenders use this, along with
                        your credit report, employment status, and other
                        financial details, to decide on your loan application.
                        credit health insurance can protect you if an illness or
                        disability prevents you from repaying debts.
                      </p>
                      <p className="mt-4">
                        A credit score is essential because it reflects how
                        reliable you are as a borrower. It's a number typically
                        between 300 and 850 that summarizes your credit history.
                        A higher score can open doors to better loan terms and
                        rates.
                      </p>

                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th width="50%">credit score range</th>
                              <th>what it means</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>300-579</td>
                              <td>
                                <strong>poor</strong>
                              </td>
                            </tr>
                            <tr>
                              <td>580-669</td>
                              <td>
                                <strong>fair</strong>
                              </td>
                            </tr>
                            <tr>
                              <td>670-739</td>
                              <td>
                                <strong>good</strong>
                              </td>
                            </tr>
                            <tr>
                              <td>740-799</td>
                              <td>
                                <strong>very good</strong>
                              </td>
                            </tr>
                            <tr>
                              <td>800-850</td>
                              <td>
                                <strong>exceptional</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="content-head">
                        A credit score of 680 or higher is seen as favorable.
                        Here's why it's good to have a solid credit score:
                      </div>
                      <ul>
                        <li>
                          It makes you a strong candidate for credit cards.
                        </li>
                        <li>Loans tend to get approved faster and easier.</li>
                        <li>
                          A good credit score often means less interest on
                          loans.
                        </li>
                        <li>You might qualify for a larger loan. </li>
                        <li>Pre-approved loans could be an option. </li>
                        <li>
                          Sometimes, you can snag lower fees or waived charges.
                        </li>
                        <li>
                          Landlords may approve rentals more quickly, trusting
                          your financial reliability.
                        </li>
                        <li>
                          A good credit score may secure you better car
                          insurance rates; with a lower score, you'd likely pay
                          more.
                        </li>
                      </ul>
                      <div className="content-head">
                        Improving your credit score is straightforward:
                      </div>
                      <ul>
                        <li>Always pay your loans and bills on time.</li>
                        <li>Don't max out your credit cards.</li>
                        <li>Settle any lingering debts.</li>
                        <li>Apply for new credit sparingly.</li>
                      </ul>
                      <div className="content-head">
                        Checking your credit score is easy online:
                      </div>
                      <ul>
                        <li>Go to the official credit bureau website.</li>
                        <li>Choose to check your score.</li>
                        <li>Select a subscription if necessary.</li>
                        <li>Provide identification details.</li>
                        <li>
                          Pay if required, then check your email for your score.
                        </li>
                      </ul>
                      <div className="content-head">
                        Your credit score is key to getting loans and credit
                        cards:
                      </div>
                      <ul>
                        <li>
                          Lenders first look at your score. Below 750, and you
                          might be overlooked.
                        </li>
                        <li>
                          A higher score equals better chances of approval.
                        </li>
                        <li>
                          Remember, your score isn't everything. Lenders also
                          consider your income, job history, and profession.
                        </li>
                      </ul>
                      <p>
                        In the U.S., credit bureaus gather your financial data
                        and provide it to lenders in the form of a credit score,
                        which gauges your credit reliability.
                      </p>
                      <div className="content-head">
                        Here are the main U.S. credit bureaus:
                      </div>
                      <p>
                        <strong>TransUnion:</strong> It serves both individual
                        consumers and businesses, offering credit scores
                        typically ranging from 300 to 850. A score of 720 or
                        above is considered excellent. TransUnion is associated
                        with many banks, lenders, and financial institutions.
                      </p>
                      <p>
                        <strong>Equifax:</strong> In addition to credit scores,
                        Equifax provides risk assessments for individuals, with
                        scores generally from 280 to 850. As a registered credit
                        agency since 1899, Equifax offers services like fraud
                        prevention and portfolio management.
                      </p>
                      <p>
                        <strong>Experian:</strong> Since 1996, Experian has been
                        analyzing creditworthiness for individuals and
                        businesses, with scores from 300 to 850. It usually
                        takes a few days to get a report.
                      </p>
                      <p>
                        <strong>FICO:</strong> Though not a traditional credit
                        bureau, FICO scores are widely used by lenders in the
                        U.S. The FICO score ranges from 300 to 850, and a high
                        score indicates lower credit risk.
                      </p>
                      <p>
                        These bureaus play a crucial role in financial decisions
                        by providing credit information that lenders use to
                        determine loan and credit eligibility.
                      </p>

                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th width="50%">credit report</th>
                              <th>credit score</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>a detailed report on your credit history.</td>
                              <td>a 3-digit summary of your credit report.</td>
                            </tr>
                            <tr>
                              <td>serves as a credit reference.</td>
                              <td>measures credit risk from your report.</td>
                            </tr>
                            <tr>
                              <td>a separate document.</td>
                              <td>based on credit report data.</td>
                            </tr>
                            <tr>
                              <td>
                                includes loan amounts, payment history, etc.
                              </td>
                              <td>
                                reflects creditworthiness; 670+ is typically
                                good.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                equifax, experian, transunion are credit bureaus
                                in the u.s.
                              </td>
                              <td>banks may use scores like fico.</td>
                            </tr>
                            <tr>
                              <td>
                                consumers can get reports from these bureaus.
                              </td>
                              <td>bureaus and lenders provide scores.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="content-head">
                        Here's a straightforward rundown of credit score
                        essentials for U.S. consumers:
                      </div>

                      <p>
                        <strong>Checking Your Credit Score:</strong> Annually,
                        you're entitled to one free credit report from each of
                        the big three bureaus - Equifax, Experian, and
                        TransUnion - via AnnualCreditReport.com or by calling
                        1-877-322-8228.
                      </p>

                      <p>
                        <strong>Factors Impacting Your Credit Score:</strong>{" "}
                        Your score is determined by your credit history, credit
                        inquiries, and your track record of repayments.
                      </p>

                      <p>
                        <strong>How to Check:</strong> Easily check your score
                        on credit websites or apps like Credit Karma. Enter
                        basic details like your name and contact info, and get
                        your report by email or text.
                      </p>

                      <p>
                        <strong>Is 786 Good?:</strong> Absolutely, 786 is a
                        great score! You're close to the 'excellent' range of
                        800-850.
                      </p>

                      <p>
                        <strong>Paying Off Credit Cards:</strong> Best to pay in
                        full to avoid interest.
                      </p>

                      <p>
                        <strong>Paying More Than Your Balance:</strong> This
                        could leave a credit on your account, meaning the credit
                        company owes you.
                      </p>

                      <p>
                        <strong>Credit Score Access:</strong> Only entities with
                        a legitimate reason, like lenders, can access your
                        score.
                      </p>

                      <p>
                        <strong>Impact on Loans/Credit Cards:</strong> Banks
                        always check your score when you apply for credit.
                      </p>

                      <p>
                        <strong>Minimum Scores for Loans:</strong> A score above
                        750 is ideal for loan applications.
                      </p>

                      <p>
                        <strong>Minimum for Personal Loans:</strong> Again, 750
                        or above is preferred by banks and finance companies.
                      </p>

                      <p>
                        <strong>Errors in Your Credit Report:</strong> If you
                        spot errors, ask the credit bureau to correct them to
                        potentially raise your score.
                      </p>

                      <p>
                        <strong>Can Bureaus Alter Your Info?:</strong> No, they
                        report info as provided by financial institutions.
                      </p>

                      <p>
                        <strong>SSN for Credit Checks?:</strong> It's a common
                        form of identification but not the only one.
                      </p>

                      <p>
                        <strong>Why a Phone Number?:</strong> It's used for
                        identity verification.
                      </p>

                      <p>
                        <strong>Frequency of Checks:</strong> Check your score
                        as often as you like; there's no cap.
                      </p>

                      <p>
                        <strong>Credit Card Applications:</strong> Aim for a
                        score of 750+.
                      </p>

                      <p>
                        <strong>Checking Score with SSN:</strong> you'd use your
                        SSN or other ID forms.
                      </p>

                      <p>
                        <strong>Does Checking Lower Your Score?:</strong>{" "}
                        Self-checks don't affect your score, but lender checks
                        might.
                      </p>

                      <p>
                        <strong>How to Check in the U.S.:</strong> Visit sites
                        of credit bureaus or use authorized apps and tools.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <FaqsSection />
      </main>
    </>
  );
};

export default CheckCreditScoreMain;
