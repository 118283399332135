import React from "react";
import Form from "react-bootstrap/Form";
import { HashLink } from "react-router-hash-link";

/* comp imports */
import FaqsSection from "./FaqsSection";

/* img imports */

const BuildCreditHistoryMain = () => {
  return (
    <>
      <main>
        <section className="comm-section white-color-section">
          <div className="shape"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        No Credit Score History? Here's How to Build One
                      </h3>

                      {/* <div className="section-content pay-page">

                      </div> */}
                      <button className="starry-btn me-3" type="button">
                        <span className="btn-text">
                          <HashLink
                            smooth
                            to="/newsroom/check-credit-score/no-credit-score-history-heres-how-to-build-one#buildCreditScoreContent"
                          >
                            learn more
                          </HashLink>
                        </span>
                        <div id="container-stars">
                          <div id="stars"></div>
                        </div>

                        <div id="glow">
                          <div className="circle"></div>
                          <div className="circle"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-6"></div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section main */}

        <section
          className="content-section white-color-section"
          id="buildCreditScoreContent"
        >
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center flex-wrap">
                    <div className="section-content-wrapper">
                      <p>
                        having a strong credit history and score is key to
                        getting approved for credit cards and loans. starting
                        your credit journey might seem tough, but with the right
                        steps, you can establish a credit history that will set
                        you up for financial success.
                      </p>

                      <div classname="content-head">
                        what's a credit history?
                      </div>
                      <p>
                        your credit history is a record of your financial
                        habits, showing lenders how you've managed loans and
                        bills. it includes your on-time payments, debts, credit
                        applications, and any missed payments. lenders use this
                        info to judge if you're likely to repay future debts.
                      </p>

                      <div classname="content-head">
                        why you need good credit
                      </div>
                      <p>
                        without a credit history, lenders can't gauge your
                        trustworthiness, which could lead to rejected
                        applications. a good credit score can save you money on
                        interest and help you get approved for credit with
                        favorable terms.
                      </p>

                      <div classname="content-head">
                        building credit from square one
                      </div>
                      <p>
                        starting out without credit can be tricky. here's how to
                        build a good credit history from scratch:
                      </p>

                      <p>
                        <strong>start with a secured credit card:</strong> these
                        require a cash deposit that typically serves as your
                        credit limit. they're easier to get without prior credit
                        and can help you demonstrate responsible use.
                      </p>

                      <p>
                        <strong>space out credit applications:</strong> applying
                        for many cards can hurt your budding score. research the
                        best card for you and apply every six months or so.
                      </p>

                      <p>
                        <strong>pay bills on time:</strong> set up auto-pay to
                        never miss a payment. your payment history has a big
                        impact on your score.
                      </p>

                      <p>
                        <strong>transition to an unsecured card:</strong> after
                        building a history with a secured card, apply for an
                        unsecured card, which doesn't require a deposit and
                        often offers better benefits.
                      </p>

                      <p>
                        <strong>save regularly:</strong> showing a stable
                        savings pattern can make you more attractive to lenders,
                        especially when you don't have a credit history yet.
                      </p>

                      <p>
                        building credit takes time—often six months or
                        more—because the credit bureaus need enough data to
                        create your report. stay patient and keep up good
                        habits, and you'll set yourself up for financial
                        success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section form */}

        <FaqsSection />
      </main>
    </>
  );
};

export default BuildCreditHistoryMain;
