import React from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../../assets/img/img-line-main-2.png";

/* comp imports */
import MainWrapper from "../MainWrapper";
import MainCareerNavbar from "./MainCareerNavbar";
import Footer from "../Footer";
import FooterCareer from "./FooterCareer";
import CareerForm from "./CareerForm";

const IosDeveloper = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        ios developer
                      </h3>
                      <div className="section-content">
                        <span className="comp-name">ChaChing</span> is
                        revolutionizing the way people manage their finances by
                        offering a payment processing platform that rewards
                        users for their responsible financial behavior. We are
                        seeking a talented iOS Developer to join our team and
                        play a crucial role in developing our mobile application
                        to provide seamless and rewarding financial experiences
                        to our users.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section white-color-section speckle-bg career-details-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 mb-5 mb-md-1">
                    <div className="section-content-wrapper">
                      <div className="content-head mt-0">responsibilities</div>

                      <ul>
                        <li>
                          Collaborate with product managers, designers, and
                          other developers to design, develop, and deploy
                          innovative features for the{" "}
                          <span className="comp-name">ChaChing</span> iOS
                          application.
                        </li>
                        <li>
                          Implement secure and efficient payment processing
                          functionalities within the app, adhering to industry
                          standards and best practices.
                        </li>
                        <li>
                          Integrate gamification elements and reward mechanisms
                          into the app to encourage and incentivize users for
                          responsible financial habits.
                        </li>
                        <li>
                          Ensure the app meets high standards of performance,
                          reliability, and user experience, addressing any
                          issues or bugs promptly.
                        </li>
                        <li>
                          Stay updated with the latest iOS development trends,
                          technologies, and best practices to continuously
                          improve the app's functionality and user engagement.
                        </li>
                        <li>
                          Work closely with backend developers to integrate
                          frontend functionalities with backend systems, APIs,
                          and databases.
                        </li>
                        <li>
                          Conduct thorough testing of the app across different
                          iOS devices and versions to ensure compatibility and
                          optimal performance.
                        </li>
                        <li>
                          Collaborate with cross-functional teams to define and
                          prioritize features, and contribute to the overall
                          product roadmap.
                        </li>
                        <li>
                          Maintain code quality, documentation, and version
                          control using Git or other relevant tools.
                        </li>
                        <li>
                          Participate in code reviews, discussions, and
                          knowledge-sharing sessions to foster a collaborative
                          and innovative development culture.
                        </li>
                      </ul>

                      <div className="content-head mt-0">requirements</div>
                      <ul>
                        <li>
                          Bachelor's degree in Computer Science, Engineering, or
                          a related field (or equivalent work experience).
                        </li>
                        <li>
                          Proven experience in iOS app development, with a
                          strong portfolio of iOS apps published on the App
                          Store.
                        </li>
                        <li>
                          Proficiency in Swift and/or Objective-C programming
                          languages, with a solid understanding of iOS SDK,
                          UIKit, and other relevant frameworks.
                        </li>
                        <li>
                          Experience working with RESTful APIs, JSON, and other
                          web services to integrate backend functionalities into
                          iOS applications.
                        </li>
                        <li>
                          Knowledge of secure coding practices and familiarity
                          with encryption, authentication, and data protection
                          techniques.
                        </li>
                        <li>
                          Strong problem-solving skills and the ability to
                          analyze complex technical challenges and propose
                          effective solutions.
                        </li>
                        <li>
                          Excellent communication skills and the ability to
                          collaborate effectively with team members from diverse
                          backgrounds.
                        </li>
                        <li>
                          Passion for financial technology (FinTech) and a
                          genuine interest in promoting financial literacy and
                          responsible financial behavior among users.
                        </li>
                      </ul>
                      <div className="content-head mt-0">
                        Preferred Qualifications
                      </div>
                      <ul>
                        <li>
                          Experience with mobile payment processing, financial
                          services, or related domains.
                        </li>
                        <li>
                          Familiarity with gamification techniques, behavioral
                          economics, or incentive-based reward systems.
                        </li>
                        <li>
                          Experience with automated testing frameworks,
                          continuous integration/delivery (CI/CD) pipelines, and
                          DevOps practices.
                        </li>
                        <li>
                          Knowledge of SwiftUI, Combine framework, or other
                          modern iOS development tools and techniques.
                        </li>
                        <li>
                          Contributions to open-source projects, participation
                          in developer communities, or attendance at iOS-related
                          conferences or events.
                        </li>
                      </ul>
                      <p>
                        Join <span className="comp-name">ChaChing</span> and be
                        part of a dynamic team that is empowering users to take
                        control of their finances and be rewarded for their
                        financial responsible behavior. If you are passionate
                        about iOS app development and eager to make a positive
                        impact in the FinTech industry, apply now and help us
                        build a brighter financial future for everyone!
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="section-content-wrapper">
                      <CareerForm />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default IosDeveloper;
