import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { baseUrl, endPoints } from "../data/endPoints";

/* img imports */
import imgLineMain from "../assets/img/img-line-main.png";

const ContactSales = () => {
  const [contactSalesMainData, setContactSalesMainData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showThankyou, setShowThankyou] = useState(false);

  const contactSalesMainDataDefault = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    companyName: "",
    businessType: "",
    companyWebsite: "",
    message: "",
  };

  useEffect(() => {
    setContactSalesMainData(contactSalesMainDataDefault);
  }, []);

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setContactSalesMainData((contactSalesMainData) => ({
      ...contactSalesMainData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;
    return regex.test(email);
  };

  const validateMobileNumber = (number) => {
    let numRegex = new RegExp(/^\d{10}$/);
    return numRegex.test(number);
  };

  let config = {
    method: "post",
    url: `${baseUrl}${endPoints.contactSales}`,
    headers: {},
    data: contactSalesMainData,
  };

  const contactSalesForm = async () => {
    try {
      setErrorMessage("");
      if (
        contactSalesMainData.firstName != "" &&
        contactSalesMainData.lastName != "" &&
        contactSalesMainData.phone != "" &&
        contactSalesMainData.email != "" &&
        contactSalesMainData.companyName != "" &&
        contactSalesMainData.message != ""
      ) {
        let numberValidated = validateMobileNumber(contactSalesMainData.phone);
        if (numberValidated) {
          let emailValidated = validateEmail(contactSalesMainData.email);
          if (emailValidated) {
            const response = await axios.request(config);
            if (response && response.data.status) {
              setShowThankyou(true);
              setContactSalesMainData(contactSalesMainDataDefault);
            } else {
              console.log(response.data.message);
              setErrorMessage("Please try again later");
            }
          } else {
            setErrorMessage("Please enter a valid email id");
          }
        } else {
          setErrorMessage("phone number should be a 10 digit number");
        }
      } else {
        setErrorMessage("Please enter all required fields");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Please try again later");
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    contactSalesForm();
  };

  // console.log("contactSalesMainData", contactSalesMainData);

  return (
    <>
      <main>
        <section className="content-section white-color-section extra-section-margin extra-section-margin-bott extra-section-padding extra-section-padding-bott speckle-bg">
          <div className="shape right"></div>
          <img src={imgLineMain} alt="" className="img-line" />
          <div className="container">
            <div className="row align-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 col-xl-6 offset-md-2 offset-xl-3 d-flex align-items-center flex-wrap text-center">
                    <div className="section-content-wrapper">
                      <div className="section-heading pay-page mb-1">
                        contact sales
                      </div>
                      <div className="form-wrapper">
                        <div className="form-title"></div>
                        <div className="form-sub-title">
                          Make checkout easy for your customers by accepting
                          multiple currencies. Let them pay their way, and get
                          your payments in the currency you prefer. Talk to us
                          about adding ChaChing to your site today!
                        </div>
                        <span className="error-text">{errorMessage}</span>
                        {showThankyou ? (
                          <>
                            <h3 className="mt-5">
                              thank you for contacting our sales team.
                            </h3>
                            <h4 className="mb-5">
                              we will get back to you shortly!
                            </h4>
                          </>
                        ) : (
                          <form onSubmit={handleFormSubmit}>
                            <div className="form-group-wrapper d-flex">
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="text"
                                  placeholder="first name*"
                                  required
                                  name="firstName"
                                  onChange={inputsHandler}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="text"
                                  placeholder="last name*"
                                  required
                                  name="lastName"
                                  onChange={inputsHandler}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="text"
                                  placeholder="phone number*"
                                  required
                                  name="phone"
                                  onChange={inputsHandler}
                                  maxLength={10}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="email"
                                  placeholder="email address*"
                                  required
                                  name="email"
                                  onChange={inputsHandler}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="text"
                                  placeholder="company name*"
                                  required
                                  name="companyName"
                                  onChange={inputsHandler}
                                />
                              </Form.Group>
                              <Form.Select
                                className="mb-3 main-form-select"
                                name="businessType"
                                required
                                onChange={inputsHandler}
                              >
                                <option value="">select business type*</option>
                                <option value="centralized exchange">
                                  centralized exchange
                                </option>
                                <option value="dao">dao</option>
                                <option value="decentralized exchange">
                                  decentralized exchange
                                </option>
                                <option value="defi">defi</option>
                                <option value="e-commerce">e-commerce</option>
                                <option value="forex">forex</option>
                                <option value="gambling">gambling</option>
                                <option value="gaming">gaming</option>
                                <option value="other">other</option>
                                <option value="primary nft marketplace">
                                  primary nft marketplace
                                </option>
                                <option value="retail">retail</option>
                                <option value="secondary nft marketplace">
                                  secondary nft marketplace
                                </option>
                                <option value="wallet">wallet</option>
                              </Form.Select>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  type="url"
                                  placeholder="company website"
                                  maxLength={100}
                                  name="companyWebsite"
                                  onChange={inputsHandler}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3 main-form-group w-100">
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  placeholder="let us know how to assist, so we can get the right person on it*."
                                  required
                                  name="message"
                                  onChange={inputsHandler}
                                />
                              </Form.Group>
                            </div>

                            <div className="form-sub-title mt-3">
                              Don't share private info (credit card, social
                              security numbers, passwords) here.
                            </div>

                            <div>
                              <button className="starry-btn mt-3" type="submit">
                                <span className="btn-text">submit</span>
                                <div id="container-stars">
                                  <div id="stars"></div>
                                </div>

                                <div id="glow">
                                  <div className="circle"></div>
                                  <div className="circle"></div>
                                </div>
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section form */}
      </main>
    </>
  );
};

export default ContactSales;
