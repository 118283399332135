import bitcoinIcon from "../assets/img/currency-icons/bitcoinIcon.svg";
import desoIcon from "../assets/img/currency-icons/desoIcon.svg";
import dirhamIcon from "../assets/img/currency-icons/dirhamIcon.svg";
import dollarIcon from "../assets/img/currency-icons/dollarIcon.svg";
import ethIcon from "../assets/img/currency-icons/ethIcon.svg";
import nearIcon from "../assets/img/currency-icons/nearIcon.svg";
import poundIcon from "../assets/img/currency-icons/poundIcon.svg";
import rupeeIcon from "../assets/img/currency-icons/rupeeIcon.svg";
import solanaIcon from "../assets/img/currency-icons/solanaIcon.svg";
import rippleIcon from "../assets/img/currency-icons/rippleIcon.svg";
import dodgecoinIcon from "../assets/img/currency-icons/dodgecoinIcon.svg";

export const currencyIcons = {
  bitcoinIcon: bitcoinIcon,
  desoIcon: desoIcon,
  dirhamIcon: dirhamIcon,
  dollarIcon: dollarIcon,
  ethIcon: ethIcon,
  nearIcon: nearIcon,
  poundIcon: poundIcon,
  rupeeIcon: rupeeIcon,
  solanaIcon: solanaIcon,
  rippleIcon: rippleIcon,
  dodgecoinIcon: dodgecoinIcon,
};
