import React, { useState, useEffect, useRef } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useCountUp } from "react-countup";
import { getRandomNumber } from "../../data/custom";

/* img imports */

import coinIcon from "../../assets/img/coin-icon-small.png";
import currencyIcon from "../../assets/img/currency-icon-small.png";
import voucherIcon from "../../assets/img/voucher-icon-small.png";

const PixelBrainQuestion = ({
  questionAnswer,
  setQuestionNum,
  questionNum,
  handleGameStart,
  setIsRightAnswer,
  isRightAnswer,
  isResettingGame,
  setIsResettingGame,
  updatePbCoins,
  updatePbMoneyback,
  setActiveArr,
}) => {
  // console.log("questionAnswer", questionAnswer);

  const [answersArr, setAnswersArr] = useState([]);
  const [shuffledAnswersArr, setShuffledAnswersArr] = useState([]);
  const [totalRightAnswers, setTotalRightAnswers] = useState(0);
  const [winMessage, setWinMessage] = useState("");

  const countUpRef = useRef(null);

  const { start, reset } = useCountUp({
    ref: countUpRef,
    start: 0.15,
    end: 0,
    duration: 15,
    delay: 0,
    decimals: 2,
    useEasing: false,
    onEnd: () => counterEnded(),
  });

  useEffect(() => {
    if (
      questionAnswer.incorrect_answers.length &&
      questionAnswer.correct_answer !== ""
    ) {
      let tempArr = [
        ...questionAnswer.incorrect_answers,
        questionAnswer.correct_answer,
      ];
      setAnswersArr(tempArr);
    }
    start();
  }, [questionAnswer]);

  const shuffleArr = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    if (answersArr.length) {
      let newAnswers = shuffleArr(answersArr);
      if (newAnswers.length) {
        setShuffledAnswersArr(newAnswers);
      }
    }
  }, [answersArr]);

  const htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

  const handleGetSolution = (e, answer) => {
    let elem = e.target;
    if (answer !== "") {
      if (answer === htmlDecode(questionAnswer.correct_answer)) {
        elem.style.backgroundColor = "#85bb65";
        setTimeout(() => {
          setQuestionNum((prevValue) => {
            return Number(prevValue) + 1;
          });
          setTotalRightAnswers((prevAns) => {
            return Number(prevAns) + 1;
          });
          elem.style.backgroundColor = "transparent";

          reset();
        }, 300);
      } else {
        elem.style.backgroundColor = "#92131a";
        setTimeout(() => {
          setIsRightAnswer(false);
          setIsResettingGame(true);
          elem.style.backgroundColor = "transparent";
        }, 300);
      }
    }
  };

  const resetGame = () => {
    setIsResettingGame(false);
    setIsRightAnswer(false);
    setTotalRightAnswers(0);
    handleGameStart();
    setTimeout(() => {
      start();
    }, 5000);
  };

  const assignReward = () => {
    if (totalRightAnswers >= 3 && totalRightAnswers < 6) {
      let randomCoin = getRandomNumber(1500, 2000);
      console.log("randomCoin", randomCoin);
      // setWinMessage(`you won ${randomCoin} chaching coins`);
      updatePbCoins(randomCoin);
    } else if (totalRightAnswers >= 6 && totalRightAnswers < 10) {
      setWinMessage("you won a voucher");
      console.log("you won a voucher");
    } else if (totalRightAnswers == 10) {
      setWinMessage("you won moneyback");
      console.log("you won a moneyback");
      let randomAmount = getRandomNumber(75, 100);
      console.log("randomAmount", randomAmount);
      updatePbMoneyback(randomAmount);
    } else {
      setWinMessage("");
      let randomCoin = getRandomNumber(100, 750);
      console.log("randomCoin", randomCoin);
      updatePbCoins(randomCoin);
    }
    setTimeout(() => {
      setActiveArr([true, true, true, true]);
    }, 2000);
  };

  // console.log("totalRightAnswers", totalRightAnswers, typeof totalRightAnswers);

  const counterEnded = () => {
    setTimeout(() => {
      setIsRightAnswer(false);
      setIsResettingGame(true);
    }, 300);
  };

  useEffect(() => {
    if (isResettingGame) {
      assignReward();
    }
  }, [isResettingGame]);

  useEffect(() => {
    if (totalRightAnswers === 10) {
      assignReward();
    }
  }, [totalRightAnswers]);

  return (
    <>
      <div className="progress-bar-wrapper">
        <ProgressBar now={totalRightAnswers * 10} />
        <div className="question-num">
          {Number(questionNum) !== 10 ? Number(questionNum) + 1 : 10}
        </div>
        <div className="gifts-wrapper">
          <span>
            <img
              src={coinIcon}
              alt=""
              className={`${
                totalRightAnswers >= 3 &&
                totalRightAnswers < 5 &&
                "no-grayscale"
              }`}
            />
          </span>
          <span>
            <img
              src={voucherIcon}
              alt=""
              className={`${
                totalRightAnswers >= 6 &&
                totalRightAnswers < 10 &&
                "no-grayscale"
              }`}
            />
          </span>
          <span>
            <img
              src={currencyIcon}
              alt=""
              className={`${totalRightAnswers == 10 && "no-grayscale"}`}
            />
          </span>
        </div>
      </div>
      <div
        className={`pixel-brain-wrapper ${
          isRightAnswer && totalRightAnswers != 10 ? "d-block" : "d-none"
        }`}
      >
        <div className="counter-wrapper">
          <div ref={countUpRef} className="counter-text" />
        </div>
        <div className="pixel-brain-question">
          {questionAnswer.question != "" && htmlDecode(questionAnswer.question)}
        </div>
        <div className="pixel-brain-answers-wrapper">
          <div className="row">
            {shuffledAnswersArr.length &&
              shuffledAnswersArr.map((val, index) => {
                return (
                  <div className="col-12 col-md-6 answer-block-col" key={index}>
                    <div
                      className="answer-block"
                      onClick={(e) => {
                        handleGetSolution(e, htmlDecode(val));
                      }}
                    >
                      {htmlDecode(val)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div
        className={`text-center game-btn-wrapper inside ${
          isResettingGame ? "d-block" : "d-none"
        }`}
      >
        <div className="win-message">{winMessage}</div>
        <div className="incorrect-answer">
          <span>uh oh!</span> game over
        </div>
        <button onClick={resetGame}>
          <span> restart </span>
        </button>
        <span className="game-price">1000</span>
      </div>

      <div
        className={`text-center game-btn-wrapper inside ${
          totalRightAnswers === 10 ? "d-block" : "d-none"
        }`}
      >
        <div className="win-message">{winMessage}</div>
        <div className="incorrect-answer all-correct-answers">
          {/* <span>Bravo!</span> all correct answers */}
        </div>
        <button onClick={resetGame}>
          <span> restart </span>
        </button>
        <span className="game-price">1000</span>
      </div>
    </>
  );
};

export default PixelBrainQuestion;
