import React from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

/* img imports */
import imgLineMain2 from "../../assets/img/img-line-main-2.png";

/* comp imports */
import MainWrapper from "../MainWrapper";
import MainCareerNavbar from "./MainCareerNavbar";
import Footer from "../Footer";
import FooterCareer from "./FooterCareer";
import CareerForm from "./CareerForm";

const PublicRelationshipManager = () => {
  const navigate = useNavigate();

  return (
    <>
      <main>
        <section className="content-section extra-section-margin white-color-section speckle-bg">
          <div className="shape right"></div>
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 d-flex align-items-center">
                    <div className="section-content-wrapper">
                      <h3 className="section-heading pay-page">
                        public relationship manager
                      </h3>
                      <div className="section-content">
                        as the pr manager at{" "}
                        <span className="comp-name">ChaChing</span>, you will be
                        responsible for developing and executing strategic
                        public relations initiatives to enhance the company's
                        brand awareness and reputation. You will work closely
                        with internal stakeholders and external partners to
                        craft compelling narratives, secure media coverage, and
                        manage communications in alignment with{" "}
                        <span className="comp-name">ChaChing</span>'s goals and
                        values.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}

        <section className="content-section white-color-section speckle-bg career-details-section">
          <img src={imgLineMain2} alt="" className="img-line-2" />
          <div className="container">
            <div className="row align-content-start">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 mb-5 mb-md-1">
                    <div className="section-content-wrapper">
                      <div className="content-head mt-0">responsibilities</div>

                      <ul>
                        <li>
                          Develop and implement comprehensive PR strategies to
                          increase <span className="comp-name">ChaChing</span>
                          's visibility and credibility in the market.
                        </li>
                        <li>
                          cultivate relationships with media outlets,
                          journalists, and industry influencers to secure press
                          coverage and feature opportunities.
                        </li>
                        <li>
                          write and distribute press releases, media pitches,
                          and other materials to communicate key messages and
                          company news effectively.
                        </li>
                        <li>
                          coordinate media interviews, press briefings, and
                          speaking engagements for company executives and
                          spokespersons.
                        </li>
                        <li>
                          monitor and analyze media coverage, industry trends,
                          and competitor activities to identify opportunities
                          and inform pr strategies.
                        </li>
                        <li>
                          manage crisis communications and reputation management
                          efforts, ensuring timely and transparent responses to
                          issues or challenges.
                        </li>
                        <li>
                          collaborate with cross-functional teams, including
                          marketing, sales, and product development, to align pr
                          initiatives with overall business objectives.
                        </li>
                        <li>
                          plan and execute events, such as product launches,
                          press conferences, and industry conferences, to engage
                          stakeholders and promote{" "}
                          <span className="comp-name">ChaChing</span>'s brand.
                        </li>
                      </ul>

                      <div className="content-head mt-0">requirements</div>
                      <ul>
                        <li>
                          bachelor's degree in public relations, communications,
                          journalism, or related field (master's degree
                          preferred).
                        </li>
                        <li>
                          proven experience in public relations, corporate
                          communications, or related roles, preferably in the
                          fintech or technology industry.
                        </li>
                        <li>
                          excellent writing, editing, and storytelling skills,
                          with the ability to craft compelling narratives and
                          press materials.
                        </li>
                        <li>
                          strong media relations skills, with a track record of
                          securing high-impact press coverage and building
                          relationships with key journalists.
                        </li>
                        <li>
                          strategic thinker with the ability to develop and
                          execute pr plans that align with business objectives
                          and target audience interests.
                        </li>
                        <li>
                          crisis management expertise, including experience in
                          managing sensitive issues and mitigating reputational
                          risks.
                        </li>
                        <li>
                          exceptional interpersonal and communication skills,
                          with the ability to collaborate effectively with
                          internal and external stakeholders.
                        </li>
                        <li>
                          creative thinker with a proactive and results-driven
                          approach to pr initiatives.
                        </li>
                        <li>
                          proficiency in media monitoring and measurement tools,
                          as well as familiarity with social media platforms and
                          digital pr strategies.
                        </li>
                      </ul>
                      <p>
                        join <span className="comp-name">ChaChing</span>'s
                        dynamic team and play a key role in shaping the
                        company's reputation and success through strategic pr
                        initiatives!
                      </p>
                      <p>
                        to apply, please submit your resume, cover letter, and
                        portfolio showcasing your pr achievements and
                        capabilities.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="section-content-wrapper">
                      <CareerForm />
                    </div>
                  </div>
                </div>
              </div>
              {/* end of main col */}
            </div>
            {/* end of main row */}
          </div>
        </section>
        {/* end of section content */}
      </main>
      {/* <CareersMain /> */}
    </>
  );
};

export default PublicRelationshipManager;
