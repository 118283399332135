import React, { useState, useEffect, useContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { CartContext } from "../../context/CartContextProvider";

/* img imports */
import dollarIcon from "../../assets/img/currency-icons/dollarIcon.svg";
import sampleImg from "../../assets/img/sample-img.jpg";

const ProductDetails = ({ productDetailsData, desoRate, selCartRandomNum }) => {
  const { getIsAdded, dispCartNumber, addToCartHandler, btnVariants } =
    useContext(CartContext);
  const [imgBaseUrl, setImgBaseUrl] = useState(
    "https://www.everythingstore.io/static/www/images/productImages/1000x1000/"
  );
  const [productImages, setProductImages] = useState([]);
  const [mainImageUrl, setMainImageUrl] = useState("");
  const [mainImageId, setMainImageId] = useState(0);
  const [isAdded, setIsAdded] = useState(false);

  // console.log("productDetailsData", productDetailsData);

  useEffect(() => {
    const timeout = setTimeout(window.scrollTo(0, 0), 200);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const { id, offerprice, productName, description, stagSkuImagesBeans } =
    productDetailsData;

  useEffect(() => {
    if (description) {
      document.getElementById("detailsTabContent").innerHTML = description;
    }
  }, [description]);

  useEffect(() => {
    if (stagSkuImagesBeans && stagSkuImagesBeans.length) {
      setProductImages(stagSkuImagesBeans);
    }
  }, [stagSkuImagesBeans]);

  useEffect(() => {
    if ((dispCartNumber, id)) {
      if (getIsAdded(id)) {
        setIsAdded(true);
      }
    }
  }, [dispCartNumber, id]);

  useEffect(() => {
    if (productImages.length) {
      setMainImageUrl(`${imgBaseUrl}${productImages[mainImageId].imageName}`);
    }
  }, [productImages, mainImageId]);

  return (
    <>
      <div className="row product-details-row">
        <div className="col-12 col-lg-6">
          <div className="product-gallery-wrapper">
            <div className="main-image">
              <picture>
                {productImages.length && (
                  <img src={mainImageUrl && mainImageUrl} alt="" />
                )}
              </picture>
            </div>
            <div className="thumb-wrapper scrollbar-success">
              {productImages.length > 1
                ? productImages.map((val, ind) => (
                    <span
                      className="img-thumb"
                      key={val.id}
                      onClick={() => {
                        setMainImageId(ind);
                      }}
                    >
                      <picture>
                        <img src={`${imgBaseUrl}${val.imageName}`} alt="" />
                      </picture>
                    </span>
                  ))
                : ""}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
          <div className="product-details-wrapper">
            <div className="product-name">{productName && productName}</div>
            <div className="product-price">
              <img src={dollarIcon} alt="" />
              <span className="prod-price">
                {(desoRate * offerprice).toFixed(2)}
              </span>
            </div>
            <div className="add-to-cart-wrapper">
              <button
                type="button"
                className={`prod-cart ${
                  btnVariants.length && btnVariants[selCartRandomNum]
                } ${isAdded ? "variant-added disabled" : ""}`}
                data-text={isAdded ? "Added" : "Add to Cart"}
                onClick={(e) => {
                  addToCartHandler(e, id, offerprice);
                }}
              ></button>
            </div>
          </div>
          <div className="product-more-details">
            <div className="shopping-tab-section product-details-tab-section">
              <Tabs
                defaultActiveKey="description"
                id="productMoreDetailsTabs"
                className="mt-2"
              >
                <Tab eventKey="description" title="Description">
                  <div
                    className="details-tab-content"
                    id="detailsTabContent"
                  ></div>
                </Tab>

                <Tab eventKey="specifications" title="Specifications">
                  <div className="details-tab-content">
                    no specification details available for this product.
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
