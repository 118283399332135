import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

/* img imports */
import mainLogo from "../../assets/img/logo/main-logo-site.png";
import mainLogoCareers from "../../assets/img/logo/main-logo-site-career.png";
import userIcon from "../../assets/img/icons/user-icon.png";
import confettiAnimation from "../../assets/img/confetti-animation.gif";
import closeIcon from "../../assets/img/close-icon.svg";

const MainCareerNavbar = () => {
  const [isMenuCareerActive, setIsMenuCareerActive] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleHomePageRedirect = () => {
    navigate("/careers");
  };

  const handleShowCareerMenu = (e) => {
    let elem = e.target;
    let menuIconIsChecked = elem.checked;
    if (menuIconIsChecked) {
      setIsMenuCareerActive(true);
    } else {
      setIsMenuCareerActive(false);
    }
  };

  useEffect(() => {
    setIsMenuCareerActive(false);
    let hamburgerIcon = document.querySelector("#mobileHamburgerIcon");
    if (hamburgerIcon) {
      hamburgerIcon.checked = false;
    }
  }, [location]);

  useEffect(() => {
    let body = document.getElementsByTagName("body")[0];
    if (isMenuCareerActive) {
      body.classList.add("body-no-scroll");
      //   body.classList.add("body-iphone-no-scroll");
    } else {
      body.classList.remove("body-no-scroll");
      //   body.classList.remove("body-iphone-no-scroll");
    }
  }, [isMenuCareerActive]);

  window.onscroll = function (e) {
    // print "false" if direction is down and "true" if up
    let menuBarCareerElem = document.getElementById("menuBarCareer");
    if (this.oldScroll > this.scrollY) {
      if (this.scrollY < 50) {
        if (menuBarCareerElem) {
          menuBarCareerElem.className = "site-header";
        }
      } else {
        if (menuBarCareerElem) {
          menuBarCareerElem.className = "site-header sticky-header";
        }
      }
    } else {
      if (menuBarCareerElem) {
        menuBarCareerElem.className = "site-header";
      }
    }

    this.oldScroll = this.scrollY;
  };

  return (
    <>
      <header className="site-header" id="menuBarCareer">
        <div className="container">
          <div className="row">
            <div className="col-12 main-header-col">
              <span
                className="brand-img brand-img-careers"
                onClick={handleHomePageRedirect}
              >
                <img src={mainLogoCareers} alt="" />
              </span>

              <div className="desktop-menu d-none d-lg-flex">
                <div></div>
                <div>
                  <Link className="menu-bar-link" to="/careers/discover">
                    discover
                  </Link>
                  <Link className="menu-bar-link ms-4" to="/careers/perks">
                    perks
                  </Link>
                  <Link className="menu-bar-link ms-4" to="/careers/principles">
                    principles
                  </Link>
                  <Link className="menu-bar-link ms-4" to="/careers/insights">
                    insights
                  </Link>
                  <Link className="menu-bar-link ms-4" to="/careers/pride">
                    pride
                  </Link>
                </div>
              </div>

              <label className="hamburger d-lg-none">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleShowCareerMenu(e);
                  }}
                  id="mobileHamburgerIcon"
                />
                <svg viewBox="0 0 32 32">
                  <path
                    className="line line-top-bottom"
                    d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                  ></path>
                  <path className="line" d="M7 16 27 16"></path>
                </svg>
              </label>
            </div>
          </div>
        </div>
      </header>

      {isMenuCareerActive && (
        <div className="menu-bar-container">
          <div className="menu-bar-wrapper">
            <div className="menu-bar-front">
              <Link className="menu-bar-link" to="/careers/discover">
                discover
              </Link>
              <Link className="menu-bar-link" to="/careers/perks">
                perks
              </Link>
              <Link className="menu-bar-link" to="/careers/principles">
                principles
              </Link>
              <Link className="menu-bar-link" to="/careers/insights">
                insights
              </Link>
              <Link className="menu-bar-link" to="/careers/pride">
                pride
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainCareerNavbar;
